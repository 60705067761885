import React, {Component} from "react";
import {connect} from "react-redux";
import {addTrauerfeier,
       deleteTrauerfeier,
       caculateFinalPrice,
       selectSarg,
       deleteSarg,
       selectSargButton,
       onSubmitBouquet,
       onSubmitTrauerkranz,
       addBouquetBudget,
       addBouquetErsteSchleife,
       addBouquetZweiteSchleife,
       removefieldsBouquet,
       addTrauerkranzBudget,
       addTrauerkranzErsteSchleife,
       addTrauerkranzZweiteSchleife,
       removefieldsTrauerkranz,
       addFloristik,
       deleteFloristik,
       showModalTrauerfeier,
       hideModalTrauerfeier,
       hideModalProduct,
       selectUrne,
       deleteUrne,
       selectSeeurne,
       deleteSeeurne,
       changeBestattungsart,
       selectBiourne,
       deleteBiourne,
       closeModalSaerge,
       showModalSaerge,
       closeModalUrnen,
       showModalUrnen,
       closeModalSeeurnen,
       showSeeUrne,
       hideModalBegleiteteSeebestattung,
       showModalBegleiteteSeebestattung,
       addBegleiteteSeebstattung,
       deleteBegleiteteSeebestattung,
       updateBemerkungen,
       showAngebotModalIndividuell,
      } from "../redux";

import "./angebot.css";
import {alleSaerge} from "../data/saerge";
import {alleBlumen} from "../data/floristik";
import {urnenArray, seeUrneArray, bioUrneArray} from "../data/urnen";
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { FaCheckCircle } from "react-icons/fa";
import {ClipLoader} from "react-spinners";
import trauerfeierImg from "../../images/bestattungsfeier/trauerfeier.jpg";
import blumeAuswahlImg from "../../images/floristik/gesteck/ge_28.jpg";
import erdImg from "../../images/bestattungsarten/bg_erdbestattung.jpg";
import anonymImg from "../../images/bestattungsarten/bg_anonyme_bestattung.jpg";
import seeImg from "../../images/bestattungsarten/bg_seebestattung.jpg";
import friedwaldImg from "../../images/bestattungsarten/bg_friedwald.jpg";
import einfacheFeuerbestattungImg from "../../images/bestattungsarten/bg_einfache_feuerbestattung.jpg";
import begleitete_seebestattung from "../../images/begleitete_seebestattung/begleitete_seebestattung.jpg";
import {Redirect} from "react-router-dom";
import "./printAngebot.css";
import {IndAngebotModalContainer} from "./IndAngebotModalBox";
import { FaTh, FaFilePdf } from "react-icons/fa";



class Angebot extends Component{

  constructor(props) {
  super(props);
  this.filterAlleSaerge = this.filterAlleSaerge.bind(this);
  this.filterAlleBlumen = this.filterAlleBlumen.bind(this);
  this.closeModalFloristik = this.closeModalFloristik.bind(this);
  this.showModalFloristik = this.showModalFloristik.bind(this);
  this.filterUrnen = this.filterUrnen.bind(this);
  this.filterAlleUrnen = this.filterAlleUrnen.bind(this);
  this.closeModalBiournen = this.closeModalBiournen.bind(this);
  this.showModalBiournen = this.showModalBiournen.bind(this);
  this.toggleBestattungAuswahl = this.toggleBestattungAuswahl.bind(this);
  this.showBestattungen = this.showBestattungen.bind(this);
  this.showAngebotModalFunc = this.showAngebotModalFunc.bind(this);
  this.closeAngebotModal = this.closeAngebotModal.bind(this);
  this.closeAllBestattungsarten = this.closeAllBestattungsarten.bind(this);
  this.openAllBestattungsarten = this.openAllBestattungsarten.bind(this);


  this.state = {
                 trauerfallpreis: 499,
                 allSaerge: alleSaerge,
                 allFloristik: alleBlumen,
                 allUrnen: urnenArray,
                 totalprice: 0,
                 modalsShowFloristik: false,
                 modalShowBiournen: false,
                 loading: false,
                 seeUrneArray: seeUrneArray,
                 bioUrneArray: bioUrneArray,
                 bestattungAuswahlOpen: false,
                 collapseFeuer: false,
                 vollmachtImg: null,
                 logoImg: null,
                 vollmacht:  null,
                 showAngebotModal: false,
                 showAllBestattungsarten: false
               };
}

closeAllBestattungsarten(){
   this.setState({showAllBestattungsarten: false})
}

openAllBestattungsarten(){
  this.setState({showAllBestattungsarten: true})
}

showAngebotModalFunc(){
  console.log("hello box")
  this.setState({ showAngebotModal: true})
}

closeAngebotModal(){
  this.setState({ showAngebotModal: false})
}


fillterSaerge(produkt){
      this.setState({allSaerge: alleSaerge.filter(elem => elem.category === produkt)})
}

filterUrnen(produkt){
    this.setState({allUrnen: urnenArray.filter(elem => elem.category === produkt)})
    if(produkt === "bio"){
     this.setState({allUrnen: bioUrneArray})
    }
}

filterAlleUrnen(ev){
  let targetElementIdent = ev.target.getAttribute("ident");

     this.filterUrnen(targetElementIdent);
}

filterBlumen(produkt){
  this.setState({allFloristik: alleBlumen.filter(elem => elem.category === produkt)})
}

filterAlleBlumen(ev){
  let targetElementIdent = ev.target.getAttribute("ident");

  this.filterBlumen(targetElementIdent);
}

filterAlleSaerge(ev){
     let targetElementIdent = ev.target.getAttribute("ident");
     this.fillterSaerge(targetElementIdent);
}


closeModalFloristik(){
   this.setState({modalsShowFloristik: false})
}

showModalFloristik(){
   this.setState({modalsShowFloristik: true})

}


showModalBiournen(){
   this.setState({modalShowBiournen: true})
}

closeModalBiournen(){
   this.setState({modalShowBiournen: false})
}


toggleBestattungAuswahl() {
  if(this.state.collapseFeuer === true){
     this.setState(prevState => ({
      collapseFeuer: prevState.collapseFeuer = false
     }))
  }
  this.setState(prevState => ({
    bestattungAuswahlOpen: !prevState.bestattungAuswahlOpen,
  }));
}

showBestattungen() {
  this.setState(prevState => ({
    collapseFeuer: !prevState.collapseFeuer,
    bestattungAuswahlOpen: !prevState.bestattungAuswahlOpen
  }));
}

componentDidMount(){
      window.scrollTo(0, 0);
      this.props.caculateFinalPrice();

      this.setState({trauerfallpreis: this.props.trauerfeierPreis})

      /* change backgroundimage for angebotContainer */
      let angebotContainer = document.querySelector(".angebotContainer");
      switch(this.props.angebotArt){
         case "Erdbestattung":
            angebotContainer.classList.add("bgErd");
         break;
         case "Seebestattung":
            angebotContainer.classList.add("bgSee");
         break;
         case "Anonyme Bestattung":
            angebotContainer.classList.add("bgAnonym");
         break;
         case "Baumbestattung":
            angebotContainer.classList.add("bgFriedwald");
         break;
         case "Einfache Feuerbestattung":
            angebotContainer.classList.add("bgEinfacheFeuerbestattung");
         break;

         default:
           return;
      }

    }



    render(){


       let komma;
       if (this.props.floristikArray.length > 1) {
           komma = ", ";
       }

       return(
            <div className="container my-4 pb-3" style={{background: "#F7F7F2"}}>

               <div className="spinner">
                    <ClipLoader
                          sizeUnit={"px"}
                          size={250}
                          color={"#0090CD"}
                          loading={this.state.loading}
                      />
                </div>
                 <h2 className="headerAngebot">Angebot - {this.props.angebotArt}</h2>

                 <p>
                  Hier sehen Sie unseren Vorschlag zur Ausgestaltung der Bestattung.
                  <br></br>Verändern Sie dieses Angebot nach Ihren persönlichen Wünschen, indem Sie
                  Leistungen abwählen oder hinzufügen.
                  <br/><br/>
                  Mit freundlichen Grüßen
                  <br/><br/>
                  Ihr Bestattungs-Team
                 </p>

              {this.props.goHome && <Redirect to="/"></Redirect>}
              <div className="d-flex flex-column angebotContainer">
                   <div id="hideproduct" onClick={this.props.hideModalProduct}></div>
                   <div id="hidebiournen" onClick={this.closeModalBiournen}></div>
                   <div id="hidefloristik" onClick={this.closeModalFloristik}></div>

                   <h3 className="h3Zusammenfassung mt-3">Zusammenfassung ihres individuellen Angebotes</h3>

                      <ul className="ulZusammenfassung " >
                        <li className="liAngebot">Beratung</li>
                        <li className="liAngebot">Abholung des Verstorbenen in {this.props.cityAreay}</li>
                        <li className="liAngebot">Trauerfall: {this.props.trauerfall}</li>
                        <li className="liAngebot">Sarg: {this.props.sarg} (für Verstorbene bis max. 100 kg oder 195 cm) </li>
                         <li className="liAngebot">Decke, Kissen und Sterbegewand aus Baumwolle (eigene Kleidung möglich) </li>
                        <li className="liAngebot">Einsargung </li>
                        <li className="liAngebot">Ankleiden eigener Kleidung möglich</li>
                        <li className="liAngebot">Hygienische Grundversorgung des Verstorbenen</li>
                        {!this.props.showSargAuswahl &&  <li className="liAngebot">Kosten der gesetzlich vorgeschriebenen zweiten Leichenschau im Krematorium</li>}
                        {!this.props.showSargAuswahl &&  <li className="liAngebot">Kosten des deutschen Krematoriums unserer Wahl</li>}
                        {!this.props.showSargAuswahl && <li className="liAngebot">Urne: {this.props.urneDesc}</li>}
                        {this.props.isBeisetzung && <li className="liAngebot">Organisation der Beisetzung (Friedhof) </li>}
                        {this.props.showSeebestattung &&<li className="liAngebot">Organisation der Beisetzung (Reederei)</li>}
                        <li className="liAngebot">Abmeldung bei der Krankenkasse und beim gesetzlichen Rententräger</li>
                        <li className="liAngebot">Abmeldung des Verstorbenen beim zuständigen Standesamt</li>
                        <li className="liAngebot">Behördengänge, Standesamt (Sterbeurkunden)</li>
                        <li className="liAngebot">Aushändigung einer gebührenpflichtigen Sterbeurkunde</li>
                        {this.props.friedhofwahl !== "" && <li className="liAngebot">{this.props.friedhofwahl}</li>}
                        {this.props.showAnonymBestattung && <li className="liAngebot">Anonymes Urnenreihengrab unserer Wahl in Deutschland </li>}
                        {this.props.isBegleiteteSeebestattung && <li className="liAngebot">Begleitete Seebestattung</li>}
                        {this.props.isTrauerfeier &&  <li className="liAngebot">Trauerfeier</li>}
                         {this.props.showBiourne && <li className="liAngebot">Urne: {this.props.biourneDesc} </li>}
                         {this.props.showSeeurne && <li className="liAngebot">Urne: {this.props.seeurneDesc}</li>}
                         { this.props.floristikArray.length === 0 ? null :
                          <li className="liAngebot">Floristik: ({ this.props.floristikArray.map((elem, index) => <span key={index}>{elem.titel}{komma}</span>)})</li>
                          }
                       </ul>
                       <p id="gesamtpreis" className="totalPreis">Gesamtpreis: {this.props.total_price} EUR</p>

                        <div className="produktBestatungsartWrapper">
                        <p className="zusatzLeistungHeader">Hier können Sie noch zusätzlich Leistungen auswählen:</p>
                        <IndAngebotModalContainer showIndPdfAngebot={this.createPdfDocument} />

                        <div className="auswahlProductWrapper">
                           <span onClick={this.props.showModalTrauerfeier} className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img src={trauerfeierImg} alt="Trauerfeier" className="auswahlProductImg" />
                                 <p  className="mb-0 pb-0 p-0">Trauerfeier</p>
                            </span>
                            {this.props.showSeeurneAuswahl && <span onClick={this.props.showModalBegleiteteSeebestattung} className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img src={begleitete_seebestattung} alt="Begleitete Seebestattung" className="auswahlProductImg" />
                                 <p  className="mb-0 pb-0 p-0">Begleitete Seebest.</p>
                            </span>}
                            {this.props.showSargAuswahl && <span onClick={this.props.showModalSaerge} className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img src={this.props.sargImg} alt="Sargauswahl" className="auswahlProductImg" />
                                 <p className="mb-0 pb-0 p-0">Sargauswahl</p>
                            </span>}
                            {this.props.showUrneAuswahl && <span onClick={this.props.showModalUrnen}  className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img src={this.props.urneImg} alt="Urnenauswahl" className="auswahlProductImg" />
                                 <p className="mb-0 pb-0 p-0">Urnenauswahl</p>
                            </span>}
                            {this.props.showBiourneAuswahl && <span  onClick={this.showModalBiournen} className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img src={this.props.biourneImg} alt="Biourneauswahl" className="auswahlProductImg" />
                                 <p className="mb-0 pb-0 p-0">Urnenauswahl</p>
                            </span>}
                            {this.props.showSeeurneAuswahl && <span  onClick={this.props.showSeeUrne} className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img src={this.props.seeurneImg} alt="Seeurneauswahl" className="auswahlProductImg" />
                                 <p   className="mb-0 pb-0 p-0">Urnenauswahl</p>
                            </span>}
                          <span  onClick={this.showModalFloristik} className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img  src={blumeAuswahlImg} alt="Floristik Auswahl" className="auswahlProductImg" />
                                 <p  className="mb-0 pb-0 p-0">Floristik</p>
                            </span>

                        </div>


         </div>

         <Table id="tableProduct"  variant="light" responsive="sm">
                         <thead>
                           <tr>
                             <th className="thTable">AUSWAHL</th>
                             <th className="thTable" style={{width: "30%"}}>BESCHREIBUNG</th>
                             <th className="thTable thPreis" style={{textAlign: "right"}}>PREIS EUR</th>
                           </tr>
                         </thead>
                         <tbody>

                          {this.props.isTrauerfeier &&  <tr>
                             <td className="tdTable tdTrauerfeier tdWrapper" >
                                 <img onClick={this.props.showModalTrauerfeier} alt="" src={trauerfeierImg} className="auswahlProductImgTable" />
                                 <span onClick={this.props.deleteTrauerfeier}  className="closeButton">&times;</span>
                              </td>
                             <td className="tdTable tdTrauerfeiertext">{this.props.trauerFeierText}</td>
                             <td className="tdTable" style={{textAlign: "right"}}>{this.props.trauerfeierPreis.toFixed(2)}</td>
                           </tr>}

                           {this.props.isBegleiteteSeebestattung &&  <tr>
                             <td className="tdTable tdTrauerfeier tdWrapper" >
                                 <img onClick={this.props.showModalBegleiteteSeebestattung} alt="Begleitete Seebestattung" src={begleitete_seebestattung} className="auswahlProductImgTable" />
                                 <span onClick={this.props.deleteBegleiteteSeebestattung}  className="closeButton">&times;</span>
                              </td>
                             <td className="tdTable tdTrauerfeiertext">Begleitete Seebestattung</td>
                             <td className=" preisAufAnfrage">auf Anfrage</td>
                           </tr>}


                           {this.props.showSarg && <tr>
                             <td className="tdTable">
                                <img onClick={this.props.showModalSaerge} src={this.props.sargImg} alt="" className="auswahlProductImgTable" />
                             </td>
                             <td className="tdTable">
                               <span className="tableSargDescription">
                                 {this.props.sarg}
                                </span>
                              </td>
                             <td className="tdTable" style={{textAlign: "right"}}>{this.props.sargPrice.toFixed(2)}</td>
                           </tr>}

                          {this.props.showUrne && <tr>
                             <td className="tdTable">
                             <img onClick={this.props.showModalUrnen} src={this.props.urneImg} alt="" className="auswahlProductImgTable" /> </td>
                             <td className="tdTable">
                               <span className="tableSargDescription">
                                 {this.props.urneDesc}
                                </span>
                              </td>
                             <td className="tdTable" style={{textAlign: "right"}}>{this.props.urnePrice.toFixed(2)}</td>
                           </tr>}

                           {this.props.showBiourne && <tr>
                             <td className="tdTable">
                                 <img onClick={this.showModalBiournen}  alt="" src={this.props.biourneImg} className="auswahlProductImgTable" />
                             </td>
                             <td className="tdTable">
                               <span className="tableSargDescription">
                                 {this.props.biourneDesc}
                                </span>
                              </td>
                             <td className="tdTable" style={{textAlign: "right"}}>{this.props.biournePrice.toFixed(2)}</td>
                           </tr>}

                           {this.props.showSeeurne && <tr>
                             <td className="tdTable">
                             <img onClick={this.props.showSeeUrne} src={this.props.seeurneImg}  alt="" className="auswahlProductImgTable" />
                             </td>
                             <td className="tdTable">
                               <span className="tableSargDescription">
                                 {this.props.seeurneDesc}
                                </span>
                              </td>
                             <td className="tdTable" style={{textAlign: "right"}}>{this.props.seeurnePrice.toFixed(2)}</td>
                           </tr>}


                          {this.props.floristikArray.length === 0 ? null :
                            this.props.floristikArray.map((elem, index) => {
                            return(
                              <tr key={index}>
                                  <td className="tdTable tdTrauerfeier tdWrapper">
                                       <img className="auswahlProductImgTable" alt="" onClick={this.showModalFloristik} src={elem.img} />
                                       <span onClick={this.props.deleteFloristik} identid={index} className="closeButton">&times;</span>
                                  </td>
                                  <td className="tdTable">{elem.titel}, Art.-Nr.: {elem.art}</td>
                                  <td className="tdTable" style={{textAlign: "right"}}>{elem.price}.00</td>
                             </tr>
                            )
                            })
                          }

                           <tr>
                             <td className="tdTable" colSpan="2">GESAMTSUMME</td>
                             <td className="tdTable " style={{textAlign: "right"}}>{this.props.total_price}</td>
                           </tr>
                         </tbody>
                    </Table>

              <div className="angebotButtonWrapper">
                    {!this.props.showAngebotWithoutVollmacht ?
                            <button onClick={this.showAngebotModalFunc} className="btn btn_button  my-3"><FaFilePdf className="artIcon" /> Auftrag und Vollmacht herunterladen</button>
                            :
                            <button onClick={this.props.showAngebotModalIndividuell} className="btn btn_button my-3"><FaFilePdf className="artIcon" /> Auftrag und Vollmacht herunterladen</button>
                    }

                    <button onClick={this.openAllBestattungsarten} className="btn btn_button btn-angebot col-sm-12 my-3"><FaTh className="artIcon" /> Bestattungsart ändern</button>
               </div>
                 {/*
                    <div className="bemerkungen">
                      <h5 className="headerBemerkungen">Zusätzliche Hinweise (falls nötig)</h5>
                      <textarea value={this.props.bemerkungenValue} onChange={this.props.updateBemerkungen} className="textareaBemerkungen" placeholder="Ihre Bemerkungen..." row="5"></textarea>
                    </div>
                 */}



                  {/*  Bestattungsart ändern */}
                  <Modal
                        size="lg"
                        show={this.state.showAllBestattungsarten}
                        onHide={this.closeAllBestattungsarten}
                        aria-labelledby="example-modal-sizes-title-lg"
                       >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
                            <p className="modalTitle">Bestattungsart</p>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                   <div className="auswahlProductWrapper">
                            <span  className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img onClick={this.props.changeBestattungsart} ident="erd"  alt="" src={erdImg} className="auswahlBestattungsartImg" />
                                 <p onClick={this.props.changeBestattungsart} ident="erd"  className="mb-0 p-0">Erdbestattung</p>
                            </span>
                            <span  className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img onClick={this.props.changeBestattungsart} ident="see" src={seeImg} alt="" className="auswahlBestattungsartImg" />
                                 <p onClick={this.props.changeBestattungsart} ident="see" className="mb-0 p-0">Seebestattung</p>
                            </span>
                          <span className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img onClick={this.props.changeBestattungsart} ident="anonym" src={anonymImg} alt="" className="auswahlBestattungsartImg" />
                                 <p onClick={this.props.changeBestattungsart} ident="anonym" className="mb-0 p-0">Anonyme Bestattung</p>
                            </span>
                           <span className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img onClick={this.props.changeBestattungsart} ident="friedwald" src={friedwaldImg} alt="" className="auswahlBestattungsartImg" />
                                 <p onClick={this.props.changeBestattungsart} ident="friedwald" className="mb-0 p-0">Baumbestattung</p>
                            </span>

                            <span className="d-flex flex-column justify-content-center align-items-center p-2 m-2 auswahlBorder">
                                 <img onClick={this.props.changeBestattungsart} ident="einfachefeuerbestattung" alt="Einfache Feuerbestattung" src={einfacheFeuerbestattungImg} className="auswahlBestattungsartImg" />
                                 <p onClick={this.props.changeBestattungsart} ident="einfachefeuerbestattung" className="mb-0 p-0">Einfache Feuerbestattung</p>
                            </span>
                            <p onClick={this.closeAllBestattungsarten} id="closeAllBestattungsarten"></p>


                        </div>
                        </Modal.Body>
                    </Modal>

  </div>


                  {/*  PopUp add product*/}
                    <Modal
                          size="xl"
                          show={this.props.modalAddProduct}
                          onHide={this.props.hideModalProduct}
                          aria-labelledby="example-modal-sizes-title-sm"

                        >
                          <Modal.Header id="modalHeaderProduct" closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">

                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body id="modalBodyProduct">
                            <div  className="iconCheckWrapper">
                              <FaCheckCircle id="checkIcon" />
                            <p className="p-0 m-0"><span id="productDesc">{this.props.productDesc}</span> hinzugefügt</p>
                          </div>
                          </Modal.Body>
                    </Modal>

                     {/*  PopUp trauerfeier*/}
                      <Modal
                        size="lg"
                        show={this.props.modalShowTrauerfeier}
                        onHide={this.props.hideModalTrauerfeier}
                        aria-labelledby="example-modal-sizes-title-lg"
                       >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
                          <p className="modalTitle">Trauerfeier</p>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="trauerfeierWrapper">
                            <ul className="">
                                    <li>Einfache Trauerfeier</li>
                                    <li>musikalische Begleitung</li>
                                    <li>Trauerbegleiter und Trauerredner kann auf Anfrage dazu gebucht werden (gesetzlicher Beistand)</li>
                                    <p className="my-2">Preis: {this.state.trauerfallpreis},00 EUR</p>
                                    <div className="d-flex">
                                      <button className="btn buttonAngebot mr-3" onClick={this.props.addTrauerfeier}>Hinzufügen</button>
                                      <button className="btn btn-danger mr-3" onClick={this.props.deleteTrauerfeier}>Entfernen</button>
                                    </div>
                              </ul>
                              <img className="trauerfeierImg" src={trauerfeierImg} alt="Trauerfeier" />

                          </div>

                        </Modal.Body>
                      </Modal>


                     {/*  PopUp begleitete Seebestattung*/}
                     <Modal
                        size="lg"
                        show={this.props.modalShowBegleiteteSeebestattung}
                        onHide={this.props.hideModalBegleiteteSeebestattung}
                        aria-labelledby="example-modal-sizes-title-lg"
                       >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
                          <p className="modalTitle">Begleitete Seebestattung</p>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="trauerfeierWrapper">
                            <ul className="ulTrauerFeierWrapper2">
                                    <li> das gesamte Schiff steht für die Zeit der Beisetzungsfahrt alleine zur Verfügung</li>
                                    <li>die Urne wird bereits vor Eintreffen der Familie und Freunde an Bord gebracht</li>
                                    <li>die Angehörigen werden vom Kapitän an Bord begrüßt und erhalten einen genauen Überblick über den Ablauf der Fahrt</li>
                                    <li>Getränke und Speisen können vorab bei uns bestellt werden</li>
                                    <li>die Flagge wird auf Halbmast gesetzt, dann legt das Schiff ab und nimmt Kurs auf die Beisetzungsposition </li>
                                    <p className="my-2">Preis: Auf Anfrage</p>
                                    <div className="d-flex">
                                      <button className="btn buttonAngebot mr-3" onClick={this.props.addBegleiteteSeebstattung}>Hinzufügen</button>
                                      <button className="btn btn-danger mr-3" onClick={this.props.deleteBegleiteteSeebestattung}>Entfernen</button>
                                    </div>
                              </ul>
                              <img className="trauerfeierImg" alt="" src={begleitete_seebestattung} />

                          </div>

                        </Modal.Body>
                      </Modal>

                     {/*  PopUp särge*/}
                      <Modal
                        size="xl"
                        show={this.props.modalShowSarge}
                        onHide={this.props.closeModalSaerge}
                        aria-labelledby="example-modal-sizes-title-lg"
                       >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
                          <p className="modalTitle">Sargauswahl</p>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="d-flex flex-wrap justify-content-center position-auswahl py-2 mt-1">
                             <p ident="kiefersarg" className="productLinkAuswahl" onClick={this.filterAlleSaerge}>Kiefersärge</p>
                             <p ident="pappelsarg" className="productLinkAuswahl" onClick={this.filterAlleSaerge}>Pappelsärge</p>
                             <p ident="eichensarg" className="productLinkAuswahl" onClick={this.filterAlleSaerge}>Eichensärge</p>
                             <p ident="designersarg" className="productLinkAuswahl" onClick={this.filterAlleSaerge}>Designersärge</p>
                        </div>

                        <div className="d-flex flex-wrap justify-content-center">
                         {this.state.allSaerge.map((elem, index) => {
                           return(
                               <div key={index} className="col-sm-12 col-md-5 col-lg-5 card m-3 sargLastPosition">
                                  <img onClick={this.props.selectSargButton} identimg={elem.img} identpreis={elem.price} identname={elem.titel} identbutton={elem.art_nr} src={elem.img} className="card-img-bottom imgSarg" alt={elem.titel} />
                                  {/*<input className="radioBox mx-auto mb-2 defaultSarg" id={"sarg"+ elem.art_nr} onChange={this.props.selectSarg} value={elem.art_nr} type="radio" name={elem.name} />*/}
                                  <span className="productInfo">{elem.titel}</span>
                                  <span className="productInfo">Art-Nr: {elem.art_nr}</span>
                                  <span className="productInfo mb-3">Preis: {elem.price},00 EUR</span>
                                  <button onClick={this.props.selectSargButton} identimg={elem.img} identpreis={elem.price} identname={elem.titel} identbutton={elem.art_nr} className="btn mb-2 buttonAuswahlSarg">Auswählen</button>
                               </div>
                           )
                          })}
                         </div>
                        </Modal.Body>
                      </Modal>

                     {/*  PopUp urnen*/}
                      <Modal
                        size="xl"
                        show={this.props.modalShowUrnen}
                        onHide={this.props.closeModalUrnen}
                        aria-labelledby="example-modal-sizes-title-lg"
                       >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
                          <p className="modalTitle">Urnenauswahl</p>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="d-flex flex-wrap justify-content-center position-auswahl py-2 mt-1">
                             <p ident="airbrush" className="productLinkAuswahl" onClick={this.filterAlleUrnen}>Airbrush</p>
                             <p ident="handgemalt" className="productLinkAuswahl" onClick={this.filterAlleUrnen}>Handgemalt</p>
                             <p ident="normal" className="productLinkAuswahl" onClick={this.filterAlleUrnen}>Normal</p>
                             <p ident="einfach" className="productLinkAuswahl" onClick={this.filterAlleUrnen}>Schlicht</p>
                             <p ident="bio" className="productLinkAuswahl" onClick={this.filterAlleUrnen}>Bio</p>
                        </div>

                        <div className="d-flex flex-wrap justify-content-center">
                         {this.state.allUrnen.map((elem, index) => {
                           return(
                               <div key={index} className="col-sm-12 col-md-5 col-lg-5 card m-3 sargLastPosition">
                                  <img onClick={this.props.selectUrne} identimg={elem.img} identpreis={elem.price} identname={elem.titel} identbutton={elem.art_nr} src={elem.img} className="card-img-bottom imgSarg" alt={elem.titel} />
                                  {/*<input className="radioBox mx-auto mb-2 defaultSarg" id={"sarg"+ elem.art_nr} onChange={this.props.selectSarg} value={elem.art_nr} type="radio" name={elem.name} />*/}
                                  <span className="productInfo">{elem.titel}</span>
                                  <span className="productInfo">Art-Nr: {elem.art_nr}</span>
                                  <span className="productInfo mb-3">Preis: {elem.price},00 EUR</span>
                                  <button onClick={this.props.selectUrne} identimg={elem.img} identpreis={elem.price} identname={elem.titel} identbutton={elem.art_nr} className="btn mb-2 buttonAuswahlSarg">Auswählen</button>
                               </div>
                           )
                          })}
                         </div>
                        </Modal.Body>
                      </Modal>

                      {/*  PopUp seeurnen*/}
                      <Modal
                        size="xl"
                        show={this.props.modalSeeurnen}
                        onHide={this.props.closeModalSeeurnen}
                        aria-labelledby="example-modal-sizes-title-lg"
                       >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
                          <p className="modalTitle">Seeurnen</p>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="d-flex flex-wrap justify-content-center">
                         {this.state.seeUrneArray.map((elem, index) => {
                           return(
                               <div key={index} className="col-sm-12 col-md-5 col-lg-5 card m-3 sargLastPosition">
                                  <img  onClick={this.props.selectSeeurne} identimg={elem.img} identpreis={elem.price} identname={elem.titel} identbutton={elem.art_nr}  src={elem.img}  className="card-img-bottom imgSarg" alt={elem.titel} />
                                  {/*<input className="radioBox mx-auto mb-2 defaultSarg" id={"sarg"+ elem.art_nr} onChange={this.props.selectSarg} value={elem.art_nr} type="radio" name={elem.name} />*/}
                                  <span className="productInfo">{elem.titel}</span>
                                  <span className="productInfo">Art-Nr: {elem.art_nr}</span>
                                  <span className="productInfo mb-3">Preis: {elem.price},00 EUR</span>
                                  <button onClick={this.props.selectSeeurne} identimg={elem.img} identpreis={elem.price} identname={elem.titel} identbutton={elem.art_nr} className="btn mb-2 buttonAuswahlSarg">Auswählen</button>
                               </div>
                           )
                          })}
                         </div>
                        </Modal.Body>
                      </Modal>

                       {/*  PopUp biournen*/}
                       <Modal
                        size="xl"
                        show={this.state.modalShowBiournen}
                        onHide={this.closeModalBiournen}
                        aria-labelledby="example-modal-sizes-title-lg"
                       >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
                          <p className="modalTitle">Biournen</p>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="d-flex flex-wrap justify-content-center">
                         {this.state.bioUrneArray.map((elem, index) => {
                           return(
                               <div key={index} className="col-sm-12 col-md-5 col-lg-5 card m-3 sargLastPosition">
                                  <img onClick={this.props.selectBiourne} identimg={elem.img} identpreis={elem.price} identname={elem.titel} identbutton={elem.art_nr} src={elem.img} className="card-img-bottom imgSarg" alt={elem.titel} />
                                  <span className="productInfo">{elem.titel}</span>
                                  <span className="productInfo">Art-Nr: {elem.art_nr}</span>
                                  <span className="productInfo mb-3">Preis: {elem.price},00 EUR</span>
                                  <button onClick={this.props.selectBiourne} identimg={elem.img} identpreis={elem.price} identname={elem.titel} identbutton={elem.art_nr} className="btn mb-2 buttonAuswahlSarg">Auswählen</button>
                               </div>
                           )
                          })}
                         </div>
                        </Modal.Body>
                      </Modal>

                        {/*  PopUp floristik*/}
                          <Modal
                            size="xl"
                            show={this.state.modalsShowFloristik}
                            onHide={this.closeModalFloristik}
                            aria-labelledby="example-modal-sizes-title-lg"
                           >
                            <Modal.Header closeButton>
                              <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
                              <p className="modalTitle">Floristikauswahl</p>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <div className="d-flex flex-wrap justify-content-center position-auswahl py-2 mt-1">
                                 <p className="productLinkAuswahl" ident="gesteck" onClick={this.filterAlleBlumen} >Gestecke</p>
                                 <p className="productLinkAuswahl" ident="kranz" onClick={this.filterAlleBlumen} >Kränze</p>
                                 {this.props.showSarg && <p className="productLinkAuswahl" ident="sarg" onClick={this.filterAlleBlumen}>Sargschmuck</p>}
                                 <p className="productLinkAuswahl" ident="tisch" onClick={this.filterAlleBlumen}>Tischdeko</p>
                                 {!this.props.showSarg && <p className="productLinkAuswahl" ident="us" onClick={this.filterAlleBlumen}>Urnenschmuck</p>}

                                {/*<div className="d-flex flex-column w-75 mt-3">
                                     <p className="mt-1 mb-0 pBudget">Verzierung mit Schleife</p>
                                     <input className="mt-1" value={this.props.bouquetErsteSchleife} onChange={this.props.addBouquetErsteSchleife} type="text" placeholder="Ihr Text für 1. Schleife:" />
                                     <input className="my-2" value={this.props.bouquetZweiteSchleife} onChange={this.props.addBouquetZweiteSchleife} type="text" placeholder="Ihr Text für 2. Schleife:" />
                                 </div>*/}
                            </div>
                            <div className="d-flex flex-wrap justify-content-center">
                             {this.state.allFloristik.map((elem, index) => {
                               return(
                                   <div key={index} className="col-sm-12 col-md-5 col-lg-5 card m-3 p-0 cardBlumen">
                                      <img onClick={this.props.addFloristik} identpreis={elem.price} identimg={elem.img} identcategory={elem.category} identtitel={elem.titel}  identart={elem.art_nr} identname={elem.titel} identbutton={elem.art_nr} src={elem.img} className="card-img-bottom imgSarg" alt={elem.titel} />
                                      <span className="productInfo">{elem.titel}</span>
                                      <span className="productInfo">Art-Nr: {elem.art_nr}</span>
                                      <span className="productInfo mb-3">Preis: {elem.price},00 EUR</span>
                                      <button onClick={this.props.addFloristik} identpreis={elem.price} identimg={elem.img} identcategory={elem.category} identtitel={elem.titel}  identart={elem.art_nr} identname={elem.titel} identbutton={elem.art_nr} className="btn mb-2 buttonAuswahlSarg">Auswählen</button>
                                   </div>
                               )
                              })}
                             </div>
                            </Modal.Body>
                          </Modal>

                         <IndAngebotModalContainer showAngebotModal={this.state.showAngebotModal}
                                                   closeAngebotModal={this.closeAngebotModal}
                         />
                         <div onClick={this.closeAngebotModal} id="closeIndAngebot"></div>

         </div>
         )
       }

}

const mapStateToProps = state => {
     return{
       total_price: state.total_price,
       sargPrice: state.sargPrice,
       destination_price: state.destination_price,
       name: state.name,
       vorName: state.vorName,
       message: state.message,
       male: state.male,
       anrede: state.anrede,
       arrayCalculate: state.arrayCalculate,
       sargArray: state.sargArray,
       sargPappel: state.sargPappel,
       sargEiche: state.sargEiche,
       sargDesigner: state.sargDesigner,
       sarg: state.sarg,
       sargImg: state.sargImg,
       //Trauerfallpreis
       trauerfeierPreis: state.trauerfeierPreis,
       //Sargbouquet
       bouquetBudgetText: state.bouquetBudgetText,
       bouquetErsteSchleife: state.bouquetErsteSchleife,
       bouquetZweiteSchleife: state.bouquetZweiteSchleife,
       //toggleTrauerKranz
       trauerkranzBudgetText: state.trauerkranzBudgetText,
       trauerkranzErsteSchleife: state.trauerkranzErsteSchleife,
       trauerkranzZweiteSchleife: state.trauerkranzZweiteSchleife,
       trauerFeierText: state.trauerFeierText,
       floristikArray: state.floristikArray,
       floristikPrice: state.floristikPrice,
       modalShowTrauerfeier: state.modalShowTrauerfeier,
       modalAddProduct: state.modalAddProduct,
       modalShowSarge: state.modalShowSarge,
       productDesc: state.productDesc,
       urneDesc: state.urneDesc,
       urnePrice: state.urnePrice,
       urneImg: state.urneImg,
       showSarg: state.showSarg,
       showUrne: state.showUrne,
       showSeebestattung: state.showSeebestattung,
       showAnonymBestattung: state.showAnonymBestattung,
       showSeeurne: state.showSeeurne,
       seeurneDesc: state.seeurneDesc,
       seeurnePrice: state.seeurnePrice,
       seeurneImg: state.seeurneImg,
       showBiourne: state.showBiourne,
       biourneDesc: state.biourneDesc,
       biourneImg: state.biourneImg,
       biournePrice: state.biournePrice,
       angebotArt: state.angebotArt,
       friedhofwahl: state.friedhofwahl,
       isTrauerfeier: state.isTrauerfeier,
       modalShowUrnen: state.modalShowUrnen,
       modalSeeurnen: state.modalSeeurnen,
       goHome: state.goHome,
       modalShowBegleiteteSeebestattung: state.modalShowBegleiteteSeebestattung,
       isBegleiteteSeebestattung: state.isBegleiteteSeebestattung,
       trauerfall: state.trauerfall,
       bemerkungenValue: state.bemerkungenValue,
       showSargAuswahl: state.showSargAuswahl,
       showUrneAuswahl: state.showUrneAuswahl,
       showSeeurneAuswahl: state.showSeeurneAuswahl,
       showBiourneAuswahl: state.showBiourneAuswahl,
       isBeisetzung: state.isBeisetzung,
        //Bestattungspflichtiger
        aliveBirthday: state.aliveBirthday,
        verwandschaftsoption: state.verwandschaftsoption,
        aliveAdresse: state.aliveAdresse,
        aliveBirthplace: state.aliveBirthplace,
        alivePhone: state.alivePhone,
        //Verstorbener
        deceasedName: state.deceasedName,
        deceasedVorname: state.deceasedVorname,
        deceasedBirthdayFrom: state.deceasedBirthdayFrom,
        deceasedBirthdayTo: state.deceasedBirthdayTo,
        deceasedAdresse: state.deceasedAdresse,
        deceasedBirthplace: state.deceasedBirthplace,
        deceasedReligion: state.deceasedReligion,
        deceasedProfession: state.deceasedProfession,
        deceasedChildren: state.deceasedChildren,
        maritalStatus: state.maritalStatus,
        deceasedPensionNumber: state.deceasedPensionNumber,
        deceasedDeathCertificates: state.deceasedDeathCertificates,
        deceasedHealthInsurance: state.deceasedHealthInsurance,
        showAngebotWithoutVollmacht: state.showAngebotWithoutVollmacht,
        datenschutzIsTrue: state.datenschutzIsTrue,
        datenschutzFailMessage: state.datenschutzFailMessage,
        cityAreay: state.cityAreay,
        plzArea: state.plzArea
     }
}


const mapStateToDispatch = dispatch => {
   return{
     caculateFinalPrice: () => dispatch(caculateFinalPrice()),
     addTrauerfeier: () => dispatch(addTrauerfeier()),
     deleteTrauerfeier: () => dispatch(deleteTrauerfeier()),
     selectSarg: ev => dispatch(selectSarg(ev)),
     selectSargButton: ev => dispatch(selectSargButton(ev)),
     deleteSarg: () => dispatch(deleteSarg()),
     onSubmitBouquet: ev => dispatch(onSubmitBouquet(ev)),
     onSubmitTrauerkranz: ev => dispatch(onSubmitTrauerkranz(ev)),
     addBouquetBudget: ev => dispatch(addBouquetBudget(ev)),
     addBouquetErsteSchleife: ev => dispatch(addBouquetErsteSchleife(ev)),
     addBouquetZweiteSchleife: ev => dispatch(addBouquetZweiteSchleife(ev)),
     removefieldsBouquet: () => dispatch(removefieldsBouquet()),
     addTrauerkranzBudget: ev => dispatch(addTrauerkranzBudget(ev)),
     addTrauerkranzErsteSchleife: ev => dispatch(addTrauerkranzErsteSchleife(ev)),
     addTrauerkranzZweiteSchleife: ev => dispatch(addTrauerkranzZweiteSchleife(ev)),
     removefieldsTrauerkranz: () => dispatch(removefieldsTrauerkranz()),
     addFloristik: ev => dispatch(addFloristik(ev)),
     deleteFloristik: (ev) => dispatch(deleteFloristik(ev)),
     showModalTrauerfeier: () => dispatch(showModalTrauerfeier()),
     hideModalTrauerfeier: () => dispatch(hideModalTrauerfeier()),
     hideModalProduct: () => dispatch(hideModalProduct()),
     selectUrne: ev => dispatch(selectUrne(ev)),
     deleteUrne: () => dispatch(deleteUrne()),
     selectSeeurne: ev => dispatch(selectSeeurne(ev)),
     deleteSeeurne: () => dispatch(deleteSeeurne()),
     changeBestattungsart: ev => dispatch(changeBestattungsart(ev)),
     selectBiourne: ev => dispatch(selectBiourne(ev)),
     deleteBiourne: () => dispatch(deleteBiourne()),
     closeModalSaerge: () => dispatch(closeModalSaerge()),
     showModalSaerge: () => dispatch(showModalSaerge()),
     showModalUrnen: () => dispatch(showModalUrnen()),
     closeModalUrnen: () => dispatch(closeModalUrnen()),
     closeModalSeeurnen: () => dispatch(closeModalSeeurnen()),
     showSeeUrne: () => dispatch(showSeeUrne()),
     hideModalBegleiteteSeebestattung: () => dispatch(hideModalBegleiteteSeebestattung()),
     showModalBegleiteteSeebestattung: () => dispatch(showModalBegleiteteSeebestattung()),
     addBegleiteteSeebstattung: () => dispatch(addBegleiteteSeebstattung()),
     deleteBegleiteteSeebestattung: () => dispatch(deleteBegleiteteSeebestattung()),
     updateBemerkungen: ev => dispatch(updateBemerkungen(ev)),
     showAngebotModalIndividuell: ev => dispatch(showAngebotModalIndividuell(ev))


   }
}



export const AngebotContainer = connect(mapStateToProps, mapStateToDispatch)(Angebot);
