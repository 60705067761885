export const hamburg = [

      [
        "Erster Abschnitt: Leichenwesen",
        {
            titel: "§ 1 Leichenschau",
            content_1: "(1) 1 Jede Leiche ist zur Feststellung des Todes, des Todeszeitpunkts, der Todesart und der Todesursache von einem Arzt zu untersuchen (Leichenschau). 2 Vor der Feststellung des Todes durch einen Arzt darf der Körper eines Verstorbenen nur dann wie eine Leiche behandelt werden, wenn der Eintritt des Todes offensichtlich ist. 3 Leichen im Sinne dieses Gesetzes sind auch tot geborene Leibesfrüchte mit einem Geburtsgewicht von mindestens 500 Gramm.",
            content_2: "(2) 1 Bei Sterbefällen in Krankenhäusern und anderen Einrichtungen, zu deren Aufgabe die ärztliche Betreuung der aufgenommenen Personen gehört, hat der Leiter der Einrichtung sicherzustellen, dass die Leichenschau unverzüglich durch einen dort tätigen Arzt oder einen beauftragten Arzt vorgenommen wird. 2 In den übrigen Fällen hat derjenige die Leichenschau unverzüglich zu veranlassen, der nach § 33 oder § 34 des Personenstandsgesetzes zur Anzeige des Todes gegenüber dem Standesbeamten verpflichtet ist. 3 Ist ein nach Satz 2 Verpflichteter nicht vorhanden oder nicht erreichbar oder wird die Leiche eines Unbekannten gefunden, so wird die Leichenschau durch die zuständige Behörde veranlasst.",
            content_3: "(3) 1 In den Fällen von Absatz 2 Sätze 2 und 3 ist jeder niedergelassene Arzt verpflichtet, die Leichenschau auf Verlangen vorzunehmen, sofern er nicht aus wichtigem Grund daran gehindert ist. 2 Während des Notfalldienstes und des Bereitschaftsdienstes trifft diese Verpflichtung die hierfür eingeteilten Ärzte.",
            content_4: "(4) Ein Arzt kann es ablehnen, über die Feststellung des Todes hinaus eine Leichenschau vorzunehmen, wenn er durch die weiteren Feststellungen sich selbst oder einen seiner in § 383 Absatz 1 Nummern 1 bis 3 der Zivilprozessordnung bezeichneten Angehörigen der Gefahr strafrechtlicher Verfolgung oder eines Verfahrens nach dem Gesetz über Ordnungswidrigkeiten aussetzen würde."
        },
        {
            titel: "§ 2 Durchführung der Leichenschau",
            content_1: "(1) Die Leichenschau ist unverzüglich, in den Fällen des § 1 Absatz 3 spätestens innerhalb von sechs Stunden nach der Aufforderung hierzu an der vollständig entkleideten Leiche sorgfältig durchzuführen.",
            content_2: "(2) Im Rahmen der Leichenschau hat der Arzt festzustellen,",
            content_3: "1. ob der Tod mit Sicherheit eingetreten ist,",
            content_4: "2. wann der Tod eingetreten ist,",
            content_5: "3. ob Anhaltspunkte für einen nichtnatürlichen Tod bestehen oder ob ein solcher sich nicht mit Sicherheit ausschließen lässt,",
            content_6: "4. ob Anhaltspunkte dafür bestehen, dass der Verstorbene an einer meldepflichtigen oder einer ähnlich gefährlichen übertragbaren Krankheit gelitten hat, die durch den Umgang mit der Leiche weiterverbreitet werden kann,",
            content_7: "5. wodurch der Tod eingetreten ist, welche Erkrankungen dazu geführt haben und welche sonstigen wesentlichen Erkrankungen zum Zeitpunkt des Todes bestanden haben.",
            content_8: "(3) 1 Die Leichenschau soll an dem Ort, an dem der Tod eingetreten oder die Leiche aufgefunden worden ist, vorgenommen werden. 2 Der Arzt, der die Leichenschau vornehmen soll, ist berechtigt, jederzeit den Ort zu betreten, an dem sich die Leiche befindet. 3 Befindet sich die Leiche nicht in einem geschlossenen Raum oder ist aus anderen Gründen eine vollständige Leichenschau an diesem Ort nicht möglich oder nicht zweckmäßig, so kann sich der Arzt auf die Feststellung des Todes und der äußeren Umstände beschränken, wenn sichergestellt ist, dass die Leichenschau an einem hierfür besser geeigneten Ort fortgesetzt wird. 4 Ein Arzt, der für die Behandlung von Notfällen eingeteilt ist und den Verstorbenen vorher nicht behandelt hat, kann sich auf die Feststellung des Todes, des Todeszeitpunkts, des Zustands der Leiche und der äußeren Umstände beschränken, wenn sichergestellt ist, dass die noch fehlenden Feststellungen nach Absatz 2 von dem behandelnden oder einem anderen Arzt getroffen werden.",
            content_9: "(4) 1 Ergeben sich Anhaltspunkte für einen nichtnatürlichen Tod oder lässt sich ein solcher nicht mit Sicherheit ausschließen, so hat der Arzt sofort die Polizei oder die Staatsanwaltschaft zu benachrichtigen. 2 Er hat außerdem dafür zu sorgen, dass an der Leiche und deren Umgebung bis zum Eintreffen der Polizei oder der Staatsanwaltschaft keine vermeidbaren Veränderungen vorgenommen werden.",
            content_10: "(5) Ergeben sich Anhaltspunkte dafür, dass der Verstorbene an einer meldepflichtigen oder einer ähnlich gefährlichen übertragbaren Krankheit gelitten hat, die durch den Umgang mit der Leiche weiterverbreitet werden kann, so hat der Arzt dafür zu sorgen, dass die Leiche entsprechend gekennzeichnet wird."
        },
        {
            titel: "§ 3 Todesbescheinigung",
            content_1: "(1) 1 Der Arzt, der die Leichenschau vornimmt, hat hierüber auf einem von der zuständigen Behörde herausgegebenen Vordruck eine Todesbescheinigung auszustellen, die dem Nachweis des Todeszeitpunktes und der Todesursache, der Aufklärung von Straftaten, die mit dem Tod im Zusammenhang stehen, der Prüfung, ob seuchenhygienische Maßnahmen erforderlich sind, sowie Zwecken der Statistik und Forschung dient. 2 Die Todesbescheinigung darf über den Verstorbenen nur die folgenden Angaben enthalten:",
            content_2: "1. Name, Geschlecht,",
            content_3: "2. letzte Wohnung,",
            content_4: "3. Zeitpunkt und Ort der Geburt und des Todes oder der Auffindung, bei Totgeborenen außerdem das Geburtsgewicht,",
            content_5: "4. Arzt oder Krankenhaus, die den Verstorbenen zuletzt behandelt haben,",
            content_6: "5. Angaben über übertragbare Krankheiten,",
            content_7: "6. Art des Todes (natürlicher, nichtnatürlicher oder unaufgeklärter Tod),",
            content_8: "7. Angaben zur Krankheitsanamnese,",
            content_9: "8. Art des Todeseintritts (Endzustand),",
            content_10:  "9. unmittelbare und mittelbare Todesursachen sowie weitere wesentliche Krankheiten oder Verletzungen zur Zeit des Todes,",
            content_11: "10. Angaben über durchgeführte Reanimationsbehandlungen,",
            content_12:  "11. Angaben zu implantierten Geräten und radioaktiven Strahlen,",
            content_13: "12. bei nichtnatürlichem Tod: Art des Unfalls oder des sonstigen nichtnatürlichen Todes,",
            content_14: "13. bei Frauen: Angaben über eine bestehende oder eine bis zu 3 Monaten zurückliegende Schwangerschaft,",
            content_15: "14. bei Totgeborenen und bei Kindern unter einem Jahr: Angaben über die Stätte der Geburt, über Gewicht und Länge bei der Geburt sowie über das Vorliegen einer Mehrlingsgeburt.",
            content_16: "3 Die in Satz 2 Nummern 7 bis 10 und 12 bis 14 genannten Angaben dürfen nur in einem verschließbaren, von außen nicht lesbaren Innenteil der Todesbescheinigung enthalten sein.",
            content_17: "(2) 1 Der Arzt hat die Todesbescheinigung, nachdem er den Innenteil verschlossen hat, unverzüglich demjenigen auszuhändigen, der nach dem Personenstandsgesetz zur Anzeige des Todes verpflichtet ist. 2 Dieser hat sie dem Standesbeamten einzureichen, der die Eintragung in das Sterbebuch auf der Todesbescheinigung vermerkt und diese der zuständigen Behörde für Zwecke des Gesundheitswesens übersendet.",
            content_18: "(3) 1 Findet eine Sektion statt, so erhält der sezierende Arzt eine Durchschrift der Todesbescheinigung. 2 Er hat die Durchschrift auf Grund des Ergebnisses der Sektion zu ergänzen oder zu berichtigen und verschlossen der zuständigen Behörde für Zwecke des Gesundheitswesens zu übersenden. 3 Ist eine Feuerbestattung beabsichtigt, so hat er außerdem eine Durchschrift des Sektionsbefundes verschlossen der Leiche beizugeben. 4 § 2 Absätze 4 und 5 gilt entsprechend.",
            content_19: "(4) 1 Die zuständige Behörde überprüft den Inhalt des äußeren und des inneren Teils der Todesbescheinigung und bereitet die Angaben für eine statistische Auswertung auf; § 2 Absatz 4 Satz 1 gilt entsprechend. 2 Lag die Hauptwohnung des Verstorbenen außerhalb der Freien und Hansestadt Hamburg, so ist der für die Hauptwohnung zuständigen Behörde für Zwecke des Gesundheitswesens eine Kopie der Todesbescheinigung zu übersenden.",
            content_20: "(5) 1 Die zuständige Behörde bewahrt die Todesbescheinigungen, die in Absatz 3 genannten Durchschriften und die ihr von auswärtigen Stellen zugesandten gleichartigen Bescheinigungen mindestens 25 und höchstens 30 Jahre lang auf. 2 Sie kann auf Antrag in diese Unterlagen Einsicht gewähren oder Auskünfte daraus erteilen,",
            content_21: "1. wenn der Antragsteller ein berechtigtes Interesse an der Kenntnis über die Todesumstände eines namentlich bezeichneten Verstorbenen glaubhaft macht und kein Grund zu der Annahme besteht, dass durch die Offenbarung schutzwürdige Belange des Verstorbenen beeinträchtigt werden, oder",
            content_22: "2. wenn der Antragsteller die Angaben für ein wissenschaftliches Forschungsvorhaben benötigt und",
            content_23: "a) durch sofortige Anonymisierung der Angaben oder auf andere Weise sichergestellt wird, dass schutzwürdige Belange der Verstorbenen nicht beeinträchtigt werden, oder",
            content_24: "b) das öffentliche Interesse an dem Forschungsvorhaben das Geheimhaltungsinteresse der Verstorbenen erheblich überwiegt.",
            content_25: "3 Der Antragsteller darf personenbezogene Angaben, die er auf diese Weise erfährt, nur zu dem von ihm im Antrag angegebenen Zweck verwenden."
        },
        {
            titel: "§ 4 Auskunftspflicht",
            content_1: "(1) Angehörige der Heil- und Heilhilfsberufe, die den Verstorbenen vor seinem Tode untersucht, behandelt oder gepflegt haben, und Personen, mit denen der Verstorbene zusammengelebt hat oder die Kenntnis von den Umständen des Todes haben, sind verpflichtet, dem die Leichenschau vornehmenden Arzt und der zuständigen Behörde die für die Vornahme der Leichenschau und die Ausstellung der Todesbescheinigung erforderlichen Auskünfte zu erteilen.",
            content_2: "(2) Außerdem sind die Ärzte, die die Leichenschau oder eine Sektion vorgenommen haben, verpflichtet, der zuständigen Behörde die zur Überprüfung und Vervollständigung der Todesbescheinigung erforderlichen Auskünfte zu erteilen.",
            content_3: "(3) Ein zur Auskunft Verpflichteter kann die Auskunft auf solche Fragen verweigern, deren Beantwortung ihn selbst oder einen seiner in § 383 Absatz 1 Nummern 1 bis 3 der Zivilprozessordnung bezeichneten Angehörigen der Gefahr strafrechtlicher Verfolgung oder eines Verfahrens nach dem Gesetz über Ordnungswidrigkeiten aussetzen würde."
        },
        {
            titel: "§ 5 Kosten der Leichenschau",
            content_1: "1 In den Fällen des § 1 Absatz 2 Satz 1 und des § 3 Absatz 3 kann eine besondere Vergütung für die Leichenschau und für die Ausstellung, Ergänzung oder Berichtigung der Todesbescheinigung nicht verlangt werden. 2 In den übrigen Fällen hat derjenige die Kosten der Leichenschau und der Ausstellung der Todesbescheinigung zu tragen oder dem Veranlasser der Leichenschau zu erstatten, der für die Kosten der Bestattung aufzukommen hat.",
        },
        {
            titel: "§ 6 Aufbewahrung von Leichen",
            content_1: "(1) 1 Jede Leiche ist unverzüglich nach der Feststellung des Todes in eine Leichenhalle zu überführen. 2 Dies gilt nicht während der ersten 36 Stunden nach dem Eintritt des Todes sowie dann nicht, wenn die Leiche unverzüglich zur Bestattung an einen Ort außerhalb der Freien und Hansestadt Hamburg überführt wird. 3 Die zuständige Behörde kann im Einzelfall Ausnahmen zulassen, sofern Gründe der Hygiene nicht entgegenstehen, oder die Frist des Satzes 2 aus Gründen der Hygiene abkürzen. 4 Als Leichenhalle im Sinne dieser Bestimmung gelten neben den öffentlichen Leichenhallen auch die Leichenaufbewahrungsräume des Gerichtsärztlichen Dienstes und des Instituts für Rechtsmedizin, der Krankenhäuser, der staatlichen Pflegeheime, der Friedhöfe und der Feuerbestattungsanlagen.",
            content_2: "(2) 1 Bei Leichen, die nach § 2 Absatz 5 zu kennzeichnen sind, ist die Kennzeichnung auf dem Sarg von demjenigen zu wiederholen, der die Einsargung vornimmt. 2 Solche Särge dürfen nur mit Zustimmung der zuständigen Behörde oder auf Weisung eines in § 87 Absatz 2 Satz 2 der Strafprozessordnung genannten Arztes geöffnet werden."
        },
        {
            titel: "§ 7 Beförderung von Leichen",
            content_1: "(1) 1 Jede Leiche ist unverzüglich nach der Feststellung des Todes in eine Leichenhalle zu überführen. 2 Dies gilt nicht während der ersten 36 Stunden nach dem Eintritt des Todes sowie dann nicht, wenn die Leiche unverzüglich zur Bestattung an einen Ort außerhalb der Freien und Hansestadt Hamburg überführt wird. 3 Die zuständige Behörde kann im Einzelfall Ausnahmen zulassen, sofern Gründe der Hygiene nicht entgegenstehen, oder die Frist des Satzes 2 aus Gründen der Hygiene abkürzen. 4 Als Leichenhalle im Sinne dieser Bestimmung gelten neben den öffentlichen Leichenhallen auch die Leichenaufbewahrungsräume des Gerichtsärztlichen Dienstes und des Instituts für Rechtsmedizin, der Krankenhäuser, der staatlichen Pflegeheime, der Friedhöfe und der Feuerbestattungsanlagen.",
            content_2: "(2) 1 Leichen sind in verschlossenen, feuchtigkeitsundurchlässigen, widerstandsfähigen Särgen ohne vermeidbare Unterbrechung zum Bestimmungsort zu befördern. 2 Ist der Tod an Bord eines Schiffes außerhalb eines Hafens eingetreten, so kann anstelle eines Sarges eine andere geeignete Umhüllung verwendet werden. 3 Zur Beförderung von Leichen im Straßenverkehr sind solche Wagen zu benutzen, die zur Leichenbeförderung eingerichtet sind und ausschließlich hierfür oder für Bestattungszwecke verwendet werden (Leichenwagen). 4 Die Beförderung von Leichen in Anhängern an Kraftfahrzeugen ist nicht zulässig. 5 Die Sätze 1 bis 4 gelten nicht für die Bergung von Leichen, insbesondere die Beförderung tödlich Verunglückter von der Unfallstelle.",
        },
        {
            titel: "§ 8 Ausgrabung von Leichen",
            content_1: "Die Ausgrabung von Leichen vor Ablauf der Ruhezeit ist nur mit Genehmigung der zuständigen Behörde und, mit Ausnahme der Ausgrabung nach § 87 Absatz 3 der Strafprozessordnung , nur in den Monaten November bis März zulässig."
        },
        {
            titel: "§ 9 Überwachung",
            content_1: "Die Leichenhallen und Friedhöfe werden von der zuständigen Behörde in gesundheitlicher Hinsicht überwacht."
        }
     ],
    [   "Zweiter Abschnitt: Bestattungswesen",
        {
            titel: "§ 10 Bestattungspflicht",
            content_1: "(1) 1 Leichen sind zu bestatten. 2 Totgeborene Leibesfrüchte mit einem Geburtsgewicht unter 1000 Gramm sind nur auf Wunsch eines Elternteils zu bestatten. 3 Für die Bestattung haben die Angehörigen ( § 22 Absatz 4 ) zu sorgen. 4 Wird im Todesfall niemand tätig, veranlasst die zuständige Behörde die Überführung der Leiche in eine Leichenhalle. 5 Wird für eine in eine Leichenhalle eingelieferte Leiche kein Antrag auf Bestattung gestellt, so kann die zuständige Behörde vierzehn Tage nach Einlieferung die Bestattung in einer Reihengrabstätte eines Friedhofes veranlassen. 6 Wird kein Antrag auf Beisetzung einer Urne gestellt, so kann die zuständige Behörde einen Monat nach der Einäscherung die Beisetzung in einer Reihengrabstätte veranlassen. 7 Die Maßnahmen werden auf Kosten des Pflichtigen vorgenommen. 8 Die Sätze 4 und 5 gelten nicht, wenn die Antragstellung oder die Überführung zu einem Friedhof nachweisbar veranlasst und in nächster Zeit zu erwarten ist.",
            content_2: "(2) 1 Totgeborene Leibesfrüchte mit einem Geburtsgewicht unter 1000 Gramm, die nicht bestattet werden, sowie aus Schwangerschaftsabbrüchen stammende Föten und Embryonen sind hygienisch einwandfrei und dem sittlichen Empfinden entsprechend einzuäschern und unter freiwilliger Teilnahme der Eltern auf einem Grabfeld zur Ruhe zu betten, sofern sie nicht rechtmäßig für wissenschaftliche Zwecke benötigt werden. 2 Abgetrennte Körperteile, Organe und Teile von Organen, die nicht für Transplantationen, für wissenschaftliche Zwecke oder für die Herstellung von Arzneimitteln benötigt werden, sind hygienisch einwandfrei und dem sittlichen Empfinden entsprechend zu beseitigen.",
            content_3: "(3) 1 Leichen dürfen wissenschaftlichen Zwecken nur zugeführt werden, wenn eine schriftliche Zustimmung des Verstorbenen vorliegt und die Voraussetzungen für eine Erdbestattung vorliegen. 2 Die wissenschaftliche Einrichtung veranlasst die Bestattung der Leiche, sobald sie nicht mehr wissenschaftlichen Zwecken dient."

        },
        {
            titel: "§ 11 Bestattungsart",
            content_1: "1 Die Bestattung kann als Erd- oder Feuerbestattung durchgeführt werden. 2 Die Art der Bestattung richtet sich nach dem Willen des Verstorbenen. 3 Ist ein Wille des Verstorbenen nicht bekannt, bestimmt der Auftraggeber die Bestattungsart."
        },
        {
            titel: "§ 12 Zulässigkeit der Bestattung",
            content_1: "(1) 1 Die Erdbestattung ist zulässig, wenn eine Leichenschau durchgeführt worden ist und eine Bescheinigung mit dem Vermerk der Eintragung in das Sterbebuch oder eine Genehmigung der zuständigen Behörde vorgelegt wird. 2 Die Erdbestattung einer Fehlgeburt ist zulässig, wenn die Bescheinigung eines bei oder nach dem Geburtsvorgang hinzugezogenen Arztes darüber vorgelegt wird, dass keine Anhaltspunkte für ein nichtnatürliches Geschehen bestehen.",
            content_2: "(2) Die Feuerbestattung ist zulässig, wenn die Voraussetzungen des Absatzes 1 vorliegen und wenn aufgrund einer zusätzlichen Leichenschau bestätigt worden ist, dass keine Anhaltspunkte für einen nichtnatürlichen Tod bestehen, oder wenn in den Fällen nach 159 Absatz 1 der Strafprozessordnung die schriftliche Genehmigung der Staatsanwaltschaft zur Bestattung vorliegt.",
            content_3: "(3) 1 Die zusätzliche Leichenschau nach Absatz 2 können vornehmen",
            content_4: "a) die zuständige Behörde durch einen Arzt, der die Anerkennung zum Führen der Gebietsbezeichnung öffentliches Gesundheitswesen oder Rechtsmedizin hat,",
            content_5:  "b) ein in § 87 Absatz 2 Satz 2 der Strafprozessordnung genannter Arzt oder",
            content_6: "c) ein Arzt mit besonderen Kenntnissen auf dem Gebiet der Rechtsmedizin, der von der zuständigen Behörde hierfür ermächtigt worden ist.",
            content_7: "2 Für die Durchführung der zusätzlichen Leichenschau erhält der Arzt eine Durchschrift der Todesbescheinigung und, sofern eine Sektion stattgefunden hat, des Sektionsbefundes. 3 Er kann ergänzende Auskünfte einholen. 4 Die Unterlagen sind höchstens fünf Jahre lang aufzubewahren. 5 § 2 Absatz 4 und § 4 gelten entsprechend."
        },
        {
            titel: "§ 13 Feuerbestattung",
            content_1: "(1) Leichen dürfen in der Freien und Hansestadt Hamburg nur in den Feuerbestattungsanlagen Öjendorf und Ohlsdorf der Hamburger Friedhöfe - Anstalt öffentlichen Rechts - eingeäschert werden.",
            content_2: "(2) 1 Die Asche jeder Leiche ist in ein von der Hamburger Friedhöfe - Anstalt öffentlichen Rechts - zu verschließendes Behältnis (Urne) aufzunehmen. 2 Es muss jederzeit festzustellen sein, wo die Urne aufbewahrt wird und um wessen Asche es sich handelt.",
            content_3: "(3) 1 Urnen sind beizusetzen oder in einem Kolumbarium aufzustellen. 2 Sie werden durch die Hamburger Friedhöfe - Anstalt öffentlichen Rechts - von der Feuerbestattungsanlage zur Beisetzung auf einen Friedhof befördert. 3 Die Hamburger Friedhöfe - Anstalt öffentlichen Rechts - kann Ausnahmen zulassen",
            content_4: "a) zur Beisetzung einer Urne von einem Schiff auf See, wenn dies dem Willen des Verstorbenen entspricht und andere Rechtsvorschriften nicht entgegenstehen,",
            content_5: "b) zum Versand einer Urne nach Friedhöfen außerhalb der Freien und Hansestadt Hamburg.",
            content_6: "4 Die Beförderung oder der Versand einer Urne sind erst zulässig, wenn eine Beisetzungsmöglichkeit am Bestimmungsort nachgewiesen worden ist."
        },
        {
            titel: "§ 14 Friedhofszwang",
            content_1: "1 Beisetzungen außerhalb staatlicher, von der Hamburger Friedhöfe - Anstalt öffentlichen Rechts - betriebener oder kirchlicher Friedhöfe sind nicht zulässig. 2 Ausnahmen können in besonderen Fällen von der zuständigen Behörde zugelassen werden."
        }
    ],
    [   "Dritter Abschnitt: Staatliches Friedhofswesen",
        {
            titel: "§ 15 Geltungsbereich",
            content_1: "Die Vorschriften dieses Abschnittes gelten für die staatlichen Friedhöfe in der Freien und Hansestadt Hamburg und für die von der Hamburger Friedhöfe - Anstalt öffentlichen Rechts - betriebenen Friedhöfe."
        },
        {
            titel: "§ 16 Widmung und Verwaltung der Friedhöfe",
            content_1: "(1) 1 Die bestehenden Friedhöfe ergeben sich aus der Anlage 1 . 2 Weitere Friedhöfe können dadurch geschaffen werden, dass der Senat Flächen für diesen Zweck widmet und die zuständige Behörde die Widmung öffentlich bekannt gibt.",
            content_2: "(2) 1 Die zuständigen Behörden haben über Nutzungs- und Beisetzungsrechte auf den Friedhöfen sowie über Bestattungen Buch zu führen. 2 Sie sind verpflichtet, die allgemeinen Friedhofsanlagen und -einrichtungen unter Berücksichtigung der Zielsetzungen der Gartendenkmalpflege zu unterhalten."
        },
        {
            titel: "§ 17 Friedhofszweck",
            content_1: "1 Die Friedhöfe dienen der Bestattung der Verstorbenen, für die ein Antrag auf Beisetzung gestellt wird. 2 Sie sind Totengedenkstätten."
        },
        {
            titel: "§ 18 Bekenntnisgebräuche",
            content_1: "1 Die Ausübung kirchlicher Amtshandlungen sowie religiöser und weltanschaulicher Gebräuche bei Bestattungen und Totengedenkfeiern wird gewährleistet. 2 Auf den Ablauf anderer Bestattungen ist Rücksicht zu nehmen."
        },
        {
            titel: "§ 19 Haftung, vorübergehende Schließung",
            content_1: "(1) 1 Die Freie und Hansestadt Hamburg hat keine Obhutspflicht für Grabstätten, ihre Ausstattung und Grabgegenstände. 2 Sie ist nicht verpflichtet, Vorkehrungen zur Verhütung von Schäden zu treffen, die durch fremde Personen oder Tiere verursacht werden können.",
            content_2: "(2) Friedhöfe oder Friedhofsteile können zur Abwendung von Gefahren für die öffentliche Sicherheit und Ordnung von der zuständigen Behörde vorübergehend geschlossen oder für die Benutzung mit Fahrzeugen gesperrt werden."
        },
        {
            titel: "§ 20 Gewerbetreibende",
            content_1: "(1) Auf den Friedhöfen darf unbeschadet der nach § 32 Nummer 2 Buchstabe c erlassenen Vorschriften nur solche gewerbliche Tätigkeit ausgeübt werden, die dem Zweck der Friedhöfe dient.",
            content_2: "(2) Gewerbetreibende bedürfen für ihre gewerbliche Tätigkeit auf den Friedhöfen der Genehmigung der zuständigen Behörde. Das Genehmigungsverfahren kann über den Einheitlichen Ansprechpartner Hamburg abgewickelt werden. Es gelten die Bestimmungen über die einheitliche Stelle nach §§ 71a bis 71e sowie über die Genehmigungsfiktion nach § 42a des Hamburgischen Verwaltungsverfahrensgesetzes vom 9. November 1977 (HmbGVBl. S. 333, 402), zuletzt geändert am 15. Dezember 2009 (HmbGVBl. S. 444, 449), in der jeweils geltenden Fassung.",
            content_3: "(3) Die Genehmigung darf nur versagt werden, wenn dem Antragsteller die für die Ausübung der Tätigkeit auf dem Friedhof erforderliche fachliche Qualifikation oder persönliche Zuverlässigkeit fehlt.",
            content_4: "(4) 1 Die Genehmigung ist zu widerrufen, wenn der Gewerbetreibende die Voraussetzungen für ihre Erteilung nicht mehr erfüllt. 2 Sie kann widerrufen werden, wenn er gegen die Vorschriften dieses Gesetzes oder der dazu erlassenen Rechtsverordnungen verstoßen hat; vor Ablauf eines Jahres darf sie nicht wieder erteilt werden.",
            content_5: "(5) Auf den Friedhöfen dürfen gewerbliche Arbeiten nur während der von der zuständigen Behörde festgesetzten Zeit unter Wahrung der Würde des Ortes ausgeführt werden."
        },
        {
            titel: "§ 21 Grabstätten",
            content_1: "(1) 1 Leichen und Urnen werden in Reihen-, Wahl- oder Ehrengrabstätten beigesetzt. 2 Es besteht kein Anspruch auf eine der Lage nach bestimmte Grabstätte und auf Unveränderlichkeit ihrer Umgebung.",
            content_2: "(2) 1 Reihengrabstätten sind einstellige Grabstätten. 2 Sie werden in Grabfeldern der Reihe nach belegt und nur bei Vorliegen eines Todesfalles für die Dauer der Ruhezeit vergeben.",
            content_3: "(3) 1 Anonyme Reihengrabstätten werden nicht gekennzeichnet. 2 Sie werden vergeben, wenn dies dem Willen des Verstorbenen entspricht.",
            content_4: "(4) 1 Wahlgrabstätten sind ein- oder mehrstellige Grabstätten. 2 An ihnen wird auf Antrag und nach Zahlung der Überlassungsgebühr für die Dauer von 25 Jahren ein Nutzungsrecht eingeräumt (Überlassungszeit).",
            content_5: "(5) 1 Ehrengrabstätten sind ein- oder mehrstellige Grabstätten. 2 Sie werden aus besonderem Anlass auf Beschluss des Senats auf Friedhofsdauer angelegt."
        },
        {
            titel: "§ 22 Wahlgrabstätten",
            content_1: "(1) 1 Das Nutzungsrecht an einer Wahlgrabstätte umfasst die Befugnis des Berechtigten zu bestimmen, wer auf der Grabstätte beigesetzt werden soll. 2 Bei der erstmaligen Einräumung des Nutzungsrechtes kann der Nutzungsberechtigte auswählen, ob die Grabstätte in einem Grabfeld mit allgemeinen oder mit zusätzlichen Gestaltungsvorschriften liegen soll und die Art und Größe der Grabstätte festlegen.",
            content_2: "(2) 1 Der Nutzungsberechtigte soll schon beim Erwerb des Nutzungsrechtes für den Fall seines Ablebens gegenüber der zuständigen Behörde einen Nachfolger im Nutzungsrecht bestimmen. 2 Das Einverständnis des Bestimmten ist nachzuweisen.",
            content_3: "(3) 1 Stirbt der Nutzungsberechtigte, ohne einen Nachfolger bestimmt oder das Einverständnis des von ihm Bestimmten nachgewiesen zu haben, so wird das Nutzungsrecht von der zuständigen Behörde auf die Angehörigen in der in Absatz 4 festgelegten Rangfolge übertragen. 2 Mit der Übertragung des Nutzungsrechtes setzt die zuständige Behörde dem betroffenen Angehörigen eine Frist zur Erklärung darüber, ob er das Nutzungsrecht annimmt. 3 Erklärt der Angehörige die Annahme des Nutzungsrechtes nicht innerhalb der gesetzten Frist, kann die zuständige Behörde das Nutzungsrecht auf den nächsten Angehörigen nach Maßgabe der in Absatz 4 fest gelegten Rangfolge übertragen.",
            content_4: "(4) 1 Angehörige im Sinne des Absatzes 3 sind",
            content_5: "a) der Ehegatte oder der Lebenspartner ,",
            content_6: "b) die ehelichen und nichtehelichen Kinder,",
            content_7: "c) die Ehegatten oder Lebenspartner der ehelichen und nichtehelichen Kinder,",
            content_8: "d) die Stiefkinder,",
            content_9: "e) die Ehegatten oder Lebenspartner der Stiefkinder,",
            content_10: "f) die Enkel,",
            content_11: "g) die Ehegatten oder Lebenspartner der Enkel,",
            content_12: "h) die Eltern,",
            content_13: "i) die Geschwister,",
            content_14: "j) die Stiefgeschwister,",
            content_15: "k) die Großeltern,",
            content_16: "l) die Verschwägerten,",
            content_17:  "m) die Kinder der Geschwister,",
            content_18: "n) die Geschwister der Eltern,",
            content_19: "o) die Kinder der Geschwister der Eltern,",
            content_20: "p) die Verlobte/der Verlobte, auch im Sinne des Lebenspartnerschaftsgesetzes,",
            content_21: "q) die Lebensgefährtin/der Lebensgefährte.",
            content_22: "2 Sind mehrere Personen einer Rangfolge vorhanden, so hat der ältere Angehörige das Vorrecht vor dem jüngeren Angehörigen.",
            content_23: "(5) Sind keine Angehörigen vorhanden, so kann die zuständige Behörde das Nutzungsrecht auch auf andere Personen übertragen, wenn ein berechtigtes Interesse am Erhalt der Grabstätte nachgewiesen wird.",
            content_24: "(6) Der Nachfolger im Nutzungsrecht ist an die Bestimmung von Beisetzungsberechtigten durch vorherige Nutzungsberechtigte gebunden.",
            content_25: "(7) 1 Das Nutzungsrecht kann auf Antrag des Nutzungsberechtigten von der zuständigen Behörde auf einen Dritten übertragen werden. 2 Absatz 2 Satz 2 und Absatz 6 gelten entsprechend.",
            content_26: "(8) 1 Stirbt einer der in Absatz 4 bezeichneten Angehörigen, dessen Beisetzung auf der Wahlgrabstätte noch nicht bestimmt ist, und ist der Nutzungsberechtigte nicht spätestens vier Tage vor der Beisetzung der Leiche oder innerhalb eines Monats nach der Einäscherung erreichbar, so kann jeder der in Absatz 4 bezeichneten Angehörigen bestimmen, dass der verstorbene Angehörige auf der Wahlgrabstätte beigesetzt werden darf. 2 Bei voneinander abweichenden Erklärungen der Angehörigen gilt die Rangfolge des Absatzes 4."
        },
        {
            titel: "§ 23 Gestaltung der Grabstätten",
            content_1: "(1) 1 Die Grabstätten sind so zu gestalten und der Umgebung anzupassen, dass die Würde und die historisch gewachsenen Strukturen des Friedhofes gewahrt werden. 2 Es ist Rücksicht auf charakteristische Grabfelder und geschichtlich oder künstlerisch bedeutende Grabmale zu nehmen. 3 Grabhügel sind nicht zulässig.",
            content_2: "(2) Die zuständige Behörde kann Grabfelder einrichten, in denen zusätzliche Gestaltungsvorschriften nach Maßgabe einer Rechtsverordnung nach § 32 Nummer 2 Buchstabe h zu beachten sind."
        },
        {
            titel: "§ 24 Grabmal",
            content_1: "(1) 1 Die Errichtung und Veränderung von Grabmalen bedürfen der schriftlichen Genehmigung der zuständigen Behörde. 2 Die Genehmigung ist auf Antrag zu erteilen, wenn dem Vorhaben keine anderen öffentlich-rechtlichen Vorschriften entgegenstehen, das Grabmal den Anforderungen dieses Gesetzes genügt und zusätzlichen Gestaltungsvorschriften nach Maßgabe einer Rechtsverordnung nach § 32 Nummer 2 Buchstabe h entspricht.",
            content_2: "(2) Das Grabmal ist, wenn seine Größe es erfordert, auf einem Fundament zu errichten und darauf so zu befestigen, dass es dauernd standsicher ist und auch beim Öffnen benachbarter Gräber nicht umstürzt oder sich senkt.",
            content_3: "(3) 1 Der Nutzungsberechtigte einer Wahlgrabstätte ist dafür verantwortlich, dass das Grabmal sich dauernd in standsicherem Zustand befindet. 2 Eine Aufforderung zur Wiederherstellung der Standsicherheit eines Grabmales darf öffentlich bekannt gegeben werden, wenn die Anschrift des Nutzungsberechtigten nicht bekannt ist.",
            content_4: "(4) Die zuständige Behörde kann für geschichtlich oder künstlerisch bedeutende Grabmale besondere Erhaltungspflichten gegenüber dem Nutzungsberechtigten festlegen.",
            content_5: "(5) Zur Abwendung einer unmittelbaren Gefahr für die öffentliche Sicherheit kann das Grabmal von der zuständigen Behörde auf Kosten des Nutzungsberechtigten umgelegt werden.",
            content_6: "(6) Wird ein Grabmal im Widerspruch zu öffentlich-rechtlichen Vorschriften errichtet oder geändert, so kann die zuständige Behörde die Beseitigung des Grabmales anordnen, wenn nicht auf andere Weise rechtmäßige Zustände hergestellt werden können.",
            content_7: "(7) Die Absätze 1 bis 6 gelten für sonstige auf der Grabstätte befindliche Sachen (Grabgegenstände) entsprechend."
        },
        {
            titel: "§ 25 Grabpflege",
            content_1: "(1) 1 Grabstätten sind zu pflegen. 2 Bei Wahlgrabstätten obliegt die Grabpflege dem Nutzungsberechtigten, bei Reihengrabstätten mit Ausnahme der anonymen Reihengrabstätten obliegt sie dem Auftraggeber der Bestattung. 3 Nutzungsberechtigter und Auftraggeber der Bestattung können einen anderen mit der Ausführung der Pflegearbeiten beauftragen; ihre Verpflichtung bleibt unberührt.",
            content_2: "(2) 1 Bei allen Grabstätten führt die zuständige Behörde die Erstherrichtung und die Mindestunterhaltung auf Kosten desjenigen aus, der das Nutzungsrecht erworben oder die Bestattung veranlasst hat. 2 Die Kosten sind für die Dauer der Überlassungszeit im Voraus zu entrichten.",
            content_3: "(3) 1 Kommt der Nutzungsberechtigte seiner Pflegeverpflichtung trotz Aufforderung und Fristsetzung durch die zuständige Behörde nicht nach, so kann die zuständige Behörde auf seine Kosten den Aufwuchs entfernen, eine Rasenanlage herstellen und bis zum Ablauf der Überlassungszeit unterhalten. 2 Ist die Anschrift des Nutzungsberechtigten nicht bekannt, so kann die Aufforderung nach Satz 1 öffentlich bekannt gegeben werden.",
            content_4: "(4) Ehrengrabstätten werden von der zuständigen Behörde gepflegt."
        },
        {
            titel: "§ 26 Ruhezeit",
            content_1: "(1) 1 Die Ruhezeit für Leichen und Urnen beträgt 25 Jahre. 2 Sie beginnt mit der Beisetzung.",
            content_2: "(2) 1 Vor Ablauf der Ruhezeit darf eine Grabstelle nicht neu belegt werden. 2 Die zuständige Behörde kann im Einzelfall Ausnahmen zulassen.",
        },
        {
            titel: "§ 27 Verlängerung des Nutzungsrechtes",
            content_1: "(1) Bei jeder Beisetzung in einer Wahlgrabstätte ist das Nutzungsrecht für die Wahlgrabstätte bis zum Ablauf der Ruhezeit der beizusetzenden Leiche oder Urne zu verlängern.",
            content_2: "(2) 1 Das Nutzungsrecht an einer Wahlgrabstätte ist auf Antrag des Nutzungsberechtigten um mindestens 5 Jahre, höchstens um 25 Jahre zu verlängern. 2 Die zuständige Behörde kann Ausnahmen zulassen.",
            content_3: "(3) Die zuständige Behörde kann eine Verlängerung der Überlassungszeit mit Auflagen versehen, wenn der Nutzungsberechtigte seiner Pflegeverpflichtung nicht nachgekommen ist oder nicht für die Standsicherheit des Grabmales gesorgt hat."
        },
        {
            titel: "§ 28 Umbettung",
            content_1: "(1) 1 Die Umbettung von Leichen und Urnen während der Ruhezeit ist nicht zulässig. 2 Eine Ausnahme kann auf Antrag von der zuständigen Behörde zugelassen werden, wenn ein wichtiger Grund vorliegt, der eine Störung der Totenruhe rechtfertigt.",
            content_2: "(2) 1 Ist eine Erlaubnis nach Absatz 1 erteilt, so wird die Umbettung einer Leiche oder Urne von der zuständigen Behörde durchgeführt. 2 Leichen dürfen nur in den Monaten November bis März umgebettet werden.",
            content_3: "(3) Mit der Umbettung beginnt keine neue Ruhezeit.",
            content_4: "(4) Das Herausnehmen von Urnen anlässlich der Beisetzung einer Leiche in einer Wahlgrabstätte und die anschließende Beisetzung der Urnen auf derselben Grabstelle sind keine Umbettung."
        },
        {
            titel: "§ 29 Ablauf von Rechten",
            content_1: "(1) 1 Nach Ablauf der Ruhezeit von Leichen und Urnen in Reihengrabstätten, bei Wahlgrabstätten nach Erlöschen des Nutzungsrechtes, werden die Grabmale und Grabgegenstände von der zuständigen Behörde mit Ausnahme der Pflanzen dem Berechtigten auf Antrag ausgehändigt. 2 Wird der Antrag nicht innerhalb einer von der zuständigen Behörde zu bestimmenden Frist gestellt, so gehen die Grabmale und Grabgegenstände in das Eigentum der Freien und Hansestadt Hamburg oder der Hamburger Friedhöfe - Anstalt öffentlichen Rechts - über.",
            content_2: "(2) 1 Absatz 1 gilt nicht für Grabstätten, die unter Denkmalschutz gestellt sind oder von der zuständigen Behörde als denkmalschutzwürdig festgestellt worden sind. 2 Nach Erlöschen des Nutzungsrechtes an solchen Wahlgrabstätten wird die zuständige Behörde für die Standsicherheit des Grabmales verantwortlich.",
            content_3: "(3) 1 Der Ablauf der Ruhezeit von Leichen und Urnen bei Reihengrabstätten, das Erlöschen des Nutzungsrechtes bei Wahlgrabstätten und die nach Absatz 1 Satz 2 von der zuständigen Behörde zu bestimmende Frist sind mindestens ein halbes Jahr vorher öffentlich bekannt zu geben. 2 Der Nutzungsberechtigte einer Wahlgrabstätte kann beantragen, dass der Ablauf der Überlassungszeit ihm oder einem von ihm beauftragten Vertreter gegen Erstattung der damit verbundenen Kosten mitgeteilt wird. 3 Dabei ist auch auf die Wirkung der Frist hinzuweisen."
        },
        {
            titel: "§ 30 Schließung und Aufhebung von Friedhöfen",
            content_1: "(1) 1 Schließung und Aufhebung der Friedhöfe werden durch Gesetz geregelt. 2 Teile von Friedhöfen und einzelne Grabstätten können aus zwingenden Gründen des öffentlichen Interesses von der zuständigen Behörde geschlossen oder aufgehoben werden.",
            content_2: "(2) 1 Durch die Schließung eines Friedhofes oder Friedhofsteiles wird die Möglichkeit weiterer Beisetzungen ausgeschlossen; durch die Aufhebung geht die Eigenschaft als Ruhestätte verloren. 2 Die Schließung oder Aufhebung von Friedhofsteilen und die Aufhebung einzelner Grabstätten werden öffentlich bekannt gegeben. 3 Bei Wahlgrabstätten wird der Nutzungsberechtigte benachrichtigt, sofern seine Anschrift der zuständigen Behörde bekannt ist.",
            content_3: "(3) Sind bei der Aufhebung Ruhezeiten noch nicht abgelaufen, so sind die in Reihengrabstätten Beigesetzten für die restliche Ruhezeit, die in Wahlgrabstätten Beigesetzten für die restliche Überlassungszeit auf Kosten der Freien und Hansestadt Hamburg oder der Hamburger Friedhöfe - Anstalt öffentlichen Rechts - in eine andere Grabstätte umzubetten."
        },
    ],
    [   "Vierter Abschnitt: Friedhöfe anderer Träger",
        {
            titel: "§ 31 Kirchliche Friedhöfe",
            content_1: "(1) In der Freien und Hansestadt Hamburg bestehen die aus der Anlage 2 ersichtlichen Friedhöfe der Religionsgesellschaften des öffentlichen Rechts (kirchliche Friedhöfe).",
            content_2: "(2) Die Religionsgesellschaften des öffentlichen Rechts dürfen im Rahmen des geltenden Rechts neue Friedhöfe einrichten sowie ihre Friedhöfe verändern und schließen.",
            content_3: "(3) Die Religionsgesellschaften des öffentlichen Rechts erlassen für ihre Friedhöfe Friedhofsordnungen und Friedhofsgebührenordnungen; sie können diese Ordnungen im Amtlichen Anzeiger bekannt machen.",
            content_4: "(4) 1 Die Ruhezeit auf kirchlichen Friedhöfen beträgt mindestens 25 Jahre. 2 Sie beginnt mit der Beisetzung. 3 Auf Antrag des Friedhofsträgers kann die zuständige Behörde für den gesamten Friedhof eine Ruhezeit unter 25 Jahren oder für einzelne Grabstellen eine Belegung vor Ablauf der Ruhezeit zulassen, wenn gesundheitliche Gefahren auszuschließen sind.",
            content_5: "(5) Die zuständige Behörde kann kirchliche Friedhöfe oder Friedhofsteile schließen, wenn gesundheitliche Gefahren für die Umgebung zu befürchten sind.",
            content_6: "(6) 1 Die Friedhofsgebühren werden auf Antrag des Friedhofsträgers im Verwaltungszwangsverfahren eingezogen. 2 Die Körperschaft, zu deren Gunsten vollstreckt wird, hat der Freien und Hansestadt Hamburg die Kosten der Verwaltungsvollstreckung (Gebühren und Auslagen) zu erstatten, die durch Zahlung des Pflichtigen nicht gedeckt werden."
        },
    ],
    [   "Fünfter Abschnitt: Überleitungs- und Schlussvorschriften",
        {
            titel: "§ 32 Rechtsverordnungsermächtigungen",
            content_1: "Der Senat wird ermächtigt, durch Rechtsverordnung",
            content_2: "1. Vorschriften zu erlassen über die Beschaffenheit und Verwendung von Särgen, Leichenhüllen, Leichenbekleidung und Urnen sowie das Öffnen der Särge,",
            content_3: "2. Vorschriften für die staatlichen Friedhöfe zu erlassen über",
            content_4: "a) das Verhalten auf den Friedhöfen einschließlich der Benutzung von Kraftfahrzeugen,",
            content_5: "b) die Anwendung von Pflanzenschutzmitteln auf Grabstätten,",
            content_6: "c) das Anbieten von Waren und Diensten auf den Friedhöfen,",
            content_7: "d) die Beschaffenheit des Sarg- und Grabschmuckes,",
            content_8: "e) die Größe der Särge und Urnen,",
            content_9: "f) die Durchführung der Bestattungen, insbesondere die Benutzung der Leichenhallen und Feierräume und ihrer Einrichtungen sowie das Ausheben und Verfüllen der Gräber,",
            content_10: "g) die Größe und Belegung der Grabstätten sowie über weitere Beisetzungen während der Ruhezeit,",
            content_11:  "h) die Gestaltung von Grabstätten in bestimmten Grabfeldern, insbesondere über Grabausstattung und über Größe, Material, Schriftzeichen und Symbole der Grabmale (zusätzliche Gestaltungsvorschriften)."
        },
        {
            titel: "§ 33 Ordnungswidrigkeiten",
            content_1: "(1) Ordnungswidrig handelt, wer vorsätzlich oder fahrlässig",
            content_2: "1. entgegen § 1 Absatz 2 Satz 1 als Leiter einer Einrichtung nicht sicherstellt, dass die Leichenschau unverzüglich vorgenommen wird,",
            content_3: "2. entgegen § 1 Absatz 2 Satz 2 als Anzeigepflichtiger die Leichenschau nicht unverzüglich veranlasst,",
            content_4: "3. entgegen § 1 Absatz 3 oder § 2 Absatz 1 oder 2 als Arzt die Leichenschau nicht, nicht rechtzeitig oder nicht in der erforderlichen Weise vornimmt,",
            content_5: "4. entgegen § 2 Absatz 4 oder § 3 Absatz 3 Satz 4 als Arzt die Polizei oder die Staatsanwaltschaft nicht oder nicht rechtzeitig benachrichtigt,",
            content_6: "5. entgegen § 2 Absatz 5 oder § 3 Absatz 3 Satz 4 als Arzt nicht dafür sorgt, dass eine Leiche mit einem Hinweis auf eine übertragbare Krankheit gekennzeichnet wird,",
            content_7: "6. entgegen § 3 Absatz 1 als Arzt eine Todesbescheinigung nicht, nicht richtig oder nicht vollständig ausstellt oder entgegen § 3 Absatz 3 Satz 2 eine Todesbescheinigung nicht oder nicht richtig ergänzt oder berichtigt oder die ergänzte oder berichtigte Todesbescheinigung nicht der zuständigen Behörde übersendet,",
            content_8: "7. entgegen § 3 Absatz 5 Satz 3 oder § 34 Absatz 5 personenbezogene Angaben für andere Zwecke verwendet,",
            content_9: "8. entgegen § 4 oder § 12 Absatz 3 Satz 5 eine Auskunft nicht oder nicht richtig erteilt,",
            content_10: "9. entgegen § 6 Absatz 1 Satz 1 eine Leiche nicht unverzüglich in eine Leichenhalle gemäß § 6 Absatz 1 Satz 4 überführt,",
            content_11: "9 a.entgegen § 6 Absatz 1 Satz 2 eine Leiche nicht unverzüglich zur Bestattung an einen Ort außerhalb der Freien und Hansestadt Hamburg überführt",
            content_12: "10. entgegen § 7 Absatz 1 für die Beförderung einer Leiche im Straßenverkehr einen Wagen benutzt, der hierfür nicht eingerichtet ist oder der nicht ausschließlich für Bestattungszwecke verwendet wird, oder eine Leiche in einem Anhänger an einem Kraftfahrzeug befördert,",
            content_13: "11. entgegen § 8 eine Leiche ausgräbt,",
            content_14: "12. entgegen § 13 Absatz 1 die Einäscherung einer Leiche außerhalb der Feuerbestattungsanlagen Öjendorf oder Ohlsdorf vornimmt,",
            content_15: "13. entgegen § 14 eine Beisetzung außerhalb von Friedhöfen ohne Genehmigung der zuständigen Behörde vornimmt,",
            content_16: "14. entgegen § 20 Absatz 2 eine gewerbliche Tätigkeit ohne Genehmigung der zuständigen Behörde ausübt,",
            content_17: "15. entgegen § 20 Absatz 5 eine gewerbliche Tätigkeit auf dem Friedhof außerhalb der von der zuständigen Behörde festgesetzten Zeit ausführt,",
            content_18: "16. einer auf Grund dieses Gesetzes erlassenen Rechtsverordnung zuwiderhandelt, soweit die Rechtsverordnung für bestimmte Tatbestände auf diese Vorschrift verweist.",
            content_19: "(2) Die Ordnungswidrigkeit kann mit einer Geldbuße geahndet werden, und zwar in den Fällen des Absatzes 1 Nummern 7, 14 und 15 mit einer Geldbuße bis zu zweitausendfünfhundert Euro."
        },
        {
            titel: "§ 34 Überleitung",
            content_1: "(1) Die Dauer der Ruhezeit der vor Inkrafttreten dieses Gesetzes beigesetzten Leichen und Urnen bestimmt sich nach den bisherigen Vorschriften.",
            content_2: "(2) Für alle Grabstätten, die vor Inkrafttreten dieses Gesetzes auf staatlichen Friedhöfen überlassen worden sind, gelten die Vorschriften dieses Gesetzes.",
            content_3: "(3) 1 Bei Grabstätten auf staatlichen Friedhöfen, die vor Inkrafttreten dieses Gesetzes für die Bestattung bestimmter Personen oder Personengruppen ohne zeitliche Begrenzung bis zur Beisetzung des letzten Berechtigten überlassen worden sind, erlischt das Nutzungsrecht mit Ablauf der Ruhezeit der zuletzt beigesetzten Leiche oder Urne. 2 Hat auf einer solchen Grabstätte seit 1. April 1970 keine Beisetzung mehr stattgefunden und sind die Ruhezeiten sämtlicher beigesetzten Leichen oder Urnen abgelaufen, erlischt das Nutzungsrecht mit Ablauf des 31. Oktober 2001. 3 Die zuständige Behörde verlängert das Nutzungsrecht auf Antrag unentgeltlich, bis alle Berechtigten, die bis zum Inkrafttreten des Gesetzes über die Gemeindefriedhöfe vom 1. November 1948 (Sammlung des bereinigten hamburgischen Landesrechts I 2134-a) auf der Grabstätte hätten beigesetzt werden können, beigesetzt worden sind und deren Ruhezeit abgelaufen ist. 4 Für Grabstätten nach Satz 2 kann der Antrag bis 31. Oktober 2001 gestellt werden.",
            content_4: "(4) 1 Bei Grabstätten auf staatlichen Friedhöfen, die vor dem 1. April 1970 auf begrenzte Zeit überlassen worden sind, erlischt das Nutzungsrecht mit Ablauf der Ruhezeit der zuletzt beigesetzten Leiche oder Urne, spätestens jedoch am 31. Dezember 1995. 2 Die zuständige Behörde verlängert das Nutzungsrecht auf Antrag unentgeltlich, soweit die weitere Überlassungszeit in den Zeitraum bis zum 31. Dezember 2020 fällt.",
            content_5: "(5) Bei Grabstätten nach Absatz 3 oder 4 ist auf Antrag des Nutzungsberechtigten eine Änderung der Größe der Grabstätte zulässig.",
            content_6: "(6) § 3 Absatz 5 gilt für Todesbescheinigungen und vergleichbare Unterlagen, die nach den bisher geltenden Vorschriften ausgestellt worden sind und noch aufbewahrt werden, mit der Maßgabe, dass die Frist von 30 Jahren erst ein Jahr nach dem Inkrafttreten dieses Gesetzes endet."
        },
        {
            titel: "§ 35 Einschränkung von Grundrechten",
            content_1: "Für die Durchführung der Leichenschau nach § 2 Absatz 3 dieses Gesetzes wird das Grundrecht der Unverletzlichkeit der Wohnung ( Artikel 13 des Grundgesetzes ) eingeschränkt."
        },
        {
            titel: "§ 36 Aufhebung von Vorschriften",
            content_1: "(1) Es werden in ihrer geltenden Fassung aufgehoben:",
            content_2: "1. in § 12 des Gesetzes über das Gesundheitswesen vom 15. März 1920 (Sammlung des bereinigten hamburgischen Landesrechts I 2120-a) die Wörter: »die ärztliche Leichenschau«,",
            content_3: "2. Abschnitt III C der Ausführungsbestimmungen zum Gesetz über das Gesundheitswesen vom 28. Juli 1920 (Sammlung des bereinigten hamburgischen Landesrechts I 2120-a-1),",
            content_4: "3. das Gesetz über die Feuerbestattung vom 15. Mai 1934 (Sammlung des bereinigten hamburgischen Landesrechts II 2128-a),",
            content_5: "4. § 24 Absatz 5 zweiter Halbsatz sowie die §§ 72 bis 77 der Dritten Durchführungsverordnung zum Gesetz über die Vereinheitlichung des Gesundheitswesens (Dienstordnung für die Gesundheitsämter - Besonderer Teil) vom 30. März 1935 (Sammlung des bereinigten hamburgischen Landesrechts II 2120-b-3),",
            content_6: "5. die Verordnung zur Durchführung des Feuerbestattungsgesetzes vom 10. August 1938 (Sammlung des bereinigten hamburgischen Landesrechts II 2128-a-1),",
            content_7: "6. das Gesetz über die Schließung und Aufhebung des Gemeindefriedhofes an der Mengestraße in Hamburg-Wilhelmsburg vom 1. März 1963 (Hamburgisches Gesetz- und Verordnungsblatt Seite 14),",
            content_8: "7. das Gesetz über die Schließung und Aufhebung des »Alten Friedhofs Bremer Straße« in Harburg vom 17. Januar 1969 (Hamburgisches Gesetz- und Verordnungsblatt Seite 6),",
            content_9: "8. das Friedhofsgesetz vom 2. Februar 1970 (Hamburgisches Gesetz- und Verordnungsblatt Seite 48),",
            content_10: "9. die Friedhofsordnung vom 17. März 1970 (Hamburgisches Gesetz- und Verordnungsblatt Seite 138), sobald eine Verordnung nach § 32 in Kraft getreten ist,",
            content_11: "10. das Gesetz über die Schließung und Aufhebung des Friedhofs Lohbrügge vom 23. Dezember 1971 (Hamburgisches Gesetz- und Verordnungsblatt Seite 253).",
            content_12: "(2) Es bleibt bei der Aufhebung der bereits geschlossenen Friedhöfe Mengestraße (Absatz 1 Nummer 6) zum 31. Dezember 1988, Bremer Straße (Absatz 1 Nummer 7) zum 31. Dezember 1994 und Lohbrügge (Absatz 1 Nummer 10) zum 31. Dezember 1996."
        },
        {
            titel: "§ 37 Inkrafttreten",
            content_1: "Dieses Gesetz tritt am 1. Januar 1989, § 32 jedoch schon am Tage nach der Verkündung in Kraft.",
            content_2: "Ausgefertigt Hamburg, den 14. September 1988.",
            content_3: "Der Senat"
        },
    ]

]