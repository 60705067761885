import React from "react";
import Modal from 'react-bootstrap/Modal';
import {seeUrneArray} from "../data/urnen";

const Seeurnen = props => {

    return(
        <Modal
        size="xl"
        show={props.showSeeurnen}
        onHide={props.closeSeeurnen}
        aria-labelledby="example-modal-sizes-title-lg"
       >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
          <p className="modalTitle">Seeurnen</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="d-flex flex-wrap justify-content-center">
         {seeUrneArray.map((elem, index) => {
           return(
               <div key={index} className="col-sm-12 col-md-5 col-lg-5 card m-3 sargLastPosition">
                  <img  src={elem.img}  className="card-img-bottom imgSarg" alt={elem.titel} />
                  {/*<input className="radioBox mx-auto mb-2 defaultSarg" id={"sarg"+ elem.art_nr} onChange={this.props.selectSarg} value={elem.art_nr} type="radio" name={elem.name} />*/}
                  <span className="productInfo">{elem.titel}</span>
                  <span className="productInfo">Art-Nr: {elem.art_nr}</span>
                  <span className="productInfo mb-3">Preis: {elem.price.toFixed(2)} EUR</span>
                  <button onClick={() => props.selectSeeurne(elem)}  className="btn mb-2 buttonAuswahlSarg">Auswählen</button>
               </div>
           )
          })}
         </div>
        </Modal.Body>
      </Modal>

    )
}

export default Seeurnen;

