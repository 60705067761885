//import airbrush
import airbrush_21110 from "../../images/urnen/airbrush/21110.png";
import airbrush_21111 from "../../images/urnen/airbrush/21111.png";
import airbrush_21113 from "../../images/urnen/airbrush/21113.png";
import airbrush_21131 from "../../images/urnen/airbrush/21131.png";
import airbrush_21132 from "../../images/urnen/airbrush/21132.png";
import airbrush_21150 from "../../images/urnen/airbrush/21150.png";
import airbrush_21160 from "../../images/urnen/airbrush/21160.png";
import airbrush_21170 from "../../images/urnen/airbrush/21170.png";
import airbrush_21340_moh from "../../images/urnen/airbrush/21340_moh.png";
import airbrush_21340_ros from "../../images/urnen/airbrush/21340_ros.png";

//import einfach
import einfach_21820 from "../../images/urnen/einfach/21820.png";
import einfach_21820r from "../../images/urnen/einfach/21820r.png";
import einfach_21821_b_gb from "../../images/urnen/einfach/21821_b_gb.png";
import einfach_21860_ae_gb from "../../images/urnen/einfach/21860_ae_gb.png";
import einfach_21860 from "../../images/urnen/einfach/21860.png";
import einfach_21880_b from "../../images/urnen/einfach/21880_b.png";
import einfach_21881_lb from "../../images/urnen/einfach/21881_lb.png";
import einfach_21881_taub from "../../images/urnen/einfach/21881_taub.png";
import einfach_21881 from "../../images/urnen/einfach/21881.png";

//import handgemalt
import hangemalt_21201 from "../../images/urnen/handgemalt/21201.png";
import hangemalt_21206 from "../../images/urnen/handgemalt/21206.png";
import hangemalt_21230 from "../../images/urnen/handgemalt/21230.png";
import hangemalt_21240 from "../../images/urnen/handgemalt/21240.png";

//import normal
import normal_21330_b_si from "../../images/urnen/normal/21330_b_si.png";
import normal_21330_hz from "../../images/urnen/normal/21330_hz.png";
import normal_21330 from "../../images/urnen/normal/21330.png";
import normal_21331 from "../../images/urnen/normal/21331.png";
import normal_21333_hz_si from "../../images/urnen/normal/21333_hz_si.png";
import normal_21333 from "../../images/urnen/normal/21333.png";
import normal_21334_hz from "../../images/urnen/normal/21334_hz.png";
import normal_21335_lb from "../../images/urnen/normal/21335_lb.png";
import normal_21336_r_si from "../../images/urnen/normal/21336_r_si.png";
import normal_25135 from "../../images/urnen/normal/25135.png";

//import see
import see_2070_ank from "../../images/urnen/see/2070_ank.png";
import see_2072 from "../../images/urnen/see/2072.png";
import see_2050 from "../../images/urnen/see/2050.png";
import see_2051 from "../../images/urnen/see/2051.png";

//import bio
import bio_2605 from "../../images/urnen/see/2605.png";
import bio_2621 from "../../images/urnen/see/2621.png";
import bio_2622 from "../../images/urnen/see/2622.png";
import bio_2632 from "../../images/urnen/see/2632.png";
import bio_2641 from "../../images/urnen/see/2641.png";
import bio_2660 from "../../images/urnen/see/2660.png";
import bio_2661 from "../../images/urnen/see/2661.png";
import bio_2670 from "../../images/urnen/see/2670.png";
import bio_2671 from "../../images/urnen/see/2671.png";

export const seeUrneArray = [
   {img: see_2070_ank, art_nr: "h2070 ANK", titel: "Seeurne, Granitstruktur, Messing Anker", price: 195, category: "see"},
   {img: see_2072, art_nr: "h2072", titel: "Seeurne, Hellblau", price: 195, category: "see"},
   {img: see_2050, art_nr: "h2050", titel: "Seeurne, Terrajura, Jura Gelb", price: 195, category: "see"},
   {img: see_2051, art_nr: "h2051", titel: "Seeurne, Terrajura, Fussspuren", price: 195, category: "see"}
]

export const bioUrneArray = [
   {img: bio_2605, art_nr: "h2605", titel: "Urne Bio-Linie, Weiss, Dekorb. indiv. zum bemalen (zusätzlich Ersatzband)", price: 245, category: "bio"},
   {img: bio_2621, art_nr: "h2621", titel: "Urne Bio-Linie, Terrajura beschichtet Beige, Zierband Edelstahl gebürstet", price: 245, category: "bio"},
   {img: bio_2622, art_nr: "h2622", titel: "Urne Bio-Linie, Terrajura beschichtet Beige, Zierband Bordeaux ", price: 245, category: "bio"},
   {img: bio_2632, art_nr: "h2632", titel: "Urne Bio-Linie, Terrajura Struktur Beige, Reliev Rot-Bronze ", price: 249, category: "bio"},
   {img: bio_2641, art_nr: "h2641", titel: "Urne Bio-Linie, Terrajura beschichtet, Grau-Graphit, Zierband Edelstahl geb. ", price: 150, category: "bio"},
   {img: bio_2660, art_nr: "h2660", titel: "Urne Bio-Linie, Terrajura, Jura Gelb ", price: 249, category: "bio"},
   {img: bio_2661, art_nr: "h2661", titel: "Urne Bio-Linie, Terrajura, Fussspuren", price: 249, category: "bio"},
   {img: bio_2670, art_nr: "h2670", titel: "Urne Bio-Linie, Terrajura, Athrazit", price: 248, category: "bio"},
   {img: bio_2671, art_nr: "h2671", titel: "Urne Bio-Linie, Terrajura, Betongrau", price: 248, category: "bio"}
]

export const urnenArray = [
   //airbrush
  {img: airbrush_21110, art_nr: "h21110", titel: "Urne Cremeweiss, Blüte", price: 233, category: "airbrush"},
  {img: airbrush_21111, art_nr: "h21111", titel: "Urne Cremeweiss, Mohnblume", price: 233, category: "airbrush"},
  {img: airbrush_21113, art_nr: "h21113", titel: "Urne Cremeweiss, Rose", price: 214, category: "airbrush"},
  {img: airbrush_21131, art_nr: "h21131", titel: "Urne Schwarz, Waldlichtung", price: 292, category: "airbrush"},
  {img: airbrush_21132, art_nr: "h21132", titel: "Urne Schwarz, Schmetterling", price: 240, category: "airbrush"},
  {img: airbrush_21150, art_nr: "h21150", titel: "Urne Himmelblau, Möwe", price: 245, category: "airbrush"},
  {img: airbrush_21160, art_nr: "h21160", titel: "Urne Terracottafarbig, Herbstbaum", price: 273, category: "airbrush"},
  {img: airbrush_21170, art_nr: "h21170", titel: "Urne Moosgrün, Margerite", price: 233, category: "airbrush"},
  {img: airbrush_21340_moh, art_nr: "h21340 MOH", titel: "Urne Hell Beige-Velour, Mohn mit Airbrush abgesetzt", price: 258, category: "airbrush"},
  {img: airbrush_21340_ros, art_nr: "h21340 ROS", titel: "Urne Hell Beige-Velour, Stielrose mit Airbrush abgesetzt", price: 258, category: "airbrush"},
  //einfach
  {img: einfach_21881, art_nr: "h21881", titel: "Urne Cremeweiss (Standarturne)", price: 124, category: "einfach"},
  {img: einfach_21820, art_nr: "h21820", titel: " Urne Nacht-Blau", price: 124, category: "einfach"},
  {img: einfach_21820r, art_nr: "h21820 R", titel: "Urne Nacht-Blau, Goldrose", price: 140, category: "einfach"},
  {img: einfach_21821_b_gb, art_nr: "h21821 B-GB", titel: "Urne Nacht-Blau Matt, Baum", price: 168, category: "einfach"},
  {img: einfach_21860_ae_gb, art_nr: "h21860 AE-GB", titel: "Urne Moosgrün matt, abgeknickte Ähre, Godbraun", price: 146, category: "einfach"},
  {img: einfach_21860, art_nr: "h21860", titel: "Urne Moosgrün matt", price: 124, category: "einfach"},
  {img: einfach_21880_b, art_nr: "h21880 B", titel: "Urne Schwarz hochglanz, Trauerweide", price: 146, category: "einfach"},
  {img: einfach_21881_lb, art_nr: "h21881 LB", titel: "Urne Cremeweiss, Lebensbaum geklebt", price: 152, category: "einfach"},
  {img: einfach_21881_taub, art_nr: "h21881 TAUB", titel: "Urne Cremeweiss, Taube in Weiss", price: 157, category: "einfach"},
  //handgemalt
  {img: hangemalt_21201, art_nr: "h21201", titel: "Urne Cremeweiss, Handgemalt, Efeu", price: 332, category: "handgemalt"},
  {img: hangemalt_21206, art_nr: "h21206", titel: "Urne Cremeweiss, Buch mit Rose", price: 377, category: "handgemalt"},
  {img: hangemalt_21230, art_nr: "h21230", titel: " Urne Nacht-Blau, Glockenblume mit Falter", price: 332, category: "handgemalt"},
  {img: hangemalt_21240, art_nr: "h21240", titel: " Urne Weinrot, Kirschblüten", price: 332, category: "handgemalt"},
  //normal
  {img: normal_21330_b_si, art_nr: "h21330 B-SI", titel: "Urne Vieolett-Velour, Silberrand, Moderner Baum", price: 220, category: "normal"},
  {img: normal_21330_hz, art_nr: "h21330 HZ", titel: "Urne Vieolett-Velour, Goldrand, Dekor Herzen", price: 220, category: "normal"},
  {img: normal_21330, art_nr: "h21330", titel: "Urne Vieolett-Velour, Goldrand", price: 163, category: "normal"},
  {img: normal_21331, art_nr: "h21331", titel: "Urne Olivegrün-Velour, Goldrand", price: 163, category: "normal"},
  {img: normal_21333_hz_si, art_nr: "h21333 HZ-SI", titel: "Urne Weinrot matt, Silberrand, Herzen", price: 163, category: "normal"},
  {img: normal_21333, art_nr: "h21333", titel: "Urne Weinrot-Velour, Goldrand", price: 220, category: "normal"},
  {img: normal_21334_hz, art_nr: "h21334 HZ", titel: "Urne Hell Beige-Velour, Goldrand, Dekor Herzen", price: 220, category: "normal"},
  {img: normal_21335_lb, art_nr: "h21335 LB", titel: "Urne Hell Beige-Velour, Braun abgesetzt, Lebensbaum Braun", price: 220, category: "normal"},
  {img: normal_21336_r_si, art_nr: "h21336 R-SI", titel: "Urne Nacht-Blau, Silberrand, Moderne Rose", price: 220, category: "normal"},
  {img: normal_25135, art_nr: "h25135", titel: "Urne Cremeweiss, Samtband Kardinalrot, Golddekor gebürstet", price: 220, category: "normal"},

]