import React, {useEffect} from "react";
import {berlin} from "../data/gesetze/berlin";
import "../../style/gesetze.css";


const Berlin = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      });

    let quantArray = [];
    for(let i = 1; i <= 30; i++){
        quantArray.push(i)
    }
    let quantCollapse = [];
    for(let i = 1; i <= 4; i++){
        quantCollapse.push(i)
    }
  
    return (
        <div className="container">
         <h1 className="headerHome">Bestattungsgesetz Berlin</h1>
         {berlin.map((elem, index) => {
           return (
                <div className="gesetzWrapper" key={index}>
                   <h3 className="titelAbschnitt">{elem[0]}</h3>
                    {elem.map((desc, index) => {
                          return(
                              <div key={index}>
                                   
                                        <h5 className="paragraphTitel">{desc.titel}</h5>
                                            {quantArray.map((elem, index) => {
                                              return  (
                                                   <p key={index}>{desc["content_" + elem]}</p>
                                              )
                                               
                                            })
                                         }
                              </div>
                          )
                    })
                 }
                </div>
             )
         })}
  
        </div>
    )
}

export default Berlin;