import React, {Component} from "react";
import "../style/agb.css";
import jsPDF from 'jspdf';
import logoImg from "../images/logo/logo.jpg";

class AGB extends Component{
  componentDidMount(){
    window.scrollTo(0, 0);
  }


   downloadGebuehren = () => {
        var pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(logoImg, 'SVG',  90, 7);
        pdf.setFontSize(20);            // Schriftgröße
        pdf.setFont("times")
        pdf.setTextColor(0, 0, 0);    // Textfarbe als RGB-Werte
        pdf.setFontSize(17);
        pdf.text ("Gebühren", 93, 55);

        let geb_1 = "- Bearbeitungsgebühr pro Beantragung eines Dokumentes 55,00 EUR";
        let geb_2 = " (Beantragung von Dokumenten bei Standesämtern und anderen Behörden und Schriftstücke für Behörden.)";
        let geb_3 = "- Bearbeitungsgebühr für jede weitere Rechnung 15,00 EUR";
        let geb_4 = " (z. B. Rechnungsteilung für mehrere Personen)";
        let geb_5 = "- Haus-, Heim-, Palliativstation- und Hospizabholung 120,00 EUR";
        let geb_6 = "- Sterbefallurkunde zusätzlich 25,00 EUR";
        let geb_7 = "- Übergroßer Sarg zusätzlich 210,00 EUR";
        let geb_8 = "- Hygienehülle (Infektiös) 60,00 EUR";
        let geb_9 = "- Urnennetz 14,00 EUR";
        let geb_10 = "- Urnenversand EU 130,00 EUR";
        let geb_11 = "- Urnenversand außerhalb EU 240,00 EUR";
        let geb_12 = " (Bitte beachten Sie, dass die Urne von den Zollbehörden beschädigt werden könnte.)";
        let geb_13 = "- Zusätzlich Überführungen, die das Angebot nicht umfassen pro Kilometer 1,80 EUR";
        let geb_14 = "- Anforderung eines Bestatters mit Fahrzeug pro Stunde 85,00 EUR";
        let geb_15 = " (Gilt auch für Stillstand des Fahrzeuges, den die Sidereum Bestattungen nicht zu verantworten hat.)";
        let preis = "In den Gebühren ist die MwSt. mit 19% schon erhalten.";

        pdf.setFontSize(12);
        pdf.text(geb_1, 20, 75);
        pdf.setFontSize(10);
        pdf.text(geb_2, 20, 80);
        pdf.setFontSize(12);
        pdf.text(geb_3, 20, 90);
        pdf.setFontSize(10);
        pdf.text(geb_4, 20, 95);
        pdf.setFontSize(12);
        pdf.text(geb_5, 20, 105);
        pdf.text(geb_6, 20, 115);
        pdf.text(geb_7, 20, 125);
        pdf.text(geb_8, 20, 135);
        pdf.text(geb_9, 20, 145);
        pdf.text(geb_10, 20, 155);
        pdf.text(geb_11, 20, 165);
        pdf.setFontSize(10);
        pdf.text(geb_12, 20, 170);
        pdf.setFontSize(12);
        pdf.text(geb_13, 20, 180);
        pdf.text(geb_14, 20, 190);
        pdf.setFontSize(10);
        pdf.text(geb_15, 20, 195);
        pdf.setFontSize(14);
        pdf.text(preis, 20, 210);


           //Anschriftblock
         pdf.setFontSize(9);
         var anschriftBlock1 = "Sidereum Bestattungen UG\nAmtsgericht Rostock\nUst.IdNr: 086/117/03156\nGeschäftsführer: René Walek";
         var anschriftBlock2 = "Anschrift\nSidereum Bestattungen\nLerchenberg 1\n18292 Krakow am See";
         var anschriftBlock3 = "Tel: 0152 22003687\nWeb: www.sidereum-bestattungen.de\nE-Mail: info@sidereum-bestattungen.de";

         pdf.setLineWidth(.2);
         pdf.line(20, 268, 190, 268);
         pdf.text (anschriftBlock1, 20, 273);
         pdf.text (anschriftBlock2, 80, 273);
         pdf.text (anschriftBlock3, 140, 273);


         /*  Seitenanzahl */
         pdf.setFontSize(10);
         /*  Seitenanzahl */
        pdf.text("Seite 1/1",  95, 290);

        pdf.save('Gebuehren.pdf');
 }

 downloadWiederruf = () => {
     var pdf = new jsPDF("p", "mm", "a4");

     //pdf.addImage(this.state.vollmachtImg, 'JPG',  12, 0);
     pdf.addImage(logoImg, 'SVG',  90, 7);
     pdf.setFontSize(20);
     pdf.setFont("times")
     //pdf.setFontType("bold")
     pdf.setTextColor(0, 0, 0);
     pdf.text ("Wiederrufsformular", 78, 55);
     pdf.setFontSize(12);
     pdf.text("Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus ", 20, 66);
     pdf.text("und senden Sie es zurück.", 20, 71);

     //Wierufsformular - Anschrift Unternehmen
     pdf.text("An:", 20, 81);
     pdf.text("Sidereum Bestattungen UG", 20, 86);
     pdf.text("Lerchenberg 1", 20, 91);
     pdf.text("18292 Krakow am See", 20, 96);
     pdf.text("E-Mail: info@sidereum-bestattungen.de", 20, 106);
    //Wierufsformular - Text: Hiermit wiederrufe ich ...
     pdf.text("Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag", 20, 121);
     pdf.text("über den Kauf der folgenden Waren / die Erbringung der folgenden Dienstleistung:", 20, 126);
     //Wiederrrufsformular - Dienstleistung
     //Bestattungsart
     pdf.setFontSize(14);



    pdf.setFontSize(12);
    //Bestellt am / Erhalten am
    pdf.setLineWidth(.3);
    pdf.line(20, 155, 80, 155);
    pdf.line(100, 155, 160, 155);
    pdf.text ("Beaftragt am (Datum)", 20, 160);
    pdf.text ("Erhalten am (Datum)", 100, 160);

    //Anschrift des Verbrauchers
    pdf.line(20, 185, 160, 185);
    pdf.text("Ihr Vor- und Nachname", 20, 190);
    pdf.line(20, 200, 160, 200);
    pdf.text("Ihre Adresse", 20, 205);

    //Unterschrift des Kunden

    //Bestellt am / Erhalten am
    pdf.setLineWidth(.3);
    pdf.line(20, 230, 80, 230);
    pdf.line(100, 230, 160, 230);
    pdf.text ("Ort, Datum", 20, 235);
    pdf.text ("Unterschrift", 100, 235);

     //Anschriftblock
     pdf.setFontSize(9);
     var anschriftBlock1 = "Sidereum Bestattungen UG\nAmtsgericht Rostock\nUst.IdNr: 086/117/03156\nGeschäftsführer: René Walek";
     var anschriftBlock2 = "Anschrift\nSidereum Bestattungen\nLerchenberg 1\n18292 Krakow am See";
     var anschriftBlock3 = "Tel: 0152 22003687\nWeb: www.sidereum-bestattungen.de\nE-Mail: info@sidereum-bestattungen.de";

     pdf.setLineWidth(.2);
     pdf.line(20, 268, 190, 268);
     pdf.text (anschriftBlock1, 20, 273);
     pdf.text (anschriftBlock2, 80, 273);
     pdf.text (anschriftBlock3, 140, 273);


     /*  Seitenanzahl */
     pdf.setFontSize(10);
     /*  Seitenanzahl */
    pdf.text("Seite 1/1",  95, 290);

    pdf.save('Wiederrufsformular.pdf');
 }

  render(){
    return(
  <>
    <div className="bg-agb">
        <h2 className="headerTop">AGB</h2>
    </div>

    <div className="container-fluid bestattungAngebot py-5">
      <div className="container py-3">
        <br />
        <b><p>1. Geltung gegenüber Unternehmern und Begriffsdefinitionen</p></b>
        <p>Die vorliegenden Geschäftbedingungen enthalten die zwischen Ihnen und uns, der Firma Sidereum Bestattungen UG, Lerchenberg 1, 18292 Krakow am See,
        Amtsgericht Rostock HRB 14651, vetreten durch René Walek ausschließlich geltenden Bedingungen, soweit diese nicht durch schriftliche Vereinbarungen zwischen
        den Parteien abgeändert werden.</p>

        <p>Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
        ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 BGB).
        </p>

<b><p>2. Zustandekommen eines Vertrages, Speicherung des Vertragstextes</p></b>
        <p>(1) Die folgenden Regelungen über den Vertragsabschluss gelten für Bestellungen über unseren Internetshop http://www.sidereum-bestattungen.de.<br />
        </p>
        <p>(2) Im Falle des Vertragsschlusses kommt der Vertrag mit<br />


<br />
Sidereum Bestattungen UG (haftungsbeschränkt)<br />
René Walek<br />
Lerchenberg 1<br />
18292 Krakow am See<br />
Umsatzsteuer ID: 086/117/03156<br />
Registernummer: HRB 14651<br />
Registergericht: Amtsgericht Rostock<br />

zustande.<br /></p>
<p>
  Dabei unterschreibt der Auftraggeber ein schriftliches Angebot der Siderium UG und gleichzeitig eine Vollmacht
  zur Abholung des Verstorbenen / der Verstorbenen sowie zur Eintragung ins Sterberegister
  in schriftlicher Form übergeben wird.
</p>


<p>
(3) Die Präsentation der Angeboten auf der Internetseite Sidereum UG stellen kein rechtlich bindendes Vertragsangebot unsererseits dar,
sondern sind nur eine unverbindliche Aufforderungen an den Verbraucher, durch eine Bestellung ein Angebot abzugeben. Mit der Bestellung der gewünschten Dienstleistungen gibt der Verbraucher ein für
ihn verbindliches Angebot auf Abschluss eines Kaufvertrages ab.</p>

<p>Die Annahme des Angebots erfolgt schriftlich oder in Textform.</p>

<p>
 Durch das Absenden der Bestellung gibt der Auftraggeber ein verbindliches Angebot gemäß § 145 BGB ab, gerichtet auf den Abschluß eines Kauf- bzw. Diensleistungsvertrages.
 Mit dem Absenden der Bestellung hat der Auftraggeber diese Geschäftsbedingungen gelesen und erkennt diese Geschäftsbedingungen als für das Rechtverhältnis mit Sidereum Bestattungen UG allein maßgeblich an.<br />
 Die Erklärung der Annahme des Vertragsangebotes im Internetgeschäft durch den Auftraggeber erfolgt bei Übersendung einer Vollmacht zur Abholung und
 Bestattung des Verstorbenen- der Verstorbenen in unterschriebenen Form (per E-Mail oder per Brief) an Sidereum UG. Ist nachweislich die o. g. Vollmacht bei der Firma Sidereum Bestattungen UG eingegangen, so nimmt diese den Vertrag an.
 <br />
Im weiteren Verlauf ist eine sofortige Zuarbeit von Dokumenten (Geburtsurkunde des Verstorbenen / der Verstorbenen, Personalausweis,
Heiratsurkunde, Sterbeurkunde Ehepartner, Scheidungsurteil usw.) sowie Informationen zum Verstorbenen durch Auftraggeber notwendig.
Die Dokumente und Informationen werden dringend zur Erfüllung des entstandenen Auftrages/Vertrages benötigt. <br />
Sollte bei der Durchführung des Auftrages zusätzliche Kosten entstehen, so sind diese auf entsprechenden Nachweis vom Auftraggeber zu tragen.
</p>

<b><p>3. Fälligkeit/Bezahlung</p></b>
<p>
Die Zahlung erfolgt nach Rechnungsstellung und ist innerhalb von 21 Tagen nach Rechnungsdatum zu leisten. Wir behalten uns vor, in Einzelfällen nur
gegen Vorkasse zu leisten. Für Auftraggeber ohne ständigen Wohnsitz in Deutschland können wir Leistungen nur gegen Vorkasse erbringen.
<br /> Bei Zahlungsverzug des Auftraggebers werden Verzugzinsen in Höhe des banküblichen Sollzinsatz berechnet. Kommt der Auftraggeber selbständigen
Zahlungsverpflichtungen nicht vertragsgemäß nach oder stellt er seine Zahlungen ein oder werden andere Umstände bekannt, die die
Kreditwürdigkeit des Auftaggebers in Frage stellen, so ist die gesamte Restschuld sofort zur Zahlung fällig und Sidereum Bestattungen UG berechtigt, Vorauszahlungen
und Sicherheitsleistungen zu verlangen.<br />
Sollte der Auftraggeber den Vertrag kündigen oder wird die Bestattung infolge eines Umstandes unmöglich, den der Auftraggeber zu vertreten hat, ist der Firma
Sidereum Bestattungen UG der nachweislich entstandene Schaden zu ersetzen, wenn die Nichtausführung oder Nichtlieferung durch
Sidereum Bestattungen UG nicht zu vertreten ist.<br />
</p>

<b><p>4. Liefer-/Leistungszeit</p></b>
<p>Die von Siderium Bestattungen UG genannten Termine und Fristen sind unverbindlich, jedoch verpflichtet sich Siderium Bestattungen UG
  zum schnellen und reibungslosen Ablauf des Auftrages.<br />
  Bei Liefer- und Leistungsverzögerungen auf Grund von höherer Gewalt und oder auf Grund von Ereignissen, die die Durchführung der Dienstleistungen
  verzögern, erschweren oder unmöglich machen (z.B. Betriebsstörungen, Streik, behördliche Anordnungen) ist Siderium Bestattungen UG
  berechtigt, die Dienstleistung um die Dauer der Behinderung hinauszuschieben oder wegen des noch nicht erfüllten Teils ganz oder
  teilweise vom Vertrag zurückzutreten.
</p>

<b><p>5. Eigentumsvorbehalt</p></b>
<p>
  Gelieferte Produkte bleiben bis zur vollständigen und endgültigen Bezahlung der diesbezüglichen Vergütung einschließlich der Versand-
  und Vertriebskosten das Eigentum  der Siderium Bestattungen UG.
</p>

<b><p>6. Gewährleistung</p></b>
<p>
  Die Gewährleistungsrechte des Auftraggebers richten sich nach den allgemeinen gesetzlichen Vorschriften, soweit nachfolgend nichts anderes bestimmt ist.
  <br />
  Der Auftraggeber hat der Siderium Bestattungen UG Mängel bzw. mangelhafte Leistungen unverzüglich in schriftlicher Form (§§ 126, 127 BGB) anzuzeigen.
  Eine Garantie wird von der Siderium Bestattungen UG nicht erklärt.
</p>

<b><p>7. Haftungsbeschränkung</p></b>
<p>
  (1) Wir haften für Vorsitz und grobe Fahrlässigkeit. Ferner haften wir für die fahrlässige Verletzung von Pflichten
  deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht, deren Verletzung
  die Erreichung des Vertragszwecks gefährdet und auf deren Einhaltung Sie als Kunden
  regelmäßig vertrauen. Im letztgenannten Fall haften wir jedoch nur für den vorhersehbaren,
  vertragstypischen Schaden. Wir haften nicht für die leicht fahrlässige Verletzung anderer als der in
  den vorstehenden Sätzen genannten Pflichten.
  <br></br>Die vorstehenden Haftungsausschüsse gelten nicht bei Verletzung von Leben, Körper und Gesundheit. Die Haftung nach Produkthaftungsgesetz bleibt unberührt.
  <br />(2)Die Datenkommunikation über das Internet kann nach dem derzeitigen Stand der Technik nicht fehlerfrei und/oder
  jederzeit verfügbar gewährleistet werden. Wir haften insoweit weder für die ständige und ununterbrochene Verfügbarkeit unseres Online-Plattform.
</p>


<b><p>8. Widerrufsrecht des Kunden als Verbraucher</p></b>


<p>Verbrauchern steht ein Widerrufsrecht nach folgender Maßgabe zu, wobei Verbraucher jede natürliche Person ist,
die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen
beruflichen Tätigkeit zugerechnet werden können:</p>
<p>Widerrufsbelehrung</p>
<p>Widerrufsrecht</p>
<p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
<p>Die Widerrufsfrist beträgt vierzehn Tage, ab dem Tag des Vertragsabschlusses.</p>

<p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
<p>
    Sidereum Bestattungen UG (haftungsbeschränkt)<br />
    René Walek<br />
    Lerchenberg 1<br />
    18292 Krakow am See<br />
    E-Mail: info@sidereum-bestattungen.de<br />
</p>
<p>mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss,
diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
</p>

<p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>

<p>Widerrufsfolgen</p>
<p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben,
dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel,
das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart;
in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.<br />
</p>
<p>Haben Sie verlangt, dass die Dienstleistungen  während der Widerrufsfrist beginnen soll,
so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt,
zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten,
bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
</p>
<p style={{cursor: "pointer", textDecoration: "underline #4f3d3f"}} onClick={this.downloadWiederruf}>Wiederruf herunterladen</p>

<b><p>9. Gebühren</p></b>
<p style={{cursor: "pointer", textDecoration: "underline #4f3d3f"}} onClick={this.downloadGebuehren}>Gebührenliste herunterladen</p>

<b><p>10. Schlussbestimmungen</p></b>

<p>Sollte sich auf den jeweiligen Seiten dennoch ein ungekennzeichnetes, aber durch fremdes Copyright
  geschütztes Bild oder Text befinden, werde ich das ebtsprechende Objekt nach Benachrichtigung aus meiner
  Publikation entfernen bzw. mit dem entsprechenden Copyright kenntlich machen.
</p>
<p>
  Die Kosten einer anwaltlichen Abmahnung ohne vorhergehende Kontaktaufnahme werden im Sinne der Schadensminderungspflicht als
  unbegründet zurückgewiesen
</p>

<p>Stand der AGB Apr.2019</p>


</div>
</div>
</>
    )
  }
}

export default AGB;
