import React, {Component} from "react";
import "../style/service.css";

class Service extends Component{
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  render(){
    return(
    <>
        <div className="bg-service">
           <h2 className="headerTop">Service</h2>
        </div>

    <div className="container-fluid bestattungAngebot py-5">
      <div className="container py-3">
        <h2 className="leistungHeader">Unsere Leistungen im Überblick</h2>
        <ul className="bestattungsAngebot py-1">
             <li>Erdbestattung</li>
             <li>Feuerbestattung</li>
             <li>Seebestattung</li>
             <li>Anonyme Bestattung</li>
             <li>Blumen und Kranztransporte</li>
             <li>Kosmetische Versorgung von Verstorbenen</li>
             <li>Verstorbenenmanagement für Krankenhäuser</li>
             <li>Lieferung von Särgen und Urnen nach Ihren Wünschen</li>
             <li>24 Stunden telefonische Erreichbarkeit, auch am Wochenende und an Feiertagen</li>
             <li>Hausaufbahrung</li>
        </ul>
      </div>
  </div>
        
    </>
    )
  }
}

export default Service;
