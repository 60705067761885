import React from "react";
import Modal from 'react-bootstrap/Modal';
import Geolocation from '../Geolocation';
import { FaMapMarkerAlt} from "react-icons/fa";

const AddPostcode = props => {
    return(
        <Modal
        size="xl"
        show={props.showAddPostcode}
        onHide={props.closePostcode}
        aria-labelledby="example-modal-sizes-title-sm"

      >
        <Modal.Header id="modalHeaderProduct" closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modalBodyProduct">
             <div className="addPlzBox">
                 <FaMapMarkerAlt className="plzIcon" />
                <p className="modalTitle">Bitte geben Sie den Ort oder die Postleitzahl ein!</p>
                <Geolocation />
             </div>


        </Modal.Body>
  </Modal>
    )
}

export default AddPostcode;