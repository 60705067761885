import React from "react";


const Gebuehren = () => {

   

    return(
        <div className="container-fluid py-5">
             <div className="container py-3">  
            <h1 className="vorsorgeHeader">Gebühren</h1>
            <ul className="gebuehren">
                   <li className="liAngebot">Bearbeitungsgebühr pro Beantragung eines Dokumentes 55,00 EUR<br></br>
                   <span style={{fontSize: "12px"}}>(Beantragung von Dokumenten bei Standesämtern und anderen Behörden und Schriftstücke für Behörden.)</span>
                    </li>
                    <li className="liAngebot">Bearbeitungsgebühr für jede weitere Rechnung 15,00 EUR<br></br>
                    <span style={{fontSize: "12px"}}>(z. B. Rechnungsteilung für mehrere Personen)</span>
                    </li>
                    <li className="liAngebot">Haus-, Heim-, Palliativstation- und Hospizabholung 120,00 EUR</li>
                    <li className="liAngebot">Sterbefallurkunde zusätzlich 25,00 EUR</li>
                    <li className="liAngebot">Übergroßer Sarg zusätzlich 210,00 EUR</li>
                    <li className="liAngebot">Hygienehülle (Infektiös) 60,00 EUR</li>
                    <li className="liAngebot">Urnennetz 14,00 EUR</li>
                    <li className="liAngebot">Urnenversand EU 130,00 EUR</li>
                    <li className="liAngebot">Urnenversand außerhalb EU 240,00 EUR
                    <span style={{fontSize: "12px"}}>(Bitte beachten Sie, dass die Urne von den Zollbehörden beschädigt werden könnte.)</span>
                    </li>
                    <li className="liAngebot">Zusätzlich Überführungen, die das Angebot nicht umfassen pro Kilometer 1,80 EUR</li>
                    <li className="liAngebot">Anforderung eines Bestatters mit Fahrzeug pro Stunde 85,00 EUR<br></br>
                    <span style={{fontSize: "12px"}}>(Gilt auch für Stillstand des Fahrzeuges, den die Sidereum Bestattungen nicht zu verantworten hat.)</span>
                    </li>
                    <p className="py-2">In den Gebühren ist die MwSt. mit 19% schon erhalten.</p>
            </ul>

           
            </div>
        </div>
    )
}

export default Gebuehren;