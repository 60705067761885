export const mecklenburg_vorpommern = [
      [   "Erster Abschnitt: Leichenwesen",
          {
              titel: "§ 1 Begriffsbestimmung",
              content_1: "(1) Leiche im Sinne dieses Gesetzes ist der Körper eines Menschen, bei dem sichere Zeichen des Todes bestehen oder bei dem der Tod auf andere Weise zuverlässig festgestellt worden ist. Als Leiche gilt auch der Körper eines Neugeborenen, bei dem nach vollständigem Verlassen des Mutterleibes",
              content_2: "1. entweder das Herz geschlagen oder die Nabelschnur pulsiert oder die natürliche Lungenatmung eingesetzt hat und das danach verstorben ist oder",
              content_3: "2. keines der unter Nummer 1 genannten Lebenszeichen festzustellen war, das Geburtsgewicht jedoch mindestens 500 Gramm betrug (Totgeborenes).",
              content_4: "(2) Eine Leibesfrucht mit einem Gewicht unter 500 Gramm, bei der nach vollständigem Verlassen des Mutterleibes keines der in Absatz 1 Satz 2 Nr. 1 genannten Lebenszeichen festzustellen war (Fehlgeborenes), gilt nicht als Leiche im Sinne dieses Gesetzes."
          },
          {
              titel: "§ 2 Ehrfurcht vor den Toten",
              content_1: "Wer mit Leichen oder Leichenteilen umgeht, hat dabei die gebotene Ehrfurcht vor dem toten Menschen zu wahren. Gleiches gilt für den Umgang mit Fehlgeborenen."
          },
          {
              titel: "§ 3 Ärztliche Leichenschau",
              content_1: "(1) Jede Leiche ist zur Feststellung des Todes, des Todeszeitpunktes, der Todesart und der Todesursache von einem Arzt zu untersuchen (Leichenschau).",
              content_2: "(2) Die Leichenschau haben unverzüglich zu veranlassen:",
              content_3: "1. die zum Haushalt des Verstorbenen gehörenden Personen,",
              content_4: "2. derjenige, in dessen Wohnung, Unternehmen oder Einrichtung sich der Sterbefall ereignet hat,",
              content_5: "3. jeder, der eine Leiche auffindet.",
              content_6: "Die Pflicht besteht nicht, wenn bereits ein anderer die Leichenschau veranlaßt hat oder wenn in den Fällen der Nummer 3 die Polizei benachrichtigt wird.",
              content_7: "(3) Zur Vornahme der Leichenschau sind verpflichtet:",
              content_8: "1. bei Sterbefällen in Krankenhäusern und anderen Einrichtungen, zu deren Aufgaben auch die ärztliche Behandlung der aufgenommenen Personen gehört, jeder dort tätige Arzt; bei mehreren Ärzten kann die Leitung der Einrichtung regeln, welcher von ihnen die Leichenschau vorzunehmen hat,",
              content_9: "2. bei Sterbefällen in einem Fahrzeug des Rettungsdienstes ohne Notarzt der im jeweils nächstgelegenen Krankenhaus diensthabende Arzt,",
              content_10: "3. in allen anderen Fällen jeder erreichbare niedergelassene Arzt sowie Ärzte im Notfalldienst und Rettungsdienst.",
              content_11: "Ein Arzt kann es ablehnen, über die Feststellung des Todes hinaus eine Leichenschau vorzunehmen, wenn er durch die weiteren Feststellungen sich selbst oder einen seiner in § 52 Abs. 1 der Strafprozeßordnung bezeichneten Angehörigen der Gefahr einer strafrechtlichen Verfolgung oder eines Verfahrens nach dem Gesetz über Ordnungswidrigkeiten aussetzen würde.",
              content_12: "(4) Ein im Notfalldienst oder Rettungsdienst tätiger Arzt kann sich auf die Feststellung des Todes, des Todeszeitpunktes und der äußeren Umstände beschränken, wenn er durch die Durchführung der Leichenschau an der Wahrnehmung seiner Aufgaben im Notfalldienst oder Rettungsdienst gehindert würde und er dafür sorgt, daß ein anderer Arzt eine vollständige Leichenschau durchführt. Er hat über die Feststellung unverzüglich eine Bescheinigung auszustellen."
          },
          {
              titel: "§ 4 Durchführung der Leichenschau",
              content_1: "(1) Die Leichenschau ist unverzüglich, spätestens jedoch innerhalb von acht Stunden nach der Aufforderung dazu durchzuführen. Die Leichenschau soll an dem Ort, an dem der Tod eingetreten ist oder die Leiche aufgefunden wird, vorgenommen werden. Der Arzt und die von ihm hinzugezogenen Helfer sind berechtigt, jederzeit den Ort zu betreten, an dem sich die Leiche befindet. Hält es der Arzt nicht für zweckmäßig, die Leichenschau an diesem Ort durchzuführen, weil sich die Leiche nicht in einem geschlossenen Raum befindet oder die Rücksicht auf anwesende Angehörige oder andere Umstände einer ordnungsgemäßen Leichenschau entgegenstehen, kann er sich auf die Todesfeststellung beschränken und die vollständige Leichenschau an einem geeigneten Ort weiterführen; Satz 1 und § 3 Abs. 4 Satz 2 gelten entsprechend. Die Leichenschau ist an der vollständig entkleideten Leiche unter Einbeziehung aller Körperregionen durchzuführen.",
              content_2: "(2) Angehörige sowie Personen, die den Verstorbenen während einer dem Tod vorausgegangenen Krankheit behandelt oder gepflegt haben, sind verpflichtet, dem Arzt auf Verlangen Auskunft über Krankheiten und andere Gesundheitsschädigungen des Verstorbenen und über sonstige für seinen Tod möglicherweise ursächliche Ereignisse zu erteilen. Sie können die Auskunft auf Fragen verweigern, wenn sie durch die Auskunft sich selbst oder einen ihrer in § 52 Abs. 1 der Strafprozeßordnung bezeichneten Angehörigen der Gefahr einer strafrechtlichen Verfolgung oder eines Verfahrens nach dem Gesetz über Ordnungswidrigkeiten aussetzen würden.",
              content_3: "(3) Ist durch äußere Merkmale bereits erkennbar oder läßt sich nicht ausschließen, daß es sich um einen nichtnatürlichen Tod handelt, oder handelt es sich um einen unbekannten Toten, hat der Arzt unverzüglich die Polizei oder Staatsanwaltschaft zu verständigen. Er hat in diesem Fall von der Leichenschau abzusehen und bis zum Eintreffen der Polizei oder der Staatsanwaltschaft dafür zu sorgen, daß keine Veränderungen an der Leiche und der unmittelbaren Umgebung vorgenommen werden. Als nichtnatürlich ist ein Tod anzunehmen, der durch Selbsttötung oder durch einen Unfall herbeigeführt wurde oder bei dem eine Einwirkung Dritter ursächlich gewesen ist. Ergeben sich erst während der Leichenschau Hinweise auf einen nichtnatürlichen Tod, hat der Arzt ebenso zu verfahren.",
              content_4: "(4) War der Verstorbene an einer Krankheit erkrankt, die aufgrund des Infektionsschutzgesetzes vom 20. Juli 2000 (BGBl. I S. 1045), zuletzt geändert durch Artikel 2 § 3 Abs. 4 des Gesetzes vom 1. September 2005 (BGBl. I S. 2618), meldepflichtig ist und die durch den Umgang mit der Leiche weiterverbreitet werden kann, gehen sonstige Gefahren von der Leiche aus oder besteht ein Verdacht hierfür, hat der Arzt die Leiche deutlich sichtbar entsprechend zu kennzeichnen."
          },
          {
              titel: "§ 5 Obduktion und Sektion",
              content_1: "(1) Eine Leichenöffnung zur Klärung der Todesursache oder zur Überprüfung der Diagnose oder der Therapie (Obduktion) ist außer in den bundesrechtlich geregelten Fällen zulässig, wenn der Verstorbene vor seinem Tode eingewilligt hatte. Liegt eine solche Erklärung des Verstorbenen nicht vor und hat dieser einer Obduktion nicht widersprochen, kann die Obduktion vorgenommen werden, wenn der in der Rangfolge des § 9 Abs. 2 nächste Angehörige oder die vom Verstorbenen bevollmächtigte Person über die Absicht, eine Obduktion durchzuführen und über die Möglichkeit, dieser innerhalb einer festgelegten Frist von mindestens 24 Stunden nach Information ohne Angabe von Gründen zu widersprechen, informiert worden ist und innerhalb der Frist kein Widerspruch erfolgt ist. Bei mehreren gleichrangigen Angehörigen genügt die Information und der Widerspruch eines Angehörigen. Die Information nach Satz 2 ist zu dokumentieren.",
              content_2: "(2) Obduktionen dürfen nur von Ärzten, die die Anerkennung zum Führen der Gebietsbezeichnung Pathologie oder Rechtsmedizin besitzen, oder unter deren Aufsicht vorgenommen werden.",
              content_3: "(3) Leichen dürfen für anatomische Sektionen oder für sonstige Zwecke der Forschung und Lehre nur dann verwendet werden, wenn die schriftliche Einwilligung des Verstorbenen vorliegt."
          },
          {
              titel: "§ 6 Todesbescheinigung",
              content_1: "(1) Unverzüglich nach Beendigung der Leichenschau hat der Arzt eine Todesbescheinigung auszustellen. Die Todesbescheinigung dient dem Nachweis des Todeszeitpunktes und der Todesursache, der für die Aufklärung von etwaigen Straftaten erforderlichen Mitteilung der Todesart, der Prüfung, ob seuchenhygienische oder sonstige Maßnahmen zur Gefahrenabwehr erforderlich sind, sowie Zwecken der Statistik und der Forschung. Kann der Arzt die Todesbescheinigung nicht sofort vollständig ausstellen, insbesondere weil ihm notwendige Angaben fehlen, hat er vorerst eine Bescheinigung über die Feststellung des Todes auszustellen und das vollständige Ausstellen der Todesbescheinigung unverzüglich nachzuholen.",
              content_2: "(2) Wird eine Obduktion durchgeführt, so hat der obduzierende Arzt dem für den Sterbeort zuständigen Gesundheitsamt unverzüglich eine Bescheinigung über die von ihm festgestellte Todesursache und andere wesentliche Krankheiten (Obduktionsschein) zu übersenden.",
              content_3: "(3) Todesbescheinigungen und Obduktionsscheine sind von dem für den Sterbeort zuständigen Gesundheitsamt auf ordnungsgemäße Ausstellung zu überprüfen und 30 Jahre lang aufzubewahren. Ärzte, die die Leichenschau oder eine Obduktion vorgenommen haben, sind verpflichtet, auf Anforderung des Gesundheitsamtes lückenhafte Todesbescheinigungen oder Obduktionsscheine zu vervollständigen. Sie sowie Ärzte, die den Verstorbenen vorher behandelt haben, sind verpflichtet, die zur Überprüfung und Vervollständigung erforderlichen Auskünfte zu erteilen.",
              content_4: "(4) Das Gesundheitsamt kann auf Antrag Auskünfte aus Todesbescheinigungen und Obduktionsscheinen im erforderlichen Umfang erteilen und insoweit auch Einsicht gewähren und Ablichtungen davon aushändigen,",
              content_5: "1. wenn der Antragsteller ein berechtigtes Interesse glaubhaft macht und kein Grund zu der Annahme besteht, daß durch die Offenbarung schutzwürdige Belange des Verstorbenen oder seiner Angehörigen beeinträchtigt werden, oder",
              content_6: "2. wenn der Antragsteller die Angaben für ein wissenschaftliches Forschungsvorhaben benötigt und",
              content_7: "a) durch sofortige Anonymisierung der Angaben sichergestellt wird, daß schutzwürdige Belange des Verstorbenen und seiner Angehörigen nicht beeinträchtigt werden, oder",
              content_8: "b) das Sozialministerium festgestellt hat, daß das öffentliche Interesse an dem Forschungsvorhaben das Geheimhaltungsinteresse des Verstorbenen und seiner Angehörigen erheblich überwiegt und der Zweck der Forschung nicht auf andere Weise erreicht werden kann.",
              content_9: "(5) Das Sozialministerium wird ermächtigt, durch Rechtsverordnung",
              content_10: "1. den Inhalt der Todesbescheinigung, der Bescheinigung über die Todesfeststellung und des Obduktionsscheins sowie",
              content_11: "2. deren Empfänger, die zu beachtenden Datenschutzmaßnahmen, die Auswertung und den sonstigen Umgang mit diesen Bescheinigungen näher zu regeln."
          },
          {
              titel: "§ 7 Kosten der Leichenschau",
              content_1: "(1) Bei Sterbefällen in Krankenhäusern und anderen Einrichtungen, zu deren Aufgaben auch die ärztliche Behandlung der aufgenommenen Personen gehört, kann eine besondere Vergütung für die Leichenschau und die Ausstellung der Todesbescheinigung nicht verlangt werden. In den übrigen Fällen hat der zur Bestattung Verpflichtete die Kosten für die Leichenschau und die Ausstellung der Todesbescheinigung zu tragen oder dem Veranlasser zu erstatten.",
              content_2: "(2) In den Fällen des § 5 Abs. 3 trägt die Einrichtung, die die Leiche für Zwecke von Forschung und Lehre übernimmt, die Kosten der Leichenschau und die Ausstellung der Todesbescheinigung."
          },
          {
              titel: "§ 8 Aufbewahrung und Beförderung von Leichen",
              content_1: "(1) Jede Leiche ist innerhalb von 36 Stunden nach Eintritt des Todes, bei späterem Auffinden unverzüglich in eine Leichenhalle zu überführen. Das Gesundheitsamt kann im Einzelfall Ausnahmen zulassen, sofern Gründe der Hygiene nicht entgegenstehen, oder die Frist nach Satz 1 aus Gründen der Hygiene verkürzen. Leichenhallen sind Räumlichkeiten, die ausschließlich der Aufbewahrung von Leichen dienen und den Anforderungen der Hygiene entsprechen.",
              content_2: "(2) Zur Beförderung von Leichen sind diese einzusargen. Dazu sind geschlossene, widerstandsfähige Särge zu verwenden. Im Straßenverkehr sind Leichen in Fahrzeugen zu befördern, die ausschließlich für den Transport von Särgen und Urnen bestimmt und hierfür eingerichtet sind. Das Gesundheitsamt kann Ausnahmen zulassen. Die Beförderung von Leichen in Anhängern von Fahrzeugen ist nicht zulässig. Unterbrechungen bei der Überführung sind zu vermeiden. Die Sätze 1 bis 3 und 5 gelten nicht für die Bergung von Leichen, insbesondere die Beförderung tödlich Verunglückter von der Unfallstelle. Särge dürfen zu Transportzwecken nur dann mehrfach verwendet werden, sofern sie rückstandsfrei reinigbar sind.",
              content_3: "(3) Wer eine nach § 4 Abs. 4 zu kennzeichnende Leiche einsargt, hat die Kennzeichnung auf dem Sarg zu wiederholen.",
              content_4: "(4) Leichen dürfen von einem Ort außerhalb der Bundesrepublik Deutschland nur dann in das Land Mecklenburg-Vorpommern befördert werden, wenn aus einem Leichenpaß oder einer amtlichen Bescheinigung hervorgeht, ob der Verstorbene an einer übertragbaren Krankheit gelitten hat. Das Gesundheitsamt kann Ausnahmen zulassen. Für die Beförderung einer Leiche aus Mecklenburg-Vorpommern an einen anderen Ort stellt das Gesundheitsamt auf Antrag einen Leichenpaß aus. Es ist berechtigt, die dafür erforderlichen Nachweise zu verlangen sowie eigene Ermittlungen durchzuführen und Auskünfte einzuholen.",
              content_5: "(5) Leichen dürfen aus dem Land Mecklenburg-Vorpommern nur dann an einen Ort außerhalb der Bundesrepublik Deutschland befördert werden, wenn vorher eine zweite Leichenschau durchgeführt worden ist. § 12 Abs. 1 und 2 gilt entsprechend.",
              content_6: "(6) Die Absätze 1 bis 5 gelten nicht für Totgeborene mit einem Gewicht unter 1 000 Gramm."
          }
      ],
      [   "Zweiter Abschnitt: Bestattungswesen",
          {
              titel: "§ 9 Bestattungspflicht",
              content_1: "(1) Leichen sind zu bestatten. Dies gilt nicht für Totgeborene mit einem Gewicht unter 1 000 Gramm. Diese Totgeborenen sowie Fehlgeborene und Feten sind auf Wunsch eines Elternteils zu bestatten. Die Einrichtung, in der eine Tot- oder Fehlgeburt erfolgt ist, hat die Eltern über die Möglichkeit der Bestattung zu informieren. Totgeborene mit einem Gewicht unter 1 000 Gramm sowie Fehlgeborene und Feten aus Schwangerschaftsabbrüchen nach der zwölften Schwangerschaftswoche, die nicht auf Wunsch eines Elternteils bestattet werden, sind von der Einrichtung auf einem Friedhof beizusetzen. Sonstige Fehlgeborene und Feten aus Schwangerschaftsabbrüchen sind hygienisch einwandfrei und dem sittlichen Empfinden entsprechend einer Verbrennung zuzuführen, sofern sie nicht rechtmäßig zu medizinischen, pharmazeutischen oder wissenschaftlichen Zwecken verwendet werden. Satz 6 gilt entsprechend für die Beseitigung von Körperteilen.",
              content_2: "(2) Für die Bestattung haben die volljährigen Angehörigen in folgender Reihenfolge zu sorgen:",
              content_3: "1. Ehegatte,",
              content_4: "2. Lebenspartner im Sinne des Lebenspartnerschaftsgesetzes vom 16. Februar 2001 (BGBl. I S. 266), zuletzt geändert durch Artikel 2 Abs. 18 des Gesetzes vom 19. Februar 2007 (BGBl. I S. 122),",
              content_5: "3. Kinder,",
              content_6: "4. Eltern,",
              content_7: "5. Geschwister,",
              content_8: "6. Großeltern,",
              content_9: "7. Enkelkinder,",
              content_10: "8. sonstiger Partner einer auf Dauer angelegten nichtehelichen Lebensgemeinschaft.,",
              content_11: "(3) Sind Bestattungspflichtige im Sinne des Absatzes 2 nicht vorhanden, nicht zu ermitteln oder nicht auffindbar oder kommen sie ihrer Pflicht nicht nach und veranlasst kein anderer die Bestattung, hat die örtliche Ordnungsbehörde für die Bestattung zu sorgen, die für den Ort zuständig ist, in dem der Verstorbene zuletzt seine Hauptwohnung hatte. Lag die letzte Hauptwohnung des Verstorbenen nicht in Mecklenburg-Vorpommern und veranlasst die dort zuständige örtliche Ordnungsbehörde die Bestattung nicht, hat diejenige örtliche Ordnungsbehörde für die Bestattung zu sorgen, in deren Gebiet der Todesfall eingetreten ist. Ist der Tod in diesem Fall auf See oder in einem Luftfahrzeug eingetreten, so ist die Ordnungsbehörde des Ortes zuständig, an dem die Leiche an Land gebracht wird. Eine Pflicht zur Erstattung der Kosten bleibt unberührt.",
              content_12: "(4) In den Fällen des § 5 Abs. 3 ist die Einrichtung, die die Leiche für Zwecke der Forschung und Lehre übernommen hat, für die Bestattung verantwortlich, sobald die Leiche für diese Zwecke nicht mehr benötigt wird."
          },
          {
              titel: "§ 10 Bestattungsart",
              content_1: "(1) Die Bestattung ist zulässig, wenn eine Leichenschau durchgeführt worden ist und ein Nachweis über die Anzeige des Sterbefalles beim Standesamt vorgelegt wird. Vor der Anzeige beim Standesamt darf der Verstorbene nur mit Genehmigung der örtlichen Ordnungsbehörde des Sterbeortes bestattet werden.",
              content_2: "(2) Erdbestattungen dürfen frühestens 48 Stunden nach Eintritt des Todes vorgenommen werden; innerhalb von zehn Tagen nach Todeseintritt soll die Erdbestattung vorgenommen, bei einer Feuerbestattungen die Leiche in ein Krematorium befördert oder zur Bestattung an einem anderen Ort auf den Weg gebracht worden sein. Das Gesundheitsamt kann Ausnahmen genehmigen. Soll eine Obduktion oder eine Sektion nach § 5 erfolgen, sind Satz 1 und 2 nicht anzuwenden.",
              content_3: "(3) Soll ein Fehlgeborenes bestattet werden, so ist dem Träger des Friedhofs oder des Krematoriums eine ärztliche Bescheinigung vorzulegen, aus der sich das Datum der Geburt sowie Name und Anschrift der Mutter ergibt."
          },
          {
            titel: "§ 11 Voraussetzungen der Bestattung",
            content_1: "(1) Die Bestattung ist zulässig, wenn eine Leichenschau durchgeführt worden ist und ein Nachweis über die Anzeige des Sterbefalles beim Standesamt vorgelegt wird. Vor der Anzeige beim Standesamt darf der Verstorbene nur mit Genehmigung der örtlichen Ordnungsbehörde des Sterbeortes bestattet werden.",
            content_2: "(2) Erdbestattungen dürfen frühestens 48 Stunden nach Eintritt des Todes vorgenommen werden; innerhalb von zehn Tagen nach Todeseintritt soll die Erdbestattung vorgenommen, bei einer Feuerbestattungen die Leiche in ein Krematorium befördert oder zur Bestattung an einem anderen Ort auf den Weg gebracht worden sein. Das Gesundheitsamt kann Ausnahmen genehmigen. Soll eine Obduktion oder eine Sektion nach § 5 erfolgen, sind Satz 1 und 2 nicht anzuwenden.",
            content_3: "(3) Soll ein Fehlgeborenes bestattet werden, so ist dem Träger des Friedhofs oder des Krematoriums eine ärztliche Bescheinigung vorzulegen, aus der sich das Datum der Geburt sowie Name und Anschrift der Mutter ergibt."
        },
          {
              titel: "§ 12 Feuerbestattung",
              content_1: "(1) Eine Feuerbestattung ist nur zulässig, wenn durch eine zweite Leichenschau bestätigt worden ist, daß keine Anhaltspunkte für einen nichtnatürlichen Tod bestehen, oder wenn die Staatsanwaltschaft bei Vorliegen von Anhaltspunkten für einen nichtnatürlichen Tod nach Abschluss der daraufhin vorgenommenen Ermittlungen dennoch einer Feuerbestattung zustimmt. Wenn eine Obduktion nach § 87 Abs. 2 der Strafprozeßordnung durchgeführt worden ist oder es sich um ein Totgeborenes mit einem Gewicht unter 1000 Gramm handelt, ist eine zweite Leichenschau nicht erforderlich.",
              content_2: "(2) Die zweite Leichenschau nach Absatz 1 soll durch einen hierfür vom Gesundheitsamt ermächtigten Facharzt für Rechtsmedizin durchgeführt werden. Sie kann auch durch einen Arzt des Gesundheitsamtes durchgeführt werden.",
              content_3: "(3) Angehörige und Personen, die den Verstorbenen während einer dem Tod vorausgegangenen Krankheit behandelt oder gepflegt haben, sowie Ärzte, die die erste Leichenschau oder eine Obduktion vorgenommen haben, sind verpflichtet, dem für die zweite Leichenschau zuständigen Arzt auf Verlangen Auskunft über Krankheiten und andere Gesundheitsschädigungen des Verstorbenen und über sonstige für seinen Tod möglicherweise ursächliche Ereignisse zu erteilen. § 4 Abs. 2 Satz 2 gilt entsprechend. Die Durchführung der zweiten Leichenschau ist zu dokumentieren. Die Aufzeichnungen sind vom Krematorium fünf Jahre lang aufzubewahren.",
              content_4: "(4) Leichen dürfen nur in kommunalen Krematorien eingeäschert werden.",
              content_5: "(5) Die Asche jeder Leiche ist in eine Urne aufzunehmen. Die Urne ist zu kennzeichnen und zu verschließen. Über die vorgenommene Einäscherung und den Verbleib der Asche hat das Krematorium ein Verzeichnis zu führen, das fünf Jahre lang aufzubewahren ist.",
              content_6: "(6) Das Krematorium darf die Urne nur zur Beisetzung aushändigen oder versenden."
          },
          {
              titel: "§ 13 Beisetzung",
              content_1: "(1) Erdbestattungen sind nur auf Friedhöfen zulässig. Die Gemeinde kann mit Zustimmung des Gesundheitsamtes im Einzelfall Ausnahmen zulassen, wenn ein wichtiger Grund vorliegt und öffentliche Belange nicht entgegenstehen.",
              content_2: "(2) Bei einer Feuerbestattung ist die Urne mit der Asche auf einem Friedhof oder in geeigneter Form in einer Kirche beizusetzen. Die Asche kann auch auf einer hierfür bestimmten Stelle eines Friedhofs verstreut werden. Auf Wunsch des Verstorbenen darf außerdem die Urne von einem Schiff aus auf See beigesetzt werden, wenn andere Rechtsvorschriften nicht entgegenstehen. Sonstige Beisetzungen von Urnen außerhalb von Friedhöfen kann die Gemeinde im Einzelfall zulassen, wenn ein wichtiger Grund vorliegt und öffentliche Belange nicht entgegenstehen."
          }
      ],
      [   "Dritter Abschnitt: Friedhofswesen",
          {
              titel: "§ 14 Friedhöfe",
              content_1: "(1) Träger von Friedhöfen können sein",
              content_2: "1. das Land und der Aufsicht des Landes unterstehende juristische Personen des öffentlichen Rechts,",
              content_3: "2. Gemeinden sowie,",
              content_4: "3. Religionsgemeinschaften, die Körperschaften des öffentlichen Rechts sind.",
              content_5: "(2) Die Gemeinden haben Friedhöfe (Gemeindefriedhöfe) einzurichten und zu unterhalten. Dies gilt nicht, wenn in der Gemeinde ein kirchlicher Friedhof vorhanden ist oder die Gemeinde durch Vereinbarung sicherstellt, daß der Friedhof eines anderen Trägers benutzt werden kann. Die Sätze 1 und 2 gelten für Leichenhallen entsprechend.",
              content_6: "(3) Die in Absatz 1 Nr. 1 und 3 genannten Körperschaften können Friedhöfe einrichten und unterhalten. Auf kirchlichen Friedhöfen ist die Bestattung aller in der Gemeinde Verstorbenen zu ermöglichen, wenn die Gemeinde keinen eigenen Friedhof unterhält und auch keine Vereinbarung nach Absatz 2 Satz 2 geschlossen hat. In diesen Fällen hat sich die Gemeinde an den Kosten des Friedhofs zu beteiligen, die nicht durch Benutzungsentgelte gedeckt werden können.",
              content_7: "(4) Erhebt der Träger eines kirchlichen Friedhofs Benutzungsentgelte in der Form von öffentlich-rechtlichen Gebühren, so sind diese auf seinen Antrag von den Oberbürgermeistern der kreisfreien Städte, den Bürgermeistern der amtsfreien Gemeinden und den Amtsvorstehern der Ämter im Wege der Vollstreckungshilfe nach den für die Verwaltungsvollstreckung geltenden Vorschriften beizutreiben. Kosten der Vollstreckungshilfe, die nicht durch Zahlung des Pflichtigen gedeckt werden, hat der Träger des kirchlichen Friedhofs der Vollstreckungsbehörde zu erstatten.",
              content_8: "(5) Der Träger des Friedhofs regelt die Ordnung, Benutzung und Gestaltung sowie die Ausübung gewerblicher Tätigkeit durch eine Friedhofsordnung. Der Träger ist verpflichtet, über erfolgte Bestattungen Buch zu führen.",
              content_9: "(6) Die Einrichtung oder Erweiterung von Friedhöfen bedarf der Genehmigung, die die Landräte oder die Oberbürgermeister der kreisfreien Städte im Benehmen mit den zuständigen Wasserbehörden erteilen. Die Genehmigung ist öffentlich bekanntzugeben."
          },
          {
              titel: "§ 15 Ruhezeiten",
              content_1: "(1) Das Gesundheitsamt legt die Mindestruhezeit für den jeweiligen Friedhof und für sonstige Grabstätten fest. Sie darf 20 Jahre nicht unterschreiten. Vor Ablauf der Ruhezeit darf in einem Grab keine weitere Erdbestattung vorgenommen werden. Das Gesundheitsamt kann Ausnahmen zulassen.",
              content_2: "(2) Für die Beisetzung der Asche von Totgeborenen, Fehlgeborenen und Feten nach § 9 Abs. 1 Satz 5 gilt keine Ruhezeit. Werden diese Körper ohne Einäscherung beigesetzt, legt das Gesundheitsamt die Ruhezeit fest."
          },
          {
              titel: "§ 16 Ausgrabungen und Umbettungen",
              content_1: "(1) Ausgrabungen und Umbettungen von Leichen und Urnen darf der Träger des Friedhofs vor Ablauf der Ruhezeit nur zulassen, wenn ein wichtiger Grund eine Störung der Totenruhe rechtfertigt. Ausgrabungen und Umbettungen von Leichen bedürfen der Zustimmung des Gesundheitsamtes. Umbettungen von Leichen im Zeitraum von zwei Wochen bis zu sechs Monaten nach der Beisetzung sind unzulässig.",
              content_2: "(2) Mit einer Umbettung beginnt keine neue Ruhezeit.",
              content_3: "(3) Werden bei Erdarbeiten außerhalb von Friedhöfen Überreste einer menschlichen Leiche gefunden, sind diese nach Abschluß etwaiger polizeilicher Ermittlungen auf einem Friedhof wieder der Erde zu übergeben, soweit sie nicht wissenschaftlichen Zwecken zugeführt werden."
          },
          {
              titel: "§ 17 Aufhebung von Friedhöfen",
              content_1: "(1) Friedhöfe, Teile von Friedhöfen oder einzelne Grabstätten dürfen nur aufgehoben werden, wenn alle Mindestruhezeiten abgelaufen sind.",
              content_2: "(2) Im öffentlichen Interesse kann ein Friedhof vor Ablauf der Mindestruhezeiten aufgehoben werden, wenn die Leichen und Urnen vorher umgebettet worden sind.",
              content_3: "(3) Die Aufhebung eines Friedhofs oder eines Teils eines Friedhofs ist öffentlich bekanntzugeben."
          }
      ],
      [   "Vieretr Abschnitt: Gemeinsame Vorschriften",
          {
              titel: "§ 18 Aufgabenwahrnehmung",
              content_1: "(1) Die Durchsetzung der nachstehenden Regelungen",
              content_2: "1. § 3 Abs. 3 und 4 und § 4 (Durchführung der Leichenschau durch Ärzte),",
              content_3: "2. § 5 (Obduktion und Sektion),",
              content_4: "3. § 6 Abs. 1, 2 und 3 Satz 2 und 3 (Ausstellung, Übersendung und Vervollständigung von Todesbescheinigungen und Obduktionsscheinen),",
              content_5: "4. § 7 (Kosten der Leichenschau),",
              content_6: "5. § 8 Abs. 3 (Wiederholung einer Kennzeichnung auf dem Sarg),",
              content_7: "6. § 9 Abs. 1 Satz 4 und 5 (Beseitigung von nicht zu bestattenden Leichen und Teilen),",
              content_8: "7. § 12 (Durchführung der Feuerbestattung mit Ausnahme der Beisetzung der Asche)",
              content_9: "sowie die Erteilung der Genehmigung nach § 14 Abs. 6 wird den Landkreisen und den kreisfreien Städten übertragen. Sie nehmen auch die nach diesem Gesetz den Gesundheitsämtern zugewiesenen Aufgaben als Aufgaben des übertragenen Wirkungskreises wahr. Die damit verbundenen Aufwendungen sind durch Zuweisungen des kommunalen Finanzausgleichs für gesetzlich übertragene Aufgaben abgegolten.",
              content_10: "(2) Die Gemeinden nehmen die ihnen zugewiesenen Aufgaben nach",
              content_11: "1. § 12 Abs. 3 Satz 4, Abs. 4, Abs. 5 Satz 3 und Abs. 6 (Betrieb von Krematorien),",
              content_12: "2. § 13 Abs. 1 Satz 2 und Abs. 2 Satz 4 (Zulassung von Beisetzungen außerhalb von Friedhöfen),",
              content_13: "3. § 14 Abs. 1 bis 3 und 5 (Einrichtung und Betrieb von Friedhöfen und Leichenhallen) im eigenen Wirkungskreis wahr.",
              content_14: "(3) Die übrigen Aufgaben nach diesem Gesetz werden, soweit sie nicht ausdrücklich bestimmten Stellen zugewiesen sind, von den Ämtern, amtsfreien Gemeinden und kreisfreien Städten als Aufgaben des übertragenen Wirkungskreises wahrgenommen. Absatz 1 Satz 3 gilt entsprechend.",
              content_15: "(4) Fachaufsichtsbehörde ist das Sozialministerium."
          },
          {
            titel: "§ 19 Einschränkung von Grundrechten",
            content_1: "Für die Durchführung der Leichenschau nach § 4 Abs. 1 dieses Gesetzes wird das Grundrecht auf Unverletzlichkeit der Wohnung ( Artikel 13 Abs. 1 des Grundgesetzes ) eingeschränkt."
          },
          {
            titel: "§ 20 Ordnungswidrigkeiten",
            content_1: "(1) Ordnungswidrig handelt, wer vorsätzlich oder fahrlässig",
            content_2: "1. entgegen § 3 Abs. 2 als Verpflichteter die Leichenschau nicht unverzüglich veranlaßt,",
            content_3: "2. entgegen § 3 Abs. 3 als Arzt die Leichenschau nicht durchführt,",
            content_5: "3. entgegen § 4 Abs. 1 die Leichenschau nicht rechtzeitig oder nicht in der erforderlichen Weise durchführt,",
            content_6: "4. entgegen § 4 Abs. 2 , § 6 Abs. 3 Satz 3 oder § 12 Abs. 3 Satz 1 eine Auskunft nicht oder nicht richtig erteilt,",
            content_7: "5. entgegen § 4 Abs. 4 als Arzt eine Leiche nicht mit einem Hinweis auf eine meldepflichtige Krankheit oder auf eine sonstige von der Leiche ausgehende Gefahr kennzeichnet oder entgegen § 8 Abs. 3 die Kennzeichnung auf dem Sarg nicht wiederholt,",
            content_8: "6. als Arzt entgegen § 6 Abs. 1 eine Todesbescheinigung nicht, nicht richtig oder nicht vollständig ausstellt oder entgegen § 6 Abs. 3 Satz 2 eine Todesbescheinigung oder einen Obduktionsschein nicht vervollständigt,",
            content_9: "7. entgegen § 8 Abs. 2 für die Beförderung von Leichen im Straßenverkehr ein Fahrzeug benutzt, das hierfür nicht bestimmt und eingerichtet ist, oder einen Anhänger benutzt,",
            content_10: "8. entgegen § 13 eine Beisetzung außerhalb eines Friedhofs vornimmt,",
            content_11: "9. einer Rechtsverordnung nach § 6 Abs. 5 zuwiderhandelt, soweit sie für einen bestimmten Tatbestand auf diese Bußgeldvorschrift verweist.",
            content_12: "(2) Die Ordnungswidrigkeit kann mit einer Geldbuße bis zu zehntausend Euro geahndet werden.",
            content_13: "(3) Verwaltungsbehörden nach § 36 Abs. 1 Nr. 1 des Gesetzes über Ordnungswidrigkeiten sind in den Fällen",
            content_14: "1. des Absatzes 1 Nr. 2 bis 6 und 9 die Landräte und die Oberbürgermeister der kreisfreien Städte,",
            content_15: "2. des Absatzes 1 Nr. 1, 7 und 8 die örtlichen Ordnungsbehörden.",
            content_16: "(4) Die Geldbußen fließen den nach Absatz 3 zuständigen Behörden zu. Diese tragen abweichend von § 105 Abs. 2 des Gesetzes über Ordnungswidrigkeiten die notwendigen Auslagen und sind ersatzpflichtig im Sinne des § 110 Abs. 4 des Gesetzes über Ordnungswidrigkeiten."
          },

      ],
      [   "Fünfter Abschnitt: Überleitungs- und Schlussvorschriften",
          {
              titel: "§ 21 Übergangsvorschriften",
              content_1: "(1) Bis zum Inkrafttreten einer Rechtsverordnung nach § 6 Abs. 5 sind die durch Runderlaß des Sozialministeriums vom 21. Dezember 1992 (Ärzteblatt Mecklenburg-Vorpommern 1993 S. 106) eingeführten Vordrucke in der dort beschriebenen Weise zu verwenden.",
              content_2: "(2) Für die Aufbewahrung der vor Inkrafttreten dieses Gesetzes ausgestellten Todesbescheinigungen und Obduktionsscheine sowie für den sonstigen Umgang mit diesen gilt § 6 Abs. 3 Satz 1 und Abs. 4 entsprechend.",
              content_3: "(3) Ärzte, die bisher mit der zweiten Leichenschau beauftragt waren, gelten bis auf Widerruf als ermächtigt im Sinne des § 12 Abs. 2 .",
              content_4: "(4) Abweichend von § 12 Abs. 4 dürfen Leichen auch in anderen Krematorien, die bei Inkrafttreten dieses Gesetzes bereits betrieben werden und deren Gesellschafter ausschließlich Gemeinden sind, eingeäschert werden.",
              content_5: "(5) Für Bestattungen nach Inkrafttreten dieses Gesetzes beträgt die Ruhezeit mindestens 20 Jahre, auch wenn für den Friedhof bisher eine kürzere Ruhezeit festgelegt war."
          },
          {
              titel: "§ 22 Aufhebung von Vorschriften",
              content_1: "Soweit sie als Landesrecht in Mecklenburg-Vorpommern fortgelten, werden aufgehoben:",
              content_2: "1. das Gesetz über die Feuerbestattung vom 15. Mai 1934 (RGBl. I S. 380),",
              content_3: "2. die Verordnung zur Durchführung des Feuerbestattungsgesetzes vom 10. August 1938 (RGBl. I S. 1000),",
              content_5: "3. die Verordnung über das Bestattungs- und Friedhofswesen vom 17. April 1980 (GBl. DDR I S. 159),",
              content_6: "4. die Erste Durchführungsbestimmung zur Verordnung über das Bestattungs- und Friedhofswesen vom 17. April 1980 (GBl. DDR I S. 162),",
              content_7: "5. die Zweite Durchführungsbestimmung zur Verordnung über das Bestattungs- und Friedhofswesen - Hygiene bei der Überführung, der Bestattung und der Exhumierung menschlicher Leichen - vom 2. Juni 1980 (GBl. DDR I S. 164),",
              content_8: "6. die Anordnung über die ärztliche Leichenschau vom 4. Dezember 1978 (GBl. DDR I 1979 S. 4),",
              content_9: "7. die Anweisung ( Nr. 1) zur ärztlichen Leichenschau vom 4. Dezember 1978 (Verfügungen und Mitteilungen des Ministeriums für Gesundheitswesen der DDR S. 101),",
              content_10: "8. die Anweisung Nr. 2 zur ärztlichen Leichenschau vom 23. Oktober 1980 (Verfügungen und Mitteilungen des Ministeriums für Gesundheitswesen der DDR S. 62),",
              content_11: "9. die Anweisung Nr. 3 über die ärztliche Leichenschau vom 4. Oktober 1983 (Verfügungen und Mitteilungen des Ministeriums für Gesundheitswesen der DDR S. 67),",
              content_12: "10. die Anordnung über die Überführung von Leichen vom 20. Oktober 1971 (GBl. DDR II S. 626),",
              content_13: "11. die Anordnung über die Leichenschau und die Seebestattung bei Sterbefällen auf Seeschiffen vom 13. Februar 1985 (GBl. DDR I S. 89),",
              content_14: "12. die Anordnung über die Sicherung der gegenwärtigen geltenden Preise des Bestattungswesens gegenüber der Bevölkerung nach Einführung der Industriepreise der 3. Etappe der Industriereform - Bestattungswesen - vom 15. Dezember 1966 (GBl. DDR II S. 1106),"
          },
          {
              titel: "§ 23 Inkrafttreten",
              content_1: "Dieses Gesetz tritt am 1. September 1998 in Kraft.",
              content_2: "Das vorstehende Gesetz wird hiermit verkündet.",
              content_3: "Schwerin, den 3. Juli 1998",
              content_4: ["Der Ministerpräsident, ", "Der Sozialminister, ", "Dr. Berndt Seite, ", "Hinrich Kuessner"]
          }
      ]

  ]