import React, {useEffect} from "react";
import "../style/vorsorge.css";

const Vorsorge = () => {

   useEffect(() => {
      window.scrollTo(0, 0);
   });
   
return(
   <>
   <div className="bg-vorsorge">
   <h2 className="headerTop">Vorsorge</h2>
  </div>

   <div className="container-fluid bestattungAngebot py-5">
      <div className="container py-3"> 

         <div className="vorsorgeBox">
             <h2 className="vorsorgeHeader">Warum Vorsorge?</h2>
              <p>
              Die Bestattungsvorsorge ermöglicht Ihnen bereits zu Lebzeiten, wie Ihre Bestattung ablaufen soll. 
              Für die Hinterbliebenen bedeutet das in der Regel eine große Erleichterung.
               Dadurch belasten Sie Ihre Angehörigen nicht oder bringen Sie in eine finanzielle Last. 
               Oder Sie haben gar keine Angehörigen, die Ihren letzten Willen umsetzen könnten.  
              </p>
         </div>

         <div className="vorsorgeBox">
             <h2 className="vorsorgeHeader">Beratung</h2>
              <p>
                Es liegt uns sehr am Herzen herauszufinden, wie Ihre Vorstellungen, Wünsche und Pläne für eine 
                Bestattung nach dem Trauerfall sind. In dieser tragischen Zeit werden wir alles drum setzen
                 Sie ohne jeglichen Zwang zu beraten. Dabei zeigen wir Ihnen die verschiedenen Möglichkeiten 
                 einer Bestattung auf und sprechen mit Ihnen über 
                die Finanzierung bzw. Bezahlung. Mit anderen Worten: Sie brauchen sich um nichts zu kümmern, 
                 lassen Sie uns einfach alles für Sie erledigen und Sie können die Zeit nutzen um für Sie oder die Angehörigen da zu sein. 
                 Sie können uns gerne einen Brief schreiben oder eine Email. 
                 Wir freuen uns auf das Gespräch mit Ihnen.
              </p>
         </div>

         <div className="vorsorgeBox">
             <h2 className="vorsorgeHeader">Ihr letzter Wille</h2>
              <p>
              Ein Vorsorgevertrag beinhaltet den Willen über eine finanzielle und organisatorische Vorsorge.
              Dieser Vertrag ist ein rechtsverbindlicher Vertrag und 
              gilt über den Tod eines Vertragspartners hinaus. Es ist nicht möglich durch eine 3. Person diesen Vertrag zu beeinflussen 
              oder gar außer Kraft setzen. In Verbindung mit einem Vorsorgevertrages, sehen die Sozialämter bis zu einer gewissen Höhe solche gesparten Beträge 
              nicht als verwertbares Vermögen an.
              </p>
         </div>

         <div className="vorsorgeBox">
             <h2 className="vorsorgeHeader">Vertrauen</h2>
              <p>
              Über den Bestattungsvorsorgevertrag wird nur Ihnen und von Ihnen bevollmächtigten Personen Auskünfte erteilt. 
              Dritte können den Vertrag weder einsehen noch ändern.
              </p>
         </div>

         <div className="vorsorgeBox">
             <h2 className="vorsorgeHeader">Spätere Änderungen</h2>
              <p>
               Nach Abschluss des abgeschlossenen Vorsorgevertrages, könen Sie  jederzeit bzgl. der Bestattungsform und   
               Einzelheiten des Bestattungsablaufes ändern.
               Die bei uns abgeschlossenen Vorsorgeverträge unterliegen von uns einer regelmäßigen Prüfung. 
               So werden wir Sie rechtzeitig informieren, wenn sich z. B. Gesetze ändern.
              </p>
         </div>

      </div>
   </div>
</>
)
 
}

export default Vorsorge;
