//import gesteck
import ge_01 from "../../images/floristik/gesteck/ge_01.jpg";
import ge_03 from "../../images/floristik/gesteck/ge_03.jpg";
import ge_05 from "../../images/floristik/gesteck/ge_05.jpg";
import ge_06 from "../../images/floristik/gesteck/ge_06.jpg";
// import ge_07 from "../../images/floristik/gesteck/ge_07.jpg";
import ge_09 from "../../images/floristik/gesteck/ge_09.jpg";
import ge_11 from "../../images/floristik/gesteck/ge_11.jpg";
import ge_14 from "../../images/floristik/gesteck/ge_14.jpg";
import ge_15 from "../../images/floristik/gesteck/ge_15.jpg";
import ge_20 from "../../images/floristik/gesteck/ge_20.jpg";
import ge_21 from "../../images/floristik/gesteck/ge_21.jpg";
import ge_25 from "../../images/floristik/gesteck/ge_25.jpg";
import ge_28 from "../../images/floristik/gesteck/ge_28.jpg";
import ge_31 from "../../images/floristik/gesteck/ge_31.jpg";
import ge_32 from "../../images/floristik/gesteck/ge_32.jpg";
import ge_33 from "../../images/floristik/gesteck/ge_33.jpg";
import ge_34 from "../../images/floristik/gesteck/ge_34.jpg";

//import kranz
import kr_01 from "../../images/floristik/kranz/kr_01.jpg";
import kr_02 from "../../images/floristik/kranz/kr_02.jpg";
import kr_06 from "../../images/floristik/kranz/kr_06.jpg";
import kr_13 from "../../images/floristik/kranz/kr_13.jpg";
import kr_16 from "../../images/floristik/kranz/kr_16.jpg";
import kr_18 from "../../images/floristik/kranz/kr_18.jpg";
import kr_22 from "../../images/floristik/kranz/kr_22.jpg";
import kr_23 from "../../images/floristik/kranz/kr_23.jpg";
import kr_25 from "../../images/floristik/kranz/kr_25.jpg";

//import sarg
import sa_1 from "../../images/floristik/sarg/sa_1.jpg";
import sa_3 from "../../images/floristik/sarg/sa_3.jpg";
import sa_7 from "../../images/floristik/sarg/sa_7.jpg";
import sa_8 from "../../images/floristik/sarg/sa_8.jpg";
import sa_9 from "../../images/floristik/sarg/sa_9.jpg";
import sa_11 from "../../images/floristik/sarg/sa_11.jpg";
import sa_12 from "../../images/floristik/sarg/sa_12.jpg";
import sa_17 from "../../images/floristik/sarg/sa_17.jpg";
import sa_18 from "../../images/floristik/sarg/sa_18.jpg";
import sa_20 from "../../images/floristik/sarg/sa_20.jpg";
import sa_21 from "../../images/floristik/sarg/sa_21.jpg";

//import tisch
import ti_03 from "../../images/floristik/tisch/ti_03.jpg";
import ti_04 from "../../images/floristik/tisch/ti_04.jpg";
import ti_05 from "../../images/floristik/tisch/ti_05.jpg";
import ti_07 from "../../images/floristik/tisch/ti_07.jpg";

//import urnenschmuck
import us_21634810 from "../../images/floristik/urnenschmuck/21634810.jpg";
import us_21634811 from "../../images/floristik/urnenschmuck/21634811.jpg";
import us_21634814 from "../../images/floristik/urnenschmuck/21634814.jpg";
import us_21634815 from "../../images/floristik/urnenschmuck/21634815.jpg";
import us_21634816 from "../../images/floristik/urnenschmuck/21634816.jpg";
import us_21634819 from "../../images/floristik/urnenschmuck/21634819.jpg";
import us_21634820 from "../../images/floristik/urnenschmuck/21634820.jpg";
import us_21634821 from "../../images/floristik/urnenschmuck/21634821.jpg";
import us_21634825 from "../../images/floristik/urnenschmuck/21634825.jpg";
import us_21634828 from "../../images/floristik/urnenschmuck/21634828.jpg";
import us_21634834 from "../../images/floristik/urnenschmuck/21634834.jpg";
import us_21634835 from "../../images/floristik/urnenschmuck/21634835.jpg";
import us_21634838 from "../../images/floristik/urnenschmuck/21634838.jpg";


export const alleBlumen = [
     //gesteck
    {id: 0, img: ge_01, art_nr: "ge_01", titel: "Gesteck 01", price: 127, category: "gesteck"},
    {id: 1, img: ge_03, art_nr: "ge_03", titel: "Gesteck 03", price: 121, category: "gesteck"},
    {id: 2, img: ge_05, art_nr: "ge_05", titel: "Gesteck 05", price: 91, category: "gesteck"},
    {id: 3, img: ge_06, art_nr: "ge_06", titel: "Gesteck 06", price: 115, category: "gesteck"},

    //{img: ge_07, art_nr: "ge_07", titel: "Gesteck 07", price: 0, category: "gesteck"},
    {id: 4, img: ge_09, art_nr: "ge_09", titel: "Gesteck 09", price: 103, category: "gesteck"},
    {id: 5, img: ge_11, art_nr: "ge_11", titel: "Gesteck 11", price: 103, category: "gesteck"},
    {id: 6, img: ge_14, art_nr: "ge_14", titel: "Gesteck 14", price: 103, category: "gesteck"},
    {id: 7, img: ge_15, art_nr: "ge_15", titel: "Gesteck 15", price: 186, category: "gesteck"},
    {id: 8, img: ge_20, art_nr: "ge_20", titel: "Gesteck 20", price: 103, category: "gesteck"},
    {id: 9, img: ge_21, art_nr: "ge_21", titel: "Gesteck 21", price: 103, category: "gesteck"},
    {id: 10, img: ge_25, art_nr: "ge_25", titel: "Gesteck 25", price: 91, category: "gesteck"},
    {id: 11, img: ge_28, art_nr: "ge_28", titel: "Gesteck 28", price: 115, category: "gesteck"},
    {id: 12, img: ge_31, art_nr: "ge_31", titel: "Gesteck 31", price: 210, category: "gesteck"},
    {id: 13, img: ge_32, art_nr: "ge_32", titel: "Gesteck 32", price: 174, category: "gesteck"},
    {id: 14, img: ge_33, art_nr: "ge_33", titel: "Gesteck 33", price: 174, category: "gesteck"},
    {id: 15, img: ge_34, art_nr: "ge_34", titel: "Gesteck 34", price: 127, category: "gesteck"},

    //urnenschmuck
    {id: 16, img: us_21634810, art_nr: "us_21634810", titel: "Gesteck 21634810", price: 65, category: "us"},
    {id: 17, img: us_21634811, art_nr: "us_21634811", titel: "Gesteck 21634811", price: 65, category: "us"},
    {id: 18, img: us_21634814, art_nr: "us_21634814", titel: "Gesteck 21634814", price: 50, category: "us"},
    {id: 19, img: us_21634815, art_nr: "us_21634815", titel: "Gesteck 21634815", price: 220, category: "us"},
    {id: 20, img: us_21634816, art_nr: "us_21634816", titel: "Gesteck 21634816", price: 60, category: "us"},
    {id: 21, img: us_21634819, art_nr: "us_21634819", titel: "Gesteck 21634819", price: 55, category: "us"},
    {id: 22, img: us_21634820, art_nr: "us_21634820", titel: "Gesteck 21634820", price: 50, category: "us"},
    {id: 23, img: us_21634821, art_nr: "us_21634821", titel: "Gesteck 21634821", price: 50, category: "us"},
    {id: 24, img: us_21634825, art_nr: "us_21634825", titel: "Gesteck 21634825", price: 50, category: "us"},
    {id: 25, img: us_21634828, art_nr: "us_21634828", titel: "Gesteck 21634828", price: 65, category: "us"},
    {id: 26, img: us_21634834, art_nr: "us_21634834", titel: "Gesteck 21634834", price: 120, category: "us"},
    {id: 27, img: us_21634835, art_nr: "us_21634835", titel: "Gesteck 21634835", price: 185, category: "us"},
    {id: 28, img: us_21634838, art_nr: "us_21634838", titel: "Gesteck 21634838", price: 120, category: "us"},

    //kranz
    {id: 29, img: kr_01, art_nr: "kr_01", titel: "Kranz 01", price: 141, category: "kranz"},
    {id: 30, img: kr_02, art_nr: "kr_02", titel: "Kranz 02", price: 141, category: "kranz"},
    {id: 31, img: kr_06, art_nr: "kr_06", titel: "Kranz 06", price: 175, category: "kranz"},
    {id: 32, img: kr_13, art_nr: "kr_13", titel: "Kranz 13", price: 175, category: "kranz"},
    {id: 33, img: kr_16, art_nr: "kr_16", titel: "Kranz 16", price: 175, category: "kranz"},
    {id: 34, img: kr_18, art_nr: "kr_18", titel: "Kranz 18", price: 175, category: "kranz"},
    {id: 35, img: kr_22, art_nr: "kr_22", titel: "Kranz 22", price: 175, category: "kranz"},
    {id: 36, img: kr_23, art_nr: "kr_24", titel: "Kranz 24", price: 141, category: "kranz"},
    {id: 37, img: kr_25, art_nr: "kr_25", titel: "Kranz 25", price: 141, category: "kranz"},

    //sarg
    {id: 38, img: sa_1, art_nr: "sa_01", titel: "Sargschmuck 01", price: 180, category: "sarg"},
    {id: 39, img: sa_3, art_nr: "sa_03", titel: "Sargschmuck 03", price: 180, category: "sarg"},
    {id: 40, img: sa_7, art_nr: "sa_07", titel: "Sargschmuck 07", price: 120, category: "sarg"},
    {id: 41, img: sa_8, art_nr: "sa_08", titel: "Sargschmuck 08", price: 150, category: "sarg"},
    {id: 42, img: sa_9, art_nr: "sa_09", titel: "Sargschmuck 09", price: 110, category: "sarg"},
    {id: 43, img: sa_11, art_nr: "sa_11", titel: "Sargschmuck 11", price: 210, category: "sarg"},
    {id: 44,img: sa_12, art_nr: "sa_12", titel: "Sargschmuck 12", price: 180, category: "sarg"},
    {id: 45, img: sa_17, art_nr: "sa_17", titel: "Sargschmuck 17", price: 180, category: "sarg"},
    {id: 46, img: sa_18, art_nr: "sa_18", titel: "Sargschmuck 18", price: 120, category: "sarg"},
    {id: 47, img: sa_20, art_nr: "sa_20", titel: "Sargschmuck 20", price: 150, category: "sarg"},
    {id: 48, img: sa_21, art_nr: "sa_21", titel: "Sargschmuck 21", price: 150, category: "sarg"},

    //tisch
    {id: 49, img: ti_03, art_nr: "ti_03", titel: "Tischdeko 01", price: 56, category: "tisch"},
    {id: 50, img: ti_04, art_nr: "ti_04", titel: "Tischdeko 04", price: 56, category: "tisch"},
    {id: 51, img: ti_05, art_nr: "ti_05", titel: "Tischdeko 05", price: 56, category: "tisch"},
    {id: 52,img: ti_07, art_nr: "ti_07", titel: "Tischdeko 07", price: 80, category: "tisch"},
]
