import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {alleSaerge} from "../data/saerge";
import { FaBars } from "react-icons/fa";

const Saerge = (props) => {

const [saerge, setAlleSaerge] = useState(alleSaerge);

 const fillterSaerge = (produkt) => {
        setAlleSaerge(alleSaerge.filter(elem => elem.category === produkt))
  }

  const filterAlleSaerge = (ev) => {
    let targetElementIdent = ev.target.getAttribute("ident");
        fillterSaerge(targetElementIdent);
 }

 const openMobileMenu = () => {
     let productMobileMenu = document.getElementById("productMobileMenu");

     productMobileMenu.classList.toggle("productMobileMenuActive");
 }


    return(
        <Modal
        size="xl"
        show={props.showSaerge}
        onHide={props.closeSaerge}
        aria-labelledby="example-modal-sizes-title-lg"
       >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
          <p className="modalTitle">Sargauswahl</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FaBars onClick={openMobileMenu} id="hamburgericonProduct" />
        <div id="productMobileMenu" className=" d-flex flex-wrap justify-content-center position-auswahl py-2 mt-1">
             <p ident="kiefersarg" className="productLinkAuswahl" onClick={filterAlleSaerge}>Kiefersärge</p>
             <p ident="pappelsarg" className="productLinkAuswahl" onClick={filterAlleSaerge}>Pappelsärge</p>
             <p ident="eichensarg" className="productLinkAuswahl" onClick={filterAlleSaerge}>Eichensärge</p>
             <p ident="designersarg" className="productLinkAuswahl" onClick={filterAlleSaerge}>Designersärge</p>
        </div>

        <div className="d-flex flex-wrap justify-content-center">
         {saerge.map((elem, index) => {
           return(
               <div key={index} className="col-sm-12 col-md-5 col-lg-5 card m-3 sargLastPosition">
                  <img 
                       src={elem.img} 
                       className="card-img-bottom imgSarg" 
                       alt={elem.titel} />
                  {/*<input className="radioBox mx-auto mb-2 defaultSarg" id={"sarg"+ elem.art_nr} onChange={this.props.selectSarg} value={elem.art_nr} type="radio" name={elem.name} />*/}
                  <span className="productInfo">{elem.titel}</span>
                  <span className="productInfo">Art-Nr: {elem.art_nr}</span>
                  <span className="productInfo mb-3">Preis: {elem.price.toFixed(2)} EUR</span>
                  <button onClick={() => props.addSarg(elem, index)}  className="btn mb-2 buttonAuswahlSarg">Auswählen</button>
               </div>
           )
          })}
         </div>
        </Modal.Body>
      </Modal>
    )
}

export default Saerge;