import React, {Component} from "react";
import "../style/kontakt.css";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
// import logo from "../images/logo/logo.svg";

class Kontakt extends Component{

   state = {
      showingInfoWindow: true,
      activeMarker: {},
      selectedPlace: {},
    };

   componentDidMount(){
      window.scrollTo(0, 0);
    }

    onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

   //  onMapClicked = (props) => {
   //    if (this.state.showingInfoWindow) {
   //      this.setState({
   //        showingInfoWindow: false,
   //        activeMarker: null
   //      })
   //    }
   //  };



  render(){

   const styleMap = {
      position: "relative",
      width: '100%',
      height: '350px'
    }

   //  const iconStyle = {
   //     height:"15px",
   //     width: "15px"
   //  }


    return(
    <>
        <div className="bg-kontakt">
           <h2 className="headerKontakt">Kontakt</h2>
        </div>
        <div className="contentKontakt p-4">
        <Map
             google={this.props.google}

             initialCenter={{
               lat: 53.7778397,
               lng: 12.192883
           }}

            containerStyle={styleMap}

             zoom={9}
            //  onClick={this.onMapClicked}

         >

         <Marker
            name={'Büro Krakow am See'}
            // title={'The marker`s title will appear as a tooltip.'}
            onClick={this.onMarkerClick}
            position={{lat: 53.65, lng: 12.266667}}
            // icon={logo}
            />

         <Marker
               // title={'The marker`s title will appear as a tooltip.'}
                name={'Büro Güstrow'}
                onClick={this.onMarkerClick}
               position={{lat: 53.7987, lng: 12.2075613}} />

            <InfoWindow
                     marker={this.state.activeMarker}
                     visible={this.state.showingInfoWindow}>

                        <p style={{textAlign: "center", margin: "auto", fontWeight: "bold"}}>{this.state.selectedPlace.name}</p>

            </InfoWindow>
         </Map>

          {/* <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4730.029405770989!2d12.261690000000002!3d53.64671!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ac177427632561%3A0xdd999f25893cdb91!2sLerchenberg+1%2C+18292+Krakow+am+See!5e0!3m2!1sde!2sde!4v1554313624980!5m2!1sde!2sde" title="map" frameBorder="0" allowFullScreen></iframe> */}

          <div className="kontaktBox">

             <span className="adressBlock">
              <h3 className="contaktHeader">Büro Güstrow</h3>
              <ul className="ulKontakt">
                 <li className="listKontakt listKontaktBold">(nach Terminvereinbarung)</li>
                 <li className="listKontakt">Rövertannen 21B</li>
                 <li className="listKontakt">18273 Güstrow </li>
                 <li className="listKontakt">Tel.: 01522 2003687 </li>
              </ul>
             </span>

             <span className="adressBlock">
              <h3 className="contaktHeader">Büro Krakow am See</h3>
              <ul className="ulKontakt">
                 <li className="listKontakt listKontaktBold">(nach Terminvereinbarung)</li>
                 <li className="listKontakt">Lerchenberg 1</li>
                 <li className="listKontakt">18292 Krakow am See</li>
                 <li className="listKontakt">Tel.: 01522 2003687</li>
              </ul>
             </span>

             <span className="adressBlock">
              <h3  className="contaktHeader">Email</h3>
              <ul className="ulKontakt">
                 <li className="listKontakt"><a href="mailto:info@sidereum-bestattungen.de"> info@sidereum-bestattungen.de</a></li>
              </ul>
             </span>

          </div>
        </div>


    </>
    )
  }
}

// export default Kontakt;
export default GoogleApiWrapper({
   apiKey: ("AIzaSyDomxaRuKzv-asGObNGh4jOVZB6oha1sOo")
 })(Kontakt)
