import React, {Component} from "react";
import "../style/home.css";
import {FaHome, FaHospital, FaRegCopy, FaPhoneSquare} from "react-icons/fa";
import { Collapse, Button} from 'reactstrap';
import {Link, Redirect} from "react-router-dom";
import {getBestattungsArt, updateTrauerfallStatus, successPlzToFalse, changeAngebotImg} from "./redux";
import {connect} from "react-redux";
// import bgImageHeader from "../images/home/bg_home.jpg";
import Geolocation from './Geolocation';
import {AngebotBoxContainer} from "./AngebotBox";
import erd from "../images/bestattungsarten/bg_erdbestattung.jpg";
import see from "../images/bestattungsarten/bg_seebestattung.jpg";
import anonym from "../images/bestattungsarten/bg_anonyme_bestattung.jpg";
import baum from "../images/bestattungsarten/bg_friedwald.jpg";
import einfach from "../images/bestattungsarten/bg_einfache_feuerbestattung.jpg";
import AddPostcode from "./ProductModals/addPostcode";
//import feuer from "../images/bestattungsarten/bg_feuerbestattung.jpg";

class Home extends Component{

  constructor(props) {
  super(props);
  this.toggleHome = this.toggleHome.bind(this);
  this.toggleHospital = this.toggleHospital.bind(this);
  this.toggleLedige = this.toggleLedige.bind(this);
  this.toggleVerheiratete = this.toggleVerheiratete.bind(this);
  this.toggleVerwitwete = this.toggleVerwitwete.bind(this);
  this.toggleGeschiedene = this.toggleGeschiedene.bind(this);
  this.toggleAussiedler = this.toggleAussiedler.bind(this);
  this.doPlaceToFalse = this.doPlaceToFalse.bind(this);
  this.doPlaceToTrue = this.doPlaceToTrue.bind(this);
  this.closePostcode = this.closePostcode.bind(this);
  this.showPostcodeBox = this.showPostcodeBox.bind(this);
  this.updateLink = this.updateLink.bind(this);

  this.state = { collapse: false,
                 collapseHospital: false,
                 collapseLedige: false,
                 collapseVerheiratete: false,
                 collapseVerwitwete: false,
                 collapseGeschiedene: false,
                 collapseAussiedler: false,
                 toggleText: "Mehr",
                 toggleTextHospital: "Mehr",
                 toggleTextLedige: "+",
                 toggleTextVerheiratete: "+",
                 toggleTextVerwitwete: "+",
                 toggleTextGeschiedene: "+",
                 toggleTextAussiedler: "+",
                 successPlz: false,
                 see: 1899,
                 anonym: 1899,
                 erd: 1899,
                 friedwald: 1899,
                 einfache_feuer: 1899,
                 bestattungsartAngebot: "",
                 isSeeLink: false,
                 isFeuerLink: false,
                 isAnonymLink: false,
                 isBaumLink: false,
                 isErdLink: false,
                 link: "",
                 showAddPostcode: false
                };
}

componentDidMount(){
  window.scrollTo(0, 0);
}

updateLink(){

     switch(this.state.link){
      case "Seebestattung":
        this.setState({isSeeLink: true})
       break;
       case "Feuerbestattung":
        this.setState({isFeuerLink: true})
       break;
       case "Anonyme Bestattung":
        this.setState({isAnonymLink: true})
       break;
       case "Baumbestattung":
        this.setState({isBaumLink: true})
       break;
       case "Erdbestattung":
        this.setState({isErdLink: true})
       break;
        default:
          return this.state.link;
     }

}

closePostcode(){
  this.setState({showAddPostcode: false})
}

showPostcodeBox(ev){
  console.log(ev)
  this.setState({showAddPostcode: true,
                link: ev})
}

toggleAussiedler(){
   if(this.state.collapseAussiedler === false){
    this.setState(state => ({ collapseAussiedler: true, toggleTextAussiedler: "-" }));
   }
   else{
    this.setState(state => ({collapseAussiedler: false, toggleTextAussiedler: "+" }));
}
}

toggleHospital() {
  if(this.state.collapseHospital === false){
    this.setState(state => ({ collapseHospital: true, toggleTextHospital: "Weniger" }));
    }
  else{
      this.setState(state => ({collapseHospital: false, toggleTextHospital: "Mehr" }));
  }
}

toggleHome() {
  if(this.state.collapse === false){
    this.setState(state => ({ collapse: true, toggleText: "Weniger" }));
    }
  else{
      this.setState(state => ({collapse: false, toggleText: "Mehr" }));
  }
}

toggleLedige() {
  if(this.state.collapseLedige === false){
    this.setState(state => ({ collapseLedige: true, toggleTextLedige: "-" }));
    }
  else{
      this.setState(state => ({collapseLedige: false, toggleTextLedige: "+" }));
  }
}

toggleVerheiratete() {
  if(this.state.collapseVerheiratete === false){
    this.setState(state => ({ collapseVerheiratete: true, toggleTextVerheiratete: "-" }));
    }
  else{
      this.setState(state => ({collapseVerheiratete: false, toggleTextVerheiratete: "+" }));
  }
}

toggleVerwitwete() {
  if(this.state.collapseVerwitwete === false){
    this.setState(state => ({ collapseVerwitwete: true, toggleTextVerwitwete: "-" }));
    }
  else{
      this.setState(state => ({collapseVerwitwete: false, toggleTextVerwitwete: "+" }));
  }
}

toggleGeschiedene() {
  if(this.state.collapseGeschiedene === false){
    this.setState(state => ({ collapseGeschiedene: true, toggleTextGeschiedene: "-" }));
    }
  else{
      this.setState(state => ({collapseGeschiedene: false, toggleTextGeschiedene: "+" }));
  }
}

doPlaceToFalse() {
   this.setState({successPlz: false})
}

doPlaceToTrue() {
  this.setState({successPlz: true})
}

changeLink = ev => {

     switch(ev.target.value){
        case "Seebestattung":
          this.setState({isSeeLink: true})
         break;
         case "Feuerbestattung":
          this.setState({isFeuerLink: true})
         break;
         case "Anonyme Bestattung":
          this.setState({isAnonymLink: true})
         break;
         case "Baumbestattung":
          this.setState({isBaumLink: true})
         break;
         case "Erdbestattung":
          this.setState({isErdLink: true})
         break;

         default:
           return ev.target.value;
     }


}

componentWillUnmount() {
  this.setState({isSeeLink: false,
                 isFeuerLink: false,
                 isAnonymLink: false,
                 isBaumLink: false,
                 isErdLink: false
  })
}


   render(){


    let bestattungsArray = [
        {title:  "Seebestattung", img: see, price:  this.state.see + this.props.extendAreaPrice , link: "/seebestattung"},
        {title:  "Feuerbestattung", img: einfach, price: this.state.einfache_feuer + this.props.extendAreaPrice, link: "/einfache_feuerbestattung"},
        {title:  "Anonyme Bestattung", img: anonym, price: this.state.anonym + this.props.extendAreaPrice, link: "/anonyme_bestattung" },
        {title:  "Baumbestattung", img: erd, price: this.state.friedwald + this.props.extendAreaPrice, link: "/friedwald"},
        {title:  "Erdbestattung", img: baum, price: this.state.erd + this.props.extendAreaPrice, link: "/erdbestattung" },
    ]

      return(
        <>
        <div className="bg-home">
            <h2 className="headerTop">Wir begleiten Sie auf Ihrem Weg</h2>
        </div>

      <a href="#labelAngebot" id="scrollToAngebot">&nbsp;</a>
      <div className="d-flex contentHome flex-column container-fluid" >
            {/* <h2 className="headerBestattungsart">Hier können Sie Ihr Angebot selbst erstellen</h2>       */}
      <div className="container pb-4">

                <div className="anzeige">
                      <p className="pAnzeige">
                      Bei uns können Sie eine Bestattung <br></br>schon ab {this.state.see + this.props.extendAreaPrice},00 € inkl. MwSt. finden!
                      <br></br>
                      Lassen Sie sich Unverbindlich Beraten.
                      <br></br>
                      Persönlich & kompetent .
                      <span className="angebotTel">
                           <FaPhoneSquare className="phoneIcon" />24h 0152 22003687
                      </span>
                      </p>
                      <p  className="pAnzeige" >Hier kommen Sie zur Günstigen Bestattung.</p>
                       <p className="textPlz">Bitte geben Sie den Ort oder die Postleitzahl ein,
                                              vom eingetretenen Sterbeort.</p>
                      {/* <MapContainer width="50%" placePlz="PLZ oder Ort hier eingeben" /> */}
                      <Geolocation />
                      <div id="closePostcodeHome" onClick={this.closePostcode}></div>
                </div>

                <AddPostcode
                   showAddPostcode={this.state.showAddPostcode}
                   closePostcode={this.closePostcode}
                />

              <p id="labelAngebot" className="angebotLabel">Gewünschte Bestattungsart wählen</p>


             {/* <select className="custom-select" value={this.state.link} onChange={this.changeLink} id="showBestatungInput">
                <option >Auswählen</option>
                <option value="Seebestattung">Seebestattung</option>
                <option value="Einfache Feuerbestattung">Feuerbestattung</option>
                <option value="Anonyme Bestattung">Anonyme Bestattung</option>
                <option value="Baumbestattung">Baumbestattung</option>
                <option value="Erdbestattung">Erdbestattung</option>
            </select>*/}

              {this.state.isSeeLink && <Redirect to="/seebestattung"></Redirect>}
              {this.state.isFeuerLink && <Redirect to="/einfache_feuerbestattung"></Redirect>}
              {this.state.isAnonymLink && <Redirect to="/anonyme_bestattung"></Redirect>}
              {this.state.isBaumLink && <Redirect to="/friedwald"></Redirect>}
              {this.state.isErdLink && <Redirect to="/erdbestattung"></Redirect>}

              <div onClick={this.updateLink} id="updateLink"></div>



            <div id="showBestatungContainer" className="d-flex flex-wrap justify-content-center" >
                  {bestattungsArray.map((elem, index) => {
                      return (
                        <div key={index}  className="card cardContainer">
                          <img src={elem.img} className="card-img-top imgCard" alt={elem.title} />
                          <div className="card-body">
                              <h5 className="card-title">{elem.title}</h5>
                              <div className="d-flex align-items-center justify-content-between">
                                  <p className="card-price m-0">ab {elem.price.toFixed(2)} EUR</p>
                                  {this.props.plzCity === "" ?
                                      <button onClick={() => this.showPostcodeBox(elem.title)}  type="button" style={{fontSize: "12.5px"}}  className="btn btn-light btnAuswahl">Auswählen</button>
                                      :
                                      <Link to={elem.link}>
                                        <button ident={elem.title} index={index}  type="button" style={{fontSize: "12.5px"}}  className="btn btn-light btnAuswahl">Auswählen</button>
                                      </Link>
                                  }
                              </div>
                          </div>
                    </div>
                      )
                  })
                  }
           </div>

              {this.state.successPlz &&
                <div className="alert alert-danger mx-auto my-3 w-100" >
                    Der Sterbeort ist nicht korrekt!
                </div>
              }


         {/* update place */}
         <span id="doPlaceToFalse" onClick={this.doPlaceToFalse}></span>
         <span onClick={this.doPlaceToTrue} id="doPlaceToTrue"></span>

          <AngebotBoxContainer />
        </div>
        </div>

       <div  className="contentHome container-fluid">

            <div className="container">
                  <p className="bestattungsArtDesc">Der Trauerfall ist eingetreten – was ist zu tun? Wenn der Tod eingetreten ist,
                  dann ist für Sie die Zeit gekommen, Abschied zu nehmen. Dazu dürfen Sie sich viel Zeit nehmen.
                  Vielleicht tut es gut, sich über Formen des Abschiednehmens schon vorher Gedanken zu machen?
                  Welche Regelungen müssen beachtet werden?
                  Gerne übernehmen wir diese ganzen Angelegenheiten für Sie und versuchen gemeinsamen mit Ihnen
                  diese Zeit zu überstehen.
                  Dabei sind wir für Sie Tag und Nacht erreichbar und unterstützen Sie gerne, wenn Sie uns brauchen.
                  </p>
            </div>

    <div className="container">
    <p className="angebotLabel my-4">Hier stellen wir Ihnen detaillierte Information zur Verfügung, damit Sie auch alles Wichtige beachten</p>
    </div>

          <div className="container sterbefallInfoAuswahl pb-4 ">

                    <div  className="card borderHome">
                         <div className="p-3">
                           <FaHome className="card-img-top imgTrauerfall" />
                           <h5 className="text-center py-3 colorTrauerfall">Trauerfall - zu Hause / Heim</h5>
                           <ul>
                              <li>Arzt rufen</li>
                              <li>Sidereum Bestattungen anrufen</li>
                              <li>Familie, Freunde, Arbeitgeber, Vermieter etc. informieren</li>
                              <li>Abschiedsfeier planen</li>
                           </ul>
                         </div>
                    </div>

                    <div  className="card borderHome ">
                        <div className="p-3">
                          <FaHospital className="card-img-top imgTrauerfall" />
                          <h5 className="text-center py-3 colorTrauerfall">Trauerfall - im Krankenhaus</h5>
                          <ul>
                             <li>Sidereum Bestattungen anrufen</li>
                             <li>Bei vielen Anrufen: Verwandte oder Freunde bitten, Anrufe zu übernehmen</li>
                             <li>Personen anrufen denen Sie gleich Bescheid geben möchten</li>
                          </ul>
                        </div>
                       </div>

                    <div className="card borderHome">
                       <div className="p-3">
                       <FaRegCopy className="card-img-top imgTrauerfall" />
                         <h5 className="text-center py-3 colorTrauerfall">Benötigte Unterlagen (im Original)</h5>
                            <span className="d-flex align-items-center ml-4">
                                <Button className="btnDoku" onClick={this.toggleLedige} style={{ marginBottom: '1rem', background: "#4f3d3f" }}>{this.state.toggleTextLedige}</Button>
                                <p className="p-2 pLedige">Ledige</p>
                            </span>
                              <Collapse isOpen={this.state.collapseLedige}>
                                  <ul>
                                      <li>Personalausweis</li>
                                      <li>Geburtsurkunde </li>
                                      <li>Krankenversicherungskarte</li>
                                      <li>ggf. Rentennummer</li>
                                  </ul>
                              </Collapse>

                              <span className="d-flex align-items-center ml-4">
                                  <Button color="secondary" onClick={this.toggleVerheiratete} style={{ marginBottom: '1rem', background: "#4f3d3f" }}>{this.state.toggleTextVerheiratete}</Button>
                                  <p className="p-2 pLedige">Verheiratete</p>
                              </span>
                                <Collapse isOpen={this.state.collapseVerheiratete}>
                                    <ul>
                                        <li>Personalausweis</li>
                                        <li>Geburtsurkunde </li>
                                        <li>Krankenversicherungskarte</li>
                                        <li>ggf. Rentennummer</li>
                                        <li>Heiratsurkunde oder Familienstammbuch</li>
                                    </ul>
                                </Collapse>

                                <span className="d-flex align-items-center ml-4">
                                    <Button  onClick={this.toggleVerwitwete} style={{ marginBottom: '1rem', background: "#4f3d3f" }}>{this.state.toggleTextVerwitwete}</Button>
                                    <p className="p-2 pLedige">Verwitwete</p>
                                </span>
                                  <Collapse isOpen={this.state.collapseVerwitwete}>
                                      <ul>
                                          <li>Personalausweis</li>
                                          <li>Geburtsurkunde </li>
                                          <li>Krankenversicherungskarte</li>
                                          <li>ggf. Rentennummer</li>
                                          <li>Heiratsurkunde oder Familienstammbuch</li>
                                          <li>Sterbeurkunde des Ehepartners</li>
                                      </ul>
                                  </Collapse>

                                  <span className="d-flex align-items-center ml-4">
                                      <Button onClick={this.toggleGeschiedene} style={{ marginBottom: '1rem', background: "#4f3d3f" }}>{this.state.toggleTextGeschiedene}</Button>
                                      <p className="p-2 pLedige">Geschiedene</p>
                                  </span>
                                    <Collapse isOpen={this.state.collapseGeschiedene}>
                                        <ul>
                                            <li>Personalausweis</li>
                                            <li>Geburtsurkunde </li>
                                            <li>Krankenversicherungskarte</li>
                                            <li>ggf. Rentennummer</li>
                                            <li>Heiratsurkunde oder Familienstammbuch</li>
                                            <li>Scheidungsurteil</li>
                                        </ul>
                                    </Collapse>

                                    <span className="d-flex align-items-center ml-4">
                                      <Button  onClick={this.toggleAussiedler} style={{ marginBottom: '1rem', background: "#4f3d3f" }}>{this.state.toggleTextAussiedler}</Button>
                                      <p className="p-2 pLedige">Für Aussiedler aus Russland</p>
                                  </span>
                                    <Collapse isOpen={this.state.collapseAussiedler}>
                                        <ul>
                                            <li>Vertriebenenausweiß</li>
                                            <li>Registrierschein </li>
                                            <li>§ 94 oder Namensänderung für Verstorbenen und Ehegatten</li>
                                        </ul>
                                    </Collapse>


                       </div>
                    </div>
                  </div>
              </div>


            <div className="contentHome container-fluid">
               <div className="container">
                  <div className="boxAnsprechpartner">
                  <h2 className="headerBeratung">Sie brauchen eine telefonische Beratung?</h2>
                  <p className="pAnsprechpartner">Wir helfen Ihnen gerne weiter!</p>
                  <div className="boxWarpperService">
                       <div className="boxService">
                           {/*<p className="serviceHeader">Für deutschsprachige</p>*/}
                           <span className="wrapperPhone">
                             <FaPhoneSquare className="iconService"/>
                             <p className="serviceNummer">24h 0152 22003687</p>
                           </span>

                       </div>
                    </div>
                    {/* <div className="boxService">
                           <p className="serviceHeader">Для русскоязычных</p>
                           <span className="wrapperPhone">
                           <FaPhoneSquare className="iconService"/>
                             <p className="serviceNummer">01522 4561394</p>
                           </span>
                    </div>*/}

                  </div>

            </div>
          </div>

        </>
      )
   }

}

const mapStateToProps = state => {
  return{
    plz: state.plz,
    einfache_feuerbestattungPreis: state.einfache_feuerbestattungPreis,
    plzInvalid: state.plzInvalid,
    showExtendsList: state.showExtendsList,
    plzIsTyped: state.plzIsTyped,
    rightPlzButton: state.rightPlzButton,
    successPlz: state.successPlz,
    plzArea: state.plzArea,
    cityAreay: state.cityAreay,
    trauerfall: state.trauerfall,
    isTrauerfall: state.isTrauerfall,
    showAngebotWithoutVollmacht: state.showAngebotWithoutVollmacht,
    bestattungsartAngebot: state.bestattungsartAngebot,
    extendAreaPrice: state.extendAreaPrice,
    plzCity: state.plzCity
  }
}

const mapStateToDispatch = dispatch => {
   return{
      updateTrauerfallStatus: ev => dispatch(updateTrauerfallStatus(ev)),
      getBestattungsArt: ev => dispatch(getBestattungsArt(ev)),
      successPlzToFalse: () => dispatch(successPlzToFalse()),
      changeAngebotImg: ev => dispatch(changeAngebotImg(ev))
   }
}


export const HomeContainer = connect(mapStateToProps, mapStateToDispatch)(Home);

