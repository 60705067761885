import React, {Component} from "react";
import {connect} from "react-redux";
import {iskostenfriedhof} from "../redux";
import trauerfeierImg from "../../images/bestattungsfeier/trauerfeier.jpg";
import blumeAuswahlImg from "../../images/floristik/gesteck/ge_28.jpg";
import {TrauerfeierModal} from "../ProductModals/Trauerfeier";
import Floristik from "../ProductModals/Floristik";
import Saerge from "../ProductModals/Saerge";
import AddedProduct from "../ProductModals/AddedProduct";
import logoImg from "../../images/logo/logo.jpg";
import jsPDF from 'jspdf';
import Geolocation from '../Geolocation';
import {IndAngebotModalContainer} from "../StatusBar/IndAngebotModalBox";
import "../../style/einfache_feuerbestattung.css";
import  sargImg from "../../images/saerge/kiefer_1.jpg";
import AlleUrnen from "../ProductModals/AlleUrnen";
import urneImg from "../../images/urnen/einfach/21881_lb.png";
//import phoneImg from "../../images/service/phone_service.jpg";
import ratenzahlungImg from "../../images/service/ratenzahlung.jpg";
import {Link} from "react-router-dom";


class Einfache_Feuerbestattung extends Component{

  state = {
       modalsShowFloristik: false,
       modalShowTrauerfeier: false,
       isTrauerfeier: false,
       toggleButtons: true,
       showFloristik: false,
       showSaerge: false,
       showUrneLi: false,
       showAlleUrnen: false,
       sarg: "Schlichter Kiefern Sarg",
       blumePrice: 0,
       sargPrice: 0,
       urnePrice: 0,
       trauerfeierPrice: 0,
       floristikArray: [],
       showAddProduct: false,
       productDesc: "",
       chooseAngebot: "",
       pdfTitel: "",
       pdfTitelArea: "",
       pdfPrice: 0,
       pdfLeistungen: "",
       showAngebot: false,
       successPlz: false,
       showVollmachtAngebot: false,
       showBegleiteteSeebestattung: false,
       toggleButtonsSee: true,
       extendAreaPrice: 0,
       feuerPrice: 1899,
       feuerStillPrice: 2100
  }



  componentDidMount(){
    window.scrollTo(0, 0);
  }


closeAlleUrnen = () => {
  this.setState({showAlleUrnen: false})
}

showModalAllUrnen = () => {
  this.setState({showAlleUrnen: true})
}

selectAllUrnen = urne => {

  this.setState({urne: urne.titel,
                urnePrice: urne.price,
                showAddProduct: true,
                productDesc: urne.titel,
                showUrneLi: true
              })
  setTimeout(() => {
     this.setState({
      showAddProduct: false,
      showAlleUrnen: false
     })
  }, 1000)
}

deleteAllUrnen = () => {
   this.setState({urnePrice: 0, showUrneLi: false })
}

closeSaerge = () => {
  this.setState({showSaerge: false})
}

showModalSarg = () => {
  this.setState({showSaerge: true})
}

addSarg = (sarg, index) => {

  this.setState({sarg: sarg.titel,
                sargPrice: sarg.price,
                showAddProduct: true,
                productDesc: sarg.titel
              })
  setTimeout(() => {
     this.setState({
      showAddProduct: false,
      showSaerge: false
     })
  }, 1000)
}


  closeModalFloristik = () => {
    this.setState({modalsShowFloristik: false})
 }

 showModalFloristik = () => {
    this.setState({modalsShowFloristik: true})
 }


 hideModalTrauerfeier = () => {
    this.setState({modalShowTrauerfeier: false})
 }

 showModalTrauerfeier = () => {
  this.setState({modalShowTrauerfeier: true})
 }

 addTrauerfeier = () => {

  this.setState({isTrauerfeier: true,
                 toggleButtons: false,
                 modalShowTrauerfeier: false,
                 trauerfeierPrice: this.state.trauerfeierPrice += 499
                })
 }


 deleteTrauerfeier = () => {

  this.setState({isTrauerfeier: false,
                 toggleButtons: true,
                 modalShowTrauerfeier: false,
                 trauerfeierPrice: 0
                })
 }

 showModalFloristik = () => {
  this.setState({showFloristik: true})
 }

 closeFloristik = () => {
    this.setState({showFloristik: false})
 }

 addFloristik = blume => {
      this.setState({
        blumePrice: blume.price,
        floristikArray: [...this.state.floristikArray, blume],
        showAddProduct: true,
        productDesc: blume.titel
      })

      setTimeout(() => {
        this.setState({
        showAddProduct: false,
        showFloristik: false
        })
    }, 1000)

 }

 deleteBlume = (elem, index) => {
    let deleteBlume = Object.assign([], this.state.floristikArray);
    deleteBlume.splice(index, 1);

        this.setState({
          floristikArray: deleteBlume,
          blumePrice: this.state.blumePrice -= elem.price
        })
 }

 closeModalProduct = () => {
      this.setState({showAddProduct: false})
 }

 changeAngebot = ev => {

  let identangebot = ev.target.getAttribute("identangebot");


  switch(identangebot){
    case "feuerNormal":
      this.setState({pdfTitel: "Feuerbestattung ohne Friedhofsgebühren",
                     pdfTitelArea: "",
                     pdfLeistungen: "feuerNormal",
                     pdfPrice: this.state.feuerPrice +
                               this.state.blumePrice +
                               this.state.urnePrice +
                               this.state.trauerfeierPrice +
                               this.state.extendAreaPrice +
                               this.state.sargPrice,
                     bestattungspreis: this.state.feuerPrice +
                                       this.state.urnePrice +
                                       this.state.blumePrice +
                                       this.state.extendAreaPrice +
                                       this.state.trauerfeierPrice +
                                       this.state.sargPrice
                    })
      break;

      case "feuerStill":
      this.setState({pdfTitel: "Feuerbestattung mit stiller Beisetzung inkl. aller Kosten",
                     pdfTitelArea: "",
                     pdfLeistungen: "feuerStill",
                     pdfPrice: this.state.feuerStillPrice +
                               this.state.blumePrice +
                               this.state.urnePrice +
                               this.state.trauerfeierPrice +
                               this.state.extendAreaPrice +
                               this.state.sargPrice,
                     bestattungspreis: this.state.feuerStillPrice +
                                       this.state.urnePrice +
                                       this.state.blumePrice +
                                       this.state.extendAreaPrice +
                                       this.state.trauerfeierPrice +
                                       this.state.sargPrice
                    })
      break;

     default:
       return identangebot;
  }


    switch(ev.target.getAttribute("ident")){
      case "angebot selbst":
        this.props.iskostenfriedhof();
        this.setState({showVollmachtAngebot: true})

          break;
      case "angebot":
        console.log("angebot");
        setTimeout(() => {
          this.angebotDownload();
        }, 100)

        break;
      default:
          return ev.target.getAttribute("ident")
  }


}

toggleAngebot = ev => {

  let moreDesc = document.querySelectorAll(".moreDesc");
  let toggleAngebot = document.querySelectorAll(".toggleAngebot");
  let identindex = ev.target.getAttribute("identindex");
  let indexInt = parseInt(identindex);

  //close all Angebote
  if(!toggleAngebot[indexInt].classList.contains("activeAngebot")) {
    for(let i = 0; i < toggleAngebot.length; i ++){
      if(toggleAngebot[i].classList.contains("activeAngebot")){
        toggleAngebot[i].classList.remove("activeAngebot");
        moreDesc[i].innerHTML = "+";
      }
  }
  }

  //open and close one Angebot
  if(toggleAngebot[indexInt].classList.contains("activeAngebot")){
    moreDesc[indexInt].innerHTML = "+";
    toggleAngebot[indexInt].classList.remove("activeAngebot");
  }

 else{
      moreDesc[indexInt].innerHTML = "-";
      toggleAngebot[indexInt].classList.toggle("activeAngebot");
  }


}


 angebotDownload = () => {

  var pdf = new jsPDF("p", "mm", "a4");
  pdf.addImage(logoImg, 'SVG',  90, 7);
  pdf.setFont("times")

      pdf.setFontSize(13);
      pdf.setTextColor(79, 61, 63);
      pdf.setFontType("bold")
      pdf.text(this.state.pdfTitel, 20, 57);
      pdf.text(this.state.pdfTitelArea, 20, 62);
      pdf.setFontSize(15);
      pdf.text (`Zum Preis von: ${this.state.pdfPrice.toFixed(2)} EUR`, 20, 70);
      pdf.setFontType("normal")

      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      /* datum */
      var datum = new Date();
      var day = datum.getDate();
      var month = datum.getMonth();
      var hours = datum.getHours();
      var minutes = (datum.getMinutes()<10?'0':'') + datum.getMinutes();
      month = month +1;
      var year = datum.getFullYear();
      pdf.text ("Sehr geehrte(r) Interessent(in),", 20, 80);
      pdf.text (`wir danken Ihnen für Ihre Anfrage vom ${day}.${month}.${year} und bieten Ihnen die von Ihnen`, 20, 88);
      pdf.text ("genannten Leistungen wie folgt an:", 20, 93);

      /* close button */
      let closeButtonsAngebot = document.querySelectorAll(".closeButtonAngebot");
      for(let i = 0; i < closeButtonsAngebot.length; i++){
        closeButtonsAngebot[i].style.color = "white";
      }
      let closeButtonAngebot = document.querySelector(".closeButtonAngebot");
      if(closeButtonAngebot){
        closeButtonAngebot.style.color = "white";
      }


      /* leistungen */
      var source = document.querySelector(`#${this.state.pdfLeistungen}`);
      var liAngebot = document.querySelectorAll(".liAngebot");

      for(let i = 0; i < liAngebot.length; i++){
        liAngebot[i].style.fontSize = "12px";
        liAngebot[i].style.lineHeight = "0.5";
        liAngebot[i].style.color = "black";
      }

      pdf.fromHTML(
        source, // HTML string or DOM elem ref.
        18, // x coord
        100, // y coord
        {
         width: 130// max width of content on PDF
        },
      );

      pdf.setFontSize(10);
      /* Anfallende Fremdleistungen... */
      pdf.text("Anfallende Fremdleistungen (z. B. Gebühren im Krankenhaus und Standesamt) werden Ihnen in tatsächlich entstandener", 20, 225);
      pdf.text("Höhe weiter berechnet.", 20, 230);
      /* Sollten nicht innerhalb von.. */
      pdf.text("Sollten nicht innerhalb von 3 Tagen alle für die Beurkundung des Sterbefalls notwendigen Dokumente vorliegen,", 20, 245);
      pdf.text("werden folgende Gebühren berechnet: ", 20, 250);
      pdf.text("Bearbeitungsgebühr 55,00 EUR für jedes notwendige Dokument zusätzliche Gebühren des Standesamtes.", 20, 255);

        /* Ort, Datum und Unterschrift */
        pdf.setDrawColor(0, 0, 0);
        pdf.setLineWidth(.3);
        pdf.line(20, 270, 80, 270);
        pdf.line(100, 270, 160, 270);
        pdf.setFontSize(12);
        pdf.text ("Ort, Datum", 20, 275);
        pdf.text ("Unterschrift", 100, 275);

        /* senden an email */
        pdf.setFontSize(12);
        pdf.setFontType("bold");
        pdf.text("Bitte senden sie das Angebot mit der Vollmacht an diese Email: info@sidereum-bestattungen.de", 20, 283);
        pdf.setFontType("normal");

        pdf.setFontSize(10);
        /*  Seitenanzahl */
        pdf.text("Seite 1/2",  95, 290);
        pdf.setFontSize(8);
        /* Zeitstempel */
        pdf.text(`Erstellt am ${day}.${month}.${year} um ${hours}:${minutes} Uhr`, 80, 294);

      /* neue Seite vollmacht */
      pdf.addPage();

      pdf.addImage(logoImg, 'SVG',  90, 7);
      pdf.setFontSize(20);
      pdf.setFont("times")
      //pdf.setFontType("bold")
      pdf.setTextColor(79, 61, 63);
      pdf.text ("Vollmacht", 92, 55);
      pdf.setFontSize(15);
      pdf.setFontType("bold");
      pdf.text ("Bestattungspflichtige(r)", 20, 67);
      pdf.setFontType("normal");
      pdf.setTextColor(0, 0, 0);

        var vollmachtDataPflichtigerLinks =
                             `<p>Name:</p>`+
                             `<p>geboren am:</p>`+
                             `<p>Anschrift:</p>`+
                             `<p>Geburtsort:</p>`
        var vollmachtDataPflichtigerRechts =
                             `<p>Vorname:</p>`+
                             `<p>Verwandschaftsverhältnis:</p>`+
                             `<p>&nbsp;</p>`+
                             `<p>Telefon:</p>`

      pdf.fromHTML(
            vollmachtDataPflichtigerLinks,
            20, // x coord
            67, // margin top coord
            {
              // y coord
              width: 100// max width of content on PDF
            },
          );
      pdf.fromHTML(
            vollmachtDataPflichtigerRechts,
            120, // x coord
            67, // margin top coord
            {
              // y coord
              width: 100// max width of content on PDF
            },
          );
      var text_1 = "Ich der / die Unterzeichner/ in, beauftrage Sidereum Bestattungen, die Beisetzung von:";
      pdf.setFontSize(12);
      pdf.text (text_1, 20, 110);
      pdf.setFontSize(15);
      pdf.setTextColor(79, 61, 63);
      pdf.setFontType("bold");
      pdf.text ("Verstorbener", 20, 120);
      pdf.setFontType("normal");
      pdf.setTextColor(0, 0, 0);
      var vollmachtDataVerstorbenerLinks =
                                        `<p>Name:</p>`+
                                        `<p>geboren am:</p>`+
                                        `<p>Letzte Anschrift:</p>`+
                                        `<p>Geburtsort:</p>`+
                                        `<p>Beruf:</p>`+
                                        `<p>Familienstand:</p>`+
                                        `<p>Krankenkasse:</p>`
                                        //`<p>Benötigte Unterlagen (im Original): ${unterlagen}${russianDoc}</p>`
      var vollmachtDataVerstorbenerRechts =
                                        `<p>Vorname:</p>`+
                                        `<p>verstorben am:</p>`+
                                        `<p>Abholanschrift:</p>`+
                                        `<p>Religion:</p>`+
                                        `<p>Kinder:</p>`+
                                        `<p>Rentennummer:</p>`+
                                        `<p>Anzahl der Sterbeurkunden:</p>`+
                                        `<p>Gewicht / Größe:</p>`

      pdf.fromHTML(
            vollmachtDataVerstorbenerLinks,
            20, // x coord
            121, // margin top coord
            {
              // y coord
              width: 90// max width of content on PDF
            },
          );

      pdf.fromHTML(
        vollmachtDataVerstorbenerRechts,
            120, // x coord
            121, // margin top coord
            {
              // y coord
              width: 90// max width of content on PDF
            },
          );

      var text_2 = "Sidereum Bestattungen ist berechtigt, alle notwendigen Schritte zur Beisetzung einzuleiten.";
      var text_3 = "Die Personenstandsurkunden müssen innerhalb von 3 Tagen Sidereum Bestattungen ";
      var text_4 = "oder dem zuständigen Standesamt vorliegen.";
      var text_5 = "Für alle Kosten der Bestattung und der Bestattungsgebühren komme ich im vollen Umfang auf.";
      var text_6 = "Ich habe die AGB zur Kenntnis genommen und akzeptiert.";

      pdf.setFontSize(12);
      pdf.text (text_2, 20, 221);
      pdf.text (text_3, 20, 226);
      pdf.text (text_4, 20, 231);
      pdf.text (text_5, 20, 236);
      pdf.text (text_6, 20, 241);

      /* Ort, Datum und Unterschrift */
      pdf.setLineWidth(.3);
      pdf.line(20, 257, 80, 257);
      pdf.line(100, 257, 160, 257);

      pdf.text ("Ort, Datum", 20, 262);
      pdf.text ("Unterschrift", 100, 262);


  //Anschriftblock
  pdf.setFontSize(9);
  var anschriftBlock1 = "Sidereum Bestattungen UG\nAmtsgericht Rostock\nUst.IdNr: 086/117/03156\nGeschäftsführer: René Walek";
  var anschriftBlock2 = "Anschrift\nSidereum Bestattungen\nLerchenberg 1\n18292 Krakow am See";
  var anschriftBlock3 = "Tel: 0152 22003687\nWeb: www.sidereum-bestattungen.de\nE-Mail: info@sidereum-bestattungen.de";

  pdf.setLineWidth(.2);
  pdf.line(20, 268, 190, 268);
  pdf.text (anschriftBlock1, 20, 273);
  pdf.text (anschriftBlock2, 80, 273);
  pdf.text (anschriftBlock3, 140, 273);


  /*  Seitenanzahl */
  pdf.setFontSize(10);
  /*  Seitenanzahl */
  pdf.text("Seite 2/2",  95, 290);
  pdf.text(`Erstellt am ${day}.${month}.${year} um ${hours}:${minutes} Uhr`, 80, 294);

   /* update liAngebot */
   for(let i = 0; i < liAngebot.length; i++){
    liAngebot[i].style.fontSize = "15px";
    liAngebot[i].style.lineHeight = "2";
    liAngebot[i].style.color = "#4F3D3F";
  }

  pdf.save('Angebot.pdf');
  /* update close button */
  for(let i = 0; i < closeButtonsAngebot.length; i++){
    closeButtonsAngebot[i].style.color = "red";
  }
  if(closeButtonAngebot){
    closeButtonAngebot.style.color = "red"
  }

}

closeVollmachtAngebot = () => {
  this.setState({showVollmachtAngebot: false})
}

doPlaceToFalse = () => {
  this.setState({successPlz: false})
}

doPlaceToTrue = () => {
 this.setState({successPlz: true})
}


  render(){

    let extendsServices = [
         {titel: "Trauerfeier", img: trauerfeierImg, show: this.showModalTrauerfeier},
         {titel: "Sargauswahl", img: sargImg, show: this.showModalSarg},
         {titel: "Urnenauswahl", img: urneImg, show: this.showModalAllUrnen},
         {titel: "Floristik", img: blumeAuswahlImg, show: this.showModalFloristik},
         {titel: "Ratenzahlung", img: ratenzahlungImg, show: null}
    ]

    let komma;
    if (this.state.floristikArray.length > 1) {
        komma = ", ";
    }

    this.state.extendAreaPrice = this.props.extendAreaPrice;


    return(
    <>
        <div className="bg_einfacheFeuerbestattung">
           <h2 className="headerTop">Feuerbestattung</h2>
        </div>
        <div style={{background: "#E8EDEF"}} className="container my-4">
          <p className="bestattungsArtDesc">
          Bei der einfachen Feuerbestattung, auch Einäscherung oder Kremation genannt, wird der Verstorbene
          verbrannt und anstelle eines Sarges eine Urne beigesetzt. Hier bestimmen Sie, in welchem Friedhof
          der Verstorbene beerdigt werden soll.
          </p>
      </div>

      <div className="container-fluid bestattungAngebot">

           <div className="container navbarAngebot ">
                <Geolocation  />
           </div>

           {/* update place */}
           <span id="doPlaceToFalse" onClick={this.doPlaceToFalse}></span>
           <span onClick={this.doPlaceToTrue} id="doPlaceToTrue"></span>
           {this.state.successPlz &&
                <div className="alert alert-danger mx-auto my-3 w-100" >
                    Der Sterbeort ist nicht korrekt!
                </div>
          }

          <div className="container angebotLeistungWrapper py-3">
                <div className="leistungsAngebotBox ">
                      <h2 className="leistungHeader">Unsere Angebote</h2>

                     {/* Einfache Feuerbestattung - 850,00 € */}
                       <div className="angebotTitelDesc">
                           <b>Feuerbestattung ohne Friedhofsgebühren</b><br></br>
                           Zum Preis von
                           <span className="totalPreis">{(this.state.feuerPrice +
                                                          this.state.urnePrice +
                                                          this.state.blumePrice +
                                                          this.state.trauerfeierPrice +
                                                          this.state.extendAreaPrice +
                                                          this.state.sargPrice).toFixed(2)} EUR</span> <span className="grundpreis">(Grundpreis)</span>
                           <span identindex="0" identtoggle="feuerNormal" onClick={this.toggleAngebot} className="moreDesc">-</span>
                       </div>

                       <div className="toggleAngebot activeAngebot">
                         <ul id="feuerNormal" className="ulAngebot py-1">
                                <li className="liAngebot">Abholung des Verstorbenen {this.props.plzCity? 'in '+this.props.plzCity:''}</li>
                                <li className="liAngebot">{this.state.sarg} für die Einäscherung (für Verstorbene bis max. 100 kg oder 195 cm)</li>
                                <li className="liAngebot">Einkleidung des Sarges</li>
                                <li className="liAngebot">Überführung vom Sterbeort zum Krematorium in Deutschland</li>
                                <li className="liAngebot">Krematoriumsurne</li>
                                <li className="liAngebot">Sargträger zur Überführung vom Sterbeort</li>
                                <li className="liAngebot">Grundversorgung des Verstorbenen</li>
                                <li className="liAngebot">Decke, Kissen und Sterbegewand (eigene Kleidung möglich)</li>
                                <li className="liAngebot">Einbetten und Kleiden des Verstorbenen</li>
                                <li className="liAngebot">Abmeldung beim Einwohnermeldeamt</li>
                                <li className="liAngebot">Beantragung einer gebührenpflichtigen Sterbeurkunde</li>
                                <li className="liAngebot">Aushändigung der Sterbeurkunde</li>
                                <li className="liAngebot">Abmelden der Krankenkasse und Rententräger</li>
                                <li className="liAngebot">Beantragung der dreimonatigen Übergangsrente ( nur für Ehepartner )</li>
                                <li className="liAngebot">Kosten eines deutschen Krematorium (unserer Wahl)</li>
                                <li className="liAngebot">Kosten der Gesetzlichen 2. Leichenschau (im Krematorium unserer Wahl)</li>
                                <li className="liAngebot">Überführung der Urne zum Friedhof in Deutschland</li>
                                <li className="liAngebot">Friedhof ist im Angebot NICHT enthalten. (Ihre Wahl)</li>
                                {this.state.showUrneLi && <li className="liAngebot">
                                  <span className="closeButtonAngebot" onClick={this.deleteAllUrnen}>&times;</span>
                                  <span className="newAngebot">{this.state.urne}</span>
                                </li>}
                                {this.state.isTrauerfeier && <li className="liAngebot">
                                <span className="closeButtonAngebot" onClick={this.deleteTrauerfeier}>&times;</span>
                                <span className="newAngebot">Trauerfeier</span>
                                  </li>}
                                {this.state.floristikArray.length === 0 ? null :
                                 <li className="liAngebot liBlumen">Floristik: { this.state.floristikArray.map((elem, index) => <span key={index}>
                                     <span className="closeButtonAngebot " onClick={() => this.deleteBlume(elem, index)}>&times;</span>
                                     <span className="newAngebot">{elem.titel}{komma}</span>
                                      </span>)}
                                 </li>
                                }
                          </ul>
                          {/* <select identangebot="feuerNormal" className="custom-select" id="inputGroupSelect_angebot"  value={this.state.chooseAngebot} onChange={this.changeAngebot}>
                              <option >Auftragsform wählen & herunterladen</option>
                              <option value="angebot">Auftrag & Vollmacht</option>
                              <option value="angebot selbst">Auftrag & Vollmacht (Vollmacht ausfüllen)</option>
                          </select> */}
                          <button className="btn button-download" identangebot="feuerNormal"  onClick={this.changeAngebot} ident="angebot">Download Auftrag & Vollmacht </button>
                          <button className="btn button-download" identangebot="feuerNormal"  onClick={this.changeAngebot} ident="angebot selbst">Download Auftrag & Vollmacht (Vollmacht ausfüllen)</button>
                      </div>

                          {/* Stille Feuerbestattung - 975,00 € */}
                       <div className="angebotTitelDesc">
                           <b>Feuerbestattung mit stiller Beisetzung inkl. aller Kosten</b><br></br>
                           Zum Preis von
                           <span className="totalPreis">{(this.state.feuerStillPrice +
                                                          this.state.urnePrice +
                                                          this.state.blumePrice +
                                                          this.state.trauerfeierPrice +
                                                          this.state.extendAreaPrice +
                                                          this.state.sargPrice).toFixed(2)} EUR</span>
                           <span identindex="1" identtoggle="feuerStill" onClick={this.toggleAngebot} className="moreDesc">+</span>
                       </div>

                       <div className="toggleAngebot">
                         <ul id="feuerStill" className="ulAngebot py-1">
                                <li className="liAngebot">Abholung des Verstorbenen {this.props.plzCity? 'in '+this.props.plzCity:''}</li>
                                <li className="liAngebot">{this.state.sarg} für die Einäscherung (für Verstorbene bis max. 100 kg oder 195 cm)</li>
                                <li className="liAngebot">Einkleidung des Sarges</li>
                                <li className="liAngebot">Überführung vom Sterbeort zum Krematorium in Deutschland</li>
                                <li className="liAngebot">Krematoriumsurne</li>
                                <li className="liAngebot">Sargträger zur Überführung vom Sterbeort</li>
                                <li className="liAngebot">Grundversorgung des Verstorbenen</li>
                                <li className="liAngebot">Decke, Kissen und Sterbegewand (eigene Kleidung möglich)</li>
                                <li className="liAngebot">Einbetten und Kleiden des Verstorbenen</li>
                                <li className="liAngebot">Abmeldung beim Einwohnermeldeamt</li>
                                <li className="liAngebot">Beantragung einer gebührenpflichtigen Sterbeurkunde</li>
                                <li className="liAngebot">Aushändigung der Sterbeurkunde</li>
                                <li className="liAngebot">Abmeldung der Krankenkasse und Rententräger</li>
                                <li className="liAngebot">Beantragung der dreimonatigen Übergangsrente</li>
                                <li className="liAngebot">Kosten eines deutschen Krematorium (unserer Wahl)</li>
                                <li className="liAngebot">Kosten der Gesetzlichen 2. Leichenschau (im Krematorium unserer Wahl)</li>
                                <li className="liAngebot">Überführung der Urne zum Friedhof in Deutschland</li>
                                <li className="liAngebot">Kosten eines Friedhof ́s in Deutschland zur stillen Beisetzung (unserer Wahl)</li>
                                {this.state.showUrneLi && <li className="liAngebot">
                                  <span className="closeButtonAngebot" onClick={this.deleteAllUrnen}>&times;</span>
                                  <span className="newAngebot">{this.state.urne}</span>
                                </li>}
                                {this.state.isTrauerfeier && <li className="liAngebot">
                                <span className="closeButtonAngebot" onClick={this.deleteTrauerfeier}>&times;</span>
                                <span className="newAngebot">Trauerfeier</span>
                                  </li>}
                                {this.state.floristikArray.length === 0 ? null :
                                 <li className="liAngebot liBlumen">Floristik: { this.state.floristikArray.map((elem, index) => <span key={index}>
                                     <span className="closeButtonAngebot " onClick={() => this.deleteBlume(elem, index)}>&times;</span>
                                     <span className="newAngebot">{elem.titel}{komma}</span>
                                      </span>)}
                                 </li>
                                }
                          </ul>

                          <button className="btn button-download" identangebot="feuerStill"  onClick={this.changeAngebot} ident="angebot">Download Auftrag & Vollmacht </button>
                          <button className="btn button-download" identangebot="feuerStill"  onClick={this.changeAngebot} ident="angebot selbst">Download Auftrag & Vollmacht (Vollmacht ausfüllen)</button>
                      </div>


                </div>

                 <div className="extendsLeistungen">
                    <h3 className="headerExtends">Weitere Leistungen auswählen</h3>
                    <div className="auswahlProductWrapper">
                        {extendsServices.map((elem, index) => {
                           return(
                            <div key={index}  className="card cardContainerExtends">
                                <img src={elem.img} alt={elem.titel} style={{height: elem.show === null && "250px"}} className="card-img-top imgExtends" />
                                <div className="card-body">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p  className="card-title titelAngebot m-0">{elem.titel}</p>
                                    {elem.show !== null ?
                                      <button onClick={elem.show} style={{fontSize: "14px"}} className="btn btn-outline-light btnAuswahl ml-2 px-2">Auswählen</button>
                                      :
                                      <button style={{fontSize: "14px"}} className="btn btn-outline-light btnAuswahl ml-2 px-2">
                                         <Link className="linkRaten" to="/ratenzahlung">Auswählen</Link>
                                     </button>
                                    }
                                  </div>
                                </div>
                            </div>
                           )
                        })
                        }
                        </div>
                </div>

          </div>
      </div>

       {/*  PopUp trauerfeier */}
       <TrauerfeierModal
          showTrauerfeier={this.state.modalShowTrauerfeier}
          hideTrauerfeier={this.hideModalTrauerfeier}
          addTrauerfeier={this.addTrauerfeier}
          deleteTrauerfeier={this.deleteTrauerfeier}
          toggleButtons={this.state.toggleButtons}
       />

     {/*  PopUp Floristik */}
      <Floristik
       showFloristik={this.state.showFloristik}
       closeFloristik={this.closeFloristik}
       addFloristik={this.addFloristik}
      />
      {/*  PopUp add Product */}
      <AddedProduct
       showAddProduct={this.state.showAddProduct}
       closeModalProduct={this.state.closeModalProduct}
       productDesc={this.state.productDesc}
       />
      {/*  PopUp add Vollmacht */}
      <IndAngebotModalContainer
       showAngebotModal={this.state.showVollmachtAngebot}
       closeAngebotModal={this.closeVollmachtAngebot}
       bestattungsPreis={this.state.bestattungspreis}
       identleistung={this.state.pdfLeistungen}
       bestattungsTitel={this.state.pdfTitel}
       titelArea={this.state.pdfTitelArea}
       />
       {/*  PopUp Särge */}
       <Saerge showSaerge={this.state.showSaerge}
               closeSaerge={this.closeSaerge}
               addSarg={this.addSarg}
       />
       {/* Alle Urnen */}
       <AlleUrnen
       showAlleUrnen={this.state.showAlleUrnen}
       closeAlleUrnen={this.closeAlleUrnen}
       selectUrne={this.selectAllUrnen}
       />

  </>
    )
  }
}

const mapStateToDispatch = dispatch => {
  return{
    iskostenfriedhof: () => dispatch(iskostenfriedhof())
  }
}

const mapStateToProps = state => {
  return{
    extendAreaPrice: state.extendAreaPrice,
    plzCity: state.plzCity
  }
}

export const EinfacheFeuerbestattungContainer = connect(mapStateToProps, mapStateToDispatch)(Einfache_Feuerbestattung);

