import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getBestattungsArt} from "../redux";
import "../../style/feuerbestattung.css";

class Feuer_Bestattung extends Component{
componentDidMount(){
    window.scrollTo(0, 0);
}
  render(){
    return(
    <>
        <div className="bg-feuerbestattung">
           <h2 className="headerTop">Feuerbestattung</h2>
        </div>
        <div className="container p-5">
          <p className="bestattungsArtDesc">Unter einer Feuerbestattung werden die Kremation und die anschließende Beisetzung der Asche der Verstorbenen verstanden. 
             Der Vorteil dieser Bestattungsart ist die Vielzahl an Möglichkeiten, die Urne beizusetzen. 
             Bei der Erdbestattung gibt es in der Regel lediglich die Auswahl zwischen Wahlgrab oder Einzelgrab. 
             Bei der Feuerbestattung kann die Urne auf dem Friedhof, im Wurzelbereich eines Baumes oder im Meer bestattet werden. 
             <br></br>
             <br></br>
             Hier können Sie zwischen Seebestattung, Anonyme Bestattung oder Friedwaldbestattung wählen. </p>
       </div>
          
       <div className="d-flex flex-wrap container-fluid mb-4 wrapperFeuerbestattung">
               <div  className="d-flex flex-column justify-content-center col-sm-12 col-md-6 col-lg-3">
                    <h5 className="text-center pt-3">Seebestattung</h5>
                    <Link className="link" to="/seebestattung">
                        <div ident="Seebestattung" onClick={this.props.getBestattungsArt} className="see d-flex justify-content-center align-items-center">
                             <button ident="Seebestattung" onClick={this.props.getBestattungsArt}  className="btn btn-light px-5 btnErd">Weiter</button>
                        </div>
                    </Link>
                </div>
                <div  className="d-flex flex-column justify-content-center col-sm-12 col-md-6 col-lg-3">
                    <h5 className="text-center pt-3">Anonyme Bestattung</h5>
                    <Link className="link" to="/anonyme_bestattung">
                        <div  ident="Anonyme Bestattung" onClick={this.props.getBestattungsArt} className="anonym d-flex justify-content-center align-items-center">
                             <button ident="Anonyme Bestattung" onClick={this.props.getBestattungsArt}  className="btn btn-light px-5 btnErd">Weiter</button>
                        </div>
                    </Link>
                </div>
                <div  className="d-flex flex-column justify-content-center col-sm-12 col-md-6 col-lg-3">
                    <h5 className="text-center pt-3">Baumbestattung</h5>
                    <Link className="link" to="/friedwald">
                        <div ident="Friedwaldbestattung" onClick={this.props.getBestattungsArt} className="friedwald d-flex justify-content-center align-items-center">
                             <button ident="Friedwaldbestattung" onClick={this.props.getBestattungsArt} className="btn btn-light px-5 btnErd">Weiter</button>
                        </div>
                    </Link>
                </div>
                <div  className="d-flex flex-column justify-content-center col-sm-12 col-md-6 col-lg-3">
                    <h5 className="text-center pt-3">Einfache Feuerbestattung</h5>
                    <Link className="link" to="/einfache_feuerbestattung">
                        <div ident="Friedwaldbestattung" onClick={this.props.getBestattungsArt} className="einfache_feuer d-flex justify-content-center align-items-center">
                             <button ident="Friedwaldbestattung" onClick={this.props.getBestattungsArt} className="btn btn-light px-5 btnErd">Weiter</button>
                        </div>
                    </Link>
                </div>
      </div>

    </>
    )
  }
}

const mapDispatchTpProps = dispatch => {
   return{
    getBestattungsArt: ev => dispatch(getBestattungsArt(ev))
   }
}


export const FeuerBestattungContainer = connect(null, mapDispatchTpProps)(Feuer_Bestattung);
