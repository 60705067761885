import React, {Component} from "react";
import "../style/uns.css";


class Ueber_Uns extends Component{
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  render(){
    return(
    <>
        <div className="bg-ueber_uns">
           <h2 className="headerTop">Über uns</h2>
        </div>

      <div className="container-fluid bestattungAngebot py-5">
          <div className="container py-3">  
          <p className="pUeberUns">
                Wir bieten Ihnen zu günstigen Konditionen Erd-, Feuer-, See- & anonyme Bestattungen mit oder ohne Trauerfeier, nach Ihren individuellen Wünschen. Unser eigener Fahrdienst gewährleistet eine kompetente und kurzfristige Abholung, Überführung, des/ der Verstorbenen aus dem gesamten Bundesgebiet und auch europaweit.
                <br/><br/>
                Wir bieten Ihnen auch gerne eine Beratung, telefonisch oder in Ihren Räumen an und erstellen Ihnen Angebote ganz nach Ihren Vorstellungen.
              <br/><br/>
                Wir helfen Ihnen nicht nur in den schweren Stunden im Trauerfall oder vor der Bestattung, sondern stehen Ihnen auch mit Rat und Tat zur Seite bei der Vorbereitung der Trauerfeier, den anstehenden Aufgaben und bei Behördengängen.
          </p>

       {/*
          <div className="reneBox">
             <img className="rene" src={rene} />
             <p className="pChef">René Walek - Geschäftsführer</p>
       </div>*/}

       </div>
     </div>
    </>
    )
  }
}

export default Ueber_Uns;
