import React, {useState} from "react";
import {ButtonToolbar, Modal} from "react-bootstrap";
// import {Link} from "react-router-dom";
import "../style/flaggen.css";
import {connect} from "react-redux";
import ReactTooltip from 'react-tooltip';
import {VollmachtContainer} from "./Vollmacht";
import {closeAngebotModal, getBestattungsArt} from "./redux";
import "../style/angebot.css";
import { IoMdClose } from "react-icons/io";
import logoImg from "../images/logo/logo.jpg";
import jsPDF from 'jspdf';

const AngebotBox = (props) => {

    const [showAngebot, setAngebot] = useState(false);
    let chooseAngebot = "";

    const changeAngebot = ev => {
         console.log("ev", ev.target.value);
         switch(ev.target.value){
             case "angebot selbst":
                    setAngebot(!showAngebot);
                    setTimeout(() => {
                      let containerAngebot = document.querySelector("#scrollTo");
                      containerAngebot.scrollIntoView({block: "start", behavior: "smooth"});
                    }, 200)


                 break;
              case "angebot":
                angebotDownload();
                break;
              default:
                  return ev.target.value
         }
    }

    const closeVollmacht = () => {
        setAngebot(false)
    }

   const angebotDownload = () => {

      var pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(logoImg, 'SVG',  90, 7);
      pdf.setFontSize(20);            // Schriftgröße
      pdf.setFont("times")
      pdf.setTextColor(0, 0, 0);    // Textfarbe als RGB-Werte
      pdf.setFontSize(17);

      pdf.setFontSize(17);
          pdf.text (`${props.bestattungsartAngebot} in ${props.cityAreay}`, 20, 60);
          pdf.text (`Gesamtpreis: ${props.bestattungsPreisAngebot.toFixed(2)} EUR`, 20, 67);

          pdf.setFontSize(12);
          /* datum */
          var datum = new Date();
          var day = datum.getDate();
          var month = datum.getMonth();
          var hours = datum.getHours();
          var minutes = (datum.getMinutes()<10?'0':'') + datum.getMinutes();
          month = month +1;
          var year = datum.getFullYear();
          pdf.text ("Sehr geehrte(r) Interessent(in),", 20, 80);
          pdf.text (`wir danken Ihnen für Ihre Anfrage vom ${day}.${month}.${year} und bieten Ihnen die von Ihnen`, 20, 88);
          pdf.text ("genannten Leistungen wie folgt an:", 20, 93);

          /* leistungen */
          var source = document.querySelector(".leistungsAngebotBox");


          var liAngebot = document.querySelectorAll(".liAngebot");
          for(let i = 0; i < liAngebot.length; i++){
            liAngebot[i].style.fontSize = "13px";
            liAngebot[i].style.lineHeight = "0.5";
          }


          pdf.fromHTML(
            source, // HTML string or DOM elem ref.
            18, // x coord
            100, {
              // y coord
             // max width of content on PDF
            },
          );

          pdf.setFontSize(10);
          props.bestattungsartAngebot === "Erdbestattung" && pdf.text("Die Kosten des Friedhofs sind in diesem Angebot nicht erhalten.", 20, 220);
          /* Anfallende Fremdleistungen... */
          pdf.text("Anfallende Fremdleistungen (z. B. Gebühren im Krankenhaus und Standesamt) werden Ihnen in tatsächlich entstandener", 20, 225);
          pdf.text("Höhe weiter berechnet. Bei Abholung außerhalb eines Krankenhauses (z. B. Pflegeheim, Hospitz, zu Hause", 20, 230);
          pdf.text("fällt ein Zuschlag von 120,00 EUR an.", 20, 235);
          /* Sollten nicht innerhalb von.. */
          pdf.text("Sollten nicht innerhalb von 3 Tagen alle für die Beurkundung des Sterbefalls notwendigen Dokumente vorliegen,", 20, 245);
          pdf.text("werden folgende Gebühren berechnet: ", 20, 250);
          pdf.text("Bearbeitungsgebühr 55,00 EUR für jedes notwendige Dokument zusätzliche Gebühren des Standesamtes.", 20, 255);

            /* Ort, Datum und Unterschrift */
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineWidth(.3);
            pdf.line(20, 270, 80, 270);
            pdf.line(100, 270, 160, 270);
            pdf.setFontSize(12);
            pdf.text ("Ort, Datum", 20, 275);
            pdf.text ("Unterschrift", 100, 275);

            /* senden an email */
            pdf.setFontSize(12);
            pdf.text("Bitte senden sie das Angebot mit der Vollmacht an diese Email: info@sidereum-bestattungen.de", 20, 283);

            pdf.setFontSize(10);
            /*  Seitenanzahl */
            pdf.text("Seite 1/2",  95, 290);
            pdf.setFontSize(8);
            /* Zeitstempel */
            pdf.text(`Erstellt am ${day}.${month}.${year} um ${hours}:${minutes} Uhr`, 80, 294);

          /* neue Seite vollmacht */
          pdf.addPage();

          pdf.addImage(logoImg, 'SVG',  90, 7);
          pdf.setFontSize(20);
          pdf.setFont("times")
          //pdf.setFontType("bold")
          //pdf.setTextColor(79, 61, 63);
          pdf.text ("Vollmacht", 92, 55);

            pdf.setFontSize(15);
            pdf.text ("Bestattungspflichtige(r)", 20, 67);

            var vollmachtDataPflichtigerLinks =
                                 `<p>Name:</p>`+
                                 `<p>geboren am:</p>`+
                                 `<p>Anschrift:</p>`+
                                 `<p>Geburtsort:</p>`
            var vollmachtDataPflichtigerRechts =
                                 `<p>Vorname:</p>`+
                                 `<p>Verwandschaftsverhältnis:</p>`+
                                 `<p>&nbsp;</p>`+
                                 `<p>Telefon:</p>`

          pdf.fromHTML(
                vollmachtDataPflichtigerLinks,
                20, // x coord
                67, // margin top coord
                {
                  // y coord
                  width: 100// max width of content on PDF
                },
              );
          pdf.fromHTML(
                vollmachtDataPflichtigerRechts,
                120, // x coord
                67, // margin top coord
                {
                  // y coord
                  width: 100// max width of content on PDF
                },
              );
          var text_1 = "Ich der / die Unterzeichner/ in, beauftrage Sidereum Bestattungen, die Beisetzung von:";
          pdf.setFontSize(12);
          pdf.text (text_1, 20, 110);
          pdf.setFontSize(15);
          pdf.text ("Verstorbener", 20, 120);

          var vollmachtDataVerstorbenerLinks =
                                            `<p>Name:</p>`+
                                            `<p>geboren am:</p>`+
                                            `<p>Letzte Anschrift:</p>`+
                                            `<p>Geburtsort:</p>`+
                                            `<p>Beruf:</p>`+
                                            `<p>Familienstand:</p>`+
                                            `<p>Krankenkasse:</p>`
                                            //`<p>Benötigte Unterlagen (im Original): ${unterlagen}${russianDoc}</p>`
          var vollmachtDataVerstorbenerRechts =
                                            `<p>Vorname:</p>`+
                                            `<p>verstorben am:</p>`+
                                            `<p>Abholanschrift:</p>`+
                                            `<p>Religion:</p>`+
                                            `<p>Kinder:</p>`+
                                            `<p>Rentennummer:</p>`+
                                            `<p>Anzahl der Sterbeurkunden:</p>`+
                                            `<p>Gewicht / Größe:</p>`

          pdf.fromHTML(
                vollmachtDataVerstorbenerLinks,
                20, // x coord
                121, // margin top coord
                {
                  // y coord
                  width: 90// max width of content on PDF
                },
              );

          pdf.fromHTML(
            vollmachtDataVerstorbenerRechts,
                120, // x coord
                121, // margin top coord
                {
                  // y coord
                  width: 90// max width of content on PDF
                },
              );

          var text_2 = "Sidereum Bestattungen ist berechtigt, alle notwendigen Schritte zur Beisetzung einzuleiten.";
          var text_3 = "Die Personenstandsurkunden müssen innerhalb von 3 Tagen Sidereum Bestattungen ";
          var text_4 = "oder dem zuständigen Standesamt vorliegen.";
          var text_5 = "Für alle Kosten der Bestattung und der Bestattungsgebühren komme ich im vollen Umfang auf.";
          var text_6 = "Ich habe die AGB zur Kenntnis genommen und akzeptiert.";

          pdf.setFontSize(12);
          pdf.text (text_2, 20, 221);
          pdf.text (text_3, 20, 226);
          pdf.text (text_4, 20, 231);
          pdf.text (text_5, 20, 236);
          pdf.text (text_6, 20, 241);

          /* Ort, Datum und Unterschrift */
          pdf.setLineWidth(.3);
          pdf.line(20, 257, 80, 257);
          pdf.line(100, 257, 160, 257);

          pdf.text ("Ort, Datum", 20, 262);
          pdf.text ("Unterschrift", 100, 262);


      //Anschriftblock
      pdf.setFontSize(9);
      var anschriftBlock1 = "Sidereum Bestattungen UG\nAmtsgericht Rostock\nUst.IdNr: 086/117/03156\nGeschäftsführer: René Walek";
      var anschriftBlock2 = "Anschrift\nSidereum Bestattungen\nLerchenberg 1\n18292 Krakow am See";
      var anschriftBlock3 = "Tel: 0152 22003687\nWeb: www.sidereum-bestattungen.de\nE-Mail: info@sidereum-bestattungen.de";

      pdf.setLineWidth(.2);
      pdf.line(20, 268, 190, 268);
      pdf.text (anschriftBlock1, 20, 273);
      pdf.text (anschriftBlock2, 80, 273);
      pdf.text (anschriftBlock3, 140, 273);


      /*  Seitenanzahl */
      pdf.setFontSize(10);
      /*  Seitenanzahl */
      pdf.text("Seite 2/2",  95, 290);
      pdf.text(`Erstellt am ${day}.${month}.${year} um ${hours}:${minutes} Uhr`, 80, 294);

       /* update liAngebot */
       for(let i = 0; i < liAngebot.length; i++){
        liAngebot[i].style.fontSize = "15px";
        liAngebot[i].style.lineHeight = "2";
      }

      pdf.save('Vollmacht.pdf');
  }


     return(
        <ButtonToolbar>
        <Modal
            size="lg"
            show={props.showAngebotModal}
            onHide={props.closeAngebotModal}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <h2 className="headerModalBox">Angebot für {props.bestattungsartAngebot}</h2>
            </Modal.Header>
            <Modal.Body>
            <div id="containerAngebot" className="container  my-3 kontaktWrapper">
            <ReactTooltip />

                   <ul className="py-1 leistungsAngebotBox">
                        <li className="liAngebot">Beratung</li>
                        <li className="liAngebot">Abholung des Verstorbenen in {props.cityAreay}</li>
                        <li className="liAngebot">Trauerfall: {props.trauerfall}</li>
                        <li className="liAngebot">Sarg: Kiefersarg roh - Standartsarg (für Verstorbene bis max. 100 kg oder 195 cm) </li>
                         <li className="liAngebot">Decke, Kissen und Sterbegewand aus Baumwolle (eigene Kleidung möglich) </li>
                        <li className="liAngebot">Einsargung </li>
                        <li className="liAngebot">Ankleiden eigener Kleidung möglich</li>
                        <li className="liAngebot">Hygienische Grundversorgung des Verstorbenen</li>
                        {props.bestattungsartAngebot !== "Erdbestattung" &&
                         <>
                            <li className="liAngebot">Kosten der gesetzlich vorgeschriebenen zweiten Leichenschau im Krematorium</li>
                            <li className="liAngebot">Kosten des deutschen Krematoriums unserer Wahl</li>
                            <li className="liAngebot">Urne: Krematoriumsurne (Standarturne)</li>
                         </>
                         }
                       {props.bestattungsartAngebot !== "Seebestattung" ?
                        <li className="liAngebot">Organisation der Beisetzung (Friedhof)</li>
                        :
                        <li className="liAngebot">Organisation der Beisetzung (Reederei)</li>
                       }
                        <li className="liAngebot">Abmeldung bei der Krankenkasse und beim gesetzlichen Rententräger</li>
                        <li className="liAngebot">Abmeldung des Verstorbenen beim zuständigen Standesamt</li>
                        <li className="liAngebot">Behördengänge, Standesamt (Sterbeurkunden)</li>
                        <li className="liAngebot">Aushändigung einer gebührenpflichtigen Sterbeurkunde</li>
                        {props.bestattungsartAngebot !== "Erdbestattung" &&
                           <li className="liAngebot">{props.friedhofart}</li>
                        }
                  </ul>
              <p className="totalPreis">Gesamtpreis: {props.bestattungsPreisAngebot.toFixed(2)} EUR</p>

            <div className="angebotButtonWrapper">
             <select className="custom-select" id="inputGroupSelect_angebot"  value={chooseAngebot} onChange={changeAngebot}>
                <option >Auswählen (Auftrag & Vollmacht herunterladen)</option>
                <option value="angebot">Auftrag & Vollmacht herunterladen</option>
                <option value="angebot selbst">Auftrag & Vollmacht herunterladen (Vollmacht ausfüllen)</option>
              </select>
           </div>

          </div>

          <div id="scrollTo"></div>
        {showAngebot &&
         <>
         <IoMdClose onClick={closeVollmacht} className="angebotCloseButton" />
          <VollmachtContainer  />
        </>
       }


            </Modal.Body>
        </Modal>
    </ButtonToolbar>
     )
}

const mapStateToProps = state => {
    return{
      cityAreay: state.cityAreay,
      trauerfall: state.trauerfall,
      bestattungsartAngebot: state.bestattungsartAngebot,
      friedhofart: state.friedhofart,
      bestattungsPreisAngebot: state.bestattungsPreisAngebot,
      showAngebotModal: state.showAngebotModal,
    }
  }

  const mapStateToDispatch = dispatch => {
     return{
        closeAngebotModal: () => dispatch(closeAngebotModal()),
        getBestattungsArt: ev => dispatch(getBestattungsArt(ev)),
     }
  }

  export const AngebotBoxContainer = connect(mapStateToProps, mapStateToDispatch)(AngebotBox);

