import {createStore} from "redux";
import L from 'leaflet';
import axios from "axios";
import jsPDF from 'jspdf';
import logoImg from "../images/logo/logo.jpg";
import * as d3 from 'd3';
import plzdata from "../Components/data/PLZ.csv";

const initialState = {
    sarg: "Kiefersarg roh - Standartsarg",
    processBarStatus: 0,
    selectedTrauerfallErd: "zu Hause",
    selectedSargEv: "",
    trauerFallIsTrue: false,
    trauerFallIsTrueButton: false,
    datenschutzIsTrue: false,
    datenschutzIsChecked: false,
    datenschutzFailMessage: false,
    sargIselected: false,
    sargButtonisTrue: false,
    sargImg: "/static/media/kiefer_1.755381a6.jpg",
    //contact form
    //change language
    bestattungspflichtiger: "Bestattungspflichtige(r)",
    choose: "Auswählen",
    female: "Frau",
    male2: "Herr",
    salutation: "Anrede",
    contactName: "Name",
    contactVorname: "Vorname",
    born: "geboren am",
    verwandschaftsoptionDesc: "Verwandschaftsverhältnis",
    actualAdresse: "Anschrift",
    birthplaceDesc: "Geburtsort",
    alivePhoneDesc: "Telefon",
    verstorbener: "Verstorbener",
    diedOn: "verstorben am",
    prevAdresse: "letzte Anschrift",
    currentAdresse: "Abholanschrift",
    religion: "Religion",
    profession: "Beruf",
    children: "Kinder",
    single: "ledig",
    married: "verheiratet",
    widowed: "verwitwet",
    divorced: "geschieden",
    familyStatus: "Familienstand",
    pensionNumber: "Rentennummer",
    certificatesNumber: "Anzahl der Sterbeurkunden",
    healthInsurance: "Krankenkasse",
    acceptDataProtection: "Hiermit akzeptiere ich die",
    and: "und",
    agb: "AGB",
    dataProtection: "Datenschutzbestimmungen",
    heightWeight: "Gewicht / Größe",
    isGerman: true,
    vollmacht: "Vollmacht",
    titelAngebot: "Um Ihnen ein Angebot auszustellen brauchen wir ein paar Angaben für die Vollmacht",
    chooseLang: "Wählen Sie Ihre Sprache aus:",
    //Bestattungspflichtiger
    name: "",
    vorName: "",
    anrede: "",
    male: "",
    aliveBirthday: "",
    verwandschaftsoption: "",
    aliveAdresse: "",
    aliveBirthplace: "",
    alivePhone: "",
    //Verstorbener
    deceasedName: "",
    deceasedVorname: "",
    deceasedBirthdayFrom: "",
    deceasedBirthdayTo: "",
    deceasedAdresse: "",
    deceasedCurrentAdresse: "",
    deceasedBirthplace: "",
    deceasedReligion: "",
    deceasedProfession: "",
    deceasedChildren: "",
    maritalStatus: "",
    deceasedPensionNumber: "",
    deceasedDeathCertificates: "",
    deceasedHeightWeight: "",
    deceasedHealthInsurance: "",
    //end contact form
    loading: false,
    plzObjectData: null,
    mapLayerGroup: {},
    currentPosition: [],
    map: {},
    plz: "",
    googleData: [],
    search: "",
    place: "",
    message: "",
    trauerfall: "",
    sargPrice: 0,
    floristikPrice: 0,
    plzIsTrue: false,
    plzInvalid: false,
    preis: 1.200,
    preisStandart: 0,
    destination_price: 0,
    total_price: 0,
    trauerfeierPreis: 799,
    arrayCalculate: [],
    //Sargbouquet
    bouquetBudgetText: "",
    bouquetErsteSchleife: "",
    bouquetZweiteSchleife: "",
    //toggleTrauerKranz
    trauerkranzBudgetText: "",
    trauerkranzErsteSchleife: "",
    trauerkranzZweiteSchleife: "",
    trauerFeierText: "Trauerfeier nicht ausgewählt",
    floristikArray: [],
    modalShowTrauerfeier: false,
    modalAddProduct: false,
    productDesc: "Produkt",
    urneDesc: "Krematoriumsurne (Standarturne)",
    urnePrice: 0,
    urneImg: "/static/media/21881.b5d2c7d9.png",
    seeurneDesc: "Seeurne, Hellblau (Standarturne)",
    seeurnePrice: 0,
    seeurneImg: "/static/media/2072.d583f3b1.png",
    showSarg: false,
    showUrne: false,
    angebotArt: "",
    showSeebestattung: false,
    showAnonymBestattung: false,
    showSeeurne: false,
    showBiourne: false,
    biourneDesc: "Urne Bio-Linie, Zierband Edelstahl gebürstet",
    biourneImg: "/static/media/2621.edfb0fca.png",
    biournePrice: 0,
    isTrauerfeier: false,
    modalShowSarge: false,
    modalShowUrnen: false,
    modalSeeurnen: false,
    dataMapis: false,
    mapLoading: true,
    goToContact: false,
    modalShowBegleiteteSeebestattung: false,
    isBegleiteteSeebestattung: false,
    bemerkungenValue: "",
    noData: false,
    loadingData: true,
    showreloadDataContainer: false,
    showSargAuswahl: false,
    showUrneAuswahl: false,
    showSeeurneAuswahl: false,
    showBiourneAuswahl: false,
    isBeisetzung: true,
    goHome: true,
    isDataLoad: true,
    // erdbestattungPreis: 1199,
    // seebestattungPreis: 1199,
    // anonymebestattungPreis: 1199,
    // friedwaldbestattungPreis: 1199,
    // einfache_feuerbestattungPreis: 1199,
    showExtendsList: false,
    plzIsTyped: false,
    rightPlzButton: false,
    areaPrice: false,
    successPlz: false,
    plzArea: "",
    cityAreay: "",
    isTrauerfall: false,
    showAngebotModal: false,
    showAngebotWithoutVollmacht: false,
    friedhofwahl: "",
    unterlagen: [],
    trauerfallGeb: 0,
    bestattungsartAngebot: "",
    bestattungsPreisAngebot: 0,
    friedhofart: "",
    extendAreaPrice: 0,
    iskostenFriedhof: false,
    plzCity: ""

}

const reducer = (state = initialState, action) => {

   const copyOfStates = {...state};

   switch (action.type) {

     case "CHANGELANGUAGEBYCONTACT":
       let ident = action.ev.target.getAttribute("ident");

       switch(ident){
         case "deutsch":
          copyOfStates.isGerman = true;
          copyOfStates.bestattungspflichtiger = "Bestattungspflichtige(r)";
          copyOfStates.choose = "Auswählen";
          copyOfStates.female = "Frau";
          copyOfStates.male2 = "Herr";
          copyOfStates.salutation = "Anrede";
          copyOfStates.contactName = "Name";
          copyOfStates.contactVorname = "Vorname";
          copyOfStates.born = "geboren am";
          copyOfStates.verwandschaftsoptionDesc = "Verwandschaftsverhältnis";
          copyOfStates.actualAdresse = "Anschrift";
          copyOfStates.birthplaceDesc = "Geburtsort";
          copyOfStates.alivePhoneDesc = "Telefon";
          copyOfStates.verstorbener = "Verstorbener";
          copyOfStates.diedOn = "verstorben am";
          copyOfStates.prevAdresse =  "letzte Anschrift";
          copyOfStates.currentAdresse = "Abholanschrift";
          copyOfStates.religion = "Religion";
          copyOfStates.profession = "Beruf";
          copyOfStates.children = "Kinder";
          copyOfStates.single = "ledig";
          copyOfStates.married = "verheiratet";
          copyOfStates.widowed = "verwitwet";
          copyOfStates.divorced = "geschieden";
          copyOfStates.familyStatus = "Familienstand";
          copyOfStates.pensionNumber = "Rentennummer";
          copyOfStates.certificatesNumber = "Anzahl der Sterbeurkunden";
          copyOfStates.healthInsurance = "Krankenkasse";
          copyOfStates.heightWeight = "Gewicht / Größe";
          copyOfStates.acceptDataProtection = "Hiermit akzeptiere ich die";
          copyOfStates.dataProtection = "Datenschutzbestimmungen";
          copyOfStates.vollmacht = "Vollmacht";
          copyOfStates.titelAngebot = "Um Ihnen ein Angebot auszustellen brauchen wir ein paar Angaben für die Vollmacht";
          copyOfStates.chooseLang = "Wählen Sie Ihre Sprache aus:";
          copyOfStates.and = "und";
          copyOfStates.agb = "AGB";
         break;
         case "russisch":

            copyOfStates.isGerman = false;
            copyOfStates.bestattungspflichtiger = "Доверитель";
            copyOfStates.choose = "Выбрать";
            copyOfStates.female = "Женщина";
            copyOfStates.male2 = "Мужчина";
            copyOfStates.salutation = "Пол";
            copyOfStates.contactName = "Фамилия";
            copyOfStates.contactVorname = "Имя";
            copyOfStates.born = "Дата рождения";
            copyOfStates.verwandschaftsoptionDesc = "Линия родства";
            copyOfStates.actualAdresse = "Адрес";
            copyOfStates.currentAdresse = "Адрес, где находится покойный(ая)";
            copyOfStates.birthplaceDesc = "Место рождения";
            copyOfStates.alivePhoneDesc = "Teлефон";
            copyOfStates.verstorbener = "Покойный(ая)";
            copyOfStates.diedOn = "Дата смерти";
            copyOfStates.prevAdresse =  "Адрес, где прописан(а)";
            copyOfStates.religion = "Религия";
            copyOfStates.profession = "Профессия";
            copyOfStates.children = "Кол-во Детей";
            copyOfStates.single = "Холост (Не замужем)";
            copyOfStates.married = "Женат (Замужем)";
            copyOfStates.widowed = "Вдовец (Вдова)";
            copyOfStates.divorced = "Pазведён(а)";
            copyOfStates.familyStatus = "Семейное положение";
            copyOfStates.pensionNumber = "Пенсионный номер";
            copyOfStates.certificatesNumber = "Кол-во свидетельств о смерти";
            copyOfStates.healthInsurance = "Медицинское страхование";
            copyOfStates.heightWeight = "Вес / Рост";
            copyOfStates.acceptDataProtection = "Я принимаю";
            copyOfStates.dataProtection = "Политика конфиденциальности";
            copyOfStates.vollmacht = "Доверенность";
            copyOfStates.titelAngebot = "Чтобы выдать вам предложение, нам нужно несколько деталей для доверенности";
            copyOfStates.chooseLang = "Выберите ваш язык:";
            copyOfStates.and = "и";
            copyOfStates.agb = "Общие положения и условия";
          break;

         default:
           return ident;
       }

     return copyOfStates;


     case "TRAUERFALLERDBESTATTUNG":
      action.ev.stopPropagation();
      //var erdBestattung = "Erdbestattung";
      copyOfStates.trauerfall = action.ev.target.value;
      copyOfStates.trauerFallIsTrue = false;
      copyOfStates.trauerFallIsTrueButton = true;
      copyOfStates.selectedTrauerfallErd = action.ev.target.value;
      return copyOfStates;

      case "UPDATETRUEBUTTONTRAUERFALL":
          copyOfStates.trauerFallIsTrueButton = false;
      return copyOfStates;

      case "BUTTONINPUTCHECKHOME":
      document.querySelector("#home").checked = true;
      copyOfStates.selectedTrauerfallErd = "zu Hause";
      copyOfStates.trauerfall = action.ev.target.getAttribute("ident");
      document.querySelector("#gotToSterbeort").click();
      return copyOfStates;

      case "BUTTONINPUTCHECKHOSPITAL":
      document.querySelector("#hospital").checked = true;
      copyOfStates.selectedTrauerfallErd = "im Krankenhaus";
      copyOfStates.trauerfall = action.ev.target.getAttribute("ident");
      document.querySelector("#gotToSterbeort").click();
      return copyOfStates;

      case "BUTTONFAILERD":
      copyOfStates.trauerFallIsTrue = state.trauerFallIsTrue = true;
      return copyOfStates;

      case "SELECTSARG":
      copyOfStates.selectedSargEv = action.ev.target.value;
      copyOfStates.sargIselected = false;
      console.log(copyOfStates.selectedSargEv);
      return copyOfStates;

      case "BUTTONFAILSARG":
      copyOfStates.sargIselected = true;
      return copyOfStates;

      case "BUTTONWEITERTOMAP":
      copyOfStates.processBarStatus = 67;
      return copyOfStates;

    case "SHOWMAP":
       var position = [53.65, 12.266667];
       copyOfStates.currentPosition = position;
       var positionGermanyCenter = [52.510494, 13.396764];
       var map = L.map('mapid').setView(positionGermanyCenter, 5);
       copyOfStates.map = map;

       var layerGroup = L.layerGroup().addTo(map);
       copyOfStates.mapLayerGroup = layerGroup;

       L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
         attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
       }).addTo(map)

    return copyOfStates;


    case "DATAMAP":
              const getData = async ()  => {
               const response = await axios.get('https://raw.githubusercontent.com/yetzt/postleitzahlen/master/data/postleitzahlen.geojson');
                         // async fetch
                         try{
                              copyOfStates.plzObjectData = response;
                              //localStorage.setItem('storage', JSON.stringify(tempArray));
                              console.log(response)
                              const setDataLoadToFalse = document.getElementById("setDataLoadToFalse");
                              setDataLoadToFalse.click();
                         }
                         catch(error){
                           console.log(error);
                         }
                }
                getData();


    return copyOfStates;

    case "GETPOSTCODEDATA":

      const getPlzFromData = async () => {
         let res = await d3.csv(plzdata);
          //console.log(res[0]);
          let tempArray = [];
          //console.log(Object.entries(res[0]))
          res.map(elem => {
              //console.log(Object.entries(elem))
            return  tempArray = [...tempArray, Object.entries(elem)]
          })
          let newTempArray = [];
          newTempArray = tempArray.map(data => {
            let objData = {
                plz: data[0][1],
                city: data[1][1],
                lat: data[2][1],
                long: data[3][1]
             }
            return  [...newTempArray, objData];
        })
        copyOfStates.plzObjectData = newTempArray;
        document.getElementById("setDataLoadToFalse").click();
      }
      getPlzFromData();

      return copyOfStates;

    case "SETDATAISLOADTOFALSE":
       copyOfStates.isDataLoad = false;
    return copyOfStates;

    case "UPDATEGOTOCONTACT":
      copyOfStates.goToContact = false;
    return copyOfStates;

    case "RELOADDATA":
      console.log("reload data...");
      copyOfStates.noData = false;
      copyOfStates.goToContact = true;
      copyOfStates.plz = "";
      //copyOfStates.noData = false;
    return copyOfStates;

    case "UPDATEPLZINVALID":
        copyOfStates.plzInvalid = false;
        copyOfStates.loading = true;
    return copyOfStates;

    case "UPDATEPLZINVALID2":
      copyOfStates.plzInvalid = true;
      copyOfStates.loading = true;
      copyOfStates.noData = false;
  return copyOfStates;


    case "CALCULATEFINALPRICE":
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
        //console.log(copyOfStates.arrayCalculate);
        return copyOfStates;

    case "UPDATEMALE":
        copyOfStates.male = action.ev.target.value;
        if(copyOfStates.male === "Frau"){
          copyOfStates.anrede = "geehrte";
        }
        else{
          copyOfStates.anrede = "geehrter";
        }
     return copyOfStates;


     case "ONCHANGECONTACT":
     action.ev.preventDefault();

       switch (action.ev.target.getAttribute("ident")){
           /* Bestattungspflichtige */
          case "vorname":
          copyOfStates.vorName = action.ev.target.value;
          break;
          case "name":
          copyOfStates.name = action.ev.target.value;
          break;
          case "aliveBirthday":
          let aliveBirthday = action.ev.target.value;
          /*
          let birthYear =  aliveBirthday.substring(0, 4);
          let birthMonth =  aliveBirthday.substring(5, 7);
          let birthday =  aliveBirthday.substring(8, 10);
          let totalBirthday = `${birthday}.${birthMonth}.${birthYear}`;
          */
          copyOfStates.aliveBirthday = aliveBirthday;
          break;
          case "verwandschaftsoption":
          copyOfStates.verwandschaftsoption = action.ev.target.value;
          break;
          case "aliveAdresse":
          copyOfStates.aliveAdresse = action.ev.target.value;
          break;
          case "aliveBirthplace":
          copyOfStates.aliveBirthplace = action.ev.target.value;
          break;
          case "alivePhone":
          copyOfStates.alivePhone = action.ev.target.value;
          break;
          /* Verstorbener */
          case "deceasedName":
          copyOfStates.deceasedName = action.ev.target.value;
          break;
          case "deceasedVorname":
          copyOfStates.deceasedVorname = action.ev.target.value;
          break;
          case "deceasedBirthdayFrom":
              let deceasedBirthdayFrom = action.ev.target.value;
              /*
              let deceasedbirthYearFrom =  deceasedBirthdayFrom.substring(0, 4);
              let deceasedbirthMonthFrom =  deceasedBirthdayFrom.substring(5, 7);
              let deceasedbirthdayFrom =  deceasedBirthdayFrom.substring(8, 10);
              let deceasedtotalBirthdayFrom = `${deceasedbirthdayFrom}.${deceasedbirthMonthFrom}.${deceasedbirthYearFrom}`;
              */
              copyOfStates.deceasedBirthdayFrom = deceasedBirthdayFrom;
          break;
          case "deceasedBirthdayTo":
              let deceasedBirthdayTo = action.ev.target.value;
              /*
              let deceasedbirthYearTo =  deceasedBirthdayTo.substring(0, 4);
              let deceasedbirthMonthTo =  deceasedBirthdayTo.substring(5, 7);
              let deceasedbirthdayTo =  deceasedBirthdayTo.substring(8, 10);
              let deceasedtotalBirthdayTo = `${deceasedbirthdayTo}.${deceasedbirthMonthTo}.${deceasedbirthYearTo}`;
              */
              copyOfStates.deceasedBirthdayTo = deceasedBirthdayTo;
              break;
          case "deceasedAdresse":
            copyOfStates.deceasedAdresse = action.ev.target.value;
            break;
          case "deceasedCurrentAdresse":
            copyOfStates.deceasedCurrentAdresse = action.ev.target.value;
          break;
          case "deceasedBirthplace":
            copyOfStates.deceasedBirthplace = action.ev.target.value;
            break;
          case "deceasedReligion":
            copyOfStates.deceasedReligion = action.ev.target.value;

            break;
          case "deceasedProfession":
            copyOfStates.deceasedProfession = action.ev.target.value;
            break;
          case "deceasedChildren":
            copyOfStates.deceasedChildren = action.ev.target.value;
            break;
          case "maritalStatus":
            copyOfStates.maritalStatus = action.ev.target.value
            break;
          case "familyStatus":
              let status = action.ev.target.getAttribute("status");
              let familyStatus = document.querySelectorAll(".familyStatus");

              for(let i = 0; i < familyStatus.length; i++ ){
                switch(status){
                  case "Personalausweis":
                    if(familyStatus[i].getAttribute("status") === "Personalausweis"){
                      familyStatus[i].classList.toggle("familyStatusToggle");
                      console.log(familyStatus[i].getAttribute("status"))
                      if(!copyOfStates.unterlagen.includes("Personalausweis")){
                        copyOfStates.unterlagen.push("Personalausweis");
                      }
                      else{
                        copyOfStates.unterlagen = copyOfStates.unterlagen.filter(elem => elem !== "Personalausweis");
                      }

                    }
                  break;
                  case "Geburtsurkunde":
                   if(familyStatus[i].getAttribute("status") === "Geburtsurkunde"){
                      familyStatus[i].classList.toggle("familyStatusToggle");
                      if(!copyOfStates.unterlagen.includes("Geburtsurkunde")){
                        copyOfStates.unterlagen.push("Geburtsurkunde");
                      }
                      else{
                        copyOfStates.unterlagen = copyOfStates.unterlagen.filter(elem => elem !== "Geburtsurkunde");
                      }
                    }
                  break;
                  case "Krankenversicherungskarte":
                    if(familyStatus[i].getAttribute("status") === "Krankenversicherungskarte"){
                      familyStatus[i].classList.toggle("familyStatusToggle");
                      if(!copyOfStates.unterlagen.includes("Krankenversicherungskarte")){
                        copyOfStates.unterlagen.push("Krankenversicherungskarte");
                      }
                      else{
                        copyOfStates.unterlagen = copyOfStates.unterlagen.filter(elem => elem !== "Krankenversicherungskarte");
                      }
                    }
                  break;
                  case "Rentennummer":
                    if(familyStatus[i].getAttribute("status") === "Rentennummer"){
                      familyStatus[i].classList.toggle("familyStatusToggle");
                      if(!copyOfStates.unterlagen.includes("Rentennummer")){
                          copyOfStates.unterlagen.push("Rentennummer");
                      }
                      else{
                        copyOfStates.unterlagen = copyOfStates.unterlagen.filter(elem => elem !== "Rentennummer");
                      }
                    }
                  break;
                  case "Heiratsurkunde":
                    if(familyStatus[i].getAttribute("status") === "Heiratsurkunde"){
                      familyStatus[i].classList.toggle("familyStatusToggle");
                      if(!copyOfStates.unterlagen.includes("Heiratsurkunde")){
                        copyOfStates.unterlagen.push("Heiratsurkunde");
                      }
                      else{
                        copyOfStates.unterlagen = copyOfStates.unterlagen.filter(elem => elem !== "Heiratsurkunde");
                      }
                    }
                  break;
                  case "Sterbeurkunde des Ehepartners":
                    if(familyStatus[i].getAttribute("status") === "Sterbeurkunde des Ehepartners"){
                      familyStatus[i].classList.toggle("familyStatusToggle");
                      if(!copyOfStates.unterlagen.includes("Sterbeurkunde des Ehepartners")){
                         copyOfStates.unterlagen.push("Sterbeurkunde des Ehepartners");
                      }
                      else{
                        copyOfStates.unterlagen = copyOfStates.unterlagen.filter(elem => elem !== "Sterbeurkunde des Ehepartners");
                      }
                    }
                  break;
                  case "Scheidungsurteil":
                    if(familyStatus[i].getAttribute("status") === "Scheidungsurteil"){
                      familyStatus[i].classList.toggle("familyStatusToggle");
                      if(!copyOfStates.unterlagen.includes("Scheidungsurteil")){
                         copyOfStates.unterlagen.push("Scheidungsurteil");
                      }
                      else{
                        copyOfStates.unterlagen = copyOfStates.unterlagen.filter(elem => elem !== "Scheidungsurteil");
                      }
                    }
                  break;
                  default:
                    return status;
                }
              }


          break;
          case "deceasedPensionNumber":
            copyOfStates.deceasedPensionNumber = action.ev.target.value;
            break;
          case "deceasedDeathCertificates":
            copyOfStates.deceasedDeathCertificates = action.ev.target.value;
            break;
          case "deceasedHealthInsurance":
            copyOfStates.deceasedHealthInsurance = action.ev.target.value;
            break;
          case "deceasedHeightWeight":
            copyOfStates.deceasedHeightWeight = action.ev.target.value;
          break;

          default:
            return action.ev.target.getAttribute("ident");
       }
     return copyOfStates;

     case "CONTACTSUBMITVOLLMACHT":
       action.ev.preventDefault();
       console.log("hello submit")
       let kontaktInputs = document.querySelectorAll(".kontaktInput");
       let goToAngebot = document.querySelector("#goToAngebot");

       let checkboxDatenschutz = document.querySelector("#checkboxDatenschutz");
       if(checkboxDatenschutz.checked === true){
        copyOfStates.datenschutzIsTrue = true;
        copyOfStates.datenschutzFailMessage = false;
        for(let i = 0; i < kontaktInputs.length; i++){
          kontaktInputs[i].value = "";
         }

         goToAngebot.click();
       }
       else{
        copyOfStates.datenschutzFailMessage = true;
       }

     return copyOfStates;

     case "HANDLEBOXDATENSCHUTZ":
      copyOfStates.datenschutzIsTrue = !copyOfStates.datenschutzIsTrue;
      //document.querySelector("#checkboxDatenschutz").checked = true;
      //copyOfStates.datenschutzIsChecked = true;
      copyOfStates.datenschutzFailMessage = false;


      if(copyOfStates.datenschutzIsTrue === true){
        copyOfStates.datenschutzFailMessage = false;

       }
       else{
         copyOfStates.datenschutzIsTrue = false;
       }

       setTimeout(() => {
        copyOfStates.datenschutzIsTrue = false;
        //console.log("copyOfStates.datenschutzIsTrue", copyOfStates.datenschutzIsTrue)
       }, 1000);


      return copyOfStates;


      case "BUTTONFAILDATENSCHUTZ":
      copyOfStates.datenschutzFailMessage = true;
      return copyOfStates;


      case "SELECTURNE":

        let hideproductUrne = document.getElementById("hideproduct");
        copyOfStates.urneDesc = action.ev.target.getAttribute("identname");
        copyOfStates.productDesc = action.ev.target.getAttribute("identname");
        copyOfStates.urneImg = action.ev.target.getAttribute("identimg");
        copyOfStates.urnePrice = parseInt(action.ev.target.getAttribute("identpreis"));

        copyOfStates.showUrne = true;

     /*
        if(copyOfStates.urneDesc === "Urne Cremeweiss (Standarturne)"){
          copyOfStates.urnePrice = 0;
        }
    */
        copyOfStates.arrayCalculate[3] = copyOfStates.urnePrice;
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
        copyOfStates.modalAddProduct = copyOfStates.modalAddProduct = true;

           setTimeout(() => {
            copyOfStates.modalShowUrnen = false;
            hideproductUrne.click();
           }, 1000);

      return copyOfStates;

      case "DELETEURNE":
          copyOfStates.urnePrice = 0;
          copyOfStates.arrayCalculate[3] = 0;
          copyOfStates.urneDesc = "Krematoriumsurne (Standarturne)";
          copyOfStates.urneImg = "/static/media/21881.b5d2c7d9.png";
          copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
      return copyOfStates;

      case "SELECTSEEURNE":
          let hideproductSeeurne = document.getElementById("hideproduct");
          copyOfStates.seeurneDesc = action.ev.target.getAttribute("identname");
          copyOfStates.productDesc = action.ev.target.getAttribute("identname");
          copyOfStates.seeurneImg = action.ev.target.getAttribute("identimg");
          console.log("copyOfStates.seeurneImg", copyOfStates.seeurneImg);
          copyOfStates.seeurnePrice = parseInt(action.ev.target.getAttribute("identpreis"));
          copyOfStates.showSeeurne = true;

          if(copyOfStates.seeurneDesc === "Seeurne, Hellblau (Standarturne) "){
            copyOfStates.seeurnePrice = 0;
            copyOfStates.modalAddProduct = copyOfStates.modalAddProduct = true;
            setTimeout(() => {
              copyOfStates.modalSeeurnen = false;
              hideproductSeeurne.click();
             }, 1000);
          }
          copyOfStates.arrayCalculate[5] = copyOfStates.seeurnePrice;
          copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
          copyOfStates.modalAddProduct = copyOfStates.modalAddProduct = true;
             setTimeout(() => {
              copyOfStates.modalSeeurnen = false;
              hideproductSeeurne.click();
             }, 1000);

        return copyOfStates;

        case "DELETESEEURNE":
            copyOfStates.seeurnePrice = 0;
            copyOfStates.arrayCalculate[5] = 0;
            copyOfStates.seeurneDesc = "Seeurne, Hellblau (Standarturne)";
            copyOfStates.seeurneImg = "/static/media/2072.d583f3b1.png";
            copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
        return copyOfStates;

        case "SELECTBIOURNE":
            let hidebiournen = document.getElementById("hidebiournen");
            let hideproductBiourne = document.getElementById("hideproduct");
            copyOfStates.biourneDesc = action.ev.target.getAttribute("identname");
            copyOfStates.productDesc = action.ev.target.getAttribute("identname");
            copyOfStates.biourneImg = action.ev.target.getAttribute("identimg");
            console.log("biourne img", copyOfStates.biourneImg);
            copyOfStates.biournePrice = parseInt(action.ev.target.getAttribute("identpreis"));
            copyOfStates.showBiourne = true;

            if(copyOfStates.biourneDesc === "Urne Bio-Linie, Zierband Edelstahl gebürstet (Standarturne) "){
              copyOfStates.biournePrice = 0;
            }
            copyOfStates.arrayCalculate[6] = copyOfStates.biournePrice;
            copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
            copyOfStates.modalAddProduct = copyOfStates.modalAddProduct = true;

               setTimeout(() => {
                hidebiournen.click();
                hideproductBiourne.click();
               }, 1000);

          return copyOfStates;

        case "DELETEBIOURNE":
            copyOfStates.biournePrice = 0;
            copyOfStates.arrayCalculate[6] = 0;
            copyOfStates.biourneDesc = "Urne Bio-Linie, Zierband Edelstahl gebürstet (Standarturne)";
            copyOfStates.biourneImg = "/static/media/2621.edfb0fca.png";
            copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
        return copyOfStates;

      case "SELECTSARGBUTTON":
       let hideproduct = document.getElementById("hideproduct");
       let hidesaerge = document.getElementById("hidesaerge");
       console.log("hidesaerge", hidesaerge);
       copyOfStates.sarg = action.ev.target.getAttribute("identname");
       copyOfStates.productDesc = action.ev.target.getAttribute("identname");
       copyOfStates.sargPrice = parseInt(action.ev.target.getAttribute("identpreis"));
       copyOfStates.sargImg = action.ev.target.getAttribute("identimg");

       copyOfStates.showSarg = true;

       //console.log("Sarg img:" + copyOfStates.sargImg);

       if(copyOfStates.sarg === "Kiefersarg roh (Standartsarg)"){
         copyOfStates.arrayCalculate[0] = 0;
         console.log(copyOfStates.arrayCalculate);
         copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
         copyOfStates.modalAddProduct = copyOfStates.modalAddProduct = true;
         setTimeout(() => {
          copyOfStates.modalShowSarge = false;
          hideproduct.click();
         }, 1000);
       }
       else{
           copyOfStates.arrayCalculate[0] = copyOfStates.sargPrice;
           copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
           copyOfStates.modalAddProduct = copyOfStates.modalAddProduct = true;
           setTimeout(() => {
            copyOfStates.modalShowSarge = false;
            hideproduct.click();
           }, 1000);
       }

       return copyOfStates;

      case "DELETESARG":
          copyOfStates.arrayCalculate[0] = 0;
          copyOfStates.sargPrice = 0;
          copyOfStates.sarg = "Kiefersarg roh (Standartsarg)";
          copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
      return copyOfStates;

      case "ADDBEGLEITETESEEBESTATTUNG":
        copyOfStates.isBegleiteteSeebestattung = true;
        copyOfStates.modalShowBegleiteteSeebestattung = false;
      return copyOfStates;

      case "DELETEBEGLEITETESEEBESTATTUNG":
          copyOfStates.isBegleiteteSeebestattung = false;
          copyOfStates.modalShowBegleiteteSeebestattung = false;
      return copyOfStates;

      case "ADDTRAUERFEIER":
      let foundTrauerfeier = copyOfStates.arrayCalculate.find(elem => elem === copyOfStates.trauerfeierPreis);
      if(!foundTrauerfeier){
        //copyOfStates.arrayCalculate.push(copyOfStates.trauerfeierPreis);
        copyOfStates.isTrauerfeier = true;
        copyOfStates.arrayCalculate[1] = copyOfStates.trauerfeierPreis = 499;
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
      }
      copyOfStates.trauerFeierText = "Trauerfeier ausgewählt";
      copyOfStates.modalShowTrauerfeier = false;

      //console.log(copyOfStates.arrayCalculate);
      return copyOfStates;

      case "DELETETRAUERFEIER":
      copyOfStates.isTrauerfeier = false;
      copyOfStates.arrayCalculate[1] = 0;
      //copyOfStates.trauerfeierPreis = 0;
      copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
      copyOfStates.trauerFeierText = "Trauerfeier nicht ausgewählt";
      console.log(copyOfStates.arrayCalculate);
      copyOfStates.modalShowTrauerfeier = false;
      return copyOfStates;

      case "ONSUBMITBOUQUET":
       action.ev.preventDefault();
       return copyOfStates;

       case "ONSUBMITTRAUERKRANZ":
        action.ev.preventDefault();
        return copyOfStates;

      case "ADDBOUQUETBUDGET":
      copyOfStates.bouquetBudgetText = parseInt(action.ev.target.value);
      var productCheckSargBouquet = document.querySelector(".productCheckSargBouquet");
      var pBudgetText = document.querySelector(".pBudgetText");
      if(isNaN(copyOfStates.bouquetBudgetText) || copyOfStates.bouquetBudgetText === 0 ){
        //console.log("hallo");
        productCheckSargBouquet.style.opacity = "0";
        pBudgetText.style.display = "none";
        copyOfStates.arrayCalculate[4] = parseInt(copyOfStates.bouquetBudgetText = "0");
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
      }
      else {
        productCheckSargBouquet.style.opacity = "1";
        pBudgetText.style.display = "block";
        copyOfStates.arrayCalculate[4] = parseInt(copyOfStates.bouquetBudgetText);
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
      }
      return copyOfStates;

      case "ADDBOUQUETERSTESCHLEIFE":

      copyOfStates.bouquetErsteSchleife = action.ev.target.value;
      var pbouquetErsteSchleife = document.querySelector(".pbouquetErsteSchleife");

      pbouquetErsteSchleife.style.display = "block";
      if(copyOfStates.bouquetErsteSchleife  === ""){
        pbouquetErsteSchleife.style.display = "none";
      }
      return copyOfStates;

     //Sargbouquet
      case "ADDBOUQUETZWEITESCHLEIFE":
      copyOfStates.bouquetZweiteSchleife = action.ev.target.value;
      var pbouquetZweiteSchleife = document.querySelector(".pbouquetZweiteSchleife");
      if(copyOfStates.bouquetZweiteSchleife === ""){
        pbouquetZweiteSchleife.style.display = "none";
      }
      else{
        pbouquetZweiteSchleife.style.display = "block";
      }
      return copyOfStates;

      case "REMOVEFIELDSBOUQUET":{
        copyOfStates.bouquetBudgetText = 0;
        copyOfStates.bouquetErsteSchleife = "";
        copyOfStates.bouquetZweiteSchleife = "";

        var productCheckSargBouquet2 = document.querySelector(".productCheckSargBouquet");
        var pBudgetText2 = document.querySelector(".pBudgetText");
        var pbouquetErsteSchleife2 = document.querySelector(".pbouquetErsteSchleife");
        var pbouquetZweiteSchleife2 = document.querySelector(".pbouquetZweiteSchleife");

        productCheckSargBouquet2.style.opacity = "0";
        pBudgetText2.style.display = "none";
        pbouquetErsteSchleife2.style.display = "none";
        pbouquetZweiteSchleife2.style.display = "none";
        copyOfStates.arrayCalculate[4] = copyOfStates.bouquetBudgetText;
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);

        return copyOfStates;
      }

      //Trauerkranz
      case "ADDTRAUERKRANZBUDGET":
      copyOfStates.trauerkranzBudgetText = parseInt(action.ev.target.value);
      var productCheckTrauerkranz = document.querySelector(".productCheckTrauerkranz");
      var pBudgetTextTrauerkranz = document.querySelector(".pBudgetTextTrauerkranz");
      if(isNaN(copyOfStates.trauerkranzBudgetText) || copyOfStates.trauerkranzBudgetText === 0 ){
        productCheckTrauerkranz.style.opacity = "0";
        pBudgetTextTrauerkranz.style.display = "none";
        copyOfStates.arrayCalculate[5] = parseInt(copyOfStates.trauerkranzBudgetText = "0");
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
      }
      else {
        productCheckTrauerkranz.style.opacity = "1";
        pBudgetTextTrauerkranz.style.display = "block";
        copyOfStates.arrayCalculate[5] = parseInt(copyOfStates.trauerkranzBudgetText);
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
      }
      return copyOfStates;

      case "ADDTRAUERKRANZERSTESCHLEIFE":
      copyOfStates.trauerkranzErsteSchleife = action.ev.target.value;
      var pTrauerkranzErsteSchleife = document.querySelector(".pTrauerkranzErsteSchleife");

      if(copyOfStates.trauerkranzErsteSchleife  === ""){
        pTrauerkranzErsteSchleife.style.display = "none";
      }
      else{
        pTrauerkranzErsteSchleife.style.display = "block";
      }
      return copyOfStates;

      case "ADDTRAUERKRANZZWEITESCHLEIFE":
      copyOfStates.trauerkranzZweiteSchleife = action.ev.target.value;
      var pTrauerkranzZweiteSchleife = document.querySelector(".pTrauerkranzZweiteSchleife");

      if(copyOfStates.trauerkranzZweiteSchleife  === ""){
        pTrauerkranzZweiteSchleife.style.display = "none";
      }
      else{
        pTrauerkranzZweiteSchleife.style.display = "block";
      }
      return copyOfStates;

      case "REMOVEFIELDSTRAUERKRANZ":
        copyOfStates.trauerkranzBudgetText = 0;
        copyOfStates.trauerkranzErsteSchleife = "";
        copyOfStates.trauerkranzZweiteSchleife = "";

        var productCheckTrauerkranz2 = document.querySelector(".productCheckTrauerkranz");
        var pBudgetTextTrauerkranz2 = document.querySelector(".pBudgetTextTrauerkranz");
        var pTrauerkranzErsteSchleife2 = document.querySelector(".pTrauerkranzErsteSchleife");
        var pTrauerkranzZweiteSchleife2 = document.querySelector(".pTrauerkranzZweiteSchleife");

        productCheckTrauerkranz2.style.opacity = "0";
        pBudgetTextTrauerkranz2.style.display = "none";
        pTrauerkranzErsteSchleife2.style.display = "none";
        pTrauerkranzZweiteSchleife2.style.display = "none";
        copyOfStates.arrayCalculate[5] = copyOfStates.trauerkranzBudgetText;
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);

        return copyOfStates;

      case "ADDFLORISTIK":
        let hidefloristik = document.getElementById("hidefloristik");
        let hideproductFloristik = document.getElementById("hideproduct");
        const identArtFloristik = action.ev.target.getAttribute("identart");
        const identPreisFloristik = action.ev.target.getAttribute("identpreis");
        const identTitelFloristik = action.ev.target.getAttribute("identtitel");
        copyOfStates.productDesc = action.ev.target.getAttribute("identtitel");
        //const identCategoryFloristik = action.ev.target.getAttribute("identcategory");
        const identImgFloristik = action.ev.target.getAttribute("identimg");

        const floristikObj = {
           art: identArtFloristik,
           price: identPreisFloristik,
           titel: identTitelFloristik,
           img: identImgFloristik
        }

        copyOfStates.floristikArray = [...copyOfStates.floristikArray, floristikObj];

        copyOfStates.arrayCalculate[2] = copyOfStates.floristikArray.reduce((a, b) => a + Number(b.price), 0);
        copyOfStates.floristikPrice = copyOfStates.floristikArray.reduce((a, b) => a + Number(b.price), 0);
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);

        copyOfStates.modalAddProduct = copyOfStates.modalAddProduct = true;
        setTimeout(() => {
          hidefloristik.click();
          hideproductFloristik.click();
        }, 1000)
        return copyOfStates;

      case "DELETEFLORISTIK":
        let identid = action.ev.target.getAttribute("identid");
       copyOfStates.floristikArray.splice(identid, 1);
       copyOfStates.arrayCalculate[2] = copyOfStates.floristikArray.reduce((a, b) => a + Number(b.price), 0);
        copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
        return copyOfStates;

      case "SHOWMODALTRAUERFEIER":
        copyOfStates.modalShowTrauerfeier = true;
      return copyOfStates;

      case "HIDEMODALTRAUERFEIER":
        copyOfStates.modalShowTrauerfeier = false;
      return copyOfStates;

      case "SHOWMODALPRODUCT":
        copyOfStates.modalAddProduct = true;
      return copyOfStates;

      case "HIDEMODALPRODUCT":
        copyOfStates.modalAddProduct = false;
      return copyOfStates;


      case "CHANGEBESTATTUNGSART":
        let angebotContainer = document.querySelector(".angebotContainer");
        let identChangeBestattungsart = action.ev.target.getAttribute("ident");
        switch(identChangeBestattungsart){
           case "erd":
              angebotContainer.classList.add("bgErd");
              angebotContainer.classList.remove("bgSee");
              angebotContainer.classList.remove("bgAnonym");
              angebotContainer.classList.remove("bgFriedwald");
              angebotContainer.classList.remove("bgEinfacheFeuerbestattung");
              //copyOfStates.showSarg = true;
              copyOfStates.showSargAuswahl = true;
              copyOfStates.showUrneAuswahl = false;
              copyOfStates.showSeeurneAuswahl = false;
              copyOfStates.showBiourneAuswahl = false;

              copyOfStates.showUrne = false;
              copyOfStates.showBiourne = false;
              copyOfStates.showSeeurne = false;
              copyOfStates.showSeebestattung = false;

              copyOfStates.isBegleiteteSeebestattung = false;
              copyOfStates.isBeisetzung = true;

              copyOfStates.friedhofwahl = "";
              copyOfStates.angebotArt = "Erdbestattung";
              copyOfStates.sarg = "Kiefersarg roh - Standartsarg";
              copyOfStates.sargImg = "/static/media/kiefer_1.755381a6.jpg";

               copyOfStates.arrayCalculate[3] = 0;
               copyOfStates.arrayCalculate[4] = copyOfStates.erdbestattungPreis;
               copyOfStates.arrayCalculate[5] = 0;
               copyOfStates.arrayCalculate[6] = 0;
               copyOfStates.arrayCalculate[7] = 0;
               copyOfStates.arrayCalculate[8] = 0;
               copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
               document.getElementById("closeAllBestattungsarten").click();

           break;

           case "einfachefeuerbestattung":
              angebotContainer.classList.add("bgEinfacheFeuerbestattung");
              angebotContainer.classList.remove("bgAnonym");
              angebotContainer.classList.remove("bgErd");
              angebotContainer.classList.remove("bgSee");
              angebotContainer.classList.remove("bgFriedwald");
              copyOfStates.showUrneAuswahl = true;
              copyOfStates.showSargAuswahl = false;
              copyOfStates.showSeeurneAuswahl = false;
              copyOfStates.showBiourneAuswahl = false;

              copyOfStates.showSarg = false;
              copyOfStates.showBiourne = false;
              copyOfStates.showSeeurne = false;
              copyOfStates.isBegleiteteSeebestattung = false;
              copyOfStates.showSeebestattung = false;

              copyOfStates.friedhofwahl = "Friedhof Ihrer Wahl";
              copyOfStates.angebotArt = "Einfache Feuerbestattung";
              copyOfStates.sarg = "Kiefersarg roh - Standartsarg";
              copyOfStates.urneDesc = "Krematoriumsurne (Standarturne)";
              copyOfStates.urneImg = "/static/media/21881.b5d2c7d9.png";

              copyOfStates.arrayCalculate[0] = 0;
              copyOfStates.arrayCalculate[1] = 0;
              copyOfStates.arrayCalculate[4] = copyOfStates.einfache_feuerbestattungPreis;
              copyOfStates.arrayCalculate[5] = 0;
              copyOfStates.arrayCalculate[6] = 0;
              copyOfStates.arrayCalculate[7] = 0;
              copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
              document.getElementById("closeAllBestattungsarten").click();
           break;

           case "anonym":
              angebotContainer.classList.add("bgAnonym");
              angebotContainer.classList.remove("bgErd");
              angebotContainer.classList.remove("bgSee");
              angebotContainer.classList.remove("bgFriedwald");
              angebotContainer.classList.remove("bgEinfacheFeuerbestattung");
              copyOfStates.showUrneAuswahl = true;
              copyOfStates.showSargAuswahl = false;
              copyOfStates.showSeeurneAuswahl = false;
              copyOfStates.showBiourneAuswahl = false;

              copyOfStates.showSarg = false;
              copyOfStates.showBiourne = false;
              copyOfStates.showSeeurne = false;

              copyOfStates.isTrauerfeier = false;
              copyOfStates.isBegleiteteSeebestattung = false;
              copyOfStates.showSeebestattung = false;
              copyOfStates.isBeisetzung = true;

              copyOfStates.friedhofwahl = "Friedhof unserer Wahl";
              copyOfStates.angebotArt = "Anonyme Bestattung";
              copyOfStates.sarg = "Kiefersarg roh - Standartsarg";
              copyOfStates.urneDesc = "Krematoriumsurne (Standarturne)";
              copyOfStates.urneImg = "/static/media/21881.b5d2c7d9.png";

              copyOfStates.arrayCalculate[0] = 0;
              copyOfStates.arrayCalculate[1] = 0;
              copyOfStates.arrayCalculate[4] = copyOfStates.anonymebestattungPreis;
              copyOfStates.arrayCalculate[5] = 0;
              copyOfStates.arrayCalculate[6] = 0;
              copyOfStates.arrayCalculate[7] = 0;
              copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
              document.getElementById("closeAllBestattungsarten").click();
           break;

           case "see":
              angebotContainer.classList.remove("bgErd");
              angebotContainer.classList.remove("bgAnonym");
              angebotContainer.classList.remove("bgFriedwald");
              angebotContainer.classList.add("bgSee");
              angebotContainer.classList.remove("bgEinfacheFeuerbestattung");

              copyOfStates.showSeeurneAuswahl = true;
              copyOfStates.showSargAuswahl = false;
              copyOfStates.showUrneAuswahl = false;
              copyOfStates.showBiourneAuswahl = false;

              copyOfStates.showSarg = false;
              copyOfStates.showUrne = false;
              copyOfStates.showBiourne = false;

              copyOfStates.isTrauerfeier = false;
              copyOfStates.isBeisetzung = false;
              copyOfStates.showSeebestattung = true;

              copyOfStates.friedhofwahl = "Seefriedhof Ihrer Wahl";
              copyOfStates.angebotArt = "Seebestattung";

              copyOfStates.seeurneDesc = "Krematoriumsurne (Standarturne)";
              copyOfStates.sarg = "Kiefersarg roh - Standartsarg";
              copyOfStates.seeurneImg = "/static/media/2072.d583f3b1.png";
              copyOfStates.friedhofwahl = "Seefriedhof Ihrer Wahl";

                copyOfStates.arrayCalculate[0] = 0;
                copyOfStates.arrayCalculate[1] = 0;
                copyOfStates.arrayCalculate[3] = 0;
                copyOfStates.arrayCalculate[4] =  copyOfStates.seebestattungPreis;
                copyOfStates.arrayCalculate[6] = 0;
                copyOfStates.arrayCalculate[8] = 0;
                copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
                document.getElementById("closeAllBestattungsarten").click();
           break;

           case "friedwald":
              angebotContainer.classList.add("bgFriedwald");
              angebotContainer.classList.remove("bgErd");
              angebotContainer.classList.remove("bgAnonym");
              angebotContainer.classList.remove("bgSee");
              angebotContainer.classList.remove("bgEinfacheFeuerbestattung");

              copyOfStates.showBiourneAuswahl = true;
              copyOfStates.showSeeurneAuswahl = false;
              copyOfStates.showSargAuswahl = false;
              copyOfStates.showUrneAuswahl = false;

              copyOfStates.showSarg = false;
              copyOfStates.showUrne = false;
              copyOfStates.showSeeurne = false;

              copyOfStates.isTrauerfeier = false;
              copyOfStates.isBegleiteteSeebestattung = false;
              copyOfStates.showSeebestattung = false;
              copyOfStates.isBeisetzung = true;

              copyOfStates.friedhofwahl = "Friedhof Ihrer Wahl";
              copyOfStates.angebotArt = "Baumbestattung";

              copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
              copyOfStates.sarg = "Kiefersarg roh - Standartsarg";
              copyOfStates.biourneDesc = "Urne Bio-Linie, Zierband Edelstahl gebürstet(Standarturne)";
              copyOfStates.urneImg = "/static/media/2621.edfb0fca.png";

              copyOfStates.arrayCalculate[0] = 0;
              copyOfStates.arrayCalculate[1] = 0;
              copyOfStates.arrayCalculate[3] = 0;
              copyOfStates.arrayCalculate[4] =  copyOfStates.friedwaldbestattungPreis;
              copyOfStates.arrayCalculate[5] = 0;
              copyOfStates.arrayCalculate[7] = 0;
              copyOfStates.arrayCalculate[8] = 0;
              copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
              document.getElementById("closeAllBestattungsarten").click();
           break;

           default:
           return identChangeBestattungsart;
        }

      return copyOfStates;

      case "CLOSEMODALSAERGE":
        copyOfStates.modalShowSarge = false;
      return copyOfStates;

      case "SHOWMODALSAERGE":
        copyOfStates.modalShowSarge = true;
      return copyOfStates;

      case "CLOSEMODALURNEN":
          copyOfStates.modalShowUrnen = false;
      return copyOfStates;

      case "SHOWMODALURNEN":
         copyOfStates.modalShowUrnen = true;
      return copyOfStates;

      case "CLOSEMODALSEEURNEN":
        copyOfStates.modalSeeurnen = false;
      return copyOfStates;

      case "SHOWSEEURNE":
        copyOfStates.modalSeeurnen = true;
      return copyOfStates;

      case "HIDEMODALBEGLEITETESEEBESTATTUNG":
        copyOfStates.modalShowBegleiteteSeebestattung = false;
      return copyOfStates;

      case "SHOWMODALBEGLEITETESEEBESTATTUNG":
        copyOfStates.modalShowBegleiteteSeebestattung = true;
      return copyOfStates;

      case "UPDATEBEMERKUNGEN":
       copyOfStates.bemerkungenValue = action.ev.target.value;
      return copyOfStates;

      case "SHOWWRONGMSG":
         copyOfStates.plzIsTyped = true;
      return copyOfStates;

      case "GETBESTATTUNGSART":

          let identBestattungsart = action.ev.target.getAttribute("ident");
          switch(identBestattungsart){
            case "Erdbestattung":
               //copyOfStates.showSarg = true;
               copyOfStates.floristikArray = [];
               copyOfStates.arrayCalculate[2] = 0;
               copyOfStates.arrayCalculate[3] = 0;
               copyOfStates.arrayCalculate[4] = copyOfStates.erdbestattungPreis;
               copyOfStates.arrayCalculate[5] = 0;
               copyOfStates.arrayCalculate[6] = 0;
               copyOfStates.arrayCalculate[7] = 0;
               copyOfStates.arrayCalculate[8] = 0;
               copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);
               copyOfStates.showSargAuswahl = true;
               copyOfStates.showUrneAuswahl = false;
               copyOfStates.showSeeurneAuswahl = false;
               copyOfStates.showBiourneAuswahl = false;

               copyOfStates.showUrne = false;
               copyOfStates.showBiourne = false;
               copyOfStates.showSeeurne = false;

               copyOfStates.isBegleiteteSeebestattung = false;
               copyOfStates.isBeisetzung = true;

               copyOfStates.friedhofwahl = "";
               copyOfStates.angebotArt = "Erdbestattung";

               break;
            case "Seebestattung":
                copyOfStates.arrayCalculate[0] = 0;
                copyOfStates.arrayCalculate[1] = 0;
                copyOfStates.floristikArray = [];
                copyOfStates.arrayCalculate[2] = 0;
                copyOfStates.arrayCalculate[3] = 0;
                copyOfStates.arrayCalculate[4] =  copyOfStates.seebestattungPreis;
                copyOfStates.arrayCalculate[6] = 0;
                copyOfStates.arrayCalculate[8] = 0;
                copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);

                copyOfStates.showSeeurneAuswahl = true;
                copyOfStates.showSargAuswahl = false;
                copyOfStates.showUrneAuswahl = false;
                copyOfStates.showBiourneAuswahl = false;

                copyOfStates.showSarg = false;
                copyOfStates.showUrne = false;
                copyOfStates.showBiourne = false;

                copyOfStates.isBeisetzung = false;

                copyOfStates.isTrauerfeier = false;
                copyOfStates.friedhofwahl = "Seefriedhof Ihrer Wahl";
                copyOfStates.angebotArt = "Seebestattung";
              break;

            case "Einfache Feuerbestattung":
                copyOfStates.arrayCalculate[0] = 0;
                copyOfStates.arrayCalculate[1] = 0;
                copyOfStates.floristikArray = [];
                copyOfStates.arrayCalculate[2] = 0;
                copyOfStates.arrayCalculate[4] = copyOfStates.einfache_feuerbestattungPreis;
                copyOfStates.arrayCalculate[5] = 0;
                copyOfStates.arrayCalculate[6] = 0;
                copyOfStates.arrayCalculate[7] = 0;
                copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);

                copyOfStates.showUrneAuswahl = true;
                copyOfStates.showSargAuswahl = false;
                copyOfStates.showSeeurneAuswahl = false;
                copyOfStates.showBiourneAuswahl = false;

                copyOfStates.showSarg = false;
                copyOfStates.showBiourne = false;
                copyOfStates.showSeeurne = false;

                copyOfStates.isTrauerfeier = false;
                copyOfStates.isBegleiteteSeebestattung = false;

                copyOfStates.isBeisetzung = true;
                copyOfStates.friedhofwahl = "Friedhof Ihrer Wahl";
                copyOfStates.angebotArt = "Einfache Feuerbestattung";

            break;
            case "Anonyme Bestattung":
                copyOfStates.arrayCalculate[0] = 0;
                copyOfStates.arrayCalculate[1] = 0;
                copyOfStates.floristikArray = [];
                copyOfStates.arrayCalculate[2] = 0;
                copyOfStates.arrayCalculate[4] = copyOfStates.anonymebestattungPreis;
                copyOfStates.arrayCalculate[5] = 0;
                copyOfStates.arrayCalculate[6] = 0;
                copyOfStates.arrayCalculate[7] = 0;
                //copyOfStates.arrayCalculate[8] = 280;
                copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);

                copyOfStates.showUrneAuswahl = true;
                copyOfStates.showSargAuswahl = false;
                copyOfStates.showSeeurneAuswahl = false;
                copyOfStates.showBiourneAuswahl = false;

                copyOfStates.showSarg = false;
                copyOfStates.showBiourne = false;
                copyOfStates.showSeeurne = false;

                copyOfStates.isTrauerfeier = false;
                copyOfStates.isBegleiteteSeebestattung = false;

                copyOfStates.isBeisetzung = true;
                copyOfStates.friedhofwahl = "Friedhof unserer Wahl";
                copyOfStates.angebotArt = "Anonyme Bestattung";
              break;
            case "Baumbestattung":
                copyOfStates.arrayCalculate[0] = 0;
                copyOfStates.arrayCalculate[1] = 0;
                copyOfStates.floristikArray = [];
                copyOfStates.arrayCalculate[2] = 0;
                copyOfStates.arrayCalculate[3] = 0;
                copyOfStates.arrayCalculate[4] =  copyOfStates.friedwaldbestattungPreis;
                copyOfStates.arrayCalculate[5] = 0;
                copyOfStates.arrayCalculate[7] = 0;
                copyOfStates.arrayCalculate[8] = 0;
                copyOfStates.total_price = copyOfStates.arrayCalculate.reduce((a, b) => a + b, 0).toFixed(2);

              copyOfStates.showBiourneAuswahl = true;
              copyOfStates.showSeeurneAuswahl = false;
              copyOfStates.showSargAuswahl = false;
              copyOfStates.showUrneAuswahl = false;

              copyOfStates.showSarg = false;
              copyOfStates.showUrne = false;
              copyOfStates.showSeeurne = false;

              copyOfStates.isTrauerfeier = false;
              copyOfStates.isBegleiteteSeebestattung = false;

              copyOfStates.isBeisetzung = true;
              copyOfStates.friedhofwahl = "Friedhof Ihrer Wahl";
              copyOfStates.angebotArt = "Baumbestattung";
              break;

            default:
              return ;
          }
      return copyOfStates;


      case "SHOWPDFANGEBOT":
         action.ev.preventDefault();

         //benötigte Unterlagen
         var unterlagen = "";
      if(copyOfStates.maritalStatus !== ""){
         switch(copyOfStates.maritalStatus){
            case "ledig":
            unterlagen = "Personalausweis, Geburtsurkunde, Krankenversicherungskarte, ggf. Rentennummer";
            break;
            case "verheiratet":
            unterlagen = "Personalausweis, Geburtsurkunde, Krankenversicherungskarte, ggf. Rentennummer, Heiratsurkunde oder Familienstammbuch";
            break;
            case "verwitwet":
            unterlagen = "Personalausweis, Geburtsurkunde, Krankenversicherungskarte, ggf. Rentennummer, Heiratsurkunde oder Familienstammbuch, Sterbeurkunde des Ehepartners";
            break;
            case "geschieden":
            unterlagen = "Personalausweis, Geburtsurkunde, Krankenversicherungskarte, ggf. Rentennummer, Heiratsurkunde oder Familienstammbuch, Scheidungsurteil";
            break;
            default:
              return copyOfStates.maritalStatus;
         }
        }


         var russianDoc = "";
         if(copyOfStates.isGerman){
          russianDoc = "";
         }
         else{
          var space = " ";
          russianDoc = `, Vertriebenenausweiß, Registrierschein,  ${space}  § 94 oder Namensänderung für Verstorbenen und Ehegatten`;
         }

          var pdf = new jsPDF("p", "mm", "a4");

          pdf.addImage(logoImg, 'SVG',  90, 7);
          pdf.setFontSize(20);            // Schriftgröße
          pdf.setFont("times")
          pdf.setTextColor(0, 0, 0);    // Textfarbe als RGB-Werte
          //pdf.text ("Angebot", 93, 55);    // Text platzieren
          pdf.setFontSize(17);
          pdf.text (`${copyOfStates.bestattungsartAngebot} in ${copyOfStates.cityAreay}`, 20, 60);
          pdf.text (`Gesamtpreis: ${copyOfStates.bestattungsPreisAngebot.toFixed(2)} EUR`, 20, 67);

          pdf.setFontSize(12);
          /* datum */
          var datum = new Date();
          var day = datum.getDate();
          var month = datum.getMonth();
          var hours = datum.getHours();
          var minutes = (datum.getMinutes()<10?'0':'') + datum.getMinutes();
          month = month +1;
          var year = datum.getFullYear();
          pdf.text (`Sehr ${copyOfStates.anrede} ${copyOfStates.male} ${copyOfStates.name },`, 20, 80);
          pdf.text (`wir danken Ihnen für Ihre Anfrage vom ${day}.${month}.${year} und bieten Ihnen die von Ihnen`, 20, 88);
          pdf.text ("genannten Leistungen wie folgt an:", 20, 93);

          /* leistungen */
          var source = document.querySelector(".leistungsAngebotBox");
          var liAngebot = document.querySelectorAll(".liAngebot");
          for(let i = 0; i < liAngebot.length; i++){
            liAngebot[i].style.fontSize = "13px";
            liAngebot[i].style.lineHeight = "0.5";
          }

          pdf.fromHTML(
            source, // HTML string or DOM elem ref.
            18, // x coord
            100, {
              // y coord
             // max width of content on PDF
            },
          );

          pdf.setFontSize(10);
          copyOfStates.showSargAuswahl && pdf.text("Die Kosten des Friedhofs sind in diesem Angebot nicht erhalten.", 20, 220);
          /* Anfallende Fremdleistungen... */
          pdf.text("Anfallende Fremdleistungen (z. B. Gebühren im Krankenhaus und Standesamt) werden Ihnen in tatsächlich entstandener", 20, 225);
          pdf.text("Höhe weiter berechnet. Bei Abholung außerhalb eines Krankenhauses (z. B. Pflegeheim, Hospitz, zu Hause", 20, 230);
          pdf.text("fällt ein Zuschlag von 120,00 EUR an.", 20, 235);
          /* Sollten nicht innerhalb von.. */
          pdf.text("Sollten nicht innerhalb von 3 Tagen alle für die Beurkundung des Sterbefalls notwendigen Dokumente vorliegen,", 20, 245);
          pdf.text("werden folgende Gebühren berechnet: ", 20, 250);
          pdf.text("Bearbeitungsgebühr 55,00 EUR für jedes notwendige Dokument zusätzliche Gebühren des Standesamtes.", 20, 255);

            /* Ort, Datum und Unterschrift */
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineWidth(.3);
            pdf.line(20, 270, 80, 270);
            pdf.line(100, 270, 160, 270);
            pdf.setFontSize(12);
            pdf.text ("Ort, Datum", 20, 275);
            pdf.text ("Unterschrift", 100, 275);

            /* senden an email */
            pdf.setFontSize(12);
            pdf.text("Bitte senden sie das Angebot mit der Vollmacht an diese Email: info@sidereum-bestattungen.de", 20, 283);

            pdf.setFontSize(10);
            /*  Seitenanzahl */
            pdf.text("Seite 1/2",  95, 290);
            pdf.setFontSize(8);
            /* Zeitstempel */
            pdf.text(`Erstellt am ${day}.${month}.${year} um ${hours}:${minutes} Uhr`, 80, 294);

          /* neue Seite vollmacht */
          pdf.addPage();
          //pdf.addImage(this.state.vollmachtImg, 'JPG',  12, 0);
          pdf.addImage(logoImg, 'SVG',  90, 7);
          pdf.setFontSize(20);
          pdf.setFont("times")
          //pdf.setFontType("bold")
          //pdf.setTextColor(79, 61, 63);
          pdf.text ("Vollmacht", 92, 55);

          //pdf.setTextColor(0, 0, 0);
          pdf.setFontSize(15);
          pdf.text ("Bestattungspflichtige(r)", 20, 67);

          var vollmachtDataPflichtigerLinks =
                               `<p>Name: ${copyOfStates.name}</p>`+
                               `<p>geboren am: ${copyOfStates.aliveBirthday}</p>`+
                               `<p>Anschrift: ${copyOfStates.aliveAdresse}</p>`+
                               `<p>Geburtsort: ${copyOfStates.aliveBirthplace}</p>`
          var vollmachtDataPflichtigerRechts =
                               `<p>Vorname: ${copyOfStates.vorName}</p>`+
                               `<p>Verwandschaftsverhältnis: ${copyOfStates.verwandschaftsoption}</p>`+
                               `<p>&nbsp;</p>`+
                               `<p>Telefon: ${copyOfStates.alivePhone}</p>`

        pdf.fromHTML(
              vollmachtDataPflichtigerLinks,
              20, // x coord
              67, // margin top coord
              {
                // y coord
                width: 100// max width of content on PDF
              },
            );
        pdf.fromHTML(
              vollmachtDataPflichtigerRechts,
              120, // x coord
              67, // margin top coord
              {
                // y coord
                width: 100// max width of content on PDF
              },
            );
        var text_1 = "Ich der / die Unterzeichner/ in, beauftrage Sidereum Bestattungen, die Beisetzung von:";
        pdf.setFontSize(12);
        pdf.text (text_1, 20, 110);
        pdf.setFontSize(15);
        pdf.text ("Verstorbener", 20, 120);

        var vollmachtDataVerstorbenerLinks =
                                          `<p>Name: ${copyOfStates.deceasedName}</p>`+
                                          `<p>geboren am: ${copyOfStates.deceasedBirthdayFrom}</p>`+
                                          `<p>Letzte Anschrift: ${copyOfStates.deceasedAdresse}</p>`+
                                          `<p>Geburtsort: ${copyOfStates.deceasedBirthplace}</p>`+
                                          `<p>Beruf: ${copyOfStates.deceasedProfession}</p>`+
                                          `<p>Familienstand: ${copyOfStates.maritalStatus}</p>`+
                                          `<p>Krankenkasse: ${copyOfStates.deceasedHealthInsurance}</p>`+
                                          `<p>Benötigte Unterlagen (im Original): ${unterlagen}${russianDoc}</p>`
        var vollmachtDataVerstorbenerRechts =
                                          `<p>Vorname: ${copyOfStates.deceasedVorname}</p>`+
                                          `<p>verstorben am: ${copyOfStates.deceasedBirthdayTo}</p>`+
                                          `<p>Abholanschrift: ${copyOfStates.deceasedCurrentAdresse}</p>`+
                                          `<p>Religion: ${copyOfStates.deceasedReligion}</p>`+
                                          `<p>Kinder: ${copyOfStates.deceasedChildren}</p>`+
                                          `<p>Rentennummer: ${copyOfStates.deceasedPensionNumber}</p>`+
                                          `<p>Anzahl der Sterbeurkunden: ${copyOfStates.deceasedDeathCertificates}</p>`+
                                          `<p>Gewicht / Größe: ${copyOfStates.deceasedHeightWeight}</p>`

        pdf.fromHTML(
              vollmachtDataVerstorbenerLinks,
              20, // x coord
              121, // margin top coord
              {
                // y coord
                width: 90// max width of content on PDF
              },
            );

        pdf.fromHTML(
          vollmachtDataVerstorbenerRechts,
              120, // x coord
              121, // margin top coord
              {
                // y coord
                width: 90// max width of content on PDF
              },
            );

        var text_2 = "Sidereum Bestattungen ist berechtigt, alle notwendigen Schritte zur Beisetzung einzuleiten.";
        var text_3 = "Die Personenstandsurkunden müssen innerhalb von 3 Tagen Sidereum Bestattungen ";
        var text_4 = "oder dem zuständigen Standesamt vorliegen.";
        var text_5 = "Für alle Kosten der Bestattung und der Bestattungsgebühren komme ich im vollen Umfang auf.";
        var text_6 = "Ich habe die AGB zur Kenntnis genommen und akzeptiert.";

        pdf.setFontSize(12);
        pdf.text (text_2, 20, 221);
        pdf.text (text_3, 20, 226);
        pdf.text (text_4, 20, 231);
        pdf.text (text_5, 20, 236);
        pdf.text (text_6, 20, 241);

        /* Ort, Datum und Unterschrift */
        pdf.setLineWidth(.3);
        pdf.line(20, 257, 80, 257);
        pdf.line(100, 257, 160, 257);

        pdf.text ("Ort, Datum", 20, 262);
        pdf.text ("Unterschrift", 100, 262);

        //Anschriftblock
        pdf.setFontSize(9);
        var anschriftBlock1 = "Sidereum Bestattungen UG\nAmtsgericht Rostock\nUst.IdNr: 086/117/03156\nGeschäftsführer: René Walek";
        var anschriftBlock2 = "Anschrift\nSidereum Bestattungen\nLerchenberg 1\n18292 Krakow am See";
        var anschriftBlock3 = "Tel: 0152 22003687\nWeb: www.sidereum-betsattungen.de\nE-Mail: info@sidereum-betsattungen.de";

        pdf.setLineWidth(.2);
        pdf.line(20, 268, 190, 268);
        pdf.text (anschriftBlock1, 20, 273);
        pdf.text (anschriftBlock2, 80, 273);
        pdf.text (anschriftBlock3, 140, 273);

        /*  Seitenanzahl */
        pdf.setFontSize(10);
         /*  Seitenanzahl */
        pdf.text("Seite 2/2",  95, 290);
        pdf.setFontSize(8);
        /* Zeitstempel */
        pdf.text(`Erstellt am ${day}.${month}.${year} um ${hours}:${minutes} Uhr`, 80, 294);


        /* update liAngebot */
        for(let i = 0; i < liAngebot.length; i++){
          liAngebot[i].style.fontSize = "15px";
          liAngebot[i].style.lineHeight = "2";
        }

    if(!copyOfStates.showAngebotWithoutVollmacht){
      var checkboxDatenschutzModal = document.querySelector("#checkboxDatenschutz");
      if(checkboxDatenschutzModal.checked === true){
       copyOfStates.datenschutzIsTrue = true;
       copyOfStates.datenschutzFailMessage = false;
       copyOfStates.showAngebotWithoutVollmacht = true;

      }
      else{
       copyOfStates.datenschutzFailMessage = true;
      }
    }


    if(copyOfStates.datenschutzIsTrue){
          copyOfStates.showAngebotModal = false;
          pdf.save('Angebot.pdf');
    }

    return copyOfStates;

      case "SHOWPDFANGEBOTINDIVIDUELL":
         action.ev.preventDefault();
         let identleistung = action.ev.target.getAttribute("identleistung");
         let identBestattungsPreis = action.ev.target.getAttribute("identpreis");
         identBestattungsPreis = Number(identBestattungsPreis);
         //let bestattungsArt = action.ev.target.getAttribute("art");
         let titel = action.ev.target.getAttribute("titel");
         let titelArea = action.ev.target.getAttribute("titelarea");
         //let ulLeistungen = action.ev.target.getAttribute("leistungen");

        var unterlagen2 = "";
        if(copyOfStates.maritalStatus !== ""){
         switch(copyOfStates.maritalStatus){
            case "ledig":
            unterlagen2 = "Personalausweis, Geburtsurkunde, Krankenversicherungskarte, ggf. Rentennummer";
            break;
            case "verheiratet":
            unterlagen2 = "Personalausweis, Geburtsurkunde, Krankenversicherungskarte, ggf. Rentennummer, Heiratsurkunde oder Familienstammbuch";
            break;
            case "verwitwet":
            unterlagen2 = "Personalausweis, Geburtsurkunde, Krankenversicherungskarte, ggf. Rentennummer, Heiratsurkunde oder Familienstammbuch, Sterbeurkunde des Ehepartners";
            break;
            case "geschieden":
            unterlagen2 = "Personalausweis, Geburtsurkunde, Krankenversicherungskarte, ggf. Rentennummer, Heiratsurkunde oder Familienstammbuch, Scheidungsurteil";
            break;
            default:
              return copyOfStates.maritalStatus;
         }
        }

         var russianDoc2 = "";
         if(copyOfStates.isGerman){
          russianDoc = "";
         }
         else{
          var space2 = " ";
          russianDoc2 = `, Vertriebenenausweiß, Registrierschein,  ${space2}  § 94 oder Namensänderung für Verstorbenen und Ehegatten`;
         }

          var pdf2 = new jsPDF("p", "mm", "a4");

          pdf2.addImage(logoImg, 'SVG',  90, 7);
          pdf2.setFontSize(20);                          // Schriftgröße
          pdf2.setFont("times")
          pdf2.setTextColor(79, 61, 63);    // Textfarbe als RGB-Werte
          pdf2.setFontType("bold");
          pdf2.setFontSize(13);
          pdf2.text(titel, 20, 57);
          pdf2.text(titelArea, 20, 62);
          pdf2.setFontSize(15);
          pdf2.text (`Zum Preis von: ${identBestattungsPreis.toFixed(2)} EUR`, 20, 70);
          pdf2.setFontType("normal");

          pdf2.setFontSize(12);
          pdf2.setTextColor(0, 0, 0);
          /* datum */
          var datum2 = new Date();
          var day2 = datum2.getDate();
          var month2 = datum2.getMonth();
          var hours2 = datum2.getHours();
          var minutes2 = (datum2.getMinutes()<10?'0':'') + datum2.getMinutes();
          month2 = month2 +1;
          var year2 = datum.getFullYear();
          pdf.text (`Sehr ${copyOfStates.anrede} ${copyOfStates.male} ${copyOfStates.name },`, 20, 80);
          pdf.text (`wir danken Ihnen für Ihre Anfrage vom ${day2}.${month2}.${year} und bieten Ihnen die von Ihnen`, 20, 88);
          pdf.text ("genannten Leistungen wie folgt an:", 20, 93);

          /* close button */
          let closeButtonsAngebot = document.querySelectorAll(".closeButtonAngebot");
          for(let i = 0; i < closeButtonsAngebot.length; i++){
            closeButtonsAngebot[i].style.color = "white";
          }
          let closeButtonAngebot = document.querySelector(".closeButtonAngebot");
          if(closeButtonAngebot){
            closeButtonAngebot.style.color = "white";
          }

          /* leistungen */
          var source2 = document.getElementById(`${identleistung}`);
          var liAngebot2 = document.querySelectorAll(".liAngebot");
          for(let i = 0; i < liAngebot2.length; i++){
            liAngebot2[i].style.fontSize = "13px";
            liAngebot2[i].style.lineHeight = "0.5";
            liAngebot2[i].style.color = "black";
          }

          pdf2.fromHTML(
            source2, // HTML string or DOM elem ref.
            18, // x coord
            100, {
              // y coord
             // max width of content on PDF
            },
          );

          pdf2.setFontSize(10);
         copyOfStates.iskostenFriedhof && pdf.text("Die Kosten des Friedhofs sind in diesem Angebot nicht erhalten.", 20, 220);
          /* Anfallende Fremdleistungen... */
          pdf2.text("Anfallende Fremdleistungen (z. B. Gebühren im Krankenhaus und Standesamt) werden Ihnen in tatsächlich entstandener", 20, 225);
          pdf.text("Höhe weiter berechnet. Bei Abholung außerhalb eines Krankenhauses (z. B. Pflegeheim, Hospitz, zu Hause", 20, 230);
          pdf2.text("fällt ein Zuschlag von 120,00 EUR an.", 20, 235);
          /* Sollten nicht innerhalb von.. */
          pdf2.text("Sollten nicht innerhalb von 3 Tagen alle für die Beurkundung des Sterbefalls notwendigen Dokumente vorliegen,", 20, 245);
          pdf2.text("werden folgende Gebühren berechnet: ", 20, 250);
          pdf2.text("Bearbeitungsgebühr 55,00 EUR für jedes notwendige Dokument zusätzliche Gebühren des Standesamtes.", 20, 255);

            /* Ort, Datum und Unterschrift */
            pdf2.setDrawColor(0, 0, 0);
            pdf2.setLineWidth(.3);
            pdf2.line(20, 270, 80, 270);
            pdf2.line(100, 270, 160, 270);
            pdf2.setFontSize(12);
            pdf2.text ("Ort, Datum", 20, 275);
            pdf2.text ("Unterschrift", 100, 275);

            /* senden an email */
            pdf2.setFontSize(12);
            pdf2.setFontType("bold");
            pdf2.text("Bitte senden sie das Angebot mit der Vollmacht an diese Email: info@sidereum-bestattungen.de", 20, 283);
            pdf2.setFontType("normal");

            pdf2.setFontSize(10);
            /*  Seitenanzahl */
            pdf2.text("Seite 1/2",  95, 290);
            pdf2.setFontSize(8);
            /* Zeitstempel */
            pdf2.text(`Erstellt am ${day2}.${month2}.${year2} um ${hours2}:${minutes2} Uhr`, 80, 294);

           /* neue Seite vollmacht */
           pdf2.addPage();
           //pdf.addImage(this.state.vollmachtImg, 'JPG',  12, 0);
           pdf2.addImage(logoImg, 'SVG',  90, 7);
           pdf2.setFontSize(20);
           pdf2.setFont("times")
           //pdf.setFontType("bold")
           pdf2.setTextColor(79, 61, 63);
           pdf2.text ("Vollmacht", 92, 55);
           pdf2.setFontSize(15);
           pdf2.setFontType("bold");
           pdf2.text ("Bestattungspflichtige(r)", 20, 67);
           pdf2.setFontType("normal");
           pdf2.setTextColor(0, 0, 0);

           var vollmachtDataPflichtigerLinks2 =
                                `<p>Name: ${copyOfStates.name}</p>`+
                                `<p>geboren am: ${copyOfStates.aliveBirthday}</p>`+
                                `<p>Anschrift: ${copyOfStates.aliveAdresse}</p>`+
                                `<p>Geburtsort: ${copyOfStates.aliveBirthplace}</p>`
           var vollmachtDataPflichtigerRechts2 =
                                `<p>Vorname: ${copyOfStates.vorName}</p>`+
                                `<p>Verwandschaftsverhältnis: ${copyOfStates.verwandschaftsoption}</p>`+
                                `<p>&nbsp;</p>`+
                                `<p>Telefon: ${copyOfStates.alivePhone}</p>`

         pdf2.fromHTML(
               vollmachtDataPflichtigerLinks2,
               20, // x coord
               67, // margin top coord
               {
                 // y coord
                 width: 100// max width of content on PDF
               },
             );
         pdf2.fromHTML(
               vollmachtDataPflichtigerRechts2,
               120, // x coord
               67, // margin top coord
               {
                 // y coord
                 width: 100// max width of content on PDF
               },
             );
         var text_1_1 = "Ich der / die Unterzeichner/ in, beauftrage Sidereum Bestattungen, die Beisetzung von:";
         pdf2.setFontSize(12);
         pdf2.text (text_1_1, 20, 110);
         pdf2.setFontSize(15);
         pdf2.setTextColor(79, 61, 63);
         pdf2.setFontType("bold");
         pdf2.text ("Verstorbener", 20, 120);
         pdf2.setFontType("normal");
         pdf2.setTextColor(0, 0, 0);
         var vollmachtDataVerstorbenerLinks2 =
                                           `<p>Name: ${copyOfStates.deceasedName}</p>`+
                                           `<p>geboren am: ${copyOfStates.deceasedBirthdayFrom}</p>`+
                                           `<p>Letzte Anschrift: ${copyOfStates.deceasedAdresse}</p>`+
                                           `<p>Geburtsort: ${copyOfStates.deceasedBirthplace}</p>`+
                                           `<p>Beruf: ${copyOfStates.deceasedProfession}</p>`+
                                           `<p>Familienstand: ${copyOfStates.maritalStatus}</p>`+
                                           `<p>Krankenkasse: ${copyOfStates.deceasedHealthInsurance}</p>`+
                                           `<p>Benötigte Unterlagen (im Original): ${unterlagen2}${russianDoc2}</p>`
         var vollmachtDataVerstorbenerRechts2 =
                                           `<p>Vorname: ${copyOfStates.deceasedVorname}</p>`+
                                           `<p>verstorben am: ${copyOfStates.deceasedBirthdayTo}</p>`+
                                           `<p>Abholanschrift: ${copyOfStates.deceasedCurrentAdresse}</p>`+
                                           `<p>Religion: ${copyOfStates.deceasedReligion}</p>`+
                                           `<p>Kinder: ${copyOfStates.deceasedChildren}</p>`+
                                           `<p>Rentennummer: ${copyOfStates.deceasedPensionNumber}</p>`+
                                           `<p>Anzahl der Sterbeurkunden: ${copyOfStates.deceasedDeathCertificates}</p>`+
                                           `<p>Gewicht / Größe: ${copyOfStates.deceasedHeightWeight}</p>`

         pdf2.fromHTML(
               vollmachtDataVerstorbenerLinks2,
               20, // x coord
               121, // margin top coord
               {
                 // y coord
                 width: 90// max width of content on PDF
               },
             );

         pdf2.fromHTML(
           vollmachtDataVerstorbenerRechts2,
               120, // x coord
               121, // margin top coord
               {
                 // y coord
                 width: 90// max width of content on PDF
               },
             );

         var text_2_2 = "Sidereum Bestattungen ist berechtigt, alle notwendigen Schritte zur Beisetzung einzuleiten.";
         var text_3_3 = "Die Personenstandsurkunden müssen innerhalb von 3 Tagen Sidereum Bestattungen ";
         var text_4_4 = "oder dem zuständigen Standesamt vorliegen.";
         var text_5_5 = "Für alle Kosten der Bestattung und der Bestattungsgebühren komme ich im vollen Umfang auf.";
         var text_6_6 = "Ich habe die AGB zur Kenntnis genommen und akzeptiert.";

         pdf2.setFontSize(12);
         pdf2.text (text_2_2, 20, 221);
         pdf2.text (text_3_3, 20, 226);
         pdf2.text (text_4_4, 20, 231);
         pdf2.text (text_5_5, 20, 236);
         pdf2.text (text_6_6, 20, 241);

         /* Ort, Datum und Unterschrift */
         pdf2.setLineWidth(.3);
         pdf2.line(20, 257, 80, 257);
         pdf2.line(100, 257, 160, 257);

         pdf2.text ("Ort, Datum", 20, 262);
         pdf2.text ("Unterschrift", 100, 262);

         pdf2.setFontSize(9);
         var anschriftBlock1_1 = "Sidereum Bestattungen UG\nAmtsgericht Rostock\nUst.IdNr: 086/117/03156\nGeschäftsführer: René Walek";
         var anschriftBlock2_2 = "Anschrift\nSidereum Bestattungen\nLerchenberg 1\n18292 Krakow am See";
         var anschriftBlock3_3 = "Tel: 0152 22003687\nWeb: www.sidereum-betsattungen.de\nE-Mail: info@sidereum-betsattungen.de";

         pdf2.setLineWidth(.2);
         pdf2.line(20, 268, 190, 268);
         pdf2.text (anschriftBlock1_1, 20, 273);
         pdf2.text (anschriftBlock2_2, 80, 273);
         pdf2.text (anschriftBlock3_3, 140, 273);

         /*  Seitenanzahl */
         pdf2.setFontSize(10);
          /*  Seitenanzahl */
         pdf2.text("Seite 2/2",  95, 290);
         pdf2.setFontSize(8);
         /* Zeitstempel */
         pdf2.text(`Erstellt am ${day2}.${month2}.${year2} um ${hours2}:${minutes2} Uhr`, 80, 294);


    if(!copyOfStates.showAngebotWithoutVollmacht){
      let checkboxDatenschutzModal = document.querySelector("#checkboxDatenschutz");
      if(checkboxDatenschutzModal.checked === true){
       copyOfStates.datenschutzIsTrue = true;
       copyOfStates.datenschutzFailMessage = false;
       copyOfStates.showAngebotWithoutVollmacht = true;

      }
      else{
       copyOfStates.datenschutzFailMessage = true;
      }
    }

      if(copyOfStates.datenschutzIsTrue){
        let closeIndAngebot = document.getElementById("closeModalVollmacht");
          //copyOfStates.showAngebotModal = false;
          pdf.save('Angebot.pdf');
          closeIndAngebot.click();
      }

      /* update close button */
      for(let i = 0; i < closeButtonsAngebot.length; i++){
        closeButtonsAngebot[i].style.color = "red";
      }
      if(closeButtonAngebot){
        closeButtonAngebot.style.color = "red"
      }


      /* update liAngebot */
      for(let i = 0; i < liAngebot2.length; i++){
          liAngebot2[i].style.fontSize = "15px";
          liAngebot2[i].style.lineHeight = "2";
          liAngebot2[i].style.color = "#4F3D3F";
      }

      copyOfStates.iskostenFriedhof = false;

      return copyOfStates;

      case "CLOSEANGEBOTMODAL":
        copyOfStates.showAngebotModal = false;
      return copyOfStates;

      case "SHOWANGEBOTMODAL":
          copyOfStates.showAngebotModal = true;
      return copyOfStates;

      case "SETANGEBOTARTTOERD":
        copyOfStates.angebotArt = "Erdbestattung";
        copyOfStates.showSeebestattung = false;
      return copyOfStates;

      case "SETANGEBOTARTTOSEE":
        copyOfStates.angebotArt = "Seebestattung";
        copyOfStates.showSeebestattung = true;
      return copyOfStates;

      case "SETANGEBOTARTTOEFEUER":
        copyOfStates.angebotArt = "Einfache Feuerbestattung";
        copyOfStates.showSeebestattung = false;
      return copyOfStates;

      case "SETANGEBOTARTTOFRIED":
        copyOfStates.angebotArt = "Baumbestattung";
        copyOfStates.showSeebestattung = false;
      return copyOfStates;

      case "SETANGEBOTARTTOANONYM":
          copyOfStates.angebotArt = "Anonyme Bestattung";
          copyOfStates.showSeebestattung = false;
        return copyOfStates;



      case "UPDATETRAUERFALLSTATUS":
          copyOfStates.search = "";
          var trauerfallstatus = action.ev.target.value;
          copyOfStates.trauerfall = trauerfallstatus;
          copyOfStates.isTrauerfall = false;

          /*
          if(trauerfallstatus === "Zu Hause / Hospiz / Seniorenheim oder Palliativstation"){
            copyOfStates.trauerfallGeb = 120;
          }
          else if (trauerfallstatus === "Im Krankenhaus"){
            if(copyOfStates.trauerfallGeb === 120){
              copyOfStates.trauerfallGeb = -120;
            }
          }

          console.log("hello world")

               let trauerfallGeb = copyOfStates.trauerfallGeb;
                //einfache feuerbestattung
                copyOfStates.einfache_feuerbestattungPreis =  copyOfStates.einfache_feuerbestattungPreis + trauerfallGeb;
                //seebestattungPreis
                copyOfStates.seebestattungPreis = copyOfStates.seebestattungPreis + trauerfallGeb;
                //erdbestattungPreis
                copyOfStates.erdbestattungPreis = copyOfStates.erdbestattungPreis + trauerfallGeb;
                //anonymebestattungPreis
                copyOfStates.anonymebestattungPreis = copyOfStates.anonymebestattungPreis + trauerfallGeb;
                //friedwaldbestattungPreis
                copyOfStates.friedwaldbestattungPreis = copyOfStates.friedwaldbestattungPreis + trauerfallGeb;
    */
        return copyOfStates;

        case "CHANGEBESTATTUNGANGEBOT":
            let bestatungsart = action.ev.target.value;
            copyOfStates.bestattungsartAngebot = bestatungsart;

            switch(bestatungsart){
              case "Seebestattung":
                copyOfStates.friedhofart = "Seefriedhof Ihrer Wahl";
                break;
            case "Erdbestattung":
                  copyOfStates.friedhofart = "";
                  break;
            case "Anonyme Bestattung":
                copyOfStates.friedhofart = "Friedhof unserer Wahl";
                break;
            case "Baumbestattung":
                copyOfStates.friedhofart = "Friedhof Ihrer Wahl";
                break;
            case "Einfache Feuerbestattung":
                copyOfStates.friedhofart = "Friedhof Ihrer Wahl";
                break;
               default:
                 return bestatungsart
            }

         return copyOfStates;

         case "CHANGEBESTATTUNGANGEBOTIMG":
         let index = action.ev.target.getAttribute("index");
         let btnAuswahl = document.querySelectorAll(".btnAuswahl");
         for(let i = 0; i < btnAuswahl.length; i++){
          btnAuswahl[i].innerHTML = "Auswählen";
          btnAuswahl[i].classList.remove("btnChoosen");
          }
          btnAuswahl[index].innerHTML = "Ausgewählt";
          btnAuswahl[index].classList.add("btnChoosen");

          let bestatungsartImg = action.ev.target.getAttribute("ident");
          copyOfStates.bestattungsartAngebot = bestatungsartImg;
          switch(bestatungsartImg){
            case "Seebestattung":
              copyOfStates.friedhofart = "Seefriedhof Ihrer Wahl";
              break;
          case "Erdbestattung":
                copyOfStates.friedhofart = "";
                break;
          case "Anonyme Bestattung":
              copyOfStates.friedhofart = "Friedhof unserer Wahl";
              break;
          case "Baumbestattung":
              copyOfStates.friedhofart = "Friedhof Ihrer Wahl";
              break;
          case "Einfache Feuerbestattung":
              copyOfStates.friedhofart = "Friedhof Ihrer Wahl";
              break;
             default:
               return bestatungsartImg
          }

       return copyOfStates;

      case "SUCCESSPLZTOFALSE":
          copyOfStates.successPlz = false;
      return copyOfStates;

      case "GETCURRENLOCATION":
        function getLocation() {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
          } else {

          }
        }

        function showPosition(position) {
          copyOfStates.isDataLoad = false;
          let getCurrentDistance = (origin, destination) => {
            // return distance in meters
             let lon1 = toRadian(origin[1]),
                  lat1 = toRadian(origin[0]),
                  lon2 = toRadian(destination[1]),
                  lat2 = toRadian(destination[0]);

              let deltaLat = lat2 - lat1;
              let deltaLon = lon2 - lon1;

              let a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
              let c = 2 * Math.asin(Math.sqrt(a));
              let EARTH_RADIUS = 6371;
              return c * EARTH_RADIUS * 1000;

              function toRadian(degree) {
                  return degree*Math.PI/180;
            }

          }
         let langLong = [position.coords.latitude, position.coords.longitude];
          let companyPosition = [53.6313508, 12.2646804];
          console.log('langLong', langLong);
          let distance = getCurrentDistance(companyPosition, langLong);
          distance = (distance / 1000).toFixed(0);
          console.log("newDistance", distance);

          if(distance < 65){
            copyOfStates.extendAreaPrice = 0;

          }

          if(distance > 65){
            copyOfStates.extendAreaPrice = 101;
          }

          if(distance > 170 ){
            copyOfStates.extendAreaPrice = 250;
          }

          if(distance > 260){
            copyOfStates.extendAreaPrice = 750;
          }

          if(distance > 320){
            copyOfStates.extendAreaPrice = 1333;
          }

          if(distance > 450){
            copyOfStates.extendAreaPrice = 3000;
          }

        }

        getLocation();

      return copyOfStates;

      case "HANDLESELECTSUGGEST":
        let data = action.ev;

        if(action.ev.place === ""){
          let doPlaceToTrue  = document.getElementById("doPlaceToTrue");
          doPlaceToTrue.click();
          return copyOfStates;
        }

        else if(action.ev.place !== "") {
            let doPlaceToFalse = document.getElementById("doPlaceToFalse");
            doPlaceToFalse.click();
        }

        let latitude = "";
        let longitude = "";

  try{
        let getCurrentDistance = Function;
        let new_price = 0;

        console.log("data", data);

      longitude = data.lon;
      latitude =  data.lat;

      console.log("longitude -->", longitude);
      console.log("latitude -->",  latitude);

        getCurrentDistance = (origin, destination) => {
          // return distance in meters
          var lon1 = toRadian(origin[1]),
              lat1 = toRadian(origin[0]),
              lon2 = toRadian(destination[1]),
              lat2 = toRadian(destination[0]);

          var deltaLat = lat2 - lat1;
          var deltaLon = lon2 - lon1;

          var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
          var c = 2 * Math.asin(Math.sqrt(a));
          var EARTH_RADIUS = 6371;
          return c * EARTH_RADIUS * 1000;

          function toRadian(degree) {
              return degree*Math.PI/180;
        }}

        let langLong = [latitude, longitude];
        var companyPosition = [53.6313508, 12.2646804];

        var distance = getCurrentDistance(companyPosition, langLong);
        distance = (distance / 1000).toFixed(0);
        console.log("newDistance", distance);

          copyOfStates.successPlz = true;

          copyOfStates.rightPlzButton = true;
          copyOfStates.plzIsTyped = false;

          let address = data.place;

          console.log('City', address);
          copyOfStates.plzCity = address;
          copyOfStates.place = address;

          if(distance < 65)copyOfStates.extendAreaPrice = 0;
          if(distance > 65)copyOfStates.extendAreaPrice = 100;
          if(distance > 170)copyOfStates.extendAreaPrice = 250;
          if(distance > 260)copyOfStates.extendAreaPrice = 750;
          if(distance > 320)copyOfStates.extendAreaPrice = 1333;
          if(distance > 450)copyOfStates.extendAreaPrice = 3000;

          //destination_price
          copyOfStates.destination_price = new_price;
          //end destination_price
          copyOfStates.arrayCalculate[4] = copyOfStates.destination_price;
          copyOfStates.rightPlzButton = true;
          copyOfStates.goHome = false;
          let closePostcode = document.getElementById("closePostcode");
          let closePostcodeHome = document.getElementById("closePostcodeHome");
          let updateLink = document.getElementById("updateLink");
          let updateLinkNavbar = document.getElementById("updateLinkNavbar");

          if(document.querySelector("#scrollToAngebot")){
              document.querySelector("#scrollToAngebot").click();
          }

          if(closePostcodeHome){
            closePostcodeHome.click();
          }
          if(closePostcode){
            closePostcode.click();
          }

          setTimeout(() => {
            if(updateLinkNavbar){
              updateLinkNavbar.click();
            }
            if(updateLink){
              updateLink.click();
            }

          }, 200)


      }

          catch (error){

          console.log("longitude -->", longitude);
          console.log("latitude -->", latitude);
            console.log("error", error);
            document.getElementById("doPlaceToTrue").click();
          }

    return copyOfStates;

      case "UPDATEPLZ":
        copyOfStates.plz = action.ev.target.value;
        copyOfStates.search = action.ev.target.value;


      return copyOfStates;


    case "ISKOSTENFRIEDHOF":
      copyOfStates.iskostenFriedhof = true;
    return copyOfStates;


     default:
     return state;

   }

}

//update kosten friedhof
export const iskostenfriedhof = () => {
   return{
      type: "ISKOSTENFRIEDHOF"
   }
}

//change angebot bestattungsart
export const changeAngebotBestattungsart = ev => {
   return{
     type: "CHANGEBESTATTUNGANGEBOT",
     ev: ev
   }
}

//change angebot img
export const changeAngebotImg = ev => {
  return{
    type: "CHANGEBESTATTUNGANGEBOTIMG",
    ev: ev
  }
}

//get data from google
export const handleSelectSuggest = ev => {
    return{
      type: "HANDLESELECTSUGGEST",
      ev: ev
    }
}

//get geolocation from leatflet
export const getCurrentLocation = () => {
    return {
        type: "GETCURRENLOCATION"
    }
}


//set angebotArt to anonyme Bestattung
export const setangebotArtToAnonym = () => {
  return{
    type: "SETANGEBOTARTTOANONYM"
  }
}

//set angebotArt to Friedwaldbestattung
export const setangebotArtToFried = () => {
  return{
    type: "SETANGEBOTARTTOFRIED"
  }
}

//set angebotArt to einfache Feuerbestattung
export const setangebotArtToEFeuer = () => {
  return{
    type: "SETANGEBOTARTTOEFEUER"
  }
}

//set angebotArt to Seebestattung
export const setangebotArtToSee = () => {
  return{
    type: "SETANGEBOTARTTOSEE"
  }
}

//set angebotArt to Erdbestattung
export const setangebotArtToErd = () => {
   return{
     type: "SETANGEBOTARTTOERD"
   }
}

//show angebot modal
export const showAngebotModalIndividuell = ev => {
  return{
     type: "SHOWPDFANGEBOTINDIVIDUELL",
     ev: ev
  }
}

//show angebot modal
export const showAngebotModal = () => {
  return{
     type: "SHOWANGEBOTMODAL"
  }
}

//close angebot modal
export const closeAngebotModal = () => {
   return{
      type: "CLOSEANGEBOTMODAL"
   }
}

//showPdfAngebot
export const showPdfAngebot = (ev) => {
   return{
      type: "SHOWPDFANGEBOT",
      ev: ev
   }
}

//update Trauerfall Status
export const updateTrauerfallStatus = ev => {
   return{
       type: "UPDATETRAUERFALLSTATUS",
       ev: ev
   }
}

//set successPlz to false
export const successPlzToFalse = () => {
    return{
       type: "SUCCESSPLZTOFALSE"
    }
}

//get bestattungsart
export const getBestattungsArt = ev => {
   return{
      type: "GETBESTATTUNGSART",
      ev: ev
   }
}

//set show plz wrong message
export const showPlzWrongMsg = () => {
   return{
       type: "SHOWWRONGMSG"
   }
}

//set dataIsLoad to false
export const dataIsLoadToFalse  = () => {
   return{
       type: "SETDATAISLOADTOFALSE"
   }
}

//change language by contact form
export const changeLanguageByContact = ev => {
   return{
     type: "CHANGELANGUAGEBYCONTACT",
     ev: ev
   }
}

//contactsubmit
export const contactsubmitVollmacht = ev => {
  return{
    type: "CONTACTSUBMITVOLLMACHT",
    ev: ev
  }
}

//update invalid plz2
export const updateInvalidPlz2 = () => {
   return{
     type: "UPDATEPLZINVALID2"
   }
}

//reload Data
export const reloadData = () => {
  return{
    type: "RELOADDATA"
  }
}

//update Plz Invalid
export const updatePlzInvalid = () => {
  return{
     type: "UPDATEPLZINVALID"
  }
}


//updateBemerkungen
export const updateBemerkungen = (ev) => {
   return{
     type: "UPDATEBEMERKUNGEN",
     ev: ev
   }
}

//delete begleitete Seebestattung
export const deleteBegleiteteSeebestattung = () => {
   return{
     type: "DELETEBEGLEITETESEEBESTATTUNG"
   }
}

//add begleitete Seebestattung
export const addBegleiteteSeebstattung = () => {
   return{
      type: "ADDBEGLEITETESEEBESTATTUNG"
   }
}

//show begleitete Seebestattung
export const showModalBegleiteteSeebestattung = () => {
  return{
     type: "SHOWMODALBEGLEITETESEEBESTATTUNG"
  }
}

//close begleitete Seebestattung
export const hideModalBegleiteteSeebestattung = () => {
  return{
     type: "HIDEMODALBEGLEITETESEEBESTATTUNG"
  }
}

//show modal seeurne
export const showSeeUrne = () => {
   return{
      type: "SHOWSEEURNE"
   }
}

// close modal seeurnen
export const closeModalSeeurnen = () => {
   return{
      type: "CLOSEMODALSEEURNEN"
   }
}

//show urne modal
export const showModalUrnen = () => {
   return{
     type: "SHOWMODALURNEN"
   }
}

//close urne modal
export const closeModalUrnen = () => {
   return{
     type: "CLOSEMODALURNEN"
   }
}

//show särge modal
export const showModalSaerge = () => {
   return{
      type: "SHOWMODALSAERGE"
   }
}

//close särge modal
export const closeModalSaerge = () => {
   return{
      type: "CLOSEMODALSAERGE"
   }
}

//change bestattungsart
export const changeBestattungsart = ev => {
   return{
     type: "CHANGEBESTATTUNGSART",
     ev: ev
   }
}


//select seeurne
export const selectSeeurne = ev => {
   return{
     type: "SELECTSEEURNE",
     ev: ev
   }
}

//delete seeurne
export const deleteSeeurne = () => {
  return{
    type: "DELETESEEURNE"
  }
}

//select biourne
export const selectBiourne = ev => {
  return{
    type: "SELECTBIOURNE",
    ev: ev
  }
}

//delete biourne
export const deleteBiourne = () => {
  return{
    type: "DELETEBIOURNE"
  }
}

//select urne
export const selectUrne = ev => {
   return{
     type: "SELECTURNE",
     ev: ev
   }
}

//delete urne
export const deleteUrne = () => {
   return{
     type: "DELETEURNE"
   }
}

//show Modal Product
export const showModalProduct = () => {
   return{
      type: "SHOWMODALPRODUCT"
   }
}

//hide Modal Product
export const hideModalProduct = () => {
   return{
      type: "HIDEMODALPRODUCT"
   }
}

//show Modal Trauerfeier
export const showModalTrauerfeier = () => {
   return{
     type: "SHOWMODALTRAUERFEIER"
   }
}

//hide Modal Trauerfeier
export const hideModalTrauerfeier = () => {
   return{
      type: "HIDEMODALTRAUERFEIER"
   }
}

//delete floristik
export const deleteFloristik = (ev) => {
   return{
      type: "DELETEFLORISTIK",
      ev: ev
   }
}

//add floristik
export const addFloristik = ev => {
   return{
     type: "ADDFLORISTIK",
     ev: ev
   }
}

//catch data for map
export const dataMap = () => {
   return{
     type: "DATAMAP"
   }
}

//show map
export const showMap = () => {
   return{
     type: "SHOWMAP"
   }
}

//form handleSubmit
export const handleSubmit = ev => {
   return{
      type: "HANDLESUBMIT",
      ev: ev
   }
}


// remove fieldsBouquet
export const removefieldsTrauerkranz = () => {
   return{
     type: "REMOVEFIELDSTRAUERKRANZ"
   }
}

export const addTrauerkranzBudget = (ev) => {
  return{
    type: "ADDTRAUERKRANZBUDGET",
    ev: ev
  }
}

// add addTrauerkranzErsteSchleife
export const addTrauerkranzErsteSchleife = (ev) => {
  return{
    type: "ADDTRAUERKRANZERSTESCHLEIFE",
    ev: ev
  }
}

// add addTrauerkranzZweiteSchleife
export const addTrauerkranzZweiteSchleife = (ev) => {
  return{
    type: "ADDTRAUERKRANZZWEITESCHLEIFE",
    ev: ev
  }
}

// remove fieldsBouquet
export const removefieldsBouquet = () => {
   return{
     type: "REMOVEFIELDSBOUQUET"
   }
}

// add addBouquetErsteSchleife
export const addBouquetErsteSchleife = (ev) => {
  return{
    type: "ADDBOUQUETERSTESCHLEIFE",
    ev: ev
  }
}

// add addBouquetZweiteSchleife
export const addBouquetZweiteSchleife = (ev) => {
  return{
    type: "ADDBOUQUETZWEITESCHLEIFE",
    ev: ev
  }
}


//onSubmitBouquet
export const onSubmitBouquet = (ev) => {
  return{
    type: "ONSUBMITBOUQUET",
    ev: ev
  }
}

//onSubmitTrauerkranz
export const onSubmitTrauerkranz = (ev) => {
  return{
    type: "ONSUBMITTRAUERKRANZ",
    ev: ev
  }
}

export const addBouquetBudget = (ev) => {
  return{
    type: "ADDBOUQUETBUDGET",
    ev: ev
  }
}

//add trauerfeier
export const addTrauerfeier = () => {
   return{
     type: "ADDTRAUERFEIER"
   }
}

//delete trauerfeier
export const deleteTrauerfeier = () => {
   return{
     type: "DELETETRAUERFEIER"
   }
}

//update male
export const updateMale = ev => {
   return{
      type: "UPDATEMALE",
      ev: ev
   }
}

//calculate total price
export const caculateFinalPrice = () => {
   return{
      type: "CALCULATEFINALPRICE"
   }
}


//update buttonFailDatenschutz
export const buttonFailDatenschutz = () => {
   return{
     type: "BUTTONFAILDATENSCHUTZ"
   }
}

//update checkBox datenschutzIsTrue
export const handleBoxDatenschutz = () => {
   return{
      type: "HANDLEBOXDATENSCHUTZ"
   }
}

//update contact form
export const onChangeContakt = ev => {
    return{
      type: "ONCHANGECONTACT",
      ev: ev
    }
}

// input plz action
export const updatePLZ = ev => {
   return{
     type: "UPDATEPLZ",
     ev: ev
   }
}



export const trauerFallErdbestattung = (ev) => {
    return{
      type: "TRAUERFALLERDBESTATTUNG",
      ev: ev

    }
}

export const buttonInputCheckHome = (ev) => {
  return{
    type: "BUTTONINPUTCHECKHOME",
    ev: ev
  }
}

export const buttonInputCheckHospital = (ev) => {
  return{
    type: "BUTTONINPUTCHECKHOSPITAL",
    ev: ev
  }
}


export const selectSarg = ev => {
   return{
     type: "SELECTSARG",
     ev: ev
   }
}

export const deleteSarg = () => {
   return{
     type: "DELETESARG"
   }
}

export const selectSargButton = ev => {
  return{
    type: "SELECTSARGBUTTON",
    ev: ev
  }
}

export const updateTrueButtonTrauerfall = () => {
   return{
      type: "UPDATETRUEBUTTONTRAUERFALL"
   }
}

export const buttonFailErd = () => {
  return{
    type: "BUTTONFAILERD"
  }
}

export const buttonFailSarg = () => {
  return{
    type: "BUTTONFAILSARG"
  }
}

export const buttonWeiterToMap = () => {
  return{
    type: "BUTTONWEITERTOMAP"
  }
}

export const updateGoToContact = () => {
  return{
     type: "UPDATEGOTOCONTACT"
  }
}


export const store = createStore(reducer);