import React, {Component} from "react";
import "../style/footer.css";
import {NavLink} from "react-router-dom";

class Footer extends Component{
  render(){
    return(
      <div className="container-fluid footer py-4">
        <p className="copyRight">All rights reserved. © sidereum-bestattungen.de</p>
        <div className="footerRightBox">
              <li className="footerLink"><NavLink className="nav-link" to="/agb">AGB</NavLink></li>
                <li className="footerLink"><NavLink className="nav-link" to="/datenschutz">Datenschutz</NavLink></li>
                <li className="footerLink"><NavLink className="nav-link" to="/impressum">Impressum</NavLink></li>
        </div>
      </div>
    )
  }
}

export default Footer;
