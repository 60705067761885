import React from "react";
import Modal from 'react-bootstrap/Modal';
import {connect} from "react-redux";
import trauerfeierImg from "../../images/bestattungsfeier/trauerfeier.jpg";

const Trauerfeier = (props) => {
     return(
        <Modal
        size="lg"
        show={props.showTrauerfeier}
        onHide={props.hideTrauerfeier}
        aria-labelledby="example-modal-sizes-title-lg"
       >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
          <p className="modalTitle">Trauerfeier</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="trauerfeierWrapper">
            <ul className="">
                    <li>Einfache Trauerfeier</li>
                    <li>musikalische Begleitung</li>
                    <li>Trauerbegleiter und Trauerredner kann auf Anfrage dazu gebucht werden (gesetzlicher Beistand)</li>
                    <p className="my-2">Preis: {props.trauerfeierPreis.toFixed(2)} EUR</p>
                    <div className="d-flex">
                     {props.toggleButtons ?
                      <button className="btn buttonAngebot mr-3" onClick={props.addTrauerfeier}>Hinzufügen</button>
                      :
                      <button className="btn btn-danger mr-3" onClick={props.deleteTrauerfeier}>Entfernen</button>
                     }
                    </div>
              </ul>
              <img className="trauerfeierImg" src={trauerfeierImg} alt="Trauerfeier" />

          </div>
            
        </Modal.Body>
      </Modal>
     )
}

const mapStateToProps = state => {
    return {
        trauerfeierPreis: state.trauerfeierPreis
    }
}

const mapStateToDispatch = dispatch => {
     return {
     }
}

export const TrauerfeierModal = connect(mapStateToProps, mapStateToDispatch)(Trauerfeier);