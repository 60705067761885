import React, {useEffect } from 'react';
import './App.css';
import "./style/erdbestattung.css";
import {BrowserRouter, Route, Switch, Link} from "react-router-dom";
import {connect} from "react-redux";
import {dataMap, dataIsLoadToFalse, getCurrentLocation} from "./Components/redux";
import CookieConsent from "react-cookie-consent";

//import Components
import Header from "./Components/Header";
import {HomeContainer} from "./Components/Home";
import {ErdBestattungContainer} from "./Components/Erdbestattung/Erdbestattung";
import {FeuerBestattungContainer} from "./Components/Feuerbestattung/Feuerbestattung";
import {SeebestattungContainer} from "./Components/Seebestattung/Seebestattung";
import {AnonymeBestattungContainer} from "./Components/AnonymeBestattung/AnonymeBestattung";
import {FriedwaldbestattungContainer} from "./Components/Friedwaldbestattung/Friedwaldbestattung";
import {EinfacheFeuerbestattungContainer} from "./Components/Einfache_Feuerbestattung/Einfache_Feuerbestattung";
import Service from "./Components/Service";
import Ueber_Uns from "./Components/Ueber_Uns";
import Kontakt from "./Components/Kontakt";
import Impressum from "./Components/Impressum";
import AGB from "./Components/AGB";
import Datenschutz from "./Components/Datenschutz";
import Footer from "./Components/Footer";
//import ProcessStatusContainer from "./Components/StatusBar/ProcessStatus";
import {AngebotContainer} from "./Components/StatusBar/Angebot";
import Vorsorge from "./Components/Vorsorge";
import Gebuehren from "./Components/Gebuehren";
import Bestattungsgesetze from "./Components/Bestattungsgesetze/Bestattungsgesetze";
import Hamburg from "./Components/Bestattungsgesetze/Hamburg";
import Berlin from "./Components/Bestattungsgesetze/Berlin";
import MV from "./Components/Bestattungsgesetze/MV";
import SH from "./Components/Bestattungsgesetze/SH";
import Ratenzahlung from "./Components/Ratenzahlung";

const App = (props) => {
   //let [isDataLoad, setDataLoad] = useState(true);

   useEffect(() => {
      // let time = 0;
      // if (props.isDataLoad) time = 0;
      // else time = 2000;

      window.scrollTo(0, 0);

      //props.getCurrentLocation();
      // setTimeout(() => {
      //    props.getCurrentLocation();
      //    setDataLoad(false);
      // }, 2000);
   })



    return (

       <BrowserRouter >
           {/* <div id="setDataLoadToFalse" onClick={props.dataIsLoadToFalse}></div>
           {props.isDataLoad || isDataLoad ? <DataLoad />
           : */}
           <>
               <CookieConsent
                  location="top"
                  buttonText="Ok, verstanden"
                  cookieName="myAwesomeCookieName2"
                  style={{ background: "#2B373B", opacity: ".8"}}
                  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                  expires={150}
                  >
                  Diese Website nutzt Cookies, um bestmögliche Funktionalität bieten zu können. <Link to="/datenschutz">Mehr Infos</Link>
               </CookieConsent>
               <Header />
               <Switch>
                  <Route exact path="/" render={() => <HomeContainer />} />
                  <Route path = "/vorsorge" render={() => <Vorsorge />} />
                  <Route path = "/gebuehren" render={() => <Gebuehren />} />
                  <Route path="/erdbestattung" render={() => <ErdBestattungContainer /> } />
                  <Route path="/feuerbestattung" render={() => <FeuerBestattungContainer />} />
                  <Route path="/seebestattung" render={() => <SeebestattungContainer />} />
                  <Route path="/anonyme_bestattung" render={() => <AnonymeBestattungContainer />} />
                  <Route path="/friedwald" render={() => <FriedwaldbestattungContainer />} />
                  <Route path="/einfache_feuerbestattung" render={() => <EinfacheFeuerbestattungContainer /> } />
                  <Route path="/service" component={Service} />
                  <Route path="/ueber_uns" component={Ueber_Uns} />
                  <Route path="/kontakt" component={Kontakt} />
                  <Route path="/impressum" component={Impressum} />
                  <Route path="/datenschutz" component={Datenschutz} />
                  <Route path="/agb" component={AGB} />
                  <Route path="/angebot" component={AngebotContainer} />
                  <Route path="/bestattungsgesetze" render={() => <Bestattungsgesetze />} />
                  <Route path="/bestattungsgesetz/Hamburg" component={Hamburg} />
                  <Route path="/bestattungsgesetz/Berlin" component={Berlin} />
                  <Route path="/bestattungsgesetz/Mecklenburg Vorpommern" component={MV} />
                  <Route path="/bestattungsgesetz/Schleswig Holstein" component={SH} />
                  <Route path="/ratenzahlung" component={Ratenzahlung} />
               </Switch>
               <Footer />
               </>
          {/* } */}
       </BrowserRouter>
    );

}

const mapDispatchToProps = dispatch => {
  return{
    dataMap: () => dispatch(dataMap()),
    dataIsLoadToFalse: () => dispatch(dataIsLoadToFalse()),
    getCurrentLocation: () => dispatch(getCurrentLocation())
  }
}

const mapStateToProps = state => {
   return{
      isDataLoad: state.isDataLoad
   }
}


export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
