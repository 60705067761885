import React, {Component} from "react";
import "../style/ratenzahlung.css";


class Ratenzahlung extends Component {

    componentDidMount() {
        const head = document.querySelector("#ratenzahlung");
        const script = document.createElement("script");
        script.setAttribute(
          "src",
           "https://www.smava.de/pp/smava-kreditvergleich/main.js"
        );
        script.setAttribute(
            "data-affiliateid",
             "42a4df0b"
          );
          script.setAttribute(
            "data-placementid",
             "783582278"
          );
          script.setAttribute(
            "data-amount",
             "20000"
          );
          script.setAttribute(
            "data-category",
             ""
          );
          script.setAttribute(
            "data-duration",
             "84"
          );
          script.setAttribute(
            "defer",
             ""
          );
          script.setAttribute(
            "async",
             ""
          );
        head.appendChild(script);
      }


     render(){
         return(
            <>
            <div className="bg-ratenzahlung">
               <h2 className="headerTop">Ratenzahlung</h2>
            </div>
         <div className="container">
           {/* <p className="p-3 descGesetz">Das Bestattungsrecht ist in der Bundesrepublik Deutschland Sache der Länder, da es dazu weder eine ausschließliche, 
               noch eine konkurrierende Gesetzgebungszuständigkeit des Bundes gibt. Alle deutschen Bundesländer haben eigene, 
               meist ähnliche Bestattungsgesetze erlassen. Sie regeln oft auch Fragen des Friedhofsrechtes und teilweise auch die Obduktion von Leichen. 
               In den meisten Bundesländern gibt es außerdem Ausführungsverordnungen, die ergänzende Regelungen zur Bestattung enthalten.</p>
                */}
               <div id="ratenzahlung"></div>

         </div>
         </>
         )
     }
}

export default Ratenzahlung;