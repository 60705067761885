import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {urnenArray, bioUrneArray, seeUrneArray} from "../data/urnen";
import { FaBars } from "react-icons/fa";

const AlleUrnen = props => {
   
    const [urnen, setUrnen] = useState(urnenArray);

    const filterUrnen = (produkt) => {
        setUrnen(urnenArray.filter(elem => elem.category === produkt))
        if(produkt === "bio"){
         setUrnen(bioUrneArray);
        }
        else if(produkt === "see"){
          setUrnen(seeUrneArray);
         }
    }
    
    const filterAlleUrnen = (ev) => {
      let targetElementIdent = ev.target.getAttribute("ident");
        filterUrnen(targetElementIdent);
    }

    const openMobileMenu = () => {
      let productMobileMenu = document.getElementById("productMobileMenu");
 
      productMobileMenu.classList.toggle("productMobileMenuActive");
  }

    return(
        <Modal
        size="xl"
        show={props.showAlleUrnen}
        onHide={props.closeAlleUrnen}
        aria-labelledby="example-modal-sizes-title-lg"
       >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
          <p className="modalTitle">Urnenauswahl</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FaBars onClick={openMobileMenu} id="hamburgericonProduct" />
        <div id="productMobileMenu" className="d-flex flex-wrap justify-content-center position-auswahl py-2 mt-1">
             <p ident="airbrush" className="productLinkAuswahl" onClick={filterAlleUrnen}>Airbrush</p>
             <p ident="handgemalt" className="productLinkAuswahl" onClick={filterAlleUrnen}>Handgemalt</p>
             <p ident="normal" className="productLinkAuswahl" onClick={filterAlleUrnen}>Normal</p>
             <p ident="einfach" className="productLinkAuswahl" onClick={filterAlleUrnen}>Schlicht</p>
             <p ident="bio" className="productLinkAuswahl" onClick={filterAlleUrnen}>Bio</p>
             {props.isSeeUrne && <p ident="see" className="productLinkAuswahl" onClick={filterAlleUrnen}>See</p>}
        </div>

        <div className="d-flex flex-wrap justify-content-center">
         {urnen.map((elem, index) => {
           return(
               <div key={index} className="col-sm-12 col-md-5 col-lg-5 card m-3 sargLastPosition">
                  <img  src={elem.img} className="card-img-bottom imgSarg" alt={elem.titel} />
                  <span className="productInfo">{elem.titel}</span>
                  <span className="productInfo">Art-Nr: {elem.art_nr}</span>
                  <span className="productInfo mb-3">Preis: {elem.price.toFixed(2)} EUR</span>
                  <button onClick={()=> props.selectUrne(elem)} className="btn mb-2 buttonAuswahlSarg">Auswählen</button>
               </div>
           )
          })}
         </div>
        </Modal.Body>
      </Modal>

    )
}

export default AlleUrnen;