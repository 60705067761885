import React, {Component} from "react";
import "../style/impressum.css";

class Impressum extends Component{
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  render(){
    return(
    <>
        <div className="bg-impressum">
           <h2 className="headerTop">Impressum</h2>
        </div>
        <div className="container-fluid bestattungAngebot py-5">
          <div className="container py-3">  

          <ul className="bestattungsAngebot py-1">
               <li className="listAnschrift">FIRMENNAME<p className="pImpressum">Sidereum Bestattungen UG</p></li>
               <li className="listAnschrift">ANSCHRIFT
                    <p className="pImpressum">Sidereum Bestattungen UG</p>
                    <p className="pImpressum">Lerchenberg 1</p>
                    <p className="pImpressum">18292 Krakow am See</p>
                </li>
                <li className="listAnschrift">TEL<p className="pImpressum">0152 220 036 87</p></li>
                <li className="listAnschrift">E-MAIL<p className="pImpressum">info@sidereum-bestattungen.de</p></li>
                <li className="listAnschrift">UMSATZSTEUER-ID<p className="pImpressum">086/117/03156</p></li>
                <li className="listAnschrift">Registernummer<p className="pImpressum">HRB 14651</p></li>
                <li className="listAnschrift">REGISTERGERICHT<p className="pImpressum">Amtsgericht Rostock</p></li>
                <li className="listAnschrift">GESCHÄFTSFÜHRER<p className="pImpressum">René Walek</p></li>
                <li className="listAnschrift">BILDERRECHT, DATENSCHUTZ, RECHTLICHES UND CO.
                  <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/hintergrund" target="_blank" rel="noopener noreferrer">- Hintergrund Foto erstellt von jannoon028 - de.freepik.com</a></p>
                  <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/hintergrund" target="_blank" rel="noopener noreferrer">- Hintergrund Foto erstellt von freepik - de.freepik.com</a></p>
                   <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/baum" target="_blank" rel="noopener noreferrer">- Baum Foto erstellt von lifeforstock - de.freepik.com</a></p>
                    <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/hintergrund" target="_blank" rel="noopener noreferrer">- Hintergrund Foto erstellt von lifeforstock - de.freepik.com</a></p>
                    <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/baum" target="_blank" rel="noopener noreferrer">- Baum Foto erstellt von lifeforstock - de.freepik.com</a></p>
                   <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/baum" target="_blank" rel="noopener noreferrer">- Baum Foto erstellt von photoangel - de.freepik.com</a></p>
                     <p className="pImpressum"><a href="https://www.bestattungen.de" target="_blank" rel="noopener noreferrer">- Textnachweise - www.bestattungen.de</a></p>
                    <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/menschen" target="_blank" rel="noopener noreferrer">- Menschen Foto erstellt von jcomp - de.freepik.com</a></p>
                     <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/jahrgang" target="_blank" rel="noopener noreferrer">- Jahrgang Foto erstellt von v.ivash - de.freepik.com</a></p>
                    <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/blume" target="_blank" rel="noopener noreferrer">- Blume Foto erstellt von jcomp - de.freepik.com</a></p>
                    <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/geschaeft">Geschäft Foto erstellt von freepik - de.freepik.com</a></p>
                    <p className="pImpressum"><a href="https://de.freepik.com/fotos-vektoren-kostenlos/hintergrund">Hintergrund Foto erstellt von Kireyonok_Yuliya - de.freepik.com</a></p>
                 </li>
          </ul>

        </div>
      </div>
    </>
    )
  }
}

export default Impressum;
