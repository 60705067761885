export const berlin = [
    [   "Erster Abschnitt: Anwendungsbereich",
        {
            titel: "§ 1 Leichen",
            content_1: "(1) Leiche im Sinne dieses Gesetzes ist der Körper eines Menschen, bei dem sichere Zeichen des Todes bestehen oder bei dem der Tod auf andere Weise zuverlässig festgestellt worden ist. Leblose Teile eines menschlichen Körpers gelten dann als einer Leiche zugehörig, wenn ohne sie ein Weiterleben des Individuums unmöglich wäre. Als Leiche gilt auch der Körper eines Neugeborenen, bei dem nach vollständigem Verlassen des Mutterleibes",
            content_2: "1. entweder das Herz geschlagen oder die Nabelschnur pulsiert oder die natürliche Lungenatmung eingesetzt hat und das danach verstorben ist oder",
            content_3: "2. keines der unter Nummer 1 genannten Lebenszeichen festzustellen war, das Geburtsgewicht jedoch mindestens 500 Gramm betrug (Totgeborenes).",
            content_4: "(2) Der Körper eines Neugeborenen mit einem Gewicht unter 500 Gramm, bei dem nach vollständigem Verlassen des Mutterleibes keines der in Absatz 1 Satz 3 Nr. 1 genannten Lebenszeichen festzustellen war (Fehlgeborenes), gilt nicht als Leiche im Sinne dieses Gesetzes."
        },
        {
            titel: "§ 2 Ehrfurcht vor Toten",
            content_1: "Wer mit Leichen umgeht, hat dabei die gebotene Ehrfurcht vor dem toten Menschen zu wahren.",
        }
    ],
    [   "Zweiter Abschnitt: Leichenschau",
        {
            titel: "§ 3 Leichenschaupflicht",
            content_1: "(1) Jede Leiche ist zur Feststellung des Todes, des Todeszeitpunkts, der Todesart und der Todesursache von einem Arzt zu untersuchen (Leichenschau).",
            content_2: "(2) Jeder niedergelassene Arzt ist verpflichtet, die Leichenschau auf Verlangen vorzunehmen, sofern er nicht aus wichtigem Grund daran gehindert ist. Bei Sterbefällen in Krankenanstalten trifft diese Verpflichtung die dort tätigen Ärzte",
            content_3: "(3) Ein in der Notfallrettung tätiger Arzt kann sich auf die Feststellung des Todes, des Todeszeitpunktes und der äußeren Umstände beschränken, wenn er durch die Durchführung der Leichenschau an der Wahrnehmung seiner Aufgaben in der Notfallrettung gehindert wird. Er hat unverzüglich eine vorläufige Todesbescheinigung auszustellen",
            content_4: "(4)  Eine Leichenschau darf nicht durchgeführt werden von Ärzten, denen ein Zeugnisverweigerungsrecht nach § 52 Abs. 1 der Strafprozessordnung zusteht."

        },
        {
            titel: "§ 4 Veranlassung der Leichenschau",
            content_1: "(1) Bei einem Sterbefall haben die Leichenschau unverzüglich zu veranlassen:",
            content_2: "1. der Ehegatte oder Lebenspartner,",
            content_3: "2. die volljährigen Kinder,",
            content_4: "3. die Eltern,",
            content_5: "4. andere Verwandte,",
            content_6: "5. Personen, mit denen der Verstorbene in häuslicher Gemeinschaft gelebt hat",
            content_7: "6. derjenige, in dessen Räumen oder auf dessen Grundstück sich der Sterbefall ereignet hat,",
            content_8: "7.  jede Person, die bei dem Tode zugegen war oder durch eigene Feststellung davon Kenntnis erlangt hat.",
            content_9: "(2) Bei einer Totgeburt haben die Leichenschau unverzüglich zu veranlassen:",
            content_10: "1. der eheliche Vater",
            content_11: "2. die Hebamme, die bei der Geburt zugegen war,",
            content_12: "3. der Arzt, der dabei zugegen war,",
            content_13: "4. jede Person, die dabei zugegen war oder durch eigene Feststellungen von der Geburt Kenntnis erlangt hat.",
            content_14: "(3) Eine Verpflichtung, die Leichenschau zu veranlassen, besteht nur, wenn die in der Reihenfolge früher genannten Personen nicht vorhanden oder aus wichtigem Grund verhindert sind.",
            content_15: "(4) Bei Sterbefällen und Totgeburten in den nachstehend aufgeführten Einrichtungen sind vor den in den Absätzen 1 und 2 genannten Personen verpflichtet, die Leichenschau zu veranlassen:",
            content_16: "1. in Krankenanstalten und Entbindungsheimen der leitende Arzt, bei mehreren selbständigen Abteilungen der leitende Abteilungsarzt,",
            content_17: "2. in sonstigen Anstalten und Heimen aller Art deren Leiter."
        },
        {
            titel: "§ 5 Leichen von Unbekannten",
            content_1: "Wer bei dem Tode eines Unbekannten zugegen ist oder die Leiche eines Unbekannten findet, hat hiervon unverzüglich die Polizeibehörde zu benachrichtigen. Die Leichenschau wird in diesen Fällen von der Polizeibehörde veranlaßt."
        },
        {
            titel: "§ 6 Vornahme der Leichenschau",
            content_1: "(1) Der Arzt hat die Leichenschau grundsätzlich innerhalb von zwölf Stunden nach der Aufforderung hierzu vorzunehmen und über seine Feststellungen unter Verwendung des amtlichen Vordrucks unverzüglich einen Leichenschauschein auszustellen.",
            content_2: "(2) Ergeben sich bei der Leichenschau Anhaltspunkte dafür, daß der Verstorbene eines nicht natürlichen Todes gestorben oder seine Todesart ungewiß ist, so beendet der Arzt die Leichenschau mit dieser Feststellung und benachrichtigt unverzüglich die Polizeibehörde."
        },
        {
            titel: "§ 7 Auskunftspflicht",
            content_1: "(1) Ärzte, Zahnärzte und Heilpraktiker, die den Verstorbenen vor seinem Tode behandelt haben, sind verpflichtet, dem Arzt, der die Leichenschau vornimmt, auf Verlangen über den von ihnen festgestellten Krankheitszustand Auskunft zu geben.",
            content_2: "(2) Die in Absatz 1 genannten Ärzte, Zahnärzte und Heilpraktiker sind berechtigt, die Auskünfte auch der Polizeibehörde zu geben."
        },
        {
            titel: "§ 8 Kosten der Leichenschau",
            content_1: "Die Kosten der Leichenschau und der Ausstellung des Leichenschauscheines hat, soweit nicht ein anderer dazu verpflichtet ist, derjenige zu tragen, der für die Kosten der Bestattung aufzukommen hat."
        }
    ],
    [   "Dritter Abschnitt: Behandlung und Beförderung von Leichen",
        {
            titel: "§ 9 Überführung in Leichenhallen",
            content_1: "(1) Jede Leiche ist innerhalb von 36 Stunden in eine Leichenhalle zu überführen, sofern sie nicht innerhalb dieser Frist bestattet oder an einen Ort außerhalb Berlins befördert wird.",
            content_2: "(2) Leichenhallen im Sinne des Absatzes 1 sind die von dem Bezirksamt als geeignet anerkannten Leichenaufbewahrungsräume der Krankenanstalten, der Friedhöfe, der Krematorien, der anatomischen Institute, der gewerblichen Bestattungsunternehmer und der Polizeibehörde.",
            content_3: "(3) Für die Verpflichtung, die Leiche in eine Leichenhalle überführen zu lassen, gilt § 16 entsprechend."
        },
        {
            titel: "§ 16 Widmung und Verwaltung der Friedhöfe",
            content_1: "(1) 1 Die bestehenden Friedhöfe ergeben sich aus der Anlage 1 . 2 Weitere Friedhöfe können dadurch geschaffen werden, dass der Senat Flächen für diesen Zweck widmet und die zuständige Behörde die Widmung öffentlich bekannt gibt.",
            content_2: "(2) 1 Die zuständigen Behörden haben über Nutzungs- und Beisetzungsrechte auf den Friedhöfen sowie über Bestattungen Buch zu führen. 2 Sie sind verpflichtet, die allgemeinen Friedhofsanlagen und -einrichtungen unter Berücksichtigung der Zielsetzungen der Gartendenkmalpflege zu unterhalten."
        },
        {
            titel: "§ 10 Einsargung",
            content_1: "Leichen sind spätestens vor der Beförderung zu dem Bestattungsort einzusargen und in einem Sarg zu bestatten. Nicht eingesargte Leichen sind bedeckt zu transportieren."
        },
        {
            titel: "§ 10 a) Rituelle Waschungen von Leichen",
            content_1: "Rituelle Waschungen von Leichen dürfen nur in den vom Bezirksamt hierfür als geeignet anerkannten Räumen in Leichenhallen oder religiösen Einrichtungen unter Einhaltung geeigneter hygienischer Schutzmaßnahmen durchgeführt werden."
        },
        {
            titel: "§ 11 Leichenpass",
            content_1: "(1) Die Beförderung einer Leiche aus dem Geltungsbereich dieses Gesetzes an einen Ort außerhalb der Bundesrepublik Deutschland ist nur mit einem Leichenpass zulässig. Die zuständige Behörde stellt den Leichenpass auf Antrag aus. Sie ist berechtigt, die für die Ausstellung des Leichenpasses erforderlichen Nachweise zu verlangen sowie eigene Ermittlungen anzustellen und Auskünfte einzuholen",
            content_2: "(2) Leichen dürfen von einem Ort außerhalb der Bundesrepublik Deutschland nur in das Land Berlin befördert werden, wenn aus einem Leichenpass oder einer amtlichen Bescheinigung hervorgeht, ob der Verstorbene zum Zeitpunkt des Todes an einer übertragbaren Krankheit gelitten hat und dass gesundheitliche Bedenken gegen die Beförderung nicht bestehen."
        },
        {
            titel: "§ 12 Leichenwagen",
            content_1: "Leichen dürfen auf Straßen nur mit Fahrzeugen befördert werden, die zur Leichenbeförderung eingerichtet sind und ausschließlich zu diesem Zweck benutzt werden. Dies gilt nicht für die Bergung von Leichen und die Beförderung tödlich Verunglückter von der Unfallstelle. "
        },
        {
            titel: "§ 13 Aufbahrung von Leichen",
            content_1: "Leichen können vor der Bestattung im geschlossenen Sarg an einem Ort aufgebahrt werden, den das Bezirksamt allgemein oder für den Einzelfall als für die Aufbahrung geeignet anerkannt hat. Die Aufbahrung ist auch über den in § 9 Abs. 1 genannten Zeitraum hinaus zulässig."
        },
        {
            titel: "§ 14 Öffentliches Ausstellen von Leichen",
            content_1: "(1) Leichen dürfen nicht öffentlich ausgestellt werden. Das Öffnen oder Offenlassen des Sarges während der Bestattungsfeierlichkeiten ist verboten.",
            content_2: "(2) Das Bezirksamt kann Ausnahmen von den Verboten des Absatzes 1 zulassen.",
        }
    ],

    [   "Vierter Abschnitt Bestattung",
        {
            titel: "§ 15 Bestattungspflicht",
            content_1: "(1) Jede Leiche muss bestattet werden. Dies gilt nicht für Totgeborene mit einem Gewicht unter 1 000 Gramm. Diese Totgeborenen sowie Fehlgeborene sind auf Wunsch eines Elternteils zu bestatten. Ist die Geburt in einer Einrichtung erfolgt, soll die Leitung der Einrichtung sicherstellen, dass die Angehörigen auf diese Bestattungsmöglichkeit hingewiesen werden.",
            content_2: "(2) Werden Totgeborene mit einem Gewicht unter 1 000 Gramm oder Fehlgeborene nicht bestattet, sind sie von der Einrichtung, in der die Geburt erfolgt ist, oder durch den Inhaber des Gewahrsams hygienisch einwandfrei und dem sittlichen Empfinden entsprechend zu beseitigen, sofern sie nicht zu wissenschaftlichen Zwecken verwendet werden. Satz 1 gilt auch für die Beseitigung von Föten aus Schwangerschaftsabbrüchen sowie von Körperteilen.",
        },
        {
            titel: "§ 16 Bestattungspflichtige Personen",
            content_1: "(1) Für die Bestattung der Leiche haben zu sorgen:",
            content_2: "1. der Ehegatte oder Lebenspartner,",
            content_3: "2. die volljährigen Kinder,",
            content_4: "3.  die Eltern,",
            content_5: "4. die volljährigen Geschwister,",
            content_6: "5. die volljährigen Enkelkinder,",
            content_7: "6. die Großeltern.",
            content_8: "(2) Eine Verpflichtung, für die Bestattung zu sorgen, besteht nur, wenn die in der Reihenfolge früher genannten Angehörigen nicht vorhanden oder aus wichtigem Grund gehindert sind, für die Bestattung zu sorgen.",
            content_9: "(3) Sind Bestattungspflichtige im Sinne des Absatzes 1 nicht vorhanden oder nicht zu ermitteln oder kommen sie ihrer Pflicht nicht oder nicht rechtzeitig nach und veranlasst kein anderer die Bestattung, hat das örtlich zuständige Bezirksamt auf Kosten des Bestattungspflichtigen für die Bestattung zu sorgen.",
            content_10: "(4) Eine auf Gesetz oder Rechtsgeschäft beruhende Verpflichtung, die Kosten der Bestattung zu tragen, wird durch diese Vorschrift nicht berührt.",
        },
        {
            titel: "§ 17 Bestattungsarten",
            content_1: "Die Bestattung kann als Erdbestattung oder als Feuerbestattung vorgenommen werden."
        },
        {
            titel: "§ 18 Bestattungsort",
            content_1: "(1) Erdbestattungen dürfen nur auf öffentlichen (landeseigenen und nichtlandeseigenen) Friedhöfen vorgenommen werden. Die zuständige Behörde kann Ausnahmen zulassen.",
            content_2: "(2) Abweichend von der Pflicht nach § 10 Satz 1, in einem Sarg zu bestatten, können Leichen aus religiösen Gründen auf vom Friedhofsträger bestimmten Grabfeldern in einem Leichentuch ohne Sarg bestattet werden. Die Leiche ist auf dem Friedhof bis zur Grabstätte in einem geeigneten Sarg zu transportieren.",
            content_3: "(3) Bei Feuerbestattungen dürfen Einäscherungen in den Krematorien des Landes Berlin vorgenommen werden. Für die Beisetzung von Aschen Verstorbener gilt Absatz 1 entsprechend.",
            content_4: "(4) Die für die Errichtung und den Betrieb von Krematorien zuständige Senatsverwaltung kann mit Zustimmung der Senatsverwaltung für Finanzen und im Einvernehmen mit der Senatsverwaltung für Inneres die Errichtung und den Betrieb einzelner Feuerbestattungsanlagen widerruflich einem privaten Rechtsträger übertragen."
        },
        {
            titel: "§ 19 Zulässigkeit der Bestattung",
            content_1: "(1) Ein in Berlin Verstorbener darf erst bestattet werden, wenn ein Bestattungsschein erteilt worden ist und der Standesbeamte die Anzeige des Sterbefalles bescheinigt hat. Bei Totgeborenen gemäß § 1 Abs. 1 Satz 3 Nr. 2 ist anstelle einer Bescheinigung mit dem Vermerk der Eintragung in das Sterbebuch eine Bescheinigung mit dem Vermerk der Eintragung in das Geburtenbuch vorzulegen.",
            content_2: "(2) Handelt es sich um die Leiche eines Unbekannten oder sind Anhaltspunkte dafür vorhanden, daß jemand eines nicht natürlichen Todes gestorben ist, so darf der Bestattungsschein erst erteilt werden, wenn die Staatsanwaltschaft die Bestattung schriftlich genehmigt hat (§ 159 Abs. 2 der Strafprozeßordnung).",
            content_3: "(3) Die den vertraulichen Teil des Leichenschauscheines verwahrende Behörde ist berechtigt, der Polizeibehörde auf Anfrage oder von Amts wegen die Auskünfte aus dem Leichenschauschein zu geben, die für den Vollzug des § 159 der Strafprozeßordnung und des § 1559 der Reichsversicherungsordnung erforderlich sind. Dies gilt nicht für Angaben, die im Leichenschauschein nur zur Durchführung des Gesetzes über die Statistik der Bevölkerungsbewegung und die Fortschreibung des Bevölkerungsstandes eingetragen sind."
        },
        {
            titel: "§ 20 Besondere Voraussetzungen der Feuerbestattung",
            content_1: "(1) Leichen, die gemäß § 15 Abs. 1 bestattet werden müssen, dürfen nur eingeäschert werden, wenn durch einen Arzt des zuständigen gerichtsmedizinischen Instituts nach Durchführung einer zweiten Leichenschau festgestellt worden ist, dass der Verstorbene eines natürlichen Todes gestorben ist. Die zweite Leichenschau erfolgt im Regelfall in dem die Einäscherung vornehmenden Krematorium",
            content_2: "(2) Ein in Berlin Verstorbener darf, soweit es sich nicht um ein Totgeborenes mit einem Gewicht unter 1 000 Gramm oder ein Fehlgeborenes handelt, zum Zweck der Einäscherung außerhalb des Landes Berlin erst nach Durchführung einer zweiten Leichenschau im Sinne des Absatzes 1 und Feststellung der dort genannten Umstände aus Berlin verbracht werden. Die zweite Leichenschau erfolgt hierbei durch einen Arzt des zuständigen gerichtsmedizinischen Instituts in einem Berliner Krematorium oder im zuständigen gerichtsmedizinischen Institut. Die Sätze 1 und 2 finden keine Anwendung in den Fällen, in denen die Einäscherung im übrigen Bundesgebiet erfolgen soll, sofern die am vorgesehenen Einäscherungsort geltenden Rechtsvorschriften eine dem Verfahren nach Absatz 1 vergleichbare zweite Leichenschau als besondere Voraussetzung für die Einäscherung vorschreiben.",
            content_3: "(3) Kann bei der Leichenschau nach den Absätzen 1 und 2 eine natürliche Todesursache nicht zuverlässig festgestellt werden, hat der Arzt Auskünfte nach § 7 einzuholen. Reichen die Auskünfte zur Feststellung einer natürlichen Todesursache nicht aus oder ergeben sich bei der Leichenschau Anhaltspunkte dafür, dass der Verstorbene eines nicht natürlichen Todes gestorben ist, so ist unverzüglich die Polizeibehörde zu benachrichtigen. In diesem Fall dürfen die Einäscherung nach Absatz 1 oder der Abtransport der Leiche nach Absatz 2 erst durchgeführt werden, wenn die Staatsanwaltschaft die Bestattung schriftlich genehmigt hat (§ 159 Abs. 2 der Strafprozessordnung)."
        },
        {
            titel: "§ 21 Zeitpunkt der Bestattung",
            content_1: "Die Bestattung darf frühestens 48 Stunden nach Eintritt des Todes stattfinden, sofern nicht die zuständige Behörde auf Grund des Infektionsschutzgesetzes eine vorzeitige Bestattung anordnet."
        },
        {
            titel: "§ 22 Bestattungsunterlagen",
            content_1: "(1) Die für die Bestattungen auf öffentlichen Friedhöfen und für Einäscherungen in Krematorien Verantwortlichen dürfen Bestattungen und Einäscherungen nur zulassen, wenn ihnen die nach § 11 Abs. 2 und § 19 Abs. 1 vorgeschriebenen Bestattungs- und Beförderungsunterlagen ausgehändigt worden sind; für Einäscherungen in Krematorien ist zusätzlich eine Bescheinigung über die nach § 20 vorgeschriebene zweite Leichenschau vorzulegen.",
            content_2: "(2) Soll ein Fehlgeborenes bestattet werden, ist den für Bestattungen auf öffentlichen Friedhöfen und für Einäscherungen in Krematorien Verantwortlichen eine ärztliche Bescheinigung vorzulegen, aus der sich das Datum und der Umstand der Fehlgeburt sowie Name und Anschrift der Mutter ergeben."
        },
        {
            titel: "§ 23 Ausgrabung von Leichen",
            content_1: "(1) Bestattete Leichen dürfen nur mit Erlaubnis des Bezirksamtes ausgegraben werden. Die Erlaubnis kann unter Bedingungen, die dem Schutz der Gesundheit dienen, erteilt werden.",
            content_2: "(2) Absatz 1 gilt nicht, wenn ein Gericht eine Leichenschau oder eine Leichenöffnung angeordnet oder die Polizeibehörde sie zur Untersuchung eines Unfalles (§ 1559 der Reichsversicherungsordnung) veranlaßt hat."
        },

    ],

    [   "Fünfter Abschnitt: Schlußvorschriften",
        {
            titel: "§ 24 Ordnungswidrigkeiten",
            content_1: "(1) Ordnungswidrig handelt, wer vorsätzlich oder fahrlässig",
            content_2: "1. als Arzt",
            content_3: "a) die Leichenschau entgegen § 3 Abs. 2 nicht oder entgegen § 6 Abs. 1 nicht rechtzeitig vornimmt,",
            content_4: "b) eine vorläufige Todesbescheinigung entgegen § 3 Abs. 3 nicht oder nicht unverzüglich ausstellt.",
            content_5: "c) eine Leichenschau entgegen § 3 Abs. 4 durchführt,",
            content_6: "d) den Leichenschauschein entgegen § 6 Abs. l unvollständig, unrichtig oder nicht unverzüglich ausstellt,",
            content_7: "e) die Polizeibehörde entgegen § 6 Abs. 2 nicht oder nicht unverzüglich benachrichtigt,",
            content_8: "2. als Arzt, Zahnarzt oder Heilpraktiker Auskünfte nach § 7 unrichtig erteilt,",
            content_9: "3. als Bestattungsunternehmer",
            content_10: "a) eine Leiche nicht innerhalb der Frist des § 9 Abs. l in eine Leichenhalle überführt, obwohl er die Bestattung übernommen hat,",
            content_11: "b) eine Leiche entgegen § 10 nicht in einem Sarg oder entgegen § 12 nicht in einem Leichenwagen befördert,",
            content_12: "c) entgegen § 20 Abs. 2 eine Leiche ohne vorangegangene zweite Leichenschau zum Zweck der Einäscherung aus Berlin verbringt.",
            content_13: "4. in grober Weise gegen das Gebot des § 2 verstößt,",
            content_14: "5. die Leichenschau entgegen § 4 nicht oder nicht rechtzeitig veranlasst,",
            content_15: "6. eine Leiche entgegen § 9 in einer nicht als geeignet anerkannten Leichenhalle aufbewahrt,",
            content_16: "6a. entgegen § 10 a rituelle Waschungen in einem nicht als geeignet anerkannten Raum oder ohne Einhaltung geeigneter hygienischer Schutzmaßnahmen durchführt,",
            content_17: "7. eine Leiche entgegen den Anforderungen des § 11 transportiert,",
            content_18: "8. eine Leiche entgegen § 15 Abs. l der Bestattung entzieht oder eine Leiche bestattet, ohne dass die Voraussetzungen des § 19 Abs. l vorliegen,",
            content_19: "9. entgegen § 15 Abs. 2 Totgeborene mit einem Gewicht unter 1.000 Gramm, Fehlgeborene, Föten aus Schwangerschaftsabbrüchen oder Körperteile nicht hygienisch einwandfrei und dem sittlichen Empfinden entsprechend beseitigt,",
            content_20: "10. als bestattungspflichtiger Angehöriger entgegen § 16 Abs. l und 2 nicht für die Bestattung sorgt, es sei denn, dass ein anderer Angehöriger oder ein Dritter für die Bestattung sorgt,",
            content_21: "11. entgegen § 18 außerhalb öffentlicher Friedhöfe eine Leiche bestattet oder Asche Verstorbener beisetzt oder eine Leiche außerhalb eines Krematoriums einäschert,",
            content_22: "12. entgegen § 20 Abs. l eine Einäscherung ohne vorangegangene zweite Leichenschau durchführt oder durchführen lässt,",
            content_23: "13. eine bestattete Leiche ohne die nach § 23 vorgeschriebene Erlaubnis ausgräbt oder den Bedingungen, unter denen die Erlaubnis erteilt wurde, zuwiderhandelt.",
            content_24: "(2) Ordnungswidrig handelt auch, wer vorsätzlich oder fahrlässig einer auf Grund des § 25 erlassenen Rechtsverordnung zuwiderhandelt, soweit die Rechtsverordnung für einen bestimmten Tatbestand auf diese Bußgeldvorschrift verweist.",
            content_25: "(3) Die Ordnungswidrigkeit kann mit einer Geldbuße bis zu 10.000 Euro geahndet werden.",
            content_26: "(4) Verwaltungsbehörde im Sinne des § 36 Abs. l Nr. l des Gesetzes über Ordnungswidrigkeiten ist im Falle des Absatzes l Nr. l Buchstabe e der Polizeipräsident in Berlin, in allen übrigen Fällen des Absatzes l das Bezirksamt."
        },
        {
            titel: "§ 25 Rechtsverordnungen",
            content_1: "(1) Der Senat kann zur Durchführung dieses Gesetzes Rechtsverordnungen erlassen über",
            content_2: "1. die Durchführung der Leichenschau, den Inhalt, die Ausstellung und die Verwendung des Leichenschauscheins, der vorläufigen Todesbescheinigung, des Bestattungsscheins und des Leichenpasses,",
            content_3: "2. die Behandlung und die Beförderung von Leichen, insbesondere über die zu treffenden Schutzmaßnahmen und die Beschaffenheit der Särge,",
            content_4: "3. die an Leichenhallen zu stellenden Anforderungen und ihre Überwachung,",
            content_5: "4. die Aufbewahrung und den Versand von Aschen Verstorbener.",
            content_6: "(2) Der Senat kann durch Rechtsverordnung aus Gründen der öffentlichen Sicherheit oder Ordnung",
            content_7: "1. Ausnahmen von den Vorschriften des § 10 Satz 1 und des § 12 zulassen,",
            content_8: "2. anordnen, daß die Feuerbestattung auch ohne die besonderen Voraussetzungen des § 20 stattfinden darf.",
            content_9: "(3) Die Geltungsdauer einer Rechtsverordnung nach Absatz 2 ist zu befristen; die Frist darf drei Monate nicht überschreiten."
        },
        {
            titel: "§ 26 Verwaltungsvorschriften",
            content_1: "Die zur Ausführung dieses Gesetzes erforderlichen Verwaltungsvorschriften erläßt das zuständige Mitglied des Senats im Einvernehmen mit dem Senator für Inneres"
        },
        {
            titel: "§ 27 Änderung des Gesetzes über die Friedhöfe Berlins"
        },
        {
            titel: "§ 28 Inkrafttreten",
            content_1: "(1) Dieses Gesetz tritt mit Ausnahme der §§ 25 und 26 neun Monate nach der Verkündung im Gesetz- und Verordnungsblatt für Berlin in Kraft; die §§ 25 und 26 treten am Tage nach der Verkündung in Kraft."
        },
    ]




]