import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import german_flag from "../images/flaggen/german_flag.svg";
import russian_flag from "../images/flaggen/russian_flag.svg";
import {onChangeContakt, handleSubmit, handleBoxDatenschutz, buttonFailDatenschutz, 
    updateMale, updateGoToContact, contactsubmitVollmacht, changeLanguageByContact, showPdfAngebot, closeAngebotModal} from "./redux";

const Vollmacht = (props) => {

    return(
 <div id="vollmacht">
        <div className="warpperFlagge d-flex flex-column align-items-center justify-content-center my-4">
            <p className="text-secondary">{props.chooseLang}</p>
            <div className="flaggenBox ">
                <img className="flaggen" onClick={props.changeLanguageByContact} ident="deutsch"  src={german_flag} alt="Deutsch" />
                <img className="flaggen" onClick={props.changeLanguageByContact} ident="russisch"  src={russian_flag} alt="Russisch" />
            </div>
         
            </div>
       
            <form  className="mx-auto" onSubmit={props.showPdfAngebot}>
            <p className="vollmacht_titel text-secondary">{props.bestattungspflichtiger}</p>
                <div className="input-group my-1">
                    <div className="input-group-append">
                      <label className="input-group-text" htmlFor="inputGroupSelect02">{props.salutation}</label>
                    </div>
                    <select className="custom-select" value={props.male} onChange={props.updateMale}>
                        <option>{props.choose}</option>
                        <option value="Frau">{props.female}</option>
                        <option value="Herr">{props.male2}</option>
                    </select>
                </div>
                
                {/* Bestattungspflichtige(r)*/}
                {/* Name und Vorname*/}
                <div className="m-0 p-0 d-flex justify-content-between">
                    <input className="form-control my-1 mr-2 kontaktInput"  name="name" value={props.name} ident="name" onChange={props.handleChange} type="text" placeholder={props.contactName} required />
                    <input className="form-control my-1 kontaktInput"  name="vorname" value={props.vorName} ident="vorname" onChange={props.handleChange}  type="text" placeholder={props.contactVorname} required />
                </div>
                    {/* Geboren am und Verwandschaftsverhältnis */}
                    <div className="m-0 p-0 d-flex justify-content-between">
                    <input className="form-control my-1 mr-2 kontaktInput" name="aliveBirthday" ident="aliveBirthday" value={props.aliveBirthday}  onChange={props.handleChange} type="text" placeholder={props.born}  />
                    <input className="form-control my-1 kontaktInput" name="verwandschaftsoption" ident="verwandschaftsoption" value={props.verwandschaftsoption}  onChange={props.handleChange}  type="text" placeholder={props.verwandschaftsoptionDesc} />
                </div>
                {/* Adresse */}
                <input className="form-control my-1 kontaktInput" name="aliveAdresse" ident="aliveAdresse" value={props.aliveAdresse}  onChange={props.handleChange}  type="text" placeholder={props.actualAdresse}  />
                {/* Geburtsort und Telefon */}
                <div className="m-0 p-0 d-flex justify-content-between">
                    <input className="form-control my-1 mr-2 kontaktInput" name="aliveBirthplace" ident="aliveBirthplace" value={props.aliveBirthplace}  onChange={props.handleChange} type="text" placeholder={props.birthplaceDesc} />
                    <input className="form-control my-1 kontaktInput" name="alivePhone" ident="alivePhone" value={props.alivePhone}  onChange={props.handleChange}  type="text" placeholder={props.alivePhoneDesc} />
                </div>

                {/* Verstorbener */}
                <p className="vollmacht_titel text-secondary mt-2">{props.verstorbener}</p>
                {/* Name und Vorname*/}
                <div className="m-0 p-0 d-flex justify-content-between">
                    <input className="form-control my-1 mr-2 kontaktInput" name="deceasedName" ident="deceasedName" value={props.deceasedName}  onChange={props.handleChange} type="text" required placeholder={props.contactName} />
                    <input className="form-control my-1 kontaktInput" name="deceasedVorname" ident="deceasedVorname" value={props.deceasedVorname}  onChange={props.handleChange}  type="text" required placeholder={props.contactVorname} />
                </div>
                    {/* Geboren am und Verwandschaftsverhältnis */}
                    <div className="m-0 p-0 d-flex justify-content-between">
                    <input className="form-control my-1 mr-2 kontaktInput" name="deceasedBirthdayFrom" ident="deceasedBirthdayFrom" value={props.deceasedBirthdayFrom}  onChange={props.handleChange} type="text" placeholder={props.born} />
                    <input className="form-control my-1 kontaktInput" name="deceasedBirthdayTo" ident="deceasedBirthdayTo" value={props.deceasedBirthdayTo}  onChange={props.handleChange}  type="text" placeholder={props.diedOn} />
                </div>
                {/* Adresse */}
                    <input className="form-control my-1 kontaktInput" name="deceasedCurrentAdresse" ident="deceasedCurrentAdresse" value={props.deceasedCurrentAdresse}  onChange={props.handleChange}  type="text" placeholder={props.currentAdresse} />
                    <input className="form-control my-1 mt-2 kontaktInput" name="deceasedAdresse" ident="deceasedAdresse" value={props.deceasedAdresse}  onChange={props.handleChange}  type="text" placeholder={props.prevAdresse} />
                    {/* Geburtsort und Religion */}
                    <div className="m-0 p-0 d-flex justify-content-between">
                    <input className="form-control my-1 mr-2 kontaktInput" name="deceasedBirthplace" ident="deceasedBirthplace" value={props.deceasedBirthplace}  onChange={props.handleChange} type="text" placeholder={props.birthplaceDesc} />
                    <input className="form-control my-1 kontaktInput" name="deceasedReligion" ident="deceasedReligion" value={props.deceasedReligion}  onChange={props.handleChange}  type="text" placeholder={props.religion}/>
                    </div>
                    {/* Beruf und Kinder */}
                    <div className="m-0 p-0 d-flex justify-content-between">
                        <input className="form-control my-1 mr-2 kontaktInput" name="deceasedProfession" ident="deceasedProfession" value={props.deceasedProfession}  onChange={props.handleChange} type="text" placeholder={props.profession} />
                        <input className="form-control my-1 kontaktInput" name="deceasedChildren" ident="deceasedChildren" value={props.deceasedChildren}  onChange={props.handleChange}  type="text"  placeholder={props.children} />
                    </div>

                    {/* Familienstand */}
            
                    <div className="input-group my-1">
                        
                     <div className="input-group-append">
                           <label className="input-group-text">{props.familyStatus}</label>
                        </div>
                        <select className="custom-select" ident="maritalStatus" value={props.maritalStatus} onChange={props.handleChange} required>
                            <option>{props.choose}</option>
                            <option value="ledig">{props.single}</option>
                            <option value="verheiratet">{props.married}</option>
                            <option value="verwitwet">{props.widowed}</option>
                            <option value="geschieden">{props.divorced}</option>
                        </select>
                     
                    </div>
               
             
                    {/* Rentennummer und Anzahl der Sterbeurkunden */}
                    <div className="m-0 p-0 d-flex justify-content-between">
                        <input className="form-control my-1 mr-2 kontaktInput "  name="deceasedPensionNumber" ident="deceasedPensionNumber" value={props.deceasedPensionNumber}  onChange={props.handleChange}  type="text"  placeholder={props.pensionNumber} />
                        <input className="form-control my-1 kontaktInput kontaktName" name="deceasedDeathCertificates" ident="deceasedDeathCertificates" value={props.deceasedDeathCertificates}  onChange={props.handleChange}  type="number" min="1" max="20" placeholder={props.certificatesNumber} />
                    </div>
                <div className="m-0 p-0 d-flex justify-content-between">
                            {/* Krankenkasse */}
                            <input className="form-control my-1 mr-2 kontaktInput" name="deceasedHealthInsurance" ident="deceasedHealthInsurance" value={props.deceasedHealthInsurance}  onChange={props.handleChange}  type="text"  placeholder={props.healthInsurance} />
                            {/* Gewicht/Größe */}
                            <input className="form-control my-1 kontaktInput" name="deceasedHeightWeight" ident="deceasedHeightWeight" value={props.deceasedHeightWeight}  onChange={props.handleChange}  type="text"  placeholder={props.heightWeight} />
                </div>
                <div className="form-check my-3">
                    <input className="form-check-input" type="checkbox"  id="checkboxDatenschutz" />
                    <label className="form-check-label" htmlFor="defaultCheck1">
             {props.acceptDataProtection} <Link className="" target="_blank" to="/datenschutz">{props.dataProtection}</Link>  {props.and} <Link className="" target="_blank" to="/agb">{props.agb}</Link>
                    </label>
                </div>
                <button type="submit" className="btn buttonDefault my-3">Bestätigen</button>
               
            </form>

            {props.datenschutzFailMessage && <div className="alert alert-danger" role="alert">
            <strong>Fehler!</strong> Bitte akzeptieren Sie die Datenschutzbestimmungen und AGB!
            </div>}

            <div id="setdatenschutzfalse" style={{display: "none"}} onClick={props.setDatenschutzTofalse}></div>

    </div>
    )
}


const mapStateToProps = state => {
    return{
      name: state.name,
      vorName: state.vorName,
      email: state.email,
      male: state.male,
      phone: state.phone,
      street_number: state.street_number,
      plz: state.plz,
      place: state.place,
      message: state.message,
      plzIsTrue: state.plzIsTrue,
      processBarStatus: state.processBarStatus,
      datenschutzIsTrue: state.datenschutzIsTrue,
      datenschutzFailMessage: state.datenschutzFailMessage,
      datenschutzIsChecked: state.datenschutzIsChecked,
      //change language
      bestattungspflichtiger: state.bestattungspflichtiger,
      choose: state.choose,
      female: state.female,
      male2: state.male2,
      salutation: state.salutation,
      contactName: state.contactName,
      contactVorname: state.contactVorname,
      born: state.born,
      verwandschaftsoptionDesc: state.verwandschaftsoptionDesc,
      actualAdresse: state.actualAdresse,
      birthplaceDesc: state.birthplaceDesc,
      alivePhoneDesc: state.alivePhoneDesc,
      verstorbener: state.verstorbener,
      diedOn: state.diedOn,
      prevAdresse: state.prevAdresse,
      religion: state.religion,
      profession: state.profession,
      children: state.children,
      single: state.single,
      married: state.married,
      widowed: state.widowed,
      divorced: state.divorced,
      familyStatus: state.familyStatus,
      pensionNumber: state.pensionNumber,
      certificatesNumber: state.certificatesNumber,
      healthInsurance: state.healthInsurance,
      acceptDataProtection: state.acceptDataProtection,
      dataProtection: state.dataProtection,
   
      heightWeight: state.heightWeight,
      deceasedHeightWeight: state.deceasedHeightWeight,
      vollmacht: state.vollmacht,
      titelAngebot: state.titelAngebot,
      chooseLang: state.chooseLang,
      and: state.and,
      agb: state.agb,
      currentAdresse: state.currentAdresse,
      deceasedCurrentAdresse: state.deceasedCurrentAdresse,
      bestattungsPreisAngebot: state.bestattungsPreisAngebot,
      cityAreay: state.cityAreay,
    }
  }
  
  const mapStateToDispatch = dispatch => {
     return{
      handleChange: ev => dispatch(onChangeContakt(ev)),
      handleSubmit: ev => dispatch(handleSubmit(ev)),
      handleBoxDatenschutz: () => dispatch(handleBoxDatenschutz()),
      buttonFailDatenschutz: () => dispatch(buttonFailDatenschutz()),
      updateMale: (ev) => dispatch(updateMale(ev)),
      updateGoToContact: () => dispatch(updateGoToContact()),
      contactsubmitVollmacht: ev => dispatch(contactsubmitVollmacht(ev)),
      changeLanguageByContact: ev => dispatch(changeLanguageByContact(ev)),
      showPdfAngebot: ev => dispatch(showPdfAngebot(ev)),
      closeAngebotModal: () => dispatch(closeAngebotModal())
      
     }
  }

  export const VollmachtContainer = connect(mapStateToProps, mapStateToDispatch)(Vollmacht);