import pappel_truhe from "../../images/saerge/pappel_truhe.jpg";
import pappel_truhe_89 from "../../images/saerge/pappel_truhe_89.jpg";
import pappel_truhe_52 from "../../images/saerge/pappel_truhe_52.jpg";
import eiche_6 from "../../images/saerge/eiche_6.jpg";
import eiche_13 from "../../images/saerge/eiche_13.jpg";
import eiche_333 from "../../images/saerge/eiche_333.jpg";
import eiche_555 from "../../images/saerge/eiche_555.jpg";
import kiefer_7 from "../../images/saerge/kiefer_7.jpg";
import kiefer_9 from "../../images/saerge/kiefer_9.jpg";
import kiefer_10 from "../../images/saerge/kiefer_10.jpg";
import kiefer_1 from "../../images/saerge/kiefer_1.jpg";
import kiefer_12 from "../../images/saerge/kiefer_12.jpg";
import kiefer_16 from "../../images/saerge/kiefer_16.jpg";
import kiefer_18 from "../../images/saerge/kiefer_18.jpg";
import kiefer_29 from "../../images/saerge/kiefer_29.jpg";
import kiefer_123 from "../../images/saerge/kiefer_123.jpg";
import designer_5 from "../../images/saerge/designer_5.jpg";
import designer_8 from "../../images/saerge/designer_8.jpg";
import designer_22 from "../../images/saerge/designer_22.jpg";
import designer_77 from "../../images/saerge/designer_77.jpg";
import designer_704 from "../../images/saerge/designer_704.jpg";


export const alleSaerge = [
  //Kiefersärge
  {img: kiefer_1, art_nr: 1, def: 1, titel: "Schlichter Kiefern Sarg", price: 0, name: "sarg", category: "kiefersarg"},
  {img: kiefer_10, art_nr: 10, titel: "Kiefer 2-Stab-Sarg astrein", price: 958.00, name: "sarg", category: "kiefersarg"},
  {img: kiefer_12, art_nr: 12, titel: "Kiefer-Palme-Patina", price: 1132.00, name: "sarg", category: "kiefersarg"},
  {img: kiefer_29, art_nr: 29, titel: "Kiefer 2-STab-Sarg Palme Patina Junior", price: 990.00, name: "sarg", category: "kiefersarg"},
  {img: kiefer_16, art_nr: 16, titel: "Kiefer 2-Stab-Sarg mittelbraun", price: 872.00, name: "sarg", category: "kiefersarg"},
  {img: kiefer_18, art_nr: 18, titel: "Kiefer 2-Stab-Sarg doppeltes Deckblatt", price: 919.00, name: "sarg", category: "kiefersarg"},
  {img: kiefer_123, art_nr: 123, titel: "Kiefer 2-Stab-Sarg Überbreite lackiert", price: 931.00, name: "sarg", category: "kiefersarg"},
  {img: kiefer_7, art_nr: 7, titel: "Kiefer Wulstsarg", price: 1132.00, name: "sarg", category: "kiefersarg"},
  {img: kiefer_9, art_nr: 9, titel: "Kiefer Truhe", price: 1273.00, name: "sarg", category: "kiefersarg"},

  //Pappelsärge
  {img: pappel_truhe, art_nr: 90, titel: "Pappeltruhe", price: 1114.00, name: "sarg", category: "pappelsarg"},
  {img: pappel_truhe_52, art_nr: 52, titel: "Pappel 2-Stab-Sarg Kastanie", price: 919.00, name: "sarg", category: "pappelsarg"},
  {img: pappel_truhe_89, art_nr: 89, titel: "Pappel 2-Stab-Sarg Honig", price: 919.00, name: "sarg", category: "pappelsarg"},

  //Eichensärge
  {img: eiche_6, art_nr: 6, titel: "Eiche 2-Stab-Sarg", price: 1358.00, name: "sarg", category: "eichensarg"},
  {img: eiche_13, art_nr: 13, titel: "Eiche Wulstsarg", price: 1358.00, name: "sarg", category: "eichensarg"},
  {img: eiche_333, art_nr: 333, titel: "Wildeiche mit Seilgriffen", price: 1311.00, name: "sarg", category: "eichensarg"},
  {img: eiche_555, art_nr: 555, titel: "Eiche Truhe Teutonia", price: 1649.00, name: "sarg", category: "eichensarg"},

  //Designersärge
  {img: designer_5, art_nr: 5, titel: "Designer - Lindentruhe", price: 1361.00, name: "sarg", category: "designersarg"},
  {img: designer_8, art_nr: 8, titel: "Designer - Birke Truhe", price: 1433.00, name: "sarg", category: "designersarg"},
  {img: designer_22, art_nr: 22, titel: "Designer - Olive Truhe", price: 2247.00, name: "sarg", category: "designersarg"},
  {img: designer_77, art_nr: 77, titel: "Designer - Esche gerillt", price: 1433.00, name: "sarg", category: "designersarg"},
  {img: designer_704, art_nr: 704, titel: "Designer - Kirschbaum Truhe", price: 2317.00, name: "sarg", category: "designersarg"}

];
//alleSaerge.push(sargArray, sargPappel, sargEiche, sargDesigner);
