import React, {Component} from "react";
import logo from "../images/logo/logo.svg";
import "../style/logo.css"
import {Link} from "react-router-dom";
import {MenuContainer} from "./Menu";

class Header extends Component{
    render(){
       return(
         <div className="header">
            <Link to="/"><img src={logo} alt="Logo" className="logo" /></Link>
            <MenuContainer />
         </div>
       );
    }
}

export default Header;
