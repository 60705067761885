import React, {useState} from 'react';
import {handleSelectSuggest} from "./redux";
import {connect} from "react-redux";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import { GoLocation } from 'react-icons/go';

//https://www.youtube.com/watch?v=rmIhGPy8rSY&t=1s
const NOMINATIM_BASE_URL = 'https://nominatim.openstreetmap.org/search?';

const Geolocation = (props) => {

    const [place_value, setPlaceValue] = useState(props.place?props.place:'');
    const [listPlaces, setListPlaces] = useState([]);
    const [isResultPlaces, updateResultPlaces] = useState(false);

    const getGeoLocation = ev => {
        let place = ev.target.value;
        setPlaceValue(place);

        const params = {
            q: place,
            format: 'json',
            addressdetails: 1,
            polygon_geojson: 0,
            countrycodes: 'de'
        }

        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        }

        const queryString = new URLSearchParams(params).toString();

        fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
            .then(res => res.text())
            .then(result => {
                console.log('RESULT', JSON.parse(result));
                setListPlaces(JSON.parse(result));
                updateResultPlaces(true);
            })
            .catch(err => console.log('ERROR', err));

    }

    const selectPlace = ev => {
        let selected_place = ev.currentTarget.getAttribute('place');
        let new_address = selected_place.replace(', Germany', "");
        const lat = ev.currentTarget.getAttribute('lat');
        const lon = ev.currentTarget.getAttribute('lon');
        updateResultPlaces(false);
        setPlaceValue(new_address);

        let dataOfPlace = {
            place: new_address,
            lat: lat,
            lon: lon
        }

        props.handleSelectSuggest(dataOfPlace);
    }


    return (
        <div id='inputplz'>
            <InputGroup>
                <Form.Control
                    placeholder="PLZ oder Ort hier eingeben"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={place_value}
                    onChange={getGeoLocation}
                />
            </InputGroup>
            <div className="listplace-wrapper">
                {listPlaces.length>0&&place_value&&isResultPlaces&&
                listPlaces.map((place, index) => {
                    return (
                        <ListGroup key={index} >
                            <ListGroup.Item
                                className="list-items"
                                onClick={selectPlace}
                                lat={place.lat}
                                lon={place.lon}
                                place={place.display_name}
                            >
                                <span className="icon"><GoLocation /></span>
                                <div className="desc-wrapper">
                                    <p className="titel_place place">{place.address.suburb}</p>
                                    <p className="desc_place place">{place.display_name}</p>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    )
                })}
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        place: state.place
    }
}

const mapDispatchToProps = dispatch => {
    return{
        handleSelectSuggest: ev => dispatch(handleSelectSuggest(ev))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Geolocation);