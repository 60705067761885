import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {alleBlumen} from "../data/floristik";
import { FaBars } from "react-icons/fa";

const Floristik = props => {

    const [blumen, setAlleBlummen] = useState(alleBlumen);

    const filterBlumen = (produkt) => {
        setAlleBlummen(alleBlumen.filter(elem => elem.category === produkt))
      }
      
     const filterAlleBlumen = (ev) => {
        let targetElementIdent = ev.target.getAttribute("ident");
        filterBlumen(targetElementIdent);
      }

      const openMobileMenu = () => {
        let productMobileMenu = document.getElementById("productMobileMenu");
   
        productMobileMenu.classList.toggle("productMobileMenuActive");
    }

    return (
        <Modal
        size="xl"
        show={props.showFloristik}
        onHide={props.closeFloristik}
        aria-labelledby="example-modal-sizes-title-lg"
       >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
          <p className="modalTitle">Floristikauswahl</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FaBars onClick={openMobileMenu} id="hamburgericonProduct" />
        <div id="productMobileMenu" className="d-flex flex-wrap justify-content-center position-auswahl py-2 mt-1">
             <p className="productLinkAuswahl" ident="gesteck" onClick={filterAlleBlumen} >Gestecke</p>
             <p className="productLinkAuswahl" ident="kranz" onClick={filterAlleBlumen} >Kränze</p>
             <p className="productLinkAuswahl" ident="sarg" onClick={filterAlleBlumen}>Sargschmuck</p>
             <p className="productLinkAuswahl" ident="tisch" onClick={filterAlleBlumen}>Tischdeko</p>
             <p className="productLinkAuswahl" ident="us" onClick={filterAlleBlumen}>Urnenschmuck</p>

            {/*<div className="d-flex flex-column w-75 mt-3">
                 <p className="mt-1 mb-0 pBudget">Verzierung mit Schleife</p>
                 <input className="mt-1" value={this.props.bouquetErsteSchleife} onChange={this.props.addBouquetErsteSchleife} type="text" placeholder="Ihr Text für 1. Schleife:" />
                 <input className="my-2" value={this.props.bouquetZweiteSchleife} onChange={this.props.addBouquetZweiteSchleife} type="text" placeholder="Ihr Text für 2. Schleife:" />
             </div>*/} 
        </div>
        <div className="d-flex flex-wrap justify-content-center">
         {blumen.map((elem, index) => {
           return(
               <div key={index} className="col-sm-12 col-md-5 col-lg-5 card m-3 p-0 cardBlumen">
                  <img src={elem.img} className="card-img-bottom imgSarg" alt={elem.titel} />
                  <span className="productInfo">{elem.titel}</span>
                  <span className="productInfo">Art-Nr: {elem.art_nr}</span>
                  <span className="productInfo mb-3">Preis: {elem.price.toFixed(2)} EUR</span>
                  <button onClick={() => props.addFloristik(elem)} className="btn mb-2 buttonAuswahlSarg">Auswählen</button>
               </div>
           )
          })}
         </div>
        </Modal.Body>
      </Modal>
    )
}

export default Floristik;