import React from "react";
import Modal from 'react-bootstrap/Modal';
import begleitete_seebestattung from "../../images/begleitete_seebestattung/begleitete_seebestattung.jpg";

const Begleitete_Seebestattung = props => {
    return(
        <Modal
        size="lg"
        show={props.showBegleiteteSeebestattung}
        onHide={props.closeBegleiteteSeebestattung}
        aria-labelledby="example-modal-sizes-title-lg"
       >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
          <p className="modalTitle">Begleitete Seebestattung</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="trauerfeierWrapper">
            <ul className="ulTrauerFeierWrapper2">
                    <li> das gesamte Schiff steht für die Zeit der Beisetzungsfahrt alleine zur Verfügung</li>
                    <li>die Urne wird bereits vor Eintreffen der Familie und Freunde an Bord gebracht</li>
                    <li>die Angehörigen werden vom Kapitän an Bord begrüßt und erhalten einen genauen Überblick über den Ablauf der Fahrt</li>
                    <li>Getränke und Speisen können vorab bei uns bestellt werden</li>
                    <li>die Flagge wird auf Halbmast gesetzt, dann legt das Schiff ab und nimmt Kurs auf die Beisetzungsposition </li>
                    <p className="my-2">Preis: Auf Anfrage</p>
    
                    <div className="d-flex">
                     {props.toggleButtonsSee ?
                       <button className="btn buttonAngebot mr-3" onClick={props.addBegleiteteSeebstattung}>Hinzufügen</button>
                      :
                      <button className="btn btn-danger mr-3" onClick={props.deleteBegleiteteSeebestattung}>Entfernen</button>
                     }
                    </div>
              </ul>
              <img className="trauerfeierImg" alt="" src={begleitete_seebestattung} />

          </div>
            
        </Modal.Body>
      </Modal>
    )
}

export default Begleitete_Seebestattung;