import React, {Component} from "react";
import {Navbar, Nav} from "react-bootstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {Link, Redirect} from "react-router-dom";
import "../style/menu.css";
// import { IoIosAddCircleOutline  } from "react-icons/io";
import { GoFlame } from "react-icons/go";
import { GiPineTree } from "react-icons/gi";
import { FaWater, FaLeaf, FaQuestionCircle, FaBars, FaPhoneSquare } from "react-icons/fa";
import {connect} from "react-redux";
import {getBestattungsArt} from "./redux";
import jsPDF from 'jspdf';
import logoImg from "../images/logo/logo.jpg";
import AddPostcode from "./ProductModals/addPostcode";


class Menu extends Component{

  constructor(props) {
  super(props);

  this.toggle = this.toggle.bind(this);
  this.showBestattungen = this.showBestattungen.bind(this);
  this.toogleNavMobile = this.toogleNavMobile.bind(this);
  this.toggleMobil = this.toggleMobil.bind(this);
  this.toogleBestattungMobile = this.toogleBestattungMobile.bind(this);
  this.toogleNavMobileCloseMenu = this.toogleNavMobileCloseMenu.bind(this);
  this.toggleWissen = this.toggleWissen.bind(this);
  this.toggleWissenMobile = this.toggleWissenMobile.bind(this);
  this.vollmachtDownload = this.vollmachtDownload.bind(this);
  this.closePostcode = this.closePostcode.bind(this);
  this.showPostcodeBox = this.showPostcodeBox.bind(this);
  this.updateLink = this.updateLink.bind(this);
  this.showPostcodeBoxMobile = this.showPostcodeBoxMobile.bind(this)


  this.state = {
    dropdownOpen: false,
    dropdownOpenWissen: false,
    collapseFeuer: false,
    toogleNavMobilex: false,
    dropdownOpenMobil: false,
    dropdownOpenWissenMobile: false,
    showAddPostcode: false,
    link: "",
    isSeeLink: false,
    isFeuerLink: false,
    isAnonymLink: false,
    isBaumLink: false,
    isErdLink: false
  };
}

componentWillUnmount() {
  this.setState({isSeeLink: false,
                 isFeuerLink: false,
                 isAnonymLink: false,
                 isBaumLink: false,
                 isErdLink: false
  })
}

updateLink(){

  switch(this.state.link){
   case "Seebestattung":
     this.setState({isSeeLink: true})
    break;
    case "Einfache Feuerbestattung":
     this.setState({isFeuerLink: true})
    break;
    case "Anonyme Bestattung":
     this.setState({isAnonymLink: true})
    break;
    case "Baumbestattung":
     this.setState({isBaumLink: true})
    break;
    case "Erdbestattung":
     this.setState({isErdLink: true})
    break;
     default:
       return this.state.link;
  }
  let ulNavMobile = document.querySelector(".ulNavMobile");
  ulNavMobile.classList.toggle("ulNavMobileActive");

}

closePostcode(){
  this.setState({showAddPostcode: false})
}

showPostcodeBox(ev){
  let linkArt = ev.target.getAttribute("link");

  setTimeout(() => {
    this.setState({link: linkArt})
  }, 200)

  this.setState({showAddPostcode: true,
                })

}

showPostcodeBoxMobile(ev){
  let linkArt = ev.target.getAttribute("link");

  this.setState({link: linkArt})

  this.setState({showAddPostcode: true,})

}



toggle() {
  if(this.state.collapseFeuer === true){
     this.setState(prevState => ({
      collapseFeuer: prevState.collapseFeuer = false
     }))
  }
  this.setState(prevState => ({
    dropdownOpen: !prevState.dropdownOpen,
  }));
}

toggleWissen() {

  this.setState(prevState => ({
    dropdownOpenWissen: !prevState.dropdownOpenWissen
  }));

}

toggleWissenMobile() {

  this.setState(prevState => ({
    dropdownOpenWissenMobile: !prevState.dropdownOpenWissenMobile
  }));

}

toggleMobil() {
  if(this.state.collapseFeuer === true){
     this.setState(prevState => ({
      collapseFeuer: prevState.collapseFeuer = false
     }))
  }
  this.setState(prevState => ({
    dropdownOpen: !prevState.dropdownOpen,
  }));
}

showBestattungen() {
  this.setState(prevState => ({
    collapseFeuer: !prevState.collapseFeuer,
    dropdownOpen: !prevState.dropdownOpen
  }));
}

toogleNavMobile(){
   let ulNavMobile = document.querySelector(".ulNavMobile");
  ulNavMobile.classList.toggle("ulNavMobileActive");

}



toogleBestattungMobile(){
  this.setState(prevState => ({
    dropdownOpenMobil: !prevState.dropdownOpenMobil
   }))
}

toogleNavMobileCloseMenu(){
  this.setState(prevState => ({
    dropdownOpenMobil: !prevState.dropdownOpenMobil,
    toogleNavMobilex: !prevState.toogleNavMobilex
   }))
}

vollmachtDownload(){

    var pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(logoImg, 'SVG',  90, 7);
    pdf.setFontSize(20);            // Schriftgröße
    pdf.setFont("times")
    pdf.setTextColor(0, 0, 0);    // Textfarbe als RGB-Werte
    pdf.setFontSize(17);
    pdf.text ("Vollmacht", 93, 55);

    //pdf.setTextColor(0, 0, 0);
          pdf.setFontSize(15);
          pdf.text ("Bestattungspflichtige(r)", 20, 67);

          var vollmachtDataPflichtigerLinks =
                               `<p>Name:</p>`+
                               `<p>geboren am:</p>`+
                               `<p>Anschrift:</p>`+
                               `<p>Geburtsort:</p>`
          var vollmachtDataPflichtigerRechts =
                               `<p>Vorname:</p>`+
                               `<p>Verwandschaftsverhältnis:</p>`+
                               `<p>&nbsp;</p>`+
                               `<p>Telefon:</p>`

        pdf.fromHTML(
              vollmachtDataPflichtigerLinks,
              20, // x coord
              67, // margin top coord
              {
                // y coord
                width: 100// max width of content on PDF
              },
            );
        pdf.fromHTML(
              vollmachtDataPflichtigerRechts,
              120, // x coord
              67, // margin top coord
              {
                // y coord
                width: 100// max width of content on PDF
              },
            );
        var text_1 = "Ich der / die Unterzeichner/ in, beauftrage Sidereum Bestattungen, die Beisetzung von:";
        pdf.setFontSize(12);
        pdf.text (text_1, 20, 110);
        pdf.setFontSize(15);
        pdf.text ("Verstorbener", 20, 120);

        var vollmachtDataVerstorbenerLinks =
                                          `<p>Name:</p>`+
                                          `<p>geboren am:</p>`+
                                          `<p>Letzte Anschrift:</p>`+
                                          `<p>Geburtsort:</p>`+
                                          `<p>Beruf:</p>`+
                                          `<p>Familienstand:</p>`+
                                          `<p>Krankenkasse:</p>`
                                          //`<p>Benötigte Unterlagen (im Original): ${unterlagen}${russianDoc}</p>`
        var vollmachtDataVerstorbenerRechts =
                                          `<p>Vorname:</p>`+
                                          `<p>verstorben am:</p>`+
                                          `<p>Abholanschrift:</p>`+
                                          `<p>Religion:</p>`+
                                          `<p>Kinder:</p>`+
                                          `<p>Rentennummer:</p>`+
                                          `<p>Anzahl der Sterbeurkunden:</p>`+
                                          `<p>Gewicht / Größe:</p>`

        pdf.fromHTML(
              vollmachtDataVerstorbenerLinks,
              20, // x coord
              121, // margin top coord
              {
                // y coord
                width: 90// max width of content on PDF
              },
            );

        pdf.fromHTML(
          vollmachtDataVerstorbenerRechts,
              120, // x coord
              121, // margin top coord
              {
                // y coord
                width: 90// max width of content on PDF
              },
            );

        var text_2 = "Sidereum Bestattungen ist berechtigt, alle notwendigen Schritte zur Beisetzung einzuleiten.";
        var text_3 = "Die Personenstandsurkunden müssen innerhalb von 3 Tagen Sidereum Bestattungen ";
        var text_4 = "oder dem zuständigen Standesamt vorliegen.";
        var text_5 = "Für alle Kosten der Bestattung und der Bestattungsgebühren komme ich im vollen Umfang auf.";
        var text_6 = "Ich habe die AGB zur Kenntnis genommen und akzeptiert.";

        pdf.setFontSize(12);
        pdf.text (text_2, 20, 221);
        pdf.text (text_3, 20, 226);
        pdf.text (text_4, 20, 231);
        pdf.text (text_5, 20, 236);
        pdf.text (text_6, 20, 241);

        /* Ort, Datum und Unterschrift */
        pdf.setLineWidth(.3);
        pdf.line(20, 257, 80, 257);
        pdf.line(100, 257, 160, 257);

        pdf.text ("Ort, Datum", 20, 262);
        pdf.text ("Unterschrift", 100, 262);


    //Anschriftblock
    pdf.setFontSize(9);
    var anschriftBlock1 = "Sidereum Bestattungen UG\nAmtsgericht Rostock\nUst.IdNr: 086/117/03156\nGeschäftsführer: René Walek";
    var anschriftBlock2 = "Anschrift\nSidereum Bestattungen\nLerchenberg 1\n18292 Krakow am See";
    var anschriftBlock3 = "Tel: 0152 22003687\nWeb: www.sidereum-bestattungen.de\nE-Mail: info@sidereum-bestattungen.de";

    pdf.setLineWidth(.2);
    pdf.line(20, 268, 190, 268);
    pdf.text (anschriftBlock1, 20, 273);
    pdf.text (anschriftBlock2, 80, 273);
    pdf.text (anschriftBlock3, 140, 273);


    /*  Seitenanzahl */
    pdf.setFontSize(10);
    /*  Seitenanzahl */
    pdf.text("Seite 1/1",  95, 290);
    pdf.save('Vollmacht.pdf');
}



render(){
  return(
    <>
      <AddPostcode
              showAddPostcode={this.state.showAddPostcode}
              closePostcode={this.closePostcode}
      />
      <div id="closePostcode" onClick={this.closePostcode}></div>

              {this.state.isSeeLink && <Redirect to="/seebestattung"></Redirect>}
              {this.state.isFeuerLink && <Redirect to="/einfache_feuerbestattung"></Redirect>}
              {this.state.isAnonymLink && <Redirect to="/anonyme_bestattung"></Redirect>}
              {this.state.isBaumLink && <Redirect to="/friedwald"></Redirect>}
              {this.state.isErdLink && <Redirect to="/erdbestattung"></Redirect>}

              <div onClick={this.updateLink} id="updateLinkNavbar"></div>

        <div className="navbarMenu">

        {/* for mobile */}
        <div className="d-flex flex-column justify-content-center align-items-center">
                <FaBars onClick={this.toogleNavMobile}  id="hamburgericon" />

                      <ul className="ulNavMobile">
                      <Link onClick={this.toogleNavMobile}  className="dropMenu" to="/"> <li className="px-3 my-2 dropMenu">Startseite</li></Link>
                      <Link onClick={this.toogleNavMobile} className="px-3 my-auto dropMenu" to="/vorsorge"> <li>Vorsorge</li></Link>

                      <Dropdown className="subDrop subDropMobile" isOpen={this.state.dropdownOpenMobil} toggle={this.toogleBestattungMobile}>
                                      <DropdownToggle  color="transparent" className="dropMenu" caret>
                                        Bestattungsarten
                                      </DropdownToggle>
                              <DropdownMenu id="menuDropDownProduct">
                                    {this.state.dropdownOpenMobil && <div className="d-flex flex-column bestattungsArtMobil">
                                          {this.props.plzCity === "" ?
                                            <span  onClick={this.showPostcodeBoxMobile}  link="Erdbestattung" className="dropdown-item dropDownItem"><FaLeaf  className="iconMenu" />Erdbestattung</span>
                                            :
                                            <Link onClick={this.toogleNavMobile}  className="dropdown-item dropDownItem" to="/erdbestattung">
                                              <li className="mobileBestattung"><FaLeaf  className="iconMenu" />Erdbestattung</li>
                                            </Link>
                                     }

                                    {this.props.plzCity === "" ?
                                                <span onClick={this.showPostcodeBoxMobile} link="Seebestattung"  className="dropdown-item dropDownItem"><FaWater className="iconMenu" />Seebestattung</span>
                                                  :
                                                 <Link onClick={this.toogleNavMobile} className="dropdown-item dropDownItem" to="/seebestattung">
                                                   <li className="mobileBestattung"><FaWater className="iconMenu" />Seebestattung</li>
                                                </Link>
                                      }
                                    {this.props.plzCity === "" ?
                                                  <span onClick={this.showPostcodeBoxMobile} link="Anonyme Bestattung"  className="dropdown-item dropDownItem"><FaQuestionCircle   className="iconMenu" />Anonyme Bestattung</span>
                                                    :
                                                  <Link onClick={this.toogleNavMobile}  className="dropdown-item dropDownItem" to="/anonyme_bestattung">
                                                     <li className="mobileBestattung"><FaQuestionCircle   className="iconMenu" />Anonyme Bestattung</li>
                                                </Link>
                                    }

                                   {this.props.plzCity === "" ?
                                                  <span  onClick={this.showPostcodeBoxMobile} link="Baumbestattung"  className="dropdown-item dropDownItem"><GiPineTree  className="iconMenu" />Baumbestattung</span>
                                                    :
                                                  <Link onClick={this.toogleNavMobile}   className="dropdown-item dropDownItem" to="/friedwald">
                                                    <li className="mobileBestattung"><GiPineTree  className="iconMenu" />Baumbestattung</li>
                                                  </Link>
                                              }
                                     {this.props.plzCity === "" ?
                                                    <span onClick={this.showPostcodeBoxMobile} link="Einfache Feuerbestattung"  className="dropdown-item dropDownItem"><GoFlame className="iconMenu" />Feuerbestattung </span>
                                                    :
                                                    <Link onClick={this.toogleNavMobile}   className="dropdown-item dropDownItem" to="/einfache_feuerbestattung">
                                                      <li className="mobileBestattung"><GoFlame className="iconMenu" />Feuerbestattung </li>
                                                   </Link>
                                    }
                                  </div>}

                                </DropdownMenu>
                        </Dropdown>

                      {/*<Link  className="px-3 my-auto dropMenu" to="/service"> <li onClick={this.toogleNavMobile}>Unser Service</li></Link>*/}
                      {/*<Link className="px-3 my-auto dropMenu" to="/ueber_uns"> <li onClick={this.toogleNavMobile}>Über uns</li></Link>*/}
                      <Dropdown className="subDrop subDropMobile" isOpen={this.state.dropdownOpenWissenMobile} toggle={this.toggleWissenMobile}>
                                      <DropdownToggle  color="transparent" className="dropMenu" caret>
                                        Gut zu wissen
                                      </DropdownToggle>
                                      <DropdownMenu className="dropDownMobileMenu">
                                      {/* <DropdownItem >
                                          <Link onClick={this.toogleNavMobile}  to="/ratenzahlung"> <span className="mobileBestattung">Ratenzahlung</span></Link>
                                      </DropdownItem> */}
                                        <DropdownItem >
                                            <span onClick={this.vollmachtDownload} className="mobileBestattung">Vollmacht herunterladen</span>
                                        </DropdownItem>

                                        <DropdownItem >
                                          <Link onClick={this.toogleNavMobile}  to="/bestattungsgesetze"> <span className="mobileBestattung">Bestattungsgesetze</span></Link>
                                      </DropdownItem>

                                      </DropdownMenu>
                          </Dropdown>
                        <Link onClick={this.toogleNavMobile} className="px-3 my-auto dropMenu" to="/kontakt"> <li>Kontakt</li></Link>
                        <span id="hotlineServiceMobile"><FaPhoneSquare className="navIconPhone"/>24h 0152 22003687</span>
                      </ul>
                </div>


        {/* for other devices */}
                    <Navbar id="navbarScreen" collapseOnSelect expand={'sm'}  className="m-auto"  variant="dark">
                          <Navbar.Toggle  className="m-auto" aria-controls="responsive-navbar-nav" />
                          <Navbar.Collapse id="responsive-navbar-nav" >
                            <Nav className="mr-auto">
                                <Link  className="px-3 my-auto dropMenu" to="/">Startseite</Link>
                                <Link className="px-3 my-auto dropMenu" to="/vorsorge">Vorsorge</Link>

                                  <Dropdown className="subDrop" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                      <DropdownToggle  color="transparent" className="dropMenu" caret>
                                        Bestattungsarten
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <DropdownItem className="dropDownItem" >
                                        {this.props.plzCity === "" ?
                                            <span  onClick={this.showPostcodeBox} link="Erdbestattung" className="dropdown-item dropDownItem"><FaLeaf  className="iconMenu" />Erdbestattung</span>
                                            :
                                            <Link  className="dropdown-item dropDownItem" to="/erdbestattung">
                                              <span  className="iconMenuWrapper"><FaLeaf  className="iconMenu" />Erdbestattung</span>
                                            </Link>
                                        }
                                        </DropdownItem>

                                        <DropdownItem className="dropDownItem">
                                            {this.props.plzCity === "" ?
                                                <span onClick={this.showPostcodeBox} link="Seebestattung"  className="dropdown-item dropDownItem"><FaWater className="iconMenu" />Seebestattung</span>
                                                  :
                                                 <Link className="dropdown-item dropDownItem" to="/seebestattung">
                                                   <span  className="iconMenuWrapper"><FaWater className="iconMenu" />Seebestattung</span>
                                                </Link>
                                          }
                                        </DropdownItem>

                                      <DropdownItem className="dropDownItem">
                                            {this.props.plzCity === "" ?
                                                  <span onClick={this.showPostcodeBox} link="Anonyme Bestattung"  className="dropdown-item dropDownItem"><FaQuestionCircle   className="iconMenu" />Anonyme Bestattung</span>
                                                    :
                                                  <Link  className="dropdown-item dropDownItem" to="/anonyme_bestattung"><span className="iconMenuWrapper"><FaQuestionCircle   className="iconMenu" />Anonyme Bestattung</span></Link>
                                            }
                                      </DropdownItem>

                                      <DropdownItem className="dropDownItem">
                                              {this.props.plzCity === "" ?
                                                  <span  onClick={this.showPostcodeBox} link="Baumbestattung"  className="dropdown-item dropDownItem"><GiPineTree  className="iconMenu" />Baumbestattung</span>
                                                    :
                                                  <Link  className="dropdown-item dropDownItem" to="/friedwald"><span  className="iconMenuWrapper"><GiPineTree  className="iconMenu" />Baumbestattung</span></Link>
                                              }
                                      </DropdownItem>

                                      <DropdownItem className="dropDownItem">
                                               {this.props.plzCity === "" ?
                                                    <span onClick={this.showPostcodeBox} link="Einfache Feuerbestattung"  className="dropdown-item dropDownItem"><GoFlame className="iconMenu" />Feuerbestattung </span>
                                                    :
                                                    <Link  className="dropdown-item dropDownItem" to="/einfache_feuerbestattung"><span  className="iconMenuWrapper"><GoFlame className="iconMenu" />Feuerbestattung </span></Link>
                                                }
                                      </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>

                          {/*<Link className="px-3 my-auto dropMenu" to="/service">Unser Service</Link>*/}
                          {/*<Link className="px-3 my-auto dropMenu" to="/ueber_uns">Über uns</Link>*/}

                          <Dropdown className="subDrop" isOpen={this.state.dropdownOpenWissen} toggle={this.toggleWissen}>
                                      <DropdownToggle  color="transparent" className="dropMenu" caret>
                                        Gut zu wissen
                                      </DropdownToggle>
                                      <DropdownMenu>

                                      {/* <DropdownItem className="dropDownItem" >
                                          <Link className="dropdown-item dropDownItem" to="/ratenzahlung"><span className="iconMenuWrapper">Ratenzahlung</span></Link>
                                        </DropdownItem> */}

                                        <DropdownItem className="dropDownItem" >
                                              <span onClick={this.vollmachtDownload}  className="dropdown-item dropDownItem">Vollmacht herunterladen</span>
                                        </DropdownItem>

                                        <DropdownItem className="dropDownItem" >
                                          <Link className="dropdown-item dropDownItem" to="/bestattungsgesetze"><span className="iconMenuWrapper">Bestattungsgesetze</span></Link>
                                        </DropdownItem>

                                      </DropdownMenu>
                          </Dropdown>

                          <Link className="px-3 my-auto dropMenu" to="/kontakt">Kontakt</Link>
                        </Nav>
                      </Navbar.Collapse>
                      <span id="hotlineService"><FaPhoneSquare className="navIconPhone"/>24h 0152 22003687</span>
                    </Navbar>
            </div>
        </>
      )
    }
}

const mapDispatchToProps = dispatch => {
   return{
       getBestattungsArt: ev => dispatch(getBestattungsArt(ev))
   }
}

const mapStateToProps = state => {
   return{
    plzCity: state.plzCity
   }
}

export const MenuContainer = connect(mapStateToProps, mapDispatchToProps)(Menu);
