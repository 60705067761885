export const schleswig_holstein = [
    [   "Erster Abschnitt: Allgemeine Vorschriften", 
        {
            titel: "§ 1 Grundsätze",
            content_1: "Der Umgang mit Leichen und mit der Asche Verstorbener hat mit der gebotenen Würde und mit Achtung vor den Verstorbenen zu erfolgen. Er hat sich auch nach den bekannt gewordenen sittlichen, weltanschaulichen und religiösen Vorstellungen der Verstorbenen zu richten, soweit dadurch Belange des Gemeinwohls, insbesondere des Gesundheits- und Umweltschutzes, nicht gefährdet werden und das sittliche Empfinden der Allgemeinheit nicht verletzt wird."
        },
        {
            titel: "§ 2 Begriffsbestimmungen",
            content_1: "1. Leiche",
            content_2: "Eine Leiche ist der Körper eines Menschen, bei dem sichere Todeszeichen bestehen oder der Tod auf andere Weise zuverlässig festgestellt wurde und bei dem der körperliche Zusammenhalt durch den Verwesungsprozess noch nicht vollständig aufgehoben ist. Kopf oder Rumpf als abgetrennte Teile des Körpers gelten als Leiche. Eine Leiche ist auch das Totgeborene im Sinne der Nummer 4.",
            content_3: "2. Leicheteile",
            content_4: "Leichenteile sind mit Ausnahme des Kopfes und des Rumpfes alle übrigen abgetrennten Körperteile und abgetrennten Organe einer verstorbenen Person.",
            content_5: "3. Infektionsleiche",
            content_6: "Eine Infektionsleiche ist eine verstorbene Person, die an einer meldepflichtigen Krankheit gemäß dem Infektionsschutzgesetz oder einer anderen schweren, übertragbaren Krankheit gelitten hat, die durch die Leiche verbreitet werden kann. Der Krankheit steht der Verdacht gleich, an einer Krankheit im Sinne des Satzes 1 gelitten zu haben.",
            content_7: "4. Totgeborenes",
            content_8: "Ein Totgeborenes ist ein totgeborenes oder in der Geburt verstorbenes Kind mit einem Gewicht von mindestens 500 Gramm, bei dem sich nach vollständigem Verlassen des Mutterleibes kein Lebenszeichen (Herzschlag, natürliche Lungenatmung oder pulsierende Nabelschnur) gezeigt hat. Als Totgeborene gelten auch Feten aus Schwangerschaftsabbrüchen mit einem Gewicht von mindestens 500 Gramm.",
            content_9: "5. Fehlgeburt",
            content_10: "Eine Fehlgeburt ist eine menschliche Leibesfrucht, welche nach vollständigem Verlassen des Mutterleibes kein Lebenszeichen gemäß Nummer 4 aufweist und weniger als 500 Gramm wiegt.",
            content_11: "6. Nichtnatürlicher Tod",
            content_12: "Ein nichtnatürlicher Tod liegt dann vor, wenn der Tod durch Selbsttötung, einen Unglücksfall oder durch andere Einwirkung, bei der ein Verhalten einer oder eines Dritten ursächlich gewesen ist, eingetreten ist.",
            content_13: "7. Ärztliche Person",
            content_14: "Eine ärztliche Person ist eine Ärztin oder ein Arzt, die oder der über eine Approbation oder eine Berufserlaubnis verfügt. Als ärztliche Person im Sinne dieses Gesetzes gilt auch die nach § 3 Abs. 2 ermächtigte andere Person.",
            content_15: "8. Bestattungseinrichtungen",
            content_16: "Bestattungseinrichtungen sind, neben den Leichenräumen nach Nummer 9, auch alle weiteren Räume, Gebäude oder Teile davon, die der Aufbewahrung, Versorgung oder Aufbahrung von Verstorbenen oder der Feuerbestattung dienen. Bestattungseinrichtung ist auch das Schiff, während es für eine Urnenbeisetzung auf See eingesetzt wird.",
            content_17: "9. Leichenräume",
            content_18: "Leichenräume sind die zur Leichenaufbewahrung geeigneten und nur diesem Zweck dienenden Räume auf Friedhöfen, in Kirchen und Krematorien, in medizinischen, medizinisch-wissenschaftlichen und pflegerischen Einrichtungen sowie in Bestattungsunternehmen.",
            content_19: "10. Friedhof",
            content_20: "Ein Friedhof ist ein öffentlicher Bestattungsort mit einer Vielzahl von Grabstätten, der auf einem räumlich abgegrenzten Grundstück eingerichtet und für die Bestattung der irdischen Überreste einer im Voraus unbestimmten Zahl Verstorbener gewidmet ist. Das sind:",
            content_21: "a) staatliche und kommunale Friedhöfe,",
            content_22: "b) kirchliche Friedhöfe als",
            content_23: "aa) Simultanfriedhöfe oder",
            content_24: "bb) konfessionelle Friedhöfe und",
            content_25: "c) private Friedhöfe.",
            content_26: "11. Private Bestattungsplätze",
            content_27: "Private Bestattungsplätze sind einzelne, außerhalb von Friedhöfen gelegene Grabstätten auf solchen Grundstücken oder Grundstücksteilen, in Anlagen oder Gebäuden, die nicht für die allgemeine Bestattung gewidmet sind. Grabstätten in Kirchen und anderen Gotteshäusern gelten als private Bestattungsplätze.",
            content_28: "12. Hinterbliebene",
            content_29: "Hinterbliebene sind die folgenden volljährigen Personen:",
            content_30: "a) die Ehegattin oder der Ehegatte,",
            content_31: "b) die eingetragene Lebenspartnerin oder der eingetragene Lebenspartner,",
            content_32: "c) leibliche und adoptierte Kinder,",
            content_33: "d) Eltern,",
            content_34: "e) Geschwister,",
            content_35: "f) Großeltern und",
            content_36: "g) Enkelkinder",
            content_37: "der verstorbenen Person.",
            content_38: "Soweit das Gesetz den Hinterbliebenen eine Pflicht auferlegt oder ein Recht einräumt, sind sie in der hier bestimmten Reihenfolge zu ihrer Erfüllung verpflichtet oder seiner Wahrnehmung berechtigt; § 9 Abs. 2 Nr. 1 bleibt unberührt.",
            content_39: "13. Todesbescheinigung",
            content_40: "Die Todesbescheinigung ist eine nach einem von der obersten Landesgesundheitsbehörde festgelegten Muster ausgestellte Bescheinigung, die dem Nachweis des Todes und des Todeszeitpunktes, der Todesart und der Todesursache dient; sie dient auch der Erfüllung der Anforderungen des Personenstandsgesetzes und der Aufklärung von Straftaten, die mit dem Tod im Zusammenhang stehen, der Prüfung, ob Maßnahmen des Infektionsschutzes erforderlich sind, sowie Zwecken der Statistik und Forschung."
        }
    ],
    [   "Zweiter Abschnitt: Leichenwesen", 
        {
            titel: "§ 3 Leichenschaupflicht",
            content_1: "(1) Jede Leiche ist zur Feststellung des Todes, des Todeszeitpunktes, der Todesart und der Todesursache ärztlich zu untersuchen (Leichenschau).",
            content_2: "(2) Der Kreis kann für Inseln und Halligen, auf denen keine Ärztin oder kein Arzt ansässig ist, und die verkehrsmäßig schwer zu erreichen sind, abweichend von Absatz 1 zur Vornahme der Leichenschau eine andere geeignete Person ermächtigen.",
            content_3: "(3) Jede niedergelassene ärztliche Person hat im Falle einer Benachrichtigung die Leichenschau unverzüglich selbst durchzuführen; in den Fällen des Absatzes 2 kann sie die andere geeignete Person mit der Leichenschau beauftragen. Bei im Krankenhaus Verstorbenen oder dort Totgeborenen obliegt die Durchführung der Leichenschau den ärztlichen Personen des Krankenhauses. Ärztliche Personen, die sich im Rettungsdiensteinsatz befinden, dürfen sich auf die Feststellung des Todes beschränken. Sie haben die weitere Durchführung der Leichenschau durch eine andere ärztliche Person unverzüglich zu veranlassen.",
            content_4: "(4) Wenn der Wunsch einer verstorbenen Person bekannt ist, dass die Leichenschau von einer ärztlichen Person gleichen Geschlechts durchgeführt wird, soll diesem Wunsch nach Möglichkeit entsprochen werden."
           
        },
        {
            titel: "§ 4 Veranlassung der Leichenschau",
            content_1: "(1) Jede Person, die eine Leiche auffindet, oder in deren Beisein eine Person verstirbt, hat unverzüglich vorbehaltlich des Absatzes 4 eine nach § 3 Abs. 3 zur Leichenschau verpflichtete Person zu benachrichtigen.",
            content_2: "(2) Bei Sterbefällen in Krankenhäusern, in Heimen und in sonstigen Gemeinschaftseinrichtungen, in Betrieben und in öffentlichen Einrichtungen ist auch die Leitung der Einrichtung, in Verkehrsmitteln die Fahrzeugführerin oder der Fahrzeugführer verpflichtet, die Leichenschau zu veranlassen.",
            content_3: "(3) Bei einem Totgeborenen haben die Leichenschau in nachstehender Reihenfolge zu veranlassen:",
            content_4: "1. die ärztliche Person, die bei der Geburt zugegen war,",
            content_5: "2. die Hebamme oder der Entbindungspfleger, die oder der zugegen war und",
            content_6: "3. jede andere Person, die zugegen war oder über das Totgeborene aus eigenem Wissen unterrichtet ist.",
            content_7: "(4) Bei Anhaltspunkten für einen nichtnatürlichen Tod oder nach dem Auffinden der Leiche einer unbekannten Person haben die durch Absatz 1 oder 2 Verpflichteten unverzüglich die Polizei zu benachrichtigen."
        },
        {
            titel: "§ 5 Durchführung der Leichenschau",
            content_1: "(1) Die ärztliche Person hat die Leichenschau an der vollständig entkleideten Leiche durchzuführen, sich dabei Gewissheit über den Eintritt des Todes zu verschaffen sowie Todeszeitpunkt, Todesart und Todesursache möglichst genau festzustellen. Soweit erforderlich, hat sie Personen zu befragen, die die verstorbene Person unmittelbar vor dem Tod behandelten, pflegten oder mit ihr zusammenlebten oder sonstige Kenntnis von den Umständen ihres Todes haben können. Befragte Personen sind zur Auskunft verpflichtet, soweit ihnen ein Aussage- oder Zeugnisverweigerungsrecht nicht zusteht.",
            content_2: "(2) Die Leichenschau soll an dem Ort vorgenommen werden, an dem der Tod eingetreten ist oder die Leiche aufgefunden wurde. Dazu ist die ärztliche Person, die die Leichenschau durchführt, berechtigt, jederzeit den Ort zu betreten, an dem sich die Leiche befindet."
        },
        {
            titel: "§ 6 Ärztliche Mitteilungspflichten",
            content_1: "(1) Ergeben sich vor oder bei Durchführung der Leichenschau Anhaltspunkte für einen nichtnatürlichen Tod oder handelt es sich um eine unbekannte oder nicht sicher zu identifizierende Person, verständigt die ärztliche Person unverzüglich die Polizei. Bis zum Eintreffen der Polizei hat sie von der weiteren Durchführung der Leichenschau abzusehen und keine Veränderungen an der Leiche vorzunehmen. Bereits vorgenommene Veränderungen an der Leiche, an der Lage oder am Fundort der Leiche sind der Polizei mitzuteilen.",
            content_2: "(2) Die ärztliche Person hat Infektionsleichen als solche zu kennzeichnen und den Kreis oder die kreisfreie Stadt unverzüglich zu unterrichten."
        },
        {
            titel: "§ 7 Todesbescheinigung",
            content_1: "(1) Nach Beendigung der Leichenschau stellt die ärztliche Person unverzüglich eine Todesbescheinigung aus.",
            content_2: "(2) Die für den Sterbeort zuständige Behörde prüft die Todesbescheinigungen und bereitet die Angaben daraus für statistische Auswertungen nach dem Bevölkerungsstatistikgesetz in der Fassung der Bekanntmachung vom 14. März 1980 (BGBl. I S. 308), zuletzt geändert durch Artikel 1 des Gesetzes vom 18. Juli 2008 (BGBl. I S. 1290), auf. Die ärztlichen Personen, die die Leichenschau oder die Obduktion ausgeführt haben, sind verpflichtet, auf Anforderung der zuständigen Behörde die Todesbescheinigungen zu vervollständigen. Sie sowie die ärztlichen Personen, die die verstorbene Person zu Lebzeiten behandelt haben, sind zu Auskünften gegenüber der zuständigen Behörde verpflichtet, soweit es sich um Inhalte der Todesbescheinigungen handelt.",
            content_3: "(3) Die für den Wohnort der verstorbenen Person zuständige Behörde kann auf Antrag Auskünfte aus dem vertraulichen Teil der Todesbescheinigungen erteilen oder Einsicht in diese gewähren,",
            content_4: "1. soweit ein rechtliches Interesse glaubhaft gemacht wird und kein Grund zu der Annahme besteht, dass durch die Offenbarung schutzwürdige Interessen der verstorbenen Person oder ihrer Angehörigen beeinträchtigt werden oder",
            content_5: "2. wenn eine wissenschaftliche Einrichtung Angaben aus dem vertraulichen Teil der Todesbescheinigung für Forschungsvorhaben benötigt und",
            content_6: "a) durch vorherige Anonymisierung der Angaben in der Todesbescheinigung sichergestellt wird, dass schutzwürdige Belange der verstorbenen Person oder ihrer Angehörigen nicht beeinträchtigt werden oder",
            content_7: "b) die für den Wohnort der verstorbenen Person zuständige Behörde festgestellt hat, dass ein öffentliches Interesse an dem Forschungsvorhaben das Geheimhaltungsinteresse der verstorbenen Person oder ihrer Angehörigen erheblich überwiegt und der Zweck der Forschung auf andere Weise nicht oder nur mit unverhältnismäßigem Aufwand erreicht werden kann.",
            content_8: "Die Antragstellerin oder der Antragsteller darf personenbezogene Angaben, die sie oder er auf diese Weise erfährt, nur zu dem von ihm im Antrag angegebenen Zweck verwenden. Im Übrigen gilt § 22 Abs. 2 und 5 Satz 1 und Abs. 6 und 7 des Landesdatenschutzgesetzes vom 9. Februar 2000 (GVOBl. Schl.-H. S. 169), zuletzt geändert durch Artikel 2 des Gesetzes vom 15. Februar 2005 (GVOBl. Schl.-H. S. 168), entsprechend.",
            content_9: "(4) Die zuständige oberste Landesbehörde wird ermächtigt, durch Verordnung den Inhalt der Todesbescheinigung, deren Empfänger, die Auswertung nach Absatz 2, die zu beachtenden Datenschutzmaßnahmen und den sonstigen Umgang mit der Todesbescheinigung näher zu regeln.",
            content_10: "(5) Die für den Wohnort zuständige Behörde bewahrt die Todesbescheinigung und die von ausländischen Stellen erhaltenen gleichartigen Bescheinigungen 30 Jahre lang auf.",
            content_11: "(6) Die genannten Stellen dürfen zur Erfüllung ihrer Aufgaben im Rahmen der ihr eingeräumten Befugnisse zur Datenverarbeitung auch Daten im Sinne von Artikel 9 Absatz 1 der Verordnung (EU) 2016/679 1 verarbeiten, soweit dies im Einzelfall zur Erfüllung dieser Aufgaben erforderlich ist. § 12 des Landesdatenschutzgesetzes gilt entsprechend.",
            content_12: "Fußnoten",
            content_13: "1 Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung) (ABl. L 119 S. 1, ber. 2016 ABl. L 314 S. 72)"
        },
        {
            titel: "§ 8 Kosten",
            content_1: "Die Kosten der Leichenschau und der Ausstellung der Todesbescheinigung sind von denjenigen zu tragen, die für die Bestattung zu sorgen haben. Rechtsvorschriften über die Kostentragung durch Dritte bleiben unberührt."
        },
        {
            titel: "§ 9 Leichenöffnung",
            content_1: "(1) Die Leichenöffnung ist ein Eingriff",
            content_2: "1. zur Aufklärung der Todesart, der den Tod bedingenden Grundleiden oder Zusammenhänge und der Todesursache (Obduktion) oder",
            content_3: "2. zu Zwecken der Forschung und Lehre über den Aufbau des menschlichen Körpers (anatomische Leichenöffnung).",
            content_4: "Die Obduktion darf nur von oder unter der Aufsicht von ärztlichen Personen vorgenommen werden, die die Anerkennung zum Führen der Gebietsbezeichnung Pathologie oder Rechtsmedizin besitzen. Eine anatomische Leichenöffnung darf auch von oder unter der Aufsicht von Hochschullehrerinnen oder Hochschullehrern der Anatomie vorgenommen werden.",
            content_5: "(2) Eine Obduktion ist zulässig,",
            content_6: "1. wenn sie zur Verfolgung rechtlicher Interessen der Hinterbliebenen, insbesondere zur Feststellung rentenrechtlicher oder versicherungsrechtlicher Leistungsansprüche, erforderlich ist und ein begründeter schriftlicher Auftrag einer oder eines Hinterbliebenen dazu vorliegt.",
            content_7: "2. aus gewichtigem medizinischem Interesse an der Klärung der Todesursache, an der Überprüfung der ärztlichen Diagnose und Therapie (Qualitätssicherung), der Lehre, der medizinischen Forschung und der Epidemiologie. Weitere Zulässigkeitsvoraussetzung ist, dass die verstorbene Person zu Lebzeiten schriftlich dazu eingewilligt hat, oder, wenn eine Erklärung der verstorbenen Person nicht vorliegt, die oder der entscheidungsberechtigte Hinterbliebene schriftlich eingewilligt hat. Die oder der Hinterbliebene kann ihre oder seine Einwilligung auch mündlich erteilen; hierüber ist ein Protokoll anzufertigen. Die Obduktion darf auch durchgeführt werden, wenn die oder der entscheidungsberechtigte Hinterbliebene nach dokumentierter Information über die beabsichtigte Obduktion und über die Möglichkeit, dieser innerhalb von 24 Stunden ohne Angabe von Gründen zu widersprechen, innerhalb dieser Frist nicht widersprochen hat.",
            content_8: "3. in den Fällen des § 17 Abs. 2 auf Grund Anordnung des Kreises oder der kreisfreien Stadt.",
            content_9: "(3) Eine anatomische Leichenöffnung ist zulässig, wenn",
            content_10: "1. die verstorbene Person zu Lebzeiten schriftlich bestimmt hat, ihren Körper zu Forschungs- oder Demonstrationszwecken einer wissenschaftlich-medizinischen Einrichtung zu überlassen (Körperspenderin oder Körperspender) und",
            content_11: "2. die Leichenschau stattgefunden hat und ein natürlicher Tod vorliegt oder die Staatsanwaltschaft die Leiche freigegeben hat.",
            content_12: "(4) Ergeben sich während der Leichenöffnung Anhaltspunkte für einen nichtnatürlichen Tod, verständigt die ärztliche Person unverzüglich die Polizei. Die Leichenöffnung darf nur mit Zustimmung der Staatsanwaltschaft fortgesetzt werden.",
            content_13: "(5) Die ärztliche Person, die eine Obduktion vorgenommen hat, ergänzt die Todesbescheinigung um die Ergebnisse ihrer Untersuchung und übermittelt die Feststellungen der veranlassenden Stelle oder Person.",
            content_14: "(6) Bei der Leichenöffnung dürfen die zur Untersuchung erforderlichen Organe und Gewebe entnommen werden. Soweit es für die Zwecke nach Absatz 1 erforderlich ist, dürfen Leichenteile zurückbehalten werden.",
            content_15: "(7) Bei der anatomischen Leichenöffnung gilt Absatz 6 Satz 2 auch für die Leiche. Soweit diese nicht mehr für Zwecke nach Absatz 1 Nr. 2 verwendet wird, gilt § 13 Abs. 1 . Abweichend von § 13 Abs. 2 veranlasst die Einrichtung die Bestattung. Bestattungspflichtige haben der Einrichtung die Kosten der Bestattung zu erstatten; eine auf einem anderen Gesetz oder Rechtsgeschäft beruhende Verpflichtung, die Kosten der Bestattung zu tragen, bleibt unberührt."
        },
        {
            titel: "§ 11 Leichenbeförderung",
            content_1: "(1) Leichen sind in verschlossenen, feuchtigkeitsundurchlässigen und widerstandsfähigen Behältnissen ohne vermeidbare Unterbrechung zu befördern.",
            content_2: "(2) Die Beförderung von Leichen im Straßenverkehr zum Bestimmungsort ist mit dafür eingerichteten Sonderkraftfahrzeugen (Bestattungswagen) und ohne vermeidbare Umwege oder Unterbrechungen durchzuführen.",
            content_3: "(3) Die Absätze 1 und 2 gelten nicht für die Bergung von Leichen.",
            content_4: "(4) Die Beförderung einer Leiche von einer Gemeinde in eine andere ist nur zulässig, wenn",
            content_5: "1. eine Todesbescheinigung, eine Sterbeurkunde, eine standesamtliche Bescheinigung über die Beurkundung des Sterbefalles oder eine Genehmigung nach § 39 Satz 1 des Personenstandsgesetzes in der Fassung der Bekanntmachung vom 8. August 1957 (BGBl. I S. 1125), zuletzt geändert durch Gesetz vom 21. August 2002 (BGBl. I S. 3322) oder",
            content_6: "2. in den Fällen des § 159 Abs. 1 der Strafprozessordnung eine Genehmigung nach § 159 Abs. 2 der Strafprozessordnung mitgeführt wird. Dies gilt nicht für eine Überführung im Sinne von Absatz 3, nicht für die Überführung an ein Institut für Anatomie, wenn die Voraussetzungen für eine anatomische Leichenöffnung erfüllt sind, und nicht für eine Überführung in einen Leichenraum nach § 10.",
            content_7: "(5) Für die Beförderung einer Leiche an einen Ort außerhalb des Geltungsbereichs dieses Gesetzes stellt die Gemeinde auf Antrag einen Leichenpass nach einem von der obersten Landesgesundheitsbehörde herausgegebenen Muster aus, wenn ihr die standesamtliche Beurkundung des Todes und die Möglichkeit der Bestattung am Bestimmungsort nachgewiesen sind.",
            content_8: "(6) Bei der Beförderung von Leichen aus dem Ausland in oder durch den Geltungsbereich dieses Gesetzes ist ein Leichenpass oder ein gleichwertiges amtliches Dokument mitzuführen. Wird eine Leiche aus dem Ausland in den Geltungsbereich dieses Gesetzes verbracht, ohne dass die Anforderungen des Satzes 1 erfüllt sind, kann der Kreis oder die kreisfreie Stadt dies schriftlich mit Geltung für ganz Schleswig-Holstein erlauben. Bei Beförderung von Leichen aus anderen Ländern der Bundesrepublik Deutschland in oder durch den Geltungsbereich dieses Gesetzes reicht das Mitführen einer Todesbescheinigung oder einer Sterbeurkunde aus."
        } 
    ],
    [   "Dritter Abschnitt: Bestattungswesen", 
        {
            titel: "§ 12 Bestattungseinrichtungen",
            content_1: "Die Bestattungseinrichtungen sind so zu gestalten und zu betreiben, dass sie den Grundsätzen der Würde und Achtung vor den verstorbenen Personen entsprechen."
        },
        {
            titel: "§ 13 Bestattungspflicht",
            content_1: "(1) Leichen sind zu bestatten. Dies gilt nicht für Totgeborene im Sinne des § 2 Nr. 4 Satz 2 . Diese Totgeborenen sowie Fehlgeburten sind auf Wunsch eines Elternteils zur Bestattung zuzulassen. Zum Nachweis einer Fehlgeburt ist dem Friedhofsträger eine formlose ärztliche Bestätigung vorzulegen. Der Träger der Einrichtung, in der die Geburt erfolgt, die Hebamme oder der Entbindungspfleger, die oder der bei der Geburt zugegen ist, sowie die Träger von Einrichtungen nach § 13 Abs. 1 des Schwangerschaftskonfliktgesetzes vom 27. Juli 1992 (BGBl I S. 1398), zuletzt geändert durch Artikel 1 des Gesetzes vom 21. August 1995 (BGBl. I S. 1050), sollen sicherstellen, dass jedenfalls ein Elternteil auf die Bestattungsmöglichkeit hingewiesen wird.",
            content_2: "(2) Für die Bestattung haben die Hinterbliebenen oder eine von der verstorbenen Person zu Lebzeiten beauftragte Person oder Einrichtung zu sorgen (Bestattungspflichtige). Sind die in Satz 1 genannten Personen nicht vorhanden oder nicht zu ermitteln oder kommen sie ihrer Pflicht nicht nach und veranlasst kein anderer die Bestattung, hat die für den Sterbe- oder Auffindungsort zuständige Gemeinde entsprechend §§ 230 und 238 des Landesverwaltungsgesetzes für die Bestattung zu sorgen. Nach § 2 Nr. 12 Buchst. c bis g vorrangig bestattungspflichtige Hinterbliebene auf demselben Rang haften für die Bestattungskosten als Gesamtschuldner.",
            content_3: "(3) Wenn die Wohnsitzgemeinde, in der die verstorbene Person zuletzt mit Hauptwohnung gemeldet war, bekannt ist, kann die Leiche an die Wohnsitzgemeinde übergeben werden. Die bestattungsrechtliche Zuständigkeit der Gemeinde des Sterbe- oder Auffindungsortes endet mit der Übergabe. Absatz 2 Satz 2 und 3 gilt für die Wohnsitzgemeinde entsprechend. Wird die Leiche nicht an die Wohnsitzgemeinde übergeben, hat diese der Gemeinde des Sterbe- oder Auffindungsortes die von bestattungspflichtigen Hinterbliebenen nicht zu erlangenden Bestattungskosten zu erstatten. Als erstattungspflichtig gelten die Kosten einer jeweils angemessenen und ortsüblichen Bestattung."
        },
        {
            titel: "§ 14 Zulässigkeit der Bestattung",
            content_1: "Zur Bestattung muss die Sterbeurkunde dem Träger des Friedhofs oder dem Seebestatter vorgelegt werden. Ohne Sterbeurkunde darf die verstorbene Person nur mit Genehmigung der Gemeinde bestattet werden. Urnen aus dem Ausland dürfen nur beigesetzt werden, wenn gleichwertige amtliche Dokumente vorliegen. Eine Leiche, die aus dem Ausland überführt worden ist, darf nur nach Vorliegen eines Leichenpasses oder eines gleichwertigen amtlichen Dokuments des Staates, in dem die Person verstorben ist, bestattet werden. § 159 Abs. 2 der Strafprozessordnung bleibt unberührt."
        },
        {
            titel: "§ 15 Bestattungsarten",
            content_1: "(1) Die Bestattung wird durchgeführt",
            content_2: "1. als Erdbestattung auf einem Friedhof in einem Sarg oder",
            content_3: "2. als Einäscherung mit Urnenbeisetzung (Feuerbestattung). Die Urnenbeisetzung erfolgt auf einem Friedhof oder von einem Schiff aus auf See (Seebestattung). § 20 Abs. 4 und § 26 Abs. 3 und 4 , insbesondere die Möglichkeit der Bestattung ohne Sarg, bleiben unberührt.",
            content_4: "(2) Särge und Urnen müssen so beschaffen sein, dass die physikalische, chemische oder biologische Beschaffenheit des Bodens oder des Grundwassers nicht nachteilig verändert wird. Särge müssen sich innerhalb der Ruhezeit zersetzen oder ohne schädliche Rückstände verbrennen.",
            content_5: "(3) Bei der Wahl des Friedhofs sowie Art und Durchführung der Bestattung ist der Wille der verstorbenen Person maßgebend, soweit Rechtsvorschriften oder zwingende öffentliche Belange nicht entgegenstehen. Ist der Wille der verstorbenen Person nicht bekannt oder war die Person bei Abgabe der Erklärung nicht geschäftsfähig, entscheiden die Bestattungspflichtigen. Hat die Gemeinde für die Bestattung zu sorgen, entscheidet sie, soll aber eine Willensbekundung nach Satz 1 berücksichtigen. Bei der Leiche einer unbekannten Person ist nur die Erdbestattung zulässig.",
            content_6: "(4) Die Urnenbeisetzung auf See hat in einem Abstand von mindestens drei Seemeilen zur Küste zu erfolgen. Für die Totenasche sind wasserlösliche und biologisch abbaubare Urnen zu verwenden. Sie dürfen keinerlei Metallteile enthalten. Die Urnen sind so zu verschließen und durch Sand oder Kies zu beschweren, dass sie nicht aufschwimmen können. Unter den Voraussetzungen der Sätze 1 bis 4 ist eine wasserrechtliche Zulassung für das Einbringen der Urne in ein Küstengewässer nach § 2 Abs. 1 , § 3 Abs. 1 Nr. 4 a in Verbindung mit § 32 a Nr. 2 des Wasserhaushaltsgesetzes in der Fassung der Bekanntmachung vom 19. August 2002 (BGBl. I S. 3245), zuletzt geändert durch Artikel 6 des Gesetzes vom 6. Januar 2004 (BGBl. I S. 2), nicht erforderlich. Im Rahmen der Urnenbeisetzung dürfen Gegenstände wie Kränze, Gestecke oder persönliche Gegenstände, die sich nicht zersetzen oder bei denen mit einem längeren Aufschwimmen zu rechnen ist, nicht in das Gewässer eingebracht werden."
        },
        {
            titel: "§ 16 Bestattungsfristen",
            content_1: "(1) Leichen dürfen frühestens 48 Stunden nach Eintritt des Todes bestattet werden; innerhalb von neun Tagen nach Todeseintritt soll die Erdbestattung oder die Einäscherung vorgenommen werden. § 10 Abs. 1 Satz 2 findet Anwendung.",
            content_2: "(2) Für Leichen, die einer Leichenöffnung unterzogen werden sollen, gilt die Bestattungsfrist des Absatzes 1 nicht. Die Gemeinde kann eine Bestattungsfrist bestimmen.",
            content_3: "(3) Urnen sollen innerhalb eines Monats nach der Einäscherung beigesetzt werden. § 10 Abs. 1 Satz 2 gilt entsprechend."
        },
        {
            titel: "§ 17 Einäscherungen",
            content_1: "(1) Vor einer Einäscherung ist eine zweite Leichenschau durch eine ärztliche Person des Öffentlichen Gesundheitsdienstes der Kreise und kreisfreien Städte durchzuführen. Soll die Einäscherung im Ausland erfolgen, haben die nach § 13 Abs. 2 Bestattungsverpflichteten die Durchführung der zweiten Leichenschau zu veranlassen. Die Kreise und kreisfreien Städte können andere ärztliche Personen, die die Anerkennung zum Führen der Gebietsbezeichnung Rechtsmedizin, Pathologie oder Öffentliches Gesundheitswesen besitzen, zur Durchführung der zweiten Leichenschau in ihrem Bezirk allgemein oder im Einzelfall ermächtigen. Sofern Leichen einer anatomische Leichenöffnung unterzogen werden sollen, können die Kreise und kreisfreien Städte ärztliche Personen in einem Institut für Anatomie zur Durchführung der zweiten Leichenschau in ihrem Bezirk allgemein oder im Einzelfall ermächtigen. ",
            content_2: "(2) Treten bei der zweiten Leichenschau Zweifel an der Richtigkeit der in der Todesbescheinigung festgestellten Todesart auf, ist eine Leichenöffnung durchzuführen, wenn die Zweifel auf andere Weise nicht ausgeräumt werden können.",
            content_3: "(3) Wird nach der zweiten Leichenschau zweifelsfrei festgestellt, dass ein Verschulden Dritter an dem Tod ausgeschlossen werden kann, ist eine Bescheinigung über die Freigabe zur Einäscherung auszustellen. Ohne eine solche Bescheinigung ist die Einäscherung nicht zulässig. In den Fällen des § 159 Abs. 2 der Strafprozessordnung gilt dies entsprechend für die Genehmigung der Staatsanwaltschaft.",
            content_4: "(4) Einäscherungen dürfen nur in Anlagen zur Feuerbestattung (Krematorien) vorgenommen werden. Die Einäscherung der verstorbenen Person erfolgt im Sarg. Die Asche aus der Einäscherung einer verstorbenen Person ist einer Urne zuzuordnen und in ihr aufzunehmen. Die Urne ist fest zu verschließen, zu versiegeln und mit den Angaben zur verstorbenen Person nach Absatz 5 Nr. 1 bis 3 zu versehen.",
            content_5: "(5) Die Einäscherung ist von der Betreiberin oder dem Betreiber des sie durchführenden Krematoriums zu dokumentieren. Die Dokumentation hat mindestens die folgenden Angaben und Nachweise zu enthalten:",
            content_6: "1. Vor- und Zunamen der verstorbenen Person,",
            content_7: "2. Geburtsort und Geburtsdatum,",
            content_8: "3. letzter Wohnort,",
            content_9: "4. Nachweis über die Zulässigkeit der Einäscherung nach Absatz 3,",
            content_10: "5. Zeitpunkt der Einäscherung und",
            content_11: "6. Verbleib der Urne.",
            content_12: "(6) Die Dokumentationsunterlagen sind mindestens 30 Jahre aufzubewahren. Bei vorheriger Aufgabe des Betriebs sind sie der Gemeinde zu übergeben."
        },
        {
            titel: "§ 18 Urnenbeisetzung",
            content_1: "Das Krematorium darf eine Urne erst aushändigen, wenn eine ordnungsgemäße Beisetzung gesichert ist. Die Beisetzung gilt als gesichert, wenn die Urne mit der Asche einem Bestattungsunternehmen übergeben wird."
        },
       
    ],
   
    [   "Vierter Abschnitt: Friedhofswesen", 
        {
            titel: "§ 19 Anforderungen an Friedhöfe",
            content_1: "(1) Friedhöfe sind so anzulegen, zu gestalten und zu betreiben, dass sie den Grundsätzen der Würde und Achtung vor den verstorbenen Personen entsprechen.",
            content_2: "(2) Friedhöfe müssen so beschaffen sein, dass sie dem Friedhofszweck, den Erfordernissen des Gewässerschutzes und der öffentlichen Sicherheit, insbesondere der Gesundheit, entsprechen; sie dürfen sonstigen Vorschriften des öffentlichen Rechts nicht widersprechen. Erdbestattungen dürfen nur auf Böden vorgenommen werden, die zur Leichenverwesung geeignet sind und die Fähigkeit haben, die Verwesungsprodukte ausreichend vom Grundwasser und der Außenluft fernzuhalten. Genehmigungserfordernisse nach anderen Rechtsvorschriften bleiben unberührt.",
        },
        {
            titel: "§ 20 Trägerschaft und Betreiben von Friedhöfen",
            content_1: "(1) Träger von Friedhöfen können nur sein:",
            content_2: "1. Gemeinden,",
            content_3: "2. als Körperschaft des öffentlichen Rechts anerkannte Religionsgemeinschaften.",
            content_4: "(2) Die Gemeinden haben sicherzustellen, dass der örtliche Bedarf an Friedhöfen im Umfang der Zulassungspflicht nach § 22 gedeckt ist. Kann ein bestehender öffentlicher Bedarf nicht auf andere Weise befriedigt werden, sind die Gemeinden zum Betreiben eigener Friedhöfe (kommunaler Friedhöfe) verpflichtet; das Gesetz über kommunale Zusammenarbeit in der Fassung vom 28. Februar 2003 (GVOBl. Schl.-H. S. 122), zuletzt geändert durch Gesetz vom 1. Februar 2005 (GVOBl. Schl.-H. S. 66) bleibt unberührt.",
            content_5: "(3) Als Körperschaften des öffentlichen Rechts anerkannte Religionsgemeinschaften können im Rahmen der Gesetze eigene Friedhöfe (kirchliche Friedhöfe) betreiben. Sie haben die beabsichtigte Anlegung und wesentliche Veränderung eines Friedhofs dem Kreis oder der kreisfreien Stadt rechtzeitig und umfassend anzuzeigen. Der Kreis oder die kreisfreie Stadt kann das beabsichtige Vorhaben binnen einer Frist von sechs Monaten untersagen, wenn es den Anforderungen des § 19 Abs. 2 widerspricht.",
            content_6: "(4) Private Bestattungsplätze dürfen nur ausnahmsweise und mit schriftlicher Genehmigung der Gemeinde neu angelegt, erweitert oder belegt werden. Mit der Genehmigung ist eine Ruhezeit festzulegen. §§ 19 und 23 gelten entsprechend."
        },
        {
            titel: "§ 21 Widmung, Schließung und Entwidmung von Friedhöfen",
            content_1: "(1) Die Widmung, Schließung und Entwidmung eines Friedhofs oder eines Teils davon sowie die Friedhofssatzung oder Benutzungsordnung und deren Änderungen sind auf Kosten des Trägers des Vorhabens örtlich bekannt zu machen. Die beabsichtigte Schließung eines kirchlichen Friedhofs ist der betroffenen Gemeinde mindestens zwei Jahre vor dem Schließungszeitpunkt anzuzeigen.",
            content_2: "(2) Eine Entwidmung darf nur erfolgen, wenn alle Ruhezeiten abgelaufen sind. Diese Frist darf ausnahmsweise unterschritten werden, wenn ein dringendes öffentliches Interesse besteht und die Leichen und Urnen, bei denen die Ruhezeit noch nicht abgelaufen ist, vorher umgebettet worden sind."
        },
        {
            titel: "§ 22 Zulassungspflicht",
            content_1: "(1) Auf kommunalen Friedhöfen ist mindestens die Bestattung der verstorbenen Einwohnerinnen und Einwohner sowie derjenigen Personen zu ermöglichen, die innerhalb des Gemeinde- oder Zweckverbandgebiets verstorben sind.",
            content_2: "(2) Auf kirchlichen Friedhöfen ist die Bestattung in einem dem Absatz 1 entsprechenden Umfang auch Nichtangehörigen der Konfession zu ermöglichen, wenn die Gemeinde weder einen eigenen Friedhof unterhalten noch die Bestattung durch Formen der kommunalen Zusammenarbeit sicherstellen kann (Simultanfriedhof). In diesen Fällen hat sich die Gemeinde an den Kosten des Friedhofs zu beteiligen, die nicht durch Gebühren oder Benutzungsentgelte gedeckt werden können."
        },
        {
            titel: "§ 23 Ruhezeit",
            content_1: "(1) Der Friedhofsträger legt nach Anhörung des Kreises oder der kreisfreien Stadt Fristen fest in denen Grabstätten nicht erneut belegt werden dürfen (Ruhezeiten). Die Ruhezeit beginnt mit der Erdbestattung oder Urnenbeisetzung und wird durch eine Umbettung nicht unterbrochen.",
            content_2: "(2) Bei der Festlegung der Ruhezeit für Erdbestattungen ist zumindest die sich aus den jeweiligen Bodenverhältnissen ergebende Verwesungsdauer als Ruhezeit einzuhalten. Im Übrigen sind bei der Festlegung sowie der Gewährung von Verlängerungen der Ruhezeiten das Bedürfnis nach einer angemessenen Dauer der Totenehrung sowie die Freiheit der Religionsausübung zu berücksichtigen.",
            content_3: "(3) Vor Ablauf der Ruhezeit darf in einem Grab keine weitere Erdbestattung vorgenommen werden. Der Kreis oder die kreisfreie Stadt kann auf Antrag des Friedhofsträgers Ausnahmen zulassen."
        },
        {
            titel: "§ 24 Grabgestaltungen",
            content_1: "Grabgestaltungen (Grabkreuze, Grabsteine, Grabdenkmale und andere bauliche Anlagen, Bepflanzungen, Bewuchs und sonstige Grabgegenstände) sind so vorzunehmen, dass die öffentliche Sicherheit nicht gefährdet wird. Die Friedhofsträger können bei Gefährdung der öffentlichen Sicherheit durch Grabgestaltungen die erforderlichen Maßnahmen auf Kosten der Nutzungsberechtigten anordnen oder durchführen. Anordnungen der Gemeinde sind an den Träger des Friedhofs zu richten."
        },
        {
            titel: "§ 25 Ausgrabungen und Umbettungen",
            content_1: "(1) Die Ausgrabung oder die Umbettung einer Leiche ist auf Antrag einer oder eines Hinterbliebenen, der nur im Einvernehmen mit dem Friedhofsträger gestellt werden kann, mit schriftlicher Genehmigung der Gemeinde zulässig. Dem Antrag ist der Nachweis beizufügen, dass eine andere Grabstätte zur Verfügung steht. Für die Ausgrabung und Umbettung von Urnen durch den Friedhofsträger ist eine Genehmigung nicht erforderlich; Satz 2 gilt entsprechend. Andere Rechtsvorschriften bleiben unberührt. Die Gemeinde stellt das Benehmen mit der Gesundheitsbehörde her.",
            content_2: "(2) Ausgrabungen und Umbettungen von Leichen sollen in der Zeit von 14 Tagen bis sechs Monaten nach der Bestattung nicht vorgenommen werden."
        },
        {
            titel: "§ 26 Friedhofsordnung",
            content_1: "(1) Der Träger des Friedhofs regelt die Ordnung, Gestaltung und Benutzung seines Friedhofs, einschließlich der Erhebung von Gebühren oder Benutzungsentgelten, durch eine Friedhofsordnung.",
            content_2: "(2) Die Gemeinden und Zweckverbände erlassen die Friedhofsordnung als Satzung (Friedhofssatzung); wird der Friedhof in privatrechtlicher Form betrieben, ist eine Benutzungsordnung zu erlassen.",
            content_3: "(3) Der Friedhofsträger kann in der Friedhofsordnung für seinen Friedhof insbesondere",
            content_4: "1. die Beisetzung von Urnen in einer Urnenhalle, einer Urnenmauer oder einem Urnenhain zulassen und",
            content_5: "2. unter Wahrung der Belange des Gesundheitsschutzes die Beisetzung von Särgen in Grüften, Grabkammern und Grabgebäuden im Einzelfall erlauben oder generell zulassen.",
            content_6: "(4) Der Friedhofsträger eines kommunalen oder Simultanfriedhofs hat die Bestattung ohne Sarg aus religiösen oder weltanschaulichen Gründen zuzulassen und die Durchführung in der Friedhofsordnung zu regeln sowie den weitergehenden Erfordernissen aus religiösen oder weltanschaulichen Gründen nach Möglichkeit Rechnung zu tragen. Für diese Fälle kann die Bestattung aufgrund von Vereinbarungen auf einem anderen Friedhof in zumutbarer Entfernung gewährleistet werden. Für die verwendete Umhüllung der Leiche gilt § 15 Abs. 2 entsprechend. Auf anderen als kommunalen Friedhöfen oder Simultanfriedhöfen kann diese Bestattungsart unter den Voraussetzungen des Satzes 1 zugelassen werden.",
            content_7: "(5) Der Friedhofsträger hat über erfolgte Bestattungen Buch zu führen. § 17 Abs. 5 Satz 2 Nr. 1 bis 3, 5, 6 und Abs. 6 gilt entsprechend."
        }
    ],

    [   "Fünfter Abschnitt: Bußgeld- und Schlussvorschriften", 
        {
            titel: "§ 27 Aufgaben und Kosten",
            content_1: "(1) Die Kreise und kreisfreien Städte erfüllen ihre Aufgaben nach diesem Gesetz als Träger des Öffentlichen Gesundheitsdienstes als pflichtige Selbstverwaltungsaufgaben. Sie überwachen die Einhaltung dieser Vorschriften sowie die Einhaltung der sich aus §§ 3 , 5 , 7 Abs. 1 bis 3 , § 9 Abs. 4 und § 17 Abs. 1 ergebenden Pflichten Dritter und treffen die nach pflichtgemäßem Ermessen notwendigen Maßnahmen zur Abwehr von Zuwiderhandlungen gegen Rechtsvorschriften.",
            content_2: "(2) Soweit die Aufgaben nach diesem Gesetz nicht nach Absatz 1 übertragen werden, nehmen die Gemeinden sie als pflichtige Selbstverwaltungsaufgaben wahr. Sie treffen die nach pflichtgemäßem Ermessen notwendigen Maßnahmen zur Abwehr von Zuwiderhandlungen.",
            content_3: "(3) Für Amtshandlungen nach diesem Gesetz werden von den Kreisen und Gemeinden Kosten (Gebühren und Auslagen) nach dem Kommunalabgabengesetz und vom Land als Friedhofsträger nach dem Verwaltungskostengesetz des Landes Schleswig-Holstein erhoben."
        },
        {
            titel: "§ 28 Behördliche Befugnisse, Duldungspflichten",
            content_1: "(1) Zur Ausführung und Überwachung der Vorschriften dieses Gesetzes sind Beschäftigte und Beauftragte der Kreise und Gemeinden berechtigt, die Bestattungseinrichtungen, Leichenräume, Friedhöfe, Arbeits-, Betriebs- und Geschäftsräume auf Friedhöfen und im Bestattungsgewerbe sowie Bestattungswagen zu betreten und zu besichtigen.",
            content_2: "(2) Die Inhaberinnen und Inhaber der in Absatz 1 genannten Einrichtungen, Anlagen, Räume und Fahrzeuge haben die Amtshandlungen zu dulden und den Zugang zu ermöglichen. Sie sind verpflichtet, die erforderlichen Auskünfte zu erteilen und die erforderlichen Unterlagen vorzulegen. Die zur Auskunft verpflichtete Person kann die Auskunft auf solche Fragen verweigern, deren Beantwortung sie selbst oder einen in § 52 Abs. 1 der Strafprozessordnung bezeichneten Angehörigen der Gefahr aussetzen würde, wegen einer Straftat oder Ordnungswidrigkeit verfolgt zu werden."
        },
        {
            titel: "§ 29 Ordnungswidrigkeiten",
            content_1: "(1) Ordnungswidrig handelt, wer vorsätzlich oder fahrlässig",
            content_2: "1. die Leichenschau entgegen § 3 Abs. 3 Satz 1 oder Satz 2 nicht oder nicht unverzüglich durchführt oder entgegen § 3 Abs. 3 Satz 4 in Verbindung mit Satz 3 nicht oder nicht unverzüglich veranlasst,",
            content_3: "2. eine ärztliche Person entgegen § 4 Abs. 1 nicht oder nicht unverzüglich benachrichtigt,",
            content_4: "3. entgegen § 4 Abs. 2 oder 3 die Leichenschau nicht veranlasst,",
            content_5: "4. entgegen § 4 Abs. 4 die Polizei nicht unverzüglich benachrichtigt,",
            content_6: "5. als ärztliche Person eine Todesbescheinigung ausstellt, ohne die Leichenschau gemäß § 5 Abs. 1 Satz 1 ordnungsgemäß vorgenommen zu haben,",
            content_7: "6. der ärztlichen Person entgegen § 5 Abs. 1 Satz 3 keine oder falsche Auskünfte erteilt,",
            content_8: "7. als ärztliche Person eine Pflicht entgegen § 6 nicht oder nicht unverzüglich erfüllt,",
            content_9: "8. eine Leichenöffnung vornimmt, ohne dass die Voraussetzungen des § 9 Abs. 2 oder 3 vorliegen,",
            content_10: "9. Leichen entgegen § 11 Abs. 1 oder Abs. 2 transportiert,",
            content_11: "10. entgegen § 13 Abs. 1 Satz 1 , ohne den Tatbestand des § 168 Abs. 1 StGB zu erfüllen, eine Leiche beiseite schafft, um sie der Bestattung zu entziehen,",
            content_12: "11. entgegen § 14 Abs. 1 eine Leiche bestattet, ohne dass eine Sterbeurkunde oder ein Leichenpass oder ein gleichwertiges amtliches Dokument des Staates vorliegt, in dem die Person verstorben ist,",
            content_13: "12. entgegen § 15 Abs. 1 eine Leiche nicht auf einem Friedhof bestattet oder eine Urne nicht auf einem Friedhof oder auf See beisetzt,",
            content_14: "13. entgegen § 7 Abs. 1 eine Todesbescheinigung nicht, nicht richtig oder nicht vollständig ausstellt, oder entgegen § 9 Abs. 5 die Todesbescheinigung nicht um die Ergebnisse der Obduktion ergänzt oder die Feststellungen der veranlassenden Stelle oder Person nicht übermittelt,",
            content_15: "14. entgegen § 15 Abs. 4 einen Abstand von mindestens drei Seemeilen zur Küste nicht einhält oder Urnen verwendet oder Stoffe einbringt oder das Einbringen von Stoffen zulässt, die den Anforderungen nach § 15 Abs. 4 nicht entsprechen,",
            content_16: "15. bei der Beförderung von Leichen ins Ausland zwecks Einäscherung entgegen § 17 Abs. 1 Satz 2 die zweite Leichenschau nicht veranlasst,",
            content_17: "16. entgegen § 17 Abs. 3 eine Einäscherung durchführt, ohne dass die nach § 17 Abs. 3 Satz 1 vorgeschriebene Bescheinigung oder die Genehmigung der Staatsanwaltschaft nach § 17 Abs. 3 Satz 3 vorliegt,",
            content_18: "17. entgegen § 17 Abs. 4 eine Einäscherung außerhalb einer Anlage zur Feuerbestattung (Krematorium) vornimmt oder",
            content_19: "18. private Bestattungsplätze entgegen § 20 Abs. 4 anlegt, erweitert oder belegt.",
            content_20: "(2) Die Ordnungswidrigkeit kann mit einer Geldbuße bis zu 10.000 Euro geahndet werden."

        },
        {
            titel: "§ 30 Einschränkung von Grundrechten",
            content_1: "(1) Dieses Gesetz tritt am Tag nach der Verkündung in Kraft.",
            content_2: "(2) Gleichzeitig treten außer Kraft",
            content_3: "1. das Gesetz über die Feuerbestattung vom 15. Mai 1934 (RGBl. I S. 380) 1) , zuletzt geändert durch Artikel 6 des Gesetzes vom 3. Januar 2003 (GVOBl. Schl.-H. S. 21),",
            content_4: "2. die Verordnung zur Durchführung des Feuerbestattungsgesetzes vom 10. August 1938 (RGBl. I S. 1000) 2) , Zuständigkeiten und Ressortbezeichnungen ersetzt durch Verordnung vom 24. Oktober 1996 (GVOBl. Schl.-H. S. 652),",
            content_5: "3. die Landesverordnung über das Leichenwesen vom 30. November 1995 (GVOBl. Schl.-H. S. 395, ber. 1996 S. 231) 3) , Zuständigkeiten und Ressortbezeichnungen zuletzt ersetzt durch Verordnung vom 16. September 2003 (GVOBl. Schl.-H. S. 503), und",
            content_6: "4. § 2 Buchst. a der Verordnung zur Regelung der Zuständigkeit der Landes- und Kreispolizeibehörden in der Fassung der Bekanntmachung vom 31. Dezember 1971 (Anlage zum Gesetz vom 5. April 1971, GVOBl. Schl.-H. S. 182) 4) , zuletzt geändert durch Verordnung vom 24. Januar 2001 (GVOBl. Schl.-H. S. 18).",
            content_7: "(3) Bestehende Friedhöfe in anderer als nach diesem Gesetz zulässiger Trägerschaft genießen Bestandsschutz. Die Vorschriften dieses Gesetzes sind auf sie entsprechend anzuwenden."
        },
        {
            titel: "Das vorstehende Gesetz wird hiermit ausgefertigt und ist zu verkünden.",
            content_1: "Kiel, 4. Februar 2005",
            content_2: "Heide Simonis, Ministerpräsidentin",
            content_3: "Dr. Gitta Trauernicht, Ministerin für Soziales, Gesundheit und Verbraucherschutz",

        },
    ]
  
]