import React from "react";
import Modal from 'react-bootstrap/Modal';
import { FaCheckCircle } from "react-icons/fa";

const AddedProduct = props => {
    return(
        <Modal
        size="xl"
        show={props.showAddProduct}
        onHide={props.closeModalProduct}
        aria-labelledby="example-modal-sizes-title-sm"
        
      >
        <Modal.Header id="modalHeaderProduct" closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className="modalTitel">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modalBodyProduct">
          <div  className="iconCheckWrapper">
            <FaCheckCircle id="checkIcon" />
            <p className="p-0 m-0"><span id="productDesc">{props.productDesc}</span> hinzugefügt</p>
        </div>
        </Modal.Body>
  </Modal>
    )
}

export default AddedProduct;