import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import "../../style/gesetze.css";
//import { useParams} from "react-router-dom";

const Bestattungsgesetze = (props) => {

   useEffect(() => {
      window.scrollTo(0, 0);
    });

    const stadtGesetze = ["Hamburg", "Berlin", "Mecklenburg Vorpommern", "Schleswig Holstein"];
    return(
        <>
        <div className="bg-gesetz">
           <h2 className="headerTop">Bestattungsgesetze</h2>
        </div>
     <div className="container">
       <p className="p-3 descGesetz">Das Bestattungsrecht ist in der Bundesrepublik Deutschland Sache der Länder, da es dazu weder eine ausschließliche, 
           noch eine konkurrierende Gesetzgebungszuständigkeit des Bundes gibt. Alle deutschen Bundesländer haben eigene, 
           meist ähnliche Bestattungsgesetze erlassen. Sie regeln oft auch Fragen des Friedhofsrechtes und teilweise auch die Obduktion von Leichen. 
           In den meisten Bundesländern gibt es außerdem Ausführungsverordnungen, die ergänzende Regelungen zur Bestattung enthalten.</p>
       <div className="d-flex flex-wrap mb-4">
       {stadtGesetze.map((elem, index) => {
           return(
                   <div key={index} className="d-flex flex-column justify-content-center wrapperGesetze mx-auto m-1 col-sm-12 col-md-6 col-lg-3 mb-5">
                      <h2 className="">{elem}</h2>
                      <Link className="text-center" to={"bestattungsgesetz/"+elem}><button className="btn btn_button px-5 my-4">Weiter</button></Link>
                   </div>
           )
        })
       }
        </div>
     </div>
     </>
    )
}

export default Bestattungsgesetze;